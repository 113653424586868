import React from "react";

export default function DateComp(props) {
  const [state, setState] = React.useState("");

  React.useEffect(() => {
    let mon = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ];
    let d = new Date(props.date);
    // let h = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
    // let m = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
    // let s = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();
    setState(`${d.getDate()} ${mon[d.getMonth()]} ${d.getFullYear()}`);
  }, [props.date]);

  return <div>{state}</div>;
}
