import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import RichUpdateNew from "../common/RichUpdateNew";
import {
  getBracket,
  getBracketPictureList,
  getDoc,
} from "../../actions/editorAction";
import SpinnerSimpleBlue from "../common/SpinnerSimpleBlue";

class EditDocument extends Component {
  componentDidMount() {
    if (!this.props.editor.bracket._id) {
      this.props.getBracket(this.props.match.params.bracket_link);
      this.props.getBracketPictureList(this.props.match.params.bracket_link);
    }
    this.props.getDoc(this.props.match.params.link);
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      if (!this.props.editor.bracket._id) {
        this.props.getBracket(this.props.match.params.bracket_link);
      }
      this.props.getDoc(this.props.match.params.link);
      this.props.getBracketPictureList(this.props.match.params.bracket_link);
    }
  }
  render() {
    //props
    const { auth, editor } = this.props;
    const { doc } = this.props.editor;
    const isAuthenticated = auth.isAuthenticated;
    const { loadingContent, bracket } = editor;
    let itemContent;
    if (loadingContent) {
      itemContent = (
        <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
      );
    } else if (!isAuthenticated) {
      itemContent = (
        <div style={{ textAlign: "center" }}>
          <h4>Please sign in</h4>
        </div>
      );
    } else {
      itemContent = (
        <RichUpdateNew isFromIOT={false} doc={doc} id={bracket._id} />
      );
    }
    return <div>{itemContent}</div>;
  }
}

EditDocument.propTypes = {
  getBracket: PropTypes.func.isRequired,
  getBracketPictureList: PropTypes.func.isRequired,
  getDoc: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  editor: state.editor,
});
export default connect(mapStateToProps, {
  getBracket,
  getBracketPictureList,
  getDoc,
})(EditDocument);
