import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import RichUpdateNew from "../common/RichUpdateNew";
import {
  getDocFolder,
  getDocDataset,
  getFolderPictureList,
  getDatasetPictureList
} from "../../actions/editorAction";
import isEmpty from "../../validation/is-empty";
import isEqual from "../../validation/is-equal";

class EditTask extends Component {
  //Init
  state = {
    doc: {}
  };
  componentDidMount() {
    if (this.props.match.params.task_or_report === "task") {
      this.props.getDocFolder(this.props.match.params.folder);
      this.props.getFolderPictureList(this.props.match.params.folder);
    } else {
      this.props.getDocDataset(this.props.match.params.dataset);
      this.props.getDatasetPictureList(this.props.match.params.dataset);
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const doc_new = !isEmpty(nextProps.editor.doc) ? nextProps.editor.doc : {};
    const doc_state = prevState.doc;
    if (!isEqual(doc_new, doc_state)) {
      return {
        doc: doc_new
      };
    } else return null;
  }
  render() {
    const { doc } = this.state;
    const viewerContent =
      this.props.match.params.task_or_report === "task" ? (
        <RichUpdateNew
          folder={this.props.match.params.folder}
          dataset={this.props.match.params.folder}
          isFromIOT={true}
          task_or_report={this.props.match.params.task_or_report}
          doc={doc}
        />
      ) : (
        <RichUpdateNew
          folder={this.props.match.params.folder}
          dataset={this.props.match.params.dataset}
          isFromIOT={true}
          task_or_report={this.props.match.params.task_or_report}
          doc={doc}
        />
      );
    return <div>{viewerContent}</div>;
  }
}
EditTask.propTypes = {
  //Object
  editor: PropTypes.object.isRequired,
  //Function
  getDocFolder: PropTypes.func.isRequired,
  getDocDataset: PropTypes.func.isRequired,
  getFolderPictureList: PropTypes.func.isRequired,
  getDatasetPictureList: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  item: state.item,
  auth: state.auth,
  editor: state.editor
});
export default connect(mapStateToProps, {
  getDocFolder,
  getDocDataset,
  getFolderPictureList,
  getDatasetPictureList
})(EditTask);
