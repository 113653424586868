import React, {useState} from 'react'
import { Line } from 'react-chartjs-2';
import palette from './../../theme/palette';
import { 
  Grid,
  Container,
  Typography,
  Button,
  ButtonGroup,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  // CircularProgress,
  // FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import { getLogV2 } from './../../actions/itemActions';

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingLeft: 60,
    // paddingRight: 60,
  },
  menuGraph: {
    textAlign: 'center',
  },
  formControl: {
    marginRight: 10,
  },
  label:{
    marginRight: 10,
    fontWeight: 'bold',
  }
}));

export default function LogNew(props) {
  const classes = useStyles();

  const [selectTotal, setSelectTotal] = useState(100);

  const [logData, setLogData] = useState([]);
  const [count, setCount] = useState(50);
  const [deleteData, setDeleteData] = useState('');
  const [deleteLabel, setDeleteLabel] = useState('');

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    console.log(deleteData, deleteLabel)
  };


  const handleChange = (event) => {
    setSelectTotal(event.target.value);
  };

  React.useEffect(() => {
    async function loadData(){
      let param = {
        id: props.match.params.id,
        page: -50,
        total: 50,
        var: 'var0',
      };
      let d = await getLogV2(param);
      let arrLog = [];
      let index;
      for (index in d.log_data) {
        if(d.log_data.hasOwnProperty(index)){
          let name = d.log_data[index].name;
          let arrLabel = [], arrData = [];
          d.log_data[index].values.forEach(element => {
            arrLabel.push(element[1])
            arrData.push(element[0])
          });
          arrLog.push({name, arrData, arrLabel})
        }
      }
      setLogData(arrLog);
    }

    loadData();
  }, [props.match.params.id])

  const graphData = {
    labels: [1,2,3,4,5,6,7,8,9],
    datasets: [
      {
        label: 'data',
        backgroundColor: 'rgba(33, 150, 243, 0.2)',
        borderColor: '#2196f3',
        data: [1,2,3,4,5,6,7,8,9],
      },
    ]
  };

  const graphOptions = {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      legend: { display: false },
      cornerRadius: 20,
      tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.divider,
      backgroundColor: palette.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary
    },
    layout: { padding: 0 },
    scales: {
      xAxes: [
      {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary
        },
          gridLines: {
          display: false,
          drawBorder: false
        }
      }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    }
  };

  const handleLeft = (e) => {
    let w = logData;
    let indek =  (parseInt(e.target.value))
    let _var = 'var' + (indek + 1);
    async function loadData(){
      let param = {
        id: props.match.params.id,
        page: -count,
        total: 50,
        var: _var,
      };
      let d = await getLogV2(param);
      let arrLog = [];
      let index;
      for (index in d.log_data) {
        if(d.log_data.hasOwnProperty(index) && index === _var){
          let name = d.log_data[index].name;
          let arrLabel = [], arrData = [];
          d.log_data[index].values.forEach(element => {
            arrLabel.push(element[1])
            arrData.push(element[0])
          });
          arrLog.push({name, arrData, arrLabel})
        }
      }
      w[indek] = arrLog[0];
      setLogData([]);
      setLogData(w);
    }
    
    loadData();
    setCount(count + 50);
  }

  const handleRight = (e) => {
    let w = logData;
    let indek =  (parseInt(e.target.value))
    let _var = 'var' + (indek + 1);
    async function loadData(){
      let param = {
        id: props.match.params.id,
        page: -(count - 50),
        total: 50,
        var: _var,
      };
      let d = await getLogV2(param);
      let arrLog = [];
      let index;
      for (index in d.log_data) {
        if(d.log_data.hasOwnProperty(index) && index === _var){
          let name = d.log_data[index].name;
          let arrLabel = [], arrData = [];
          d.log_data[index].values.forEach(element => {
            arrLabel.push(element[1])
            arrData.push(element[0])
          });
          arrLog.push({name, arrData, arrLabel})
        }
      }
      w[indek] = arrLog[0];
      setLogData([]);
      setLogData(w);
    }
    
    loadData();
    setCount(count - 50);
  }
  
  return (
    <div>
      <Container className={classes.root}>
        <Grid container >
          {
            logData.map((data, index) => (
              <Grid item md={12} sm={12} xs={12} lg={12} key={index}>
                <Typography variant="h6" align="center">
                  {data.name}
                </Typography>
                <div>
                  <Line
                    data={
                      {
                        labels: graphData.labels,
                        datasets: [
                          {
                            label: `${data.name}`,
                            backgroundColor: 'rgba(33, 150, 243, 0.2)',
                            borderColor: '#2196f3',
                            data: graphData.data,
                          },
                        ]
                      }
                    }
                    options={graphOptions}
                    height={300}
                    onElementsClick={elems => {
                      if(elems.length !== 0){
                        var activePoint = elems[0];
                        var data = activePoint._chart.data;
                        var datasetIndex = activePoint._datasetIndex;
                        // var label = data.datasets[datasetIndex].label;
                        var value = data.datasets[datasetIndex].data[activePoint._index];
                        // console.log(label, value);
                        // console.log(data.labels[activePoint._index]);
                        setDeleteData(value);
                        setDeleteLabel(data.labels[activePoint._index]);
                        handleClickOpen();
                      }
                    }}
                  />
                </div>
                <div className={classes.menuGraph}>
                  <span className={classes.label}>
                    Amount of data : 
                  </span>
                  <FormControl className={classes.formControl}>
                    <Select
                      value={selectTotal}
                      name={index}
                      onChange={handleChange}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value={200}>200</MenuItem>
                      <MenuItem value={300}>300</MenuItem>
                      <MenuItem value={400}>400</MenuItem>
                      <MenuItem value={500}>500</MenuItem>
                    </Select>
                    {/* <FormHelperText>Placeholder</FormHelperText> */}
                  </FormControl>
                  <ButtonGroup variant="contained" color="primary">
                    <Button startIcon={<DoubleArrowIcon style={{transform: 'rotate(180deg)'}} value={index} />} onClick={handleLeft} value={index}></Button>
                    {/* <Button endIcon={<CircularProgress color={'inherit'} size={20} />}></Button> */}
                    <Button endIcon={<DoubleArrowIcon />} onClick={handleRight} value={index}></Button>
                  </ButtonGroup>
                </div>
              </Grid>
            ))
          }
        </Grid>
      </Container>
      <br/>
      <br/>

      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
