import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import ModalComponent from "../common/ModalComponent";
import NumberFormat from "react-number-format";
import isEqual from "../../validation/is-equal";
import {
  geoCancelPayment,
  iotCancelPayment,
  iotConfirmPayment,
  geoConfirmPayment,
  getAdminPayments,
} from "../../actions/adminAction";
import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";
class AdminPayments extends Component {
  state = {
    payment_id: "",
    modal_cancel_iot: false,
    modal_cancel_geo: false,
    modal_confirm_iot: false,
    modal_confirm_geo: false,
    status_clicked: "needconfirm",
  };
  componentDidMount() {
    this.props.getAdminPayments();
  }
  componentDidUpdate(prevProps) {
    const prev_payments =
      prevProps.admin && prevProps.admin.payments
        ? prevProps.admin.payments
        : [];
    const this_payments =
      this.props.admin && this.props.admin.payments
        ? this.props.admin.payments
        : [];
    if (!isEqual(prev_payments, this_payments)) {
      this.props.getAdminPayments();
    }
  }
  handleClickStatus = (status) => {
    this.setState({ status_clicked: status });
  };

  toggleCancelIot = (id) => {
    this.setState({ payment_id: id }, () => {
      this.setState({
        modal_cancel_iot: !this.state.modal_cancel_iot,
      });
    });
  };
  toggleCancelGeo = (id, price) => {
    this.setState({ payment_id: id, price }, () => {
      this.setState({
        modal_cancel_geo: !this.state.modal_cancel_geo,
      });
    });
  };
  toggleConfirmIot = (id, price) => {
    this.setState({ payment_id: id, price }, () => {
      this.setState({
        modal_confirm_iot: !this.state.modal_confirm_iot,
      });
    });
  };
  toggleConfirmGeo = (id, price) => {
    this.setState({ payment_id: id, price }, () => {
      this.setState({
        modal_confirm_geo: !this.state.modal_confirm_geo,
      });
    });
  };
  //1. Cancel IOT
  handleCancelIot = () => {
    const { payment_id } = this.state;
    const status = "admincancel";
    const config = {
      payment_id,
      status,
    };
    this.props.iotCancelPayment(config);
    this.toggleCancelIot();
  };
  //2. Cancel GEO
  handleCancelGeo = () => {
    const { payment_id } = this.state;
    const status = "admincancel";
    const config = {
      payment_id,
      status,
    };
    this.props.geoCancelPayment(config);
    this.toggleCancelGeo();
  };
  //3. Confirm IOT
  handleConfirmIot = () => {
    const { payment_id } = this.state;
    const status = "success";
    const config = {
      payment_id,
      status,
    };
    this.props.iotConfirmPayment(config);
    this.toggleConfirmIot();
  };
  //4. Confirm GEO
  handleConfirmGeo = () => {
    const { payment_id } = this.state;
    const status = "success";
    const config = {
      payment_id,
      status,
    };
    this.props.geoConfirmPayment(config);
    this.toggleConfirmGeo();
  };
  //4. Undo Confirm IOT = GEO
  handleUndoConfirm = (id) => {
    this.setState({ payment_id: id }, () => {
      const { payment_id } = this.state;
      const status = "needconfirm";
      const config = {
        payment_id,
        status,
      };
      this.props.adminConfirmPayment(config);
    });
  };
  render() {
    const { payments, loading } = this.props.admin;
    const {
      status_clicked,
      price,
      modal_cancel_iot,
      modal_cancel_geo,
      modal_confirm_iot,
      modal_confirm_geo,
    } = this.state;
    const needconfirm_payments = payments.filter(
      (payment) => payment.status === "needconfirm"
    );
    const pending_payments = payments.filter(
      (payment) => payment.status === "pending"
    );
    const success_payments = payments.filter(
      (payment) => payment.status === "success"
    );
    const admincancel_payments = payments.filter(
      (payment) => payment.status === "admincancel"
    );
    const usercancel_payments = payments.filter(
      (payment) => payment.status === "usercancel"
    );
    var payments_array = [];
    switch (status_clicked) {
      case "all":
        payments_array = payments;
        break;
      case "needconfirm":
        payments_array = needconfirm_payments;
        break;
      case "pending":
        payments_array = pending_payments;
        break;
      case "success":
        payments_array = success_payments;
        break;
      case "admincancel":
        payments_array = admincancel_payments;
        break;
      case "usercancel":
        payments_array = usercancel_payments;
        break;
      default:
        payments_array = payments;
    }
    const payments_array_iot = payments_array.filter(
      (payment) => payment.quota
    );
    const payments_array_geo = payments_array.filter(
      (payment) => payment.license
    );
    var itemContent;
    var loadingContent;
    const buttonArray = [
      {
        value: "all",
      },
      {
        value: "needconfirm",
      },
      {
        value: "pending",
      },
      {
        value: "success",
      },
      {
        value: "admincancel",
      },
      {
        value: "usercancel",
      },
    ];
    const buttonItem = (
      <div>
        {buttonArray.map(({ value }, idx) => {
          const buttonClass =
            value === status_clicked ? "buttonSimpleRed" : "buttonSimple2";
          return (
            <div key={idx}>
              <div
                className={buttonClass}
                onClick={this.handleClickStatus.bind(this, value)}
              >
                {value}
              </div>
              <br />
            </div>
          );
        })}
      </div>
    );

    if (loading) {
      loadingContent = (
        <SpinnerSimpleLogo
          width={200}
          unik="loading_item_list"
          marginTop="0px"
        />
      );
    } else {
      loadingContent = null;
    }

    itemContent = (
      <div>
        <h1 className="welcomeBig">Payment GEO</h1>
        <div>
          {payments_array_geo.map(
            (
              {
                _id,
                purchase,
                date,
                status,
                picture_url,
                user,
                price,
                license,
                license_num,
              },
              idx
            ) => {
              var confirmButton;
              var cancelButton;
              var imageItem;
              imageItem = !picture_url ? (
                <div>No image</div>
              ) : (
                <div>
                  <img
                    key={`receipt${_id}`}
                    alt={`receipt${_id}`}
                    className={`rounded`}
                    style={{ width: "150px", height: "150px" }}
                    src={picture_url}
                  />
                  <br />
                  <br />
                </div>
              );
              switch (status) {
                case "admincancel":
                  confirmButton = null;
                  cancelButton = (
                    <button
                      className="buttonShadowSmall"
                      id="deleteBright"
                      onClick={this.handleUndoConfirm.bind(this, _id)}
                    >
                      <div>Undo cancel</div>
                    </button>
                  );
                  break;
                case "usercancel":
                  confirmButton = null;
                  cancelButton = null;
                  break;
                case "pending":
                  confirmButton = null;
                  cancelButton = null;
                  break;
                case "needconfirm":
                  confirmButton = (
                    <button
                      className="buttonShadowSmall"
                      id="deleteBright"
                      onClick={this.toggleConfirmGeo.bind(this, _id, price)}
                    >
                      <div>Confirm</div>
                    </button>
                  );
                  cancelButton = (
                    <button
                      className="buttonShadowSmall"
                      id="deleteBright"
                      onClick={this.toggleCancelGeo.bind(this, _id, price)}
                    >
                      <div>Cancel</div>
                    </button>
                  );
                  break;
                default:
                  confirmButton = null;
                  cancelButton = null;
              }
              return (
                <div key={idx} className="specialItem">
                  <div className="four-container">
                    <div>
                      <h1>
                        Rp.
                        <NumberFormat
                          value={price}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h1>
                      {moment(date).format("DD-MMM-YYYY")}
                      <div className="buttonShadowSmall">{status}</div>
                    </div>
                    <div>
                      {purchase.name_bank}
                      {purchase.no_bank}
                      {purchase.type_bank}
                    </div>
                    <div>{user.email}</div>
                  </div>
                  <div>
                    {imageItem}
                    {confirmButton}
                    {cancelButton}
                  </div>
                </div>
              );
            }
          )}
        </div>
        <h1 className="welcomeBig">Payment IOT</h1>
        <div>
          {payments_array_iot.map(
            (
              {
                _id,
                purchase,
                date,
                quota_text,
                price_text,
                status,
                picture_url,
                user,
              },
              idx
            ) => {
              var confirmButton;
              var cancelButton;
              var imageItem;
              imageItem = !picture_url ? (
                <div>No image</div>
              ) : (
                <div>
                  <img
                    key={`receipt${_id}`}
                    alt={`receipt${_id}`}
                    className={`rounded`}
                    style={{ width: "150px", height: "150px" }}
                    src={picture_url}
                  />
                  <br />
                  <br />
                </div>
              );
              switch (status) {
                case "admincancel":
                  confirmButton = null;
                  cancelButton = (
                    <button
                      className="buttonShadowSmall"
                      id="deleteBright"
                      onClick={this.handleUndoConfirm.bind(this, _id)}
                    >
                      <div>Undo cancel</div>
                    </button>
                  );
                  break;
                case "usercancel":
                  confirmButton = null;
                  cancelButton = null;
                  break;
                case "pending":
                  confirmButton = null;
                  cancelButton = null;
                  break;
                case "needconfirm":
                  confirmButton = (
                    <button
                      className="buttonShadowSmall"
                      id="deleteBright"
                      onClick={this.toggleConfirmIot.bind(this, _id)}
                    >
                      <div>Confirm</div>
                    </button>
                  );
                  cancelButton = (
                    <button
                      className="buttonShadowSmall"
                      id="deleteBright"
                      onClick={this.toggleCancelIot.bind(this, _id)}
                    >
                      <div>Cancel</div>
                    </button>
                  );
                  break;
                default:
                  confirmButton = null;
                  cancelButton = null;
              }
              return (
                <div key={idx} className="specialItem">
                  <div className="four-container">
                    <div>
                      <b>{quota_text} api calls</b>
                      {price_text}
                      {moment(date).format("DD-MMM-YYYY")}
                      <div
                        className="buttonShadowSmall"
                        style={{ fontSize: "100%" }}
                      >
                        {status}
                      </div>
                    </div>

                    <div>
                      {purchase.name_bank}
                      {purchase.no_bank}
                      {purchase.type_bank}
                    </div>

                    <div>{user.email}</div>
                  </div>
                  <div>
                    {imageItem}
                    {confirmButton}
                    {cancelButton}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    );

    const modalCancelIot = modal_cancel_iot && (
      <ModalComponent
        modalSize="small"
        id="modal"
        isOpen={modal_cancel_iot}
        onClose={this.toggleCancelIot}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }}>
            Yakin cancel?
            <button
              className="buttonShadowSmall"
              id="deleteBright"
              onClick={this.handleCancelIot.bind(this)}
            >
              <div>Batalkan pembayaran</div>
            </button>
          </div>
        </div>
      </ModalComponent>
    );

    const modalCancelGeo = modal_cancel_geo && (
      <ModalComponent
        modalSize="small"
        id="modal"
        isOpen={modal_cancel_geo}
        onClose={this.toggleCancelGeo}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }}>
            <h1>
              Rp.
              <NumberFormat
                value={price}
                displayType={"text"}
                thousandSeparator={true}
              />
            </h1>
            <button
              className="buttonShadowSmall"
              id="deleteBright"
              onClick={this.handleCancelGeo.bind(this)}
            >
              <div>Batalkan pembayaran GEO LICENSE</div>
            </button>
          </div>
        </div>
      </ModalComponent>
    );

    const modalConfirmIot = modal_confirm_iot && (
      <ModalComponent
        modalSize="small"
        id="modal"
        isOpen={modal_confirm_iot}
        onClose={this.toggleConfirmIot}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }}>
            Yakin konfirmasi IOT?
            <button
              className="buttonShadowSmall"
              id="deleteBright"
              onClick={this.handleConfirmIot.bind(this)}
            >
              <div>Konfirmasi pembayaran</div>
            </button>
          </div>
        </div>
      </ModalComponent>
    );

    const modalConfirmGeo = modal_confirm_geo && (
      <ModalComponent
        modalSize="small"
        id="modal"
        isOpen={modal_confirm_geo}
        onClose={this.toggleConfirmGeo}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }}>
            Yakin konfirmasi GEO?
            <button
              className="buttonShadowSmall"
              id="deleteBright"
              onClick={this.handleConfirmGeo.bind(this)}
            >
              <div>Konfirmasi pembayaran</div>
            </button>
          </div>
        </div>
      </ModalComponent>
    );

    return (
      <div className="dashboardSpecial" style={{ marginBottom: "200px" }}>
        {buttonItem}
        <br />
        {loadingContent}
        {itemContent}
        {modalCancelIot}
        {modalCancelGeo}
        {modalConfirmIot}
        {modalConfirmGeo}
        <style>{`
        #loginModal{ visibility: hidden;}
     `}</style>
      </div>
    );
  }
}
AdminPayments.propTypes = {
  admin: PropTypes.object.isRequired,
  getAdminPayments: PropTypes.func.isRequired,
  iotConfirmPayment: PropTypes.func.isRequired,
  geoConfirmPayment: PropTypes.func.isRequired,
  iotCancelPayment: PropTypes.func.isRequired,
  geoCancelPayment: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  admin: state.admin,
});
export default connect(mapStateToProps, {
  getAdminPayments,
  iotConfirmPayment,
  geoConfirmPayment,
  iotCancelPayment,
  geoCancelPayment,
})(AdminPayments);
