/* eslint-disable */
import React, { Component } from "react";
import pic_static from "../../img/profile_pic_comment.svg";
class QuotaCounter extends Component {
  state = {
    value: 0,
    donut1dasharray: 0,
    shown_number: 0,
    dasharray: 0,
    janganDiganggu: false,
  };
  componentDidMount() {
    const value = parseFloat(this.props.value);
    const nMin = this.props.nMin;
    const nMax = this.props.nMax;
    if (this.state.janganDiganggu) {
      return null;
    } else {
      if (!value || value === null || value === undefined) {
        // console.log("kasus kosong");
        this.setState({ donut1dasharray: 0, shown_number: 0, dasharray: 0 });
      } else {
        if (value < nMin) {
          // console.log("kasus minus");
          this.setState({
            donut1dasharray: nMin,
            shown_number: value,
            dasharray: nMin,
          });
        } else if (value > nMax) {
          // console.log("kasus melebihi");
          this.setState({
            donut1dasharray: nMax,
            shown_number: value,
            dasharray: nMax,
          });
        } else {
          if (value !== this.state.shown_number) {
            this.setState({ janganDiganggu: true });
            var start = this.state.donut1dasharray;
            var end = value; //value 0-50
            var range = end - start;
            var duration = 100;
            var incBesar = Math.abs(range) / 400;
            var incKecil = Math.abs(range) / 4;
            var increment;
            if (Math.abs(range) > 5) {
              increment = end > start ? incBesar : -incBesar;
            } else {
              increment = end > start ? incKecil : -incKecil;
            }
            var stepTime = Math.abs(Math.floor(duration / range));
            this.timer = setInterval(() => {
              this.setState((prevState) => ({
                donut1dasharray: prevState.donut1dasharray + increment,
                dasharray: parseFloat(
                  prevState.donut1dasharray + increment
                ).toFixed(1),
                shown_number: parseFloat(
                  prevState.donut1dasharray + increment
                ).toFixed(2),
                janganDiganggu: true,
              }));
              var current = this.state.donut1dasharray;
              var selisih = Math.abs(end - current);
              // console.log("proses");
              // console.log("selisih", selisih);
              // console.log("increment", increment);
              // console.log("jangan diganggu", this.state.janganDiganggu);
              //console.log("state", this.state.donut1dasharray);
              if (selisih < 0.01) {
                this.setState({ janganDiganggu: false });
                selisih = 0;
                // console.log("done");
                // console.log("selisih akhir", selisih);
                // console.log("donut1dasharray", this.state.donut1dasharray);
                // console.log("shown_number", this.state.shown_number);
                // console.log("jangan diganggu", this.state.janganDiganggu);
                clearInterval(this.timer);
              }
            }, stepTime);
          } else {
            // console.log("done");
            this.setState((prevState) => ({
              donut1dasharray: prevState.donut1dasharray,
              shown_number: prevState.shown_number,
              dasharray: prevState.dasharray,
              janganDiganggu: false,
            }));
          }
        }
      }
    }
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      const value = parseFloat(this.props.value);
      const nMin = this.props.nMin;
      const nMax = this.props.nMax;
      if (this.state.janganDiganggu) {
        return null;
      } else {
        if (!value || value === null || value === undefined) {
          // console.log("kasus kosong");
          this.setState({ donut1dasharray: 0, shown_number: 0, dasharray: 0 });
        } else {
          if (value < nMin) {
            // console.log("kasus minus");
            this.setState({
              donut1dasharray: nMin,
              shown_number: value,
              dasharray: nMin,
            });
          } else if (value > nMax) {
            // console.log("kasus melebihi");
            this.setState({
              donut1dasharray: nMax,
              shown_number: value,
              dasharray: nMax,
            });
          } else {
            if (value !== this.state.shown_number) {
              this.setState({ janganDiganggu: true });
              var start = this.state.donut1dasharray;
              var end = value; //value 0-50
              var range = end - start;
              var duration = 100;
              var incBesar = Math.abs(range) / 100;
              var incKecil = Math.abs(range) / 4;
              var increment;
              if (Math.abs(range) > 5) {
                increment = end > start ? incBesar : -incBesar;
              } else {
                increment = end > start ? incKecil : -incKecil;
              }
              var stepTime = Math.abs(Math.floor(duration / range));
              this.timer = setInterval(() => {
                this.setState((prevState) => ({
                  donut1dasharray: prevState.donut1dasharray + increment,
                  dasharray: parseFloat(
                    prevState.donut1dasharray + increment
                  ).toFixed(1),
                  shown_number: parseFloat(
                    prevState.donut1dasharray + increment
                  ).toFixed(2),
                  janganDiganggu: true,
                }));
                var current = this.state.donut1dasharray;
                var selisih = Math.abs(end - current);
                // console.log("proses");
                // console.log("selisih", selisih);
                // console.log("increment", increment);
                // console.log("jangan diganggu", this.state.janganDiganggu);
                //console.log("state", this.state.donut1dasharray);
                if (selisih < 0.01) {
                  this.setState({ janganDiganggu: false });
                  selisih = 0;
                  // console.log("done");
                  // console.log("selisih akhir", selisih);
                  // console.log("donut1dasharray", this.state.donut1dasharray);
                  // console.log("shown_number", this.state.shown_number);
                  // console.log("jangan diganggu", this.state.janganDiganggu);
                  clearInterval(this.timer);
                }
              }, stepTime);
            } else {
              // console.log("done");
              this.setState((prevState) => ({
                donut1dasharray: prevState.donut1dasharray,
                shown_number: prevState.shown_number,
                dasharray: prevState.dasharray,
                janganDiganggu: false,
              }));
            }
          }
        }
      }
    } else return null;
  }
  render() {
    var { dasharray } = this.state;
    var { nMin, nMax, url_pic, isPicture } = this.props;
    const keliling = 100;
    var mapValue = 1 * keliling * ((dasharray - nMin) / (nMax - nMin));
    //RGB Color
    const rMin = 0;
    const rMax = 211;
    const gMin = 124;
    const gMax = 64;
    const bMin = 250;
    const bMax = 85;
    function colorMap(min, max) {
      return min + ((max - min) / (nMax - nMin)) * (dasharray - nMin);
    }
    var red = colorMap(rMax, rMin);
    var green = colorMap(gMax, gMin);
    var blue = colorMap(bMax, bMin);
    //console.log(this.state);
    var picLogic = isPicture ? url_pic : pic_static;
    var pictureSize = 28;
    var picturePosition = 21;
    var donut1 = (
      <div className="text-center">
        <svg
          width="50%"
          height="50%"
          viewBox="0 0 42 42"
          className="donut"
          xmlns="http://www.w3.org/2000/svg"
        >
          <foreignObject
            x={picturePosition - pictureSize / 2}
            y={picturePosition - pictureSize / 2}
            width="100%"
            height="100%"
          >
            <div xmlns="http://www.w3.org/1999/xhtml">
              <div
                key="User photo"
                alt="User photo"
                className={` photoQ`}
                draggable={false}
              />
              <style>
                {`.photoQ{
                    background-image: url(${picLogic});
                    background-size: cover;
                    height:${pictureSize}px;
                    width:${pictureSize}px;
                    border-radius:${pictureSize / 2}px;
                  }
                  `}
              </style>
            </div>
          </foreignObject>
          <circle
            className="donut-ring"
            cx="21"
            cy="21"
            r="15.91549430918954"
            fill="transparent"
            stroke="#ffffff"
            strokeWidth="2"
            strokeDasharray="0 0"
            strokeDashoffset="0"
            strokeLinecap="square"
          />
          <circle
            className="donut-segment1"
            cx="21"
            cy="21"
            r="15.91549430918954"
            fill="transparent"
            strokeWidth="1"
            strokeDashoffset="50"
            strokeLinecap="butt"
          />
        </svg>
        <style>
          {`
          .donut-segment1{
            stroke-dasharray:${mapValue},${keliling - mapValue};
            animation: dash 5s linear forwards;
            stroke:rgb(${red},${green}, ${blue});
            }       
            `}
        </style>
      </div>
    );
    return <div>{donut1}</div>;
  }
}

export default QuotaCounter;
