import React, { Component } from "react";
import ModalComponent from "../common/ModalComponent";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import {
  updateItem,
  getItem,
  clearCurrentItem,
  clearCurrentItems,
  clearAllData,
} from "../../actions/itemActions";
import { UnControlled as CodeMirror } from "react-codemirror2";
import PropTypes from "prop-types";
import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";
import SpinnerSimple from "../common/SpinnerSimple";
import arrow_icon from "../../img/auth.svg";
import GaugeRender from "../render/GaugeRender";
import CompassRender from "../render/CompassRender";
import CounterRender from "../render/CounterRender";
import Toggle from "../widget/Toggle";
import String from "../widget/String";
class UpdateItem extends Component {
  state = {
    id: "",
    datasetName: "",
    isPublic: "",
    isDownload: "",
    isNotif: "",
    indexState: 0,
    tipeState: "CHART",
    tipeModal: false,
    lastData: [],
    modal: false,
    isManualLocation: false,
    latitude: 0,
    longitude: 0,
    locationLoading: false,
    //coba
    write_key: "",
    read_key: "",
    //modal
    modalWidget: false,
    modalFilter: false,
    modalMessage: false,

    modalDelete: false,
    _var: 0,
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  toggleWidget = (indexState) => {
    this.setState({ indexState: indexState }, () => {
      this.setState({
        modalWidget: !this.state.modalWidget,
      });
    });
  };
  toggleFilter = (indexState) => {
    this.setState({ indexState: indexState }, () => {
      this.setState({
        modalFilter: !this.state.modalFilter,
      });
    });
  };
  toggleMessage = (indexState) => {
    this.setState({ indexState: indexState }, () => {
      this.setState({
        modalMessage: !this.state.modalMessage,
      });
    });
  };
  handleChangeWidget = (tipeState) => {
    const indexState = this.state.indexState;
    let lastData = [...this.state.lastData];
    lastData[indexState].tipe = tipeState;
    this.setState({ lastData }, () => {
      this.toggleWidget(this.state.indexState);
    });
  };
  handleChangeFilter = (filterState) => {
    const indexState = this.state.indexState;
    let lastData = [...this.state.lastData];
    lastData[indexState].filter = filterState;
    lastData[indexState].isFilter = true;
    this.setState({ lastData });
  };
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const idx = target.id;
    if (
      [
        "name",
        "unit",
        "nMin",
        "nMax",
        "correction",
        "notif",
        "isFilter",
        "isCustomMessage",
        "minMessage",
        "maxMessage",
        "config_sensor",
      ].includes(name)
    ) {
      let lastData = [...this.state.lastData];
      lastData[idx][name] = value;
      this.setState({ lastData });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  handleCode = (e, idx) => {
    const index = idx;
    this.setState((prevState) => {
      let lastData = [...prevState.lastData];
      lastData[index].filter = e.target.value;
      return { lastData };
    });
  };
  onGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
      this.setState({ locationLoading: true });
    } else {
      return null;
    }
  };
  showPosition = (position) => {
    var latitude = Number(parseFloat(position.coords.latitude).toFixed(5));
    var longitude = Number(parseFloat(position.coords.longitude).toFixed(8));
    this.setState({ latitude, longitude, locationLoading: false });
  };
  componentDidMount() {
    this.props.getItem(this.props.match.params.id);
    this.props.clearCurrentItems();
    this.props.clearCurrentItem();
  }
  componentWillUnmount() {
    this.props.clearCurrentItem();
    this.props.clearCurrentItems();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.item.item._id !== prevState.id) {
      var item = nextProps.item.item;
      var datasetName = item.name;
      var read_key = item.read_key;
      var write_key = item.write_key;
      var id = item._id;
      var isPublic = item.isPublic;
      var isNotif = item.isNotif;
      var isDownload = item.isDownload;
      var buffer_data = item.last_data;
      var gabungan = [];
      var isManualLocation =
        item.isManualLocation !== undefined ? item.isManualLocation : false;
      var latitude = item.latitude !== undefined ? item.latitude : 0;
      var longitude = item.longitude !== undefined ? item.longitude : 0;
      for (var property in buffer_data) {
        if (buffer_data.hasOwnProperty(property)) {
          var varTag = property;
          var varData = buffer_data[property];
          var name = varData.name;
          var unit = varData.unit;
          var tipe = varData.tipe ? varData.tipe : "";
          var correction = varData.correction ? varData.correction : 0;
          var notif = varData.notif ? varData.notif : false;
          var hold = varData.hold ? varData.hold : false;
          var nMin = varData.nMin ? varData.nMin : 0;
          var nMax = varData.nMax ? varData.nMax : 100;
          var isCustomMessage =
            varData.isCustomMessage !== undefined
              ? varData.isCustomMessage
              : false;
          var minMessage = varData.minMessage ? varData.minMessage : "";
          var maxMessage = varData.maxMessage ? varData.maxMessage : "";
          var isFilter = varData.isFilter ? varData.isFilter : false;
          var filter = varData.filter
            ? varData.filter
            : //Default filter script
              `var prevOne = values[i - 1] ? values[i - 1] : values[i];
                if (
                Math.abs(values[i][0] - prevOne[0]) > 20
                ) {
                filterArray[i] = "delete";
                } else {
                filterArray[i] = parseInt(values[i][0]);
                }`;
          var codeLength = 15;
          var config_sensor = varData.config_sensor
            ? parseInt(varData.config_sensor)
            : 0;
        }
        gabungan.push({
          varTag,
          name,
          unit,
          tipe,
          correction,
          notif,
          hold,
          nMin,
          nMax,
          isFilter,
          filter,
          codeLength,
          isCustomMessage,
          minMessage,
          maxMessage,
          config_sensor,
        });
      }
      return {
        id: id,
        datasetName: datasetName,
        isPublic: isPublic,
        isNotif: isNotif,
        isDownload: isDownload,
        isManualLocation: isManualLocation,
        latitude: latitude,
        longitude: longitude,
        lastData: gabungan,
        read_key: read_key,
        write_key: write_key,
      };
    } else return null;
  }
  handleSubmit = (e) => {
    e.preventDefault();
    var last_data = {};
    var {
      lastData,
      datasetName,
      id,
      isPublic,
      isNotif,
      isDownload,
      // read_key,
      // write_key,
      isManualLocation,
      latitude,
      longitude,
    } = this.state;
    for (var i = 0; i < lastData.length; i++) {
      last_data["var" + (i + 1)] = lastData[i];
    }
    const newItem = {
      name: datasetName,
      id: id,
      isPublic: isPublic,
      isNotif: isNotif,
      isDownload: isDownload,
      last_data: last_data,
      // read_key: read_key,
      // write_key: write_key,
      isManualLocation,
      latitude,
      longitude,
    };
    console.log(newItem);
    this.props.updateItem(newItem);
    this.toggle();
  };

  clearAllData = async () => {
    let _var = `var${this.state._var + 1}`;
    let d = await clearAllData([_var, this.state.id]);
    console.log(d);
    if (d.status === "delete all data success") {
      this.setState({ modalDelete: false });
    }
  };

  btnDeleteAllData = (e) => {
    this.setState({ modalDelete: true, _var: e });
  };

  toggleModalDelete = () => {
    this.setState({ modalDelete: false });
  };

  render() {
    let {
      datasetName,
      lastData,
      isPublic,
      isDownload,
      isNotif,
      indexState,
      isManualLocation,
      latitude,
      longitude,
      locationLoading,
      // write_key,
      // read_key,
      modalFilter,
      modalMessage,
      modalWidget,
      modalDelete,
    } = this.state;
    var { loading } = this.props.item;
    var itemContent;
    const arrayValuesFiltered = [
      { x: 1560645250531, y: 234.4 },
      { x: 1560645447972, y: 234.38 },
      { x: 1560645647403, y: 235.32 },
      { x: 1560645847679, y: 237.47 },
      { x: 1560646046155, y: 236.32 },
      { x: 1560646250667, y: 237.08 },
      { x: 1560646444374, y: 237.35 },
      { x: 1560646642382, y: 237.3 },
      { x: 1560646843808, y: 239.86 },
      { x: 1560647045304, y: 240.46 },
      { x: 1560647440307, y: 241.57 },
      { x: 1560647638191, y: 241.07 },
      { x: 1560647837788, y: 242.08 },
      { x: 1560648037368, y: 241.25 },
      { x: 1560648238109, y: 243.1 },
      { x: 1560648643626, y: 245.21 },
      { x: 1560648835330, y: 245.36 },
      { x: 1560649033405, y: 245.64 },
      { x: 1560649234478, y: 246.28 },
      { x: 1560649432499, y: 246.98 },
      { x: 1560649636673, y: 247.12 },
      { x: 1560649833860, y: 248.13 },
      { x: 1560650037146, y: 249.07 },
    ];
    const data = {
      datasets: [
        {
          // label: name,
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderWidth: 1.5,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: arrayValuesFiltered,
        },
      ],
    };
    const options = {
      legend: {
        display: false,
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            id: "one",
            display: true,
            type: "time",
            time: {
              displayFormats: {
                day: "MMM D",
                hour: "hA",
                minute: "HH.mm a",
              },
            },
            distribution: "linear",
          },
          {
            id: "two",
            display: true,
            type: "time",
            time: {
              unit: "day",
              displayFormats: {
                day: "MMM D",
              },
            },
            distribution: "linear",
            scaleLabel: {
              display: true,
              labelString: "Time",
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: `Temperature`,
            },
            ticks: {
              beginAtZero: false,
              source: "auto",
            },
          },
        ],
      },
    };
    if (loading) {
      itemContent = (
        <div style={{ textAlign: "center" }}>
          <SpinnerSimpleLogo
            width={200}
            unik="loading_item_list"
            marginTop="0px"
          />
          <p>Loading edit data...</p>
        </div>
      );
    } else if (datasetName === undefined) {
      itemContent = (
        <div style={{ textAlign: "center" }}>
          <SpinnerSimpleLogo
            width={200}
            unik="loading_item_list"
            marginTop="0px"
          />
          <p>Loading edit data...</p>
        </div>
      );
    } else {
      itemContent = (
        <form>
          <input
            className="form-control"
            type="text"
            name="datasetName"
            value={datasetName}
            onChange={this.handleChange}
          />
          <br />
          {/* Coba */}
          {/* <input
            className="form-control"
            type="text"
            name="write_key"
            value={write_key}
            onChange={this.handleChange}
          />
          <br />
          <input
            className="form-control"
            type="text"
            name="read_key"
            value={read_key}
            onChange={this.handleChange}
          />
          <br /> */}
          {/* Coba */}
          <div
            className="widget-container"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <div>
              <label className="simpleCheck">
                <input
                  type="checkbox"
                  name="isPublic"
                  checked={isPublic}
                  onChange={this.handleChange}
                />
                <span className="buttonCheck">
                  Public chart{" "}
                  <span className="buttonSimpleTightNormal">
                    {isPublic ? "ON" : "OFF"}
                  </span>
                </span>
              </label>
            </div>

            <div>
              <label className="simpleCheck">
                <input
                  type="checkbox"
                  name="isDownload"
                  checked={isDownload}
                  onChange={this.handleChange}
                />
                <span className="buttonCheck">
                  Public download{" "}
                  <span className="buttonSimpleTightNormal">
                    {isDownload ? "ON" : "OFF"}
                  </span>
                </span>
              </label>
            </div>

            <div>
              <label className="simpleCheck">
                <input
                  type="checkbox"
                  name="isManualLocation"
                  checked={isManualLocation}
                  onChange={this.handleChange}
                />
                <span className="buttonCheck">
                  Manual Location{" "}
                  <span className="buttonSimpleTightNormal">
                    {isManualLocation ? "ON" : "OFF"}
                  </span>
                </span>
              </label>
            </div>

            {isManualLocation ? (
              <div>
                <p>Latitude:</p>
                <input
                  className="form-control"
                  type="number"
                  name="latitude"
                  value={latitude}
                  onChange={this.handleChange}
                />
                <br />
                <p>Longitude:</p>
                <input
                  className="form-control"
                  type="number"
                  name="longitude"
                  value={longitude}
                  onChange={this.handleChange}
                />
                <div className="buttonSimple2" onClick={this.onGetLocation}>
                  Use current location
                </div>
                {locationLoading ? (
                  <SpinnerSimple width={40} unik="loadingContent" />
                ) : null}
              </div>
            ) : null}

            <div>
              <label className="simpleCheck">
                <input
                  type="checkbox"
                  name="isNotif"
                  checked={isNotif}
                  onChange={this.handleChange}
                />
                <span className="buttonCheck">
                  All Notification{" "}
                  <span className="buttonSimpleTightNormal">
                    {isNotif ? "ON" : "OFF"}
                  </span>
                </span>
              </label>
            </div>
          </div>

          <div className="widget-container">
            {
              //eslint-disable-next-line
              lastData.map(({}, idx) => {
                return (
                  <div key={idx} className="specialItem">
                    <div className="grid-container-edit-dataset">
                      <div className="var">
                        <div
                          className="buttonSimpleTightNormal"
                          id="dark_blue"
                          style={{ textAlign: "center" }}
                        >
                          var{idx + 1}
                        </div>
                      </div>

                      <div className="name">
                        <div>Name:</div>
                        <input
                          type="text"
                          id={idx}
                          name="name"
                          value={lastData[idx].name}
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="unit">
                        <div>Unit:</div>
                        <input
                          type="text"
                          id={idx}
                          name="unit"
                          value={lastData[idx].unit}
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="notification">
                        <label className="simpleCheck">
                          <input
                            type="checkbox"
                            id={idx}
                            name="notif"
                            value={lastData[idx].notif}
                            checked={lastData[idx].notif}
                            onChange={this.handleChange}
                          />
                          <span className="buttonCheck">
                            Notification{" "}
                            <span className="buttonSimpleTightNormal">
                              {lastData[idx].notif ? "ON" : "OFF"}
                            </span>
                          </span>
                        </label>
                      </div>

                      <div className="min">
                        Min
                        <input
                          type="number"
                          id={idx}
                          name="nMin"
                          value={lastData[idx].nMin}
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="max">
                        Max
                        <input
                          type="number"
                          id={idx}
                          name="nMax"
                          value={lastData[idx].nMax}
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="footer">
                        <div
                          className="buttonShadowSmall"
                          onClick={this.toggleWidget.bind(this, idx)}
                        >
                          Widget{" "}
                          <span className="buttonSimpleTightNormal">
                            {lastData[idx].tipe ? lastData[idx].tipe : "CHART"}
                          </span>
                        </div>

                        <div
                          className="buttonShadowSmall"
                          onClick={this.toggleFilter.bind(this, idx)}
                        >
                          Filter{" "}
                          <span className="buttonSimpleTightNormal">
                            {lastData[idx].isFilter ? "ON" : "OFF"}
                          </span>
                        </div>

                        <div
                          className="buttonShadowSmall"
                          onClick={this.toggleMessage.bind(this, idx)}
                        >
                          Custom Message{" "}
                          <span className="buttonSimpleTightNormal">
                            {lastData[idx].isCustomMessage ? "ON" : "OFF"}
                          </span>
                        </div>

                        <div
                          className="buttonShadowSmall"
                          onClick={this.btnDeleteAllData.bind(this, idx)}
                        >
                          Delete All Data
                        </div>
                        <div>
                          <label htmlFor="">Config Value Sensor</label>
                          <input
                            type="number"
                            id={idx}
                            name="config_sensor"
                            onChange={this.handleChange}
                            defaultValue={lastData[idx]?.config_sensor}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="buttonSimple2"
              onClick={this.handleSubmit}
            >
              Save
              <img src={arrow_icon} alt="login" width="25" />
            </button>
          </div>
        </form>
      );
    }

    const modalContent = this.state.modal && (
      <ModalComponent
        modalSize="small"
        id="modal"
        isOpen={this.state.modal}
        onClose={this.toggle}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }}>
            <h1>Successfully updated</h1>
            <br />
            <br />
            You will redirect to dashboard, please wait.
            <br />
            <SpinnerSimpleLogo
              width={200}
              unik="loading_item_list"
              marginTop="0px"
            />
          </div>
        </div>
      </ModalComponent>
    );

    const arrayOfWidgets = [
      {
        name: "Compass",
        value: "CMP",
        component: <CompassRender width="100%" height="100%" />,
      },
      {
        name: "Car Gauge",
        value: "GG",
        component: <GaugeRender width="100%" height="100%" />,
      },
      {
        name: "Arc Gauge",
        value: "ARC",
        component: <CounterRender width="100%" height="100%" />,
      },
      {
        name: "Chart",
        value: "CHART",
        component: (
          <Line width={100} height={100} data={data} options={options} />
        ),
      },
      {
        name: "Toggle",
        value: "TG",
        component: <Toggle width="100%" height="100%" />,
      },
      {
        name: "String",
        value: "STRING",
        component: <String width="100%" height="100%" />,
      },
    ];

    const modalWidgetContent = modalWidget && (
      <ModalComponent
        modalSize="medium"
        id="modal"
        isOpen={modalWidget}
        onClose={this.toggleWidget.bind(this, indexState)}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          Realtime Widget
          <a
            href="https://doc.mapid.io/doc_iot/widget"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "block",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            See documentation
          </a>
          <div style={{ textAlign: "center" }}>
            <div className="widget-container">
              {arrayOfWidgets.map(({ name, value, component }, idx) => {
                let classNameContent;
                const currentTipe = lastData[indexState].tipe
                  ? lastData[indexState].tipe
                  : "CHART";
                if (currentTipe === value) {
                  classNameContent = "specialItemSelected";
                } else {
                  classNameContent = "specialItem";
                }
                return (
                  <div
                    key={idx}
                    className={classNameContent}
                    style={{ textAlign: "center" }}
                    onClick={this.handleChangeWidget.bind(this, value)}
                  >
                    {component}
                    <br />
                    {name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </ModalComponent>
    );
    const arrayOfFilter = [
      {
        name: "Filter 1",
        desc: "This is filter 1",
        value: `var prevOne = values[i - 1] ? values[i - 1] : values[i];
if (
Math.abs(values[i][0] - prevOne[0]) > 20
) {
filterArray[i] = "delete";
} else {
filterArray[i] = parseInt(values[i][0]);
}`,
      },
      {
        name: "Filter 1 & Translation",
        desc: "This is filter 1 & Base Translation",
        value: `var base = 136;
var prevOne = values[i - 1] ? values[i - 1] : values[i];
if (
Math.abs(values[i][0] - prevOne[0]) > 20
) {
filterArray[i] = "delete";
} else {
filterArray[i] = parseInt(base - values[i][0]);
}`,
      },

      {
        name: "Filter 2 & Translation",
        desc: "This is filter 2 & Base Translation",
        value: `var base = 136;
var prevOne = values[i - 1] ? values[i - 1] : values[i];
var prevTwo = values[i - 2] ? values[i - 2] : values[i];
var prevThree = values[i - 3] ? values[i - 3] : values[i];
var prevFour = values[i - 4] ? values[i - 4] : values[i];
if (
Math.abs(values[i][0] - prevOne[0]) > 15 ||
Math.abs(values[i][0] - prevTwo[0]) > 15 ||
Math.abs(values[i][0] - prevThree[0]) > 15 ||
Math.abs(values[i][0] - prevFour[0]) > 15 ||
base-values[i][0]  > 160 
) {
filterArray[i] = "delete";
} else {
filterArray[i] = parseInt(base - values[i][0]);
}`,
      },
    ];
    const modalFilterContent = modalFilter && (
      <ModalComponent
        modalSize="lg"
        id="modal"
        isOpen={modalFilter}
        onClose={this.toggleFilter.bind(this, indexState)}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <label className="simpleCheck">
            <input
              type="checkbox"
              id={indexState}
              name="isFilter"
              value={lastData[indexState].isFilter}
              checked={lastData[indexState].isFilter}
              onChange={this.handleChange}
            />
            <span className="buttonCheck">
              Filter{" "}
              <span className="buttonSimpleTightNormal">
                {lastData[indexState].isFilter ? "ON" : "OFF"}
              </span>
            </span>
          </label>
          <a
            href="https://doc.mapid.io/doc_iot/filter"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "block",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            See documentation
          </a>
          {arrayOfFilter.map(({ name, desc, value }, idx) => {
            return (
              <div
                key={idx}
                className="buttonSimpleTightNormal"
                id="edit"
                style={{ marginLeft: "5px", marginRight: "5px" }}
                onClick={this.handleChangeFilter.bind(this, value)}
              >
                {name}
              </div>
            );
          })}
          <div style={{ textAlign: "left", marginTop: "10px" }}>
            <CodeMirror
              value={lastData[indexState].filter}
              options={{
                mode: "javascript",
                theme: "material",
                lineNumbers: true,
                lineWrapping: true,
                firstLineNumber: 1,
              }}
              autoCursor={false}
              onChange={(editor, data, value) => {
                this.setState((prevState) => {
                  let lastData = [...prevState.lastData];
                  lastData[indexState].filter = value;
                  return { lastData };
                });
              }}
            />
          </div>
        </div>
      </ModalComponent>
    );
    const modalMessageContent = modalMessage && (
      <ModalComponent
        modalSize="medium"
        id="modal"
        isOpen={modalMessage}
        onClose={this.toggleMessage.bind(this, indexState)}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <label className="simpleCheck">
            <input
              type="checkbox"
              id={indexState}
              name="isCustomMessage"
              value={lastData[indexState].isCustomMessage}
              checked={lastData[indexState].isCustomMessage}
              onChange={this.handleChange}
            />
            <span className="buttonCheck">
              Custom Message{" "}
              <span className="buttonSimpleTightNormal">
                {lastData[indexState].isCustomMessage ? "ON" : "OFF"}
              </span>
            </span>
          </label>
          <a
            href="https://doc.mapid.io/doc_iot/notification"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "block",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            See documentation
          </a>
          <div className="widget-container" style={{ marginTop: "10px" }}>
            <div className="specialItem">
              <p>Min Message</p>
              <textarea
                rows="4"
                type="text"
                id={indexState}
                name="minMessage"
                value={lastData[indexState].minMessage}
                onChange={this.handleChange}
              />
            </div>
            <div className="specialItem">
              <p>Max Message</p>
              <textarea
                rows="4"
                type="text"
                id={indexState}
                name="maxMessage"
                value={lastData[indexState].maxMessage}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
      </ModalComponent>
    );
    const modalAlertDelete = modalDelete && (
      <ModalComponent
        modalSize="small"
        // id="modal"
        isOpen={modalDelete}
        onClose={this.toggleModalDelete.bind(this, indexState)}
      >
        <div style={{ textAlign: "center" }}>
          Are You Sure Want Delete All Data
          <br />
          <button
            className="buttonSimpleTightNormal"
            id="delete"
            onClick={this.clearAllData}
          >
            Delete
          </button>
        </div>
      </ModalComponent>
    );
    return (
      <div className="dashboardSpecial">
        {itemContent}
        {modalContent}
        {modalWidgetContent}
        {modalFilterContent}
        {modalMessageContent}
        {modalAlertDelete}
        <br />
        <br />
      </div>
    );
  }
}
UpdateItem.propTypes = {
  getItem: PropTypes.func.isRequired,
  clearCurrentItem: PropTypes.func.isRequired,
  clearCurrentItems: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({ item: state.item });
export default connect(mapStateToProps, {
  updateItem,
  getItem,
  clearCurrentItem,
  clearCurrentItems,
})(UpdateItem);
