import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import {
  getBracket,
  getDocList,
  pushSubBracket,
  editSubBracket,
  deleteSubBracket,
  deleteDoc,
  setDoc,
} from "../../actions/editorAction";
import ModalComponent from "../common/ModalComponent";
import SpinnerSimpleBlue from "../common/SpinnerSimpleBlue";
import RichViewer from "../common/RichViewer";
import RichViewerEmpty from "../common/RichViewerEmpty";
import RichViewerLoading from "../common/RichViewerLoading";
import isEmpty from "../../validation/is-empty";
import isEqual from "../../validation/is-equal";
const batasPixel = 1172;
const batasScroll = 300;
class ViewerDashboard extends Component {
  //Init
  state = {
    openStatus: true,
    modalPushSubBracket: false,
    modalEditSubBracket: false,
    modalDeleteSubBracket: false,
    modalDeleteDoc: false,
    width: window.innerWidth,
    sub_bracket_name: "",
    sub_bracket_id: "",
    doc: {},
    link: "",
    link_params: "",
    first_doc: {},
    scrollPixelsY: window.scrollY,
    sidebarClassname: "sidebar scrollSidebar",
    openbtnClassname: "openbtn scrollOpenbtn",
    doc_id: "",
    bracket: "",
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const link_params_next =
      nextProps &&
      nextProps.match &&
      nextProps.match.params &&
      nextProps.match.params.link
        ? nextProps.match.params.link
        : "";
    const doc_list_new = !isEmpty(nextProps.editor.doc_list)
      ? nextProps.editor.doc_list
      : [];
    const first_doc = doc_list_new[0];
    const doc_new = doc_list_new.filter(
      (doc) => doc.link === link_params_next
    )[0];
    const doc_state = prevState.doc;
    const link_params_state = prevState.link_params;
    if (
      doc_list_new.length > 0 &&
      link_params_next &&
      (link_params_next !== link_params_state || !isEqual(doc_new, doc_state))
    ) {
      return {
        first_doc: doc_new,
        doc: doc_new,
        link_params: link_params_next,
      };
    } else if (!link_params_next) {
      return {
        first_doc,
      };
    } else return null;
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      this.props.getBracket(this.props.match.params.bracket_link);
      this.props.getDocList(this.props.match.params.bracket_link);
    }
    if (!isEqual(this.props.editor.bracket, prevProps.editor.bracket)) {
      this.setState({
        modalPushSubBracket: false,
        modalEditSubBracket: false,
        modalDeleteSubBracket: false,
      });
    }
  }
  //handleClick doc list link
  handleClickDoc = (doc, link) => {
    this.setState({ doc, link }, () => {
      window.scrollTo(0, 0);
      this.props.setDoc(doc);
    });
  };
  //editor action
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  togglePushSubBracket = () => {
    this.setState({
      modalPushSubBracket: !this.state.modalPushSubBracket,
    });
  };
  toggleEditSubBracket = (sub_bracket_name, sub_bracket_id) => {
    this.setState(
      {
        sub_bracket_name,
        sub_bracket_id,
      },
      () => {
        this.setState({ modalEditSubBracket: !this.state.modalEditSubBracket });
      }
    );
  };
  toggleDeleteSubBracket = (sub_bracket_name, sub_bracket_id) => {
    this.setState(
      {
        sub_bracket_name,
        sub_bracket_id,
      },
      () => {
        this.setState({
          modalDeleteSubBracket: !this.state.modalDeleteSubBracket,
        });
      }
    );
  };
  toggleDeleteDoc = (doc) => {
    this.setState(
      {
        doc,
      },
      () => {
        this.setState({
          modalDeleteDoc: !this.state.modalDeleteDoc,
        });
      }
    );
  };
  handleSubmitPushSubBracket = (e) => {
    e.preventDefault();
    const { sub_bracket_name } = this.state;
    const { _id } = this.props.editor.bracket;
    const content = {
      id: _id,
      name: sub_bracket_name,
      link: sub_bracket_name
        .replace(/ /g, "_")
        .toLowerCase()
        .replace(/[^\w\s]/gi, ""),
    };
    this.props.pushSubBracket(content);
  };
  handleSubmitEditSubBracket = (e) => {
    e.preventDefault();
    const { sub_bracket_name, sub_bracket_id } = this.state;
    const content = {
      sub_bracket_id,
      name: sub_bracket_name,
    };
    this.props.editSubBracket(content);
  };
  handleSubmitDeleteSubBracket = (e) => {
    e.preventDefault();
    const { sub_bracket_id } = this.state;
    const { _id } = this.props.editor.bracket;
    const content = {
      id: _id,
      sub_bracket_id,
    };
    this.props.deleteSubBracket(content);
  };
  handleSubmitDeleteDoc = (e) => {
    e.preventDefault();
    const { _id, bracket } = this.state.doc;
    const content = {
      id: _id,
      bracket,
    };
    this.props.deleteDoc(content);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getBracket(this.props.match.params.bracket_link);
    this.props.getDocList(this.props.match.params.bracket_link);
    window.addEventListener("resize", this.handleWindowSizeChange);
    document.getElementById("mySidebar").style.left = "2vw";
    document.getElementById("buttonSlider").style.marginLeft =
      "calc((2vw + 300px - 20px)/2)";
    if (this.state.width < batasPixel) {
      this.closeNav();
    } else {
      this.openNav();
    }
    window.addEventListener("scroll", this.handleScroll);
  }
  //SideBar action
  toggleSideBar = () => {
    this.setState(
      {
        openStatus: !this.state.openStatus,
      },
      () => {
        this.state.openStatus ? this.openNav() : this.closeNav();
      }
    );
  };
  openNav = () => {
    this.setState({ openStatus: true }, () => {
      document.getElementById("mySidebar").style.left = "2vw";
      document.getElementById("buttonSlider").style.marginLeft =
        "calc((2vw + 300px - 20px)/2)";
    });
  };
  closeNav = () => {
    this.setState({ openStatus: false }, () => {
      document.getElementById("mySidebar").style.left = "-300px";
      document.getElementById("buttonSlider").style.marginLeft = "2vw";
    });
  };
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth }, () => {
      if (this.state.width < batasPixel) {
        this.closeNav();
      } else {
        this.openNav();
      }
    });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    if (window.scrollY > batasScroll) {
      this.setState({
        sidebarClassname: "sidebar stickySidebar",
        openbtnClassname: "openbtn stickyOpenbtn",
      });
    } else {
      this.setState({
        sidebarClassname: "sidebar scrollSidebar",
        openbtnClassname: "openbtn scrollOpenbtn",
      });
    }
  };
  render() {
    //props
    const { isAuthenticated } = this.props.auth;
    const {
      loadingProcess,
      loadingList,
      loadingContent,
      doc_list,
      bracket,
    } = this.props.editor;
    //state
    const {
      openStatus,
      modalPushSubBracket,
      modalEditSubBracket,
      modalDeleteSubBracket,
      modalDeleteDoc,
      sidebarClassname,
      openbtnClassname,
      sub_bracket_name,
      sub_bracket_id,
      doc,
      first_doc,
    } = this.state;
    const doc_final = isEmpty(doc) ? first_doc : doc;

    let viewerContent;
    if (loadingList || loadingContent) {
      viewerContent = <RichViewerLoading link={this.props.match.params.link} />;
    } else if (doc_final && doc_final.link) {
      viewerContent = (
        <RichViewer
          isFromIOT={false}
          doc={doc_final}
          toggleDelete={this.toggleDeleteDoc.bind(this)}
        />
      );
    } else {
      viewerContent = <RichViewerEmpty link={this.props.match.params.link} />;
    }
    const authContent = (
      <div style={{ marginBottom: "500px" }}>
        <div style={{ textAlign: "center" }}>
          <div className="buttonShadow" onClick={this.togglePushSubBracket}>
            New Folder
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Link
            className="buttonShadow"
            to={`/create_document/${this.props.match.params.bracket_link}`}
          >
            New Document
          </Link>
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Link className="buttonShadow" to={`/editor`}>
            Home
          </Link>
        </div>
        {bracket.sub_brackets.map(({ _id, name, link }, idx) => {
          const list_on_bracket = doc_list.filter(
            (blog) => blog.sub_bracket === link
          );
          const list_on_bracket_render = (
            <div>
              {list_on_bracket.map((doc, idx) => {
                const { title, _id, link } = doc;
                const idButton =
                  doc_final && doc_final._id && doc_final._id === _id
                    ? "activated"
                    : "not-activated";
                return (
                  <Link
                    to={`/viewer/${this.props.match.params.bracket_link}/${link}`}
                    className="buttonTableOfContent docList"
                    id={idButton}
                    key={_id}
                    onClick={this.handleClickDoc.bind(this, doc, link)}
                  >
                    <div className="textTableOfContent">{title}</div>
                  </Link>
                );
              })}
            </div>
          );
          return (
            <div key={_id}>
              <div className="buttonTableOfContent parentTableOfContent">
                <div className="textTableOfContent">
                  {name}
                  <br />
                  <div
                    className="smallButton"
                    onClick={this.toggleEditSubBracket.bind(this, name, _id)}
                  >
                    Rename
                  </div>
                  <div
                    className="smallButton"
                    id="deleteBright"
                    onClick={this.toggleDeleteSubBracket.bind(this, name, _id)}
                  >
                    Delete
                  </div>
                </div>
              </div>
              {list_on_bracket_render}
            </div>
          );
        })}
      </div>
    );
    const guestContent = <div>Guest</div>;
    const itemContent =
      isAuthenticated && !loadingList ? authContent : guestContent;
    var iconContentPushSubBracket;
    var iconContentEditSubBracket;
    var iconContentDeleteSubBracket;
    var iconContentDeleteDoc;
    if (loadingProcess) {
      iconContentPushSubBracket = (
        <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
      );
      iconContentEditSubBracket = (
        <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
      );
      iconContentDeleteSubBracket = (
        <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
      );
      iconContentDeleteDoc = (
        <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
      );
    } else {
      iconContentPushSubBracket = (
        <button
          className="btn buttonSimple2"
          type="submit"
          onClick={this.handleSubmitPushSubBracket}
        >
          Create
        </button>
      );
      iconContentEditSubBracket = (
        <button
          className="btn buttonSimple2"
          type="submit"
          onClick={this.handleSubmitEditSubBracket}
        >
          Save
        </button>
      );
      iconContentDeleteSubBracket = (
        <button
          className="btn buttonSimple2"
          id="deleteBright"
          type="submit"
          onClick={this.handleSubmitDeleteSubBracket}
        >
          Delete
        </button>
      );
      iconContentDeleteDoc = (
        <button
          className="btn buttonSimple2"
          id="deleteBright"
          type="submit"
          onClick={this.handleSubmitDeleteDoc}
        >
          Delete
        </button>
      );
    }
    const modalPushSubBracketContent = modalPushSubBracket && (
      <ModalComponent
        modalSize="small"
        id="createBracketModal"
        isOpen={modalPushSubBracket}
        onClose={this.togglePushSubBracket}
      >
        <div className="box-body">
          Create Folder
          <form>
            <input
              className="form-control"
              type="text"
              name="sub_bracket_name"
              id="sub_bracket_name"
              value={sub_bracket_name}
              onChange={this.handleChange}
            />
            <div className="text-center">{iconContentPushSubBracket}</div>
          </form>
        </div>
      </ModalComponent>
    );
    const modalEditSubBracketContent = modalEditSubBracket && (
      <ModalComponent
        modalSize="small"
        id="editBracketModal"
        isOpen={modalEditSubBracket}
        onClose={this.toggleEditSubBracket.bind(
          this,
          sub_bracket_name,
          sub_bracket_id
        )}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }}>Rename Folder</div>
          <form>
            <input
              className="form-control"
              type="text"
              name="sub_bracket_name"
              id="sub_bracket_name"
              value={sub_bracket_name}
              onChange={this.handleChange}
            />
            <div style={{ textAlign: "center" }}>
              {iconContentEditSubBracket}
            </div>
          </form>
        </div>
      </ModalComponent>
    );
    const modalDeleteSubBracketContent = modalDeleteSubBracket && (
      <ModalComponent
        modalSize="small"
        id="editBracketModal"
        isOpen={modalDeleteSubBracket}
        onClose={this.toggleDeleteSubBracket.bind(
          this,
          sub_bracket_name,
          sub_bracket_id
        )}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }}>
            Are you sure to delete <b>{sub_bracket_name}</b> folder?
            <br />
            This action can't be undone.
          </div>
          <br />
          <div style={{ textAlign: "center" }}>
            {iconContentDeleteSubBracket}
          </div>
        </div>
      </ModalComponent>
    );
    const modalDeleteDocContent = modalDeleteDoc && (
      <ModalComponent
        modalSize="small"
        id="editBracketModal"
        isOpen={modalDeleteDoc}
        onClose={this.toggleDeleteDoc.bind(this, doc_final)}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }}>
            Are you sure to delete{" "}
            <b>{doc_final && doc_final.title ? doc_final.title : ""}</b>{" "}
            document?
            <br />
            This action can't be undone.
          </div>
          <br />
          <div style={{ textAlign: "center" }}>{iconContentDeleteDoc}</div>
        </div>
      </ModalComponent>
    );

    return (
      <div>
        <div id="mySidebar" className={sidebarClassname}>
          {itemContent}
        </div>
        <div
          className={openbtnClassname}
          id="buttonSlider"
          onClick={this.toggleSideBar}
          style={{ zIndex: "2" }}
        >
          {openStatus ? "<" : ">"}
        </div>
        {viewerContent}
        {modalPushSubBracketContent}
        {modalEditSubBracketContent}
        {modalDeleteSubBracketContent}
        {modalDeleteDocContent}
      </div>
    );
  }
}
ViewerDashboard.propTypes = {
  //Object
  editor: PropTypes.object.isRequired,
  //Function
  getBracket: PropTypes.func.isRequired,
  getDocList: PropTypes.func.isRequired,
  pushSubBracket: PropTypes.func.isRequired,
  editSubBracket: PropTypes.func.isRequired,
  deleteSubBracket: PropTypes.func.isRequired,
  deleteDoc: PropTypes.func.isRequired,
  setDoc: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  item: state.item,
  auth: state.auth,
  editor: state.editor,
});
export default connect(mapStateToProps, {
  getBracket,
  getDocList,
  pushSubBracket,
  editSubBracket,
  deleteSubBracket,
  deleteDoc,
  setDoc,
})(ViewerDashboard);
