import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { Helmet } from "react-helmet";

import ModalComponent from "../common/ModalComponent";
import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";
import {
  getItem,
  clearCurrentItem,
  getItemPublic,
  updatePublicItem,
  sendToMachine,
} from "../../actions/itemActions";
import pic_static from "../../img/profile_pic_comment.svg";
import add_img from "../../img/add.svg";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from "@material-ui/core/ListItemText";
// import Divider from '@material-ui/core/Divider';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import DateComp from "./DateComp";

class Chart extends Component {
  state = {
    width: window.innerWidth,
    iFrame: "CHART",
    modal: false,
    modalPublic: false,
    variable: "var1",
    variableName: "Sensor",
    copied: false,
    modal_image: false,
    url_image: "",

    data_X: 0,
    data_y: 0,
    nama_var: "",
    modal_detail_data: false,
  };
  toggleImage = (url) => {
    this.setState({ url_image: url }, () => {
      this.setState({
        modal_image: !this.state.modal_image,
      });
    });
  };
  handleCopied = () => {
    this.setState({
      copied: true,
    });
    setTimeout(
      function () {
        this.setState({ copied: false });
      }.bind(this),
      3000
    );
  };
  handlePublic = () => {
    const id = this.props.id;
    const item = {
      id,
    };
    this.props.updatePublicItem(item);
  };
  handleMachine = (write_key, property, value) => {
    const item = {
      write_key,
      property,
      value,
    };
    this.props.sendToMachine(item);
  };
  toggle = (variable, name) => {
    this.setState({
      modal: !this.state.modal,
      variable: variable,
      variableName: name,
    });
  };
  togglePublic = () => {
    this.setState({
      modalPublic: !this.state.modalPublic,
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  componentDidMount() {
    var status = this.props.status;
    var id = this.props.id;
    switch (status) {
      case "private":
        this.props.getItem(id);
        break;
      case "public":
        this.props.getItemPublic(id);
        break;
      default:
        return null;
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      var status = this.props.status;
      var id = this.props.id;
      switch (status) {
        case "private":
          this.props.getItem(id);
          break;
        case "public":
          this.props.getItemPublic(id);
          break;
        default:
          return null;
      }
    }
  }
  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  render() {
    const { item, loadingContent } = this.props.item;
    const { status } = this.props;
    const { iFrame, variable, copied, variableName } = this.state;
    const iFrameLink = `https://iot.mapid.io/chart/${item._id}/${variable}/${iFrame}`;
    const reactLink = `https://iot.mapid.io/chart/${item._id}/${variable}/${iFrame}`;
    const iFrameString = `<iframe
                          id="${variableName.replace(/ +/g, "")}"
                          title="${variableName}"
                          width="300"
                          height="175"
                          src="${iFrameLink}">
                          </iframe>`;

    var headerContent;
    var imageContent;
    var itemContent;
    if (!item || loadingContent) {
      itemContent = (
        <div style={{ textAlign: "center" }}>
          <SpinnerSimpleLogo
            width={200}
            unik="loading_item_list"
            marginTop="0px"
          />
        </div>
      );
    } else {
      var user = item.user;
      var isPublic = item.isPublic;
      var last_data = item.last_data;
      var buffer_data = item.buffer_data;
      var gabungan = [];
      var data;
      var options;
      var varTag;
      for (var property in buffer_data) {
        if (buffer_data.hasOwnProperty(property)) {
          varTag = property;
          var varData = buffer_data[property];
          var values = varData.values;
          var name = varData.name;
          var unit = varData.unit;
          var xValues = [];
          var yValues = [];
          var arrayValues = [];
          var arrayValuesFiltered = [];
          var tipe = varData.tipe ? varData.tipe : "";
          //min max
          var varLast = last_data[property];
          var nMin = varLast.nMin;
          var nMax = varLast.nMax;
          //filter
          var isFilter = varLast.isFilter ? varLast.isFilter : false;
          var filter = varLast.filter ? varLast.filter : "";
          var filterArray = [];
          var i;
          if (isFilter && filter) {
            for (i in values) {
              //eslint-disable-next-line
              eval(filter);
              yValues.push(filterArray[i]);
              xValues.push(values[i][1]);
            }
          } else {
            for (i in values) {
              yValues.push(values[i][0]);
              xValues.push(values[i][1]);
            }
          }

          for (var e = 0; e < xValues.length; e++) {
            // for toyo
            if (varLast.config_sensor) {
              arrayValues.push({
                x: xValues[e],
                y: varLast.config_sensor - yValues[e],
              });
            } else {
              arrayValues.push({
                x: xValues[e],
                y: yValues[e],
              });
            }
          }
          arrayValuesFiltered = arrayValues
            .filter((value) => value.y !== "NaN")
            .filter((value) => value.y !== "undefined")
            .filter((value) => value.y !== "delete");
        }

        data = {
          datasets: [
            {
              // label: name,
              fill: false,
              lineTension: 0.1,
              backgroundColor: "rgba(75,192,192,0.4)",
              borderColor: "rgba(75,192,192,1)",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderWidth: 1.5,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(75,192,192,1)",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(75,192,192,1)",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: arrayValuesFiltered,
            },
          ],
        };
        options = {
          maintainAspectRatio: true,
          legend: {
            display: false,
          },
          responsive: true,
          scales: {
            xAxes: [
              {
                id: "one",
                display: true,
                type: "time",
                time: {
                  displayFormats: {
                    day: "MMM D",
                    hour: "hA",
                    minute: "HH.mm a",
                  },
                },
                distribution: "linear",
              },
              {
                id: "two",
                display: true,
                type: "time",
                time: {
                  unit: "day",
                  displayFormats: {
                    day: "MMM D",
                  },
                },
                distribution: "linear",
                scaleLabel: {
                  display: true,
                  labelString: "Time",
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: `${unit}`,
                },
                ticks: {
                  beginAtZero: false,
                  source: "auto",
                },
              },
            ],
          },
        };
        console.log({
          data,
          options,
          varTag,
          name,
          tipe,
          nMin,
          nMax,
        });
        gabungan.push({
          data,
          options,
          varTag,
          name,
          tipe,
          nMin,
          nMax,
        });
      }

      // if(gabungan.length === 1){
      //   this.setState({widhtChart: '50%'})
      // }

      if (item.pictures.length === 0) {
        if (status === "private") {
          imageContent = (
            <Link to={`/upload/${item._id}`}>
              <div className={`bgUpload`}>
                <style>
                  {`.bgUpload{
                    background-image: url(${add_img});  
                    background-size: 180px 140px;
                    height:140px;
                    width:180px;
                    border-radius: 10px;
                    }`}
                </style>
              </div>
            </Link>
          );
        } else {
          imageContent = (
            <div style={{ textAlign: "center" }}>
              <img
                src={require("../../img/nopic.svg")}
                alt="map"
                className="img-fluid"
              />
              <p>{""}</p>
              <p className="text-muted">No image for this dataset</p>
            </div>
          );
        }
      } else {
        if (status === "private") {
          imageContent = (
            <div className="carousell">
              {item.pictures.map(
                ({ name, url, url_compressed, isDeleted }, idx) => {
                  var url_image = url_compressed ? url_compressed : url;
                  return (
                    <div key={name}>
                      <div
                        key={name}
                        alt={name}
                        className={`bg${idx}`}
                        onClick={this.toggleImage.bind(this, url)}
                      >
                        <style>
                          {`.bg${idx}{
                    background-image: url(${url_image});  
                    background-size: cover;
                    height:140px;
                    width:180px;
                    border-radius: 10px;
                    }`}
                        </style>
                      </div>
                    </div>
                  );
                }
              )}
              <Link to={`/upload/${item._id}`}>
                <div className={`bgUpload`}>
                  <style>
                    {`.bgUpload{
                        background-image: url(${add_img});  
                        background-size: 180px 140px;
                        height:140px;
                        width:180px;
                        border-radius: 10px;
                        }`}
                  </style>
                </div>
              </Link>
            </div>
          );
        } else {
          imageContent = (
            <div className="carousell">
              {item.pictures.map(
                ({ name, url, url_compressed, isDeleted }, idx) => {
                  var url_image = url_compressed ? url_compressed : url;
                  return (
                    <div key={name}>
                      <div
                        key={name}
                        alt={name}
                        className={`btn btn-light bg${idx}`}
                        onClick={this.toggleImage.bind(this, url)}
                      >
                        <style>
                          {`
                          .bg${idx}{
                            background-image: url(${url_image});  
                            background-size: cover;
                            height:140px;
                            width:180px;
                            border-radius: 10px;
                          }`}
                        </style>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          );
        }
      }

      headerContent = (
        <div>
          <Helmet>
            <title>{item.name}</title>
            <meta name="description" content={`${item.name} | IOT MAPID`} />
          </Helmet>
          <div
            key="user_profile"
            alt="user_profile"
            className={`bgUser`}
            draggable={false}
          />
          <div
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              marginLeft: "10px",
              fontSize: "17px",
              color: "black",
            }}
          >
            <div className="welcome" style={{ fontSize: "30px" }}>
              {item.name}
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "black",
              }}
            >
              {item && item.user && item.user.full_name
                ? item.user.full_name
                : "Owner"}
            </div>
            <style>
              {`.bgUser{
                  background: url(${
                    user &&
                    user.profile_picture &&
                    user.profile_picture.url_compressed
                      ? user.profile_picture.url_compressed
                      : user &&
                        user.profile_picture &&
                        user.profile_picture.url &&
                        user.profile_picture.url !== "-"
                      ? user.profile_picture.url
                      : pic_static
                  }) no-repeat center center/ cover;
                  background-color: #dcdcdc;
                  height: 80px;
                  width: 80px;
                  border-radius: 50%;
                  display: inline-block;
                  align: center;
                  vertical-align: middle;
                  border: 2px solid #b0b0b0;
                  box-shadow: 0 0 12px #0000004e;
                  -webkit-box-shadow: 0 0 12px #0000004e;
                  -moz-box-shadow: 0 0 12px #0000004e;
                  margin-top: 20px;
                  margin-bottom: 20px;
                  }`}
            </style>
          </div>
          <br />

          <div
            className="buttonShadowSmall"
            style={{
              padding: "10px",
              width: "200px",
              marginRight: "10px",
              textAlign: "left",
            }}
          >
            <div style={{ display: "block", marginBottom: "20px" }}>
              Last update at:
            </div>
            {item.last_entry_at ? moment(item.last_entry_at).from() : "No data"}
          </div>
          <div
            className="buttonShadowSmall"
            style={{
              padding: "10px",
              width: "200px",
              marginRight: "10px",
              textAlign: "left",
            }}
          >
            <div style={{ display: "block", marginBottom: "20px" }}>
              Calls amount:
            </div>
            {item.entries_number}
          </div>
          <div
            className="buttonShadowSmall"
            style={{
              padding: "10px",
              width: "200px",
              marginRight: "10px",
              textAlign: "left",
            }}
          >
            <div style={{ display: "block", marginBottom: "20px" }}>
              Created since:
            </div>
            {moment(item.created_at).from()}
          </div>
          <br />
          {/* <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            {this.props.status === "private" ? (
              <Link
                className="btn buttonSimple2"
                to={`/edit_doc_iot/report/${
                  item && item.folder && item.folder._id
                    ? item.folder._id
                    : item._id
                }/${item._id}`}
              >
                Edit Doc
              </Link>
            ) : null}
            <Link
              className="btn buttonSimple2"
              to={`/view_doc_iot/report/${item._id}`}
            >
              View Doc
            </Link>
          </div> */}
        </div>
      );
      itemContent = (
        <div
          className="widget-container"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          {gabungan.map(({ varTag, data, options, name, tipe, nMin, nMax }) => {
            const unit = options.scales.yAxes[0].scaleLabel.labelString;
            nMax = Number(nMax);
            nMin = Number(nMin);
            const value =
              tipe === "STRING"
                ? data.datasets[0].data &&
                  data.datasets[0].data[data.datasets[0].data.length - 1] &&
                  data.datasets[0].data[data.datasets[0].data.length - 1].y
                  ? data.datasets[0].data[data.datasets[0].data.length - 1].y
                  : 0
                : Number(
                    data.datasets[0].data &&
                      data.datasets[0].data[data.datasets[0].data.length - 1] &&
                      data.datasets[0].data[data.datasets[0].data.length - 1].y
                      ? data.datasets[0].data[data.datasets[0].data.length - 1]
                          .y
                      : 0
                  );
            // const nMaxWidget = nMax + Math.abs(nMax - nMin) * 0.1;
            var property = varTag;
            var actionValue;
            actionValue = value === 0 ? 1 : 0;
            var chartComponent;
            switch (tipe) {
              case "STRING":
                chartComponent = (
                  <div>
                    <div className="buttonSimple2">{value}</div>
                  </div>
                );
                break;
              case "TG":
                chartComponent = (
                  <div>
                    <div
                      className="buttonShadowSmall"
                      onClick={this.handleMachine.bind(
                        this,
                        item.write_key,
                        property,
                        actionValue
                      )}
                    >
                      Click here to take toggle action!
                    </div>
                    <br />
                    <br />
                    <div>
                      <label className="switch2" id="realtime text-right">
                        <input
                          type="checkbox"
                          id="isPublic"
                          value={value}
                          checked={value}
                          readOnly
                        />
                        <div className="slider2" />
                      </label>
                    </div>
                    <label htmlFor="realtime">
                      <b>{name} status</b>
                    </label>
                  </div>
                );
                break;
              default:
                chartComponent = (
                  <div>
                    <Line
                      data={data}
                      options={options}
                      key={"chart" + varTag}
                      // width={50}
                      // height={50}
                      // onElementsClick={elems => {
                      //   if(elems.length !== 0){
                      //     var activePoint = elems[0];
                      //     var data = activePoint._chart.data;
                      //     var datasetIndex = activePoint._datasetIndex;
                      //     // var label = data.datasets[datasetIndex].label;
                      //     var value = data.datasets[datasetIndex].data[activePoint._index];

                      //     this.setState({
                      //       modal_detail_data: true,
                      //       data_X: value.x,
                      //       data_y: value.y,
                      //     })
                      //   }
                      // }}
                    />
                    <div className="buttonSimple2">
                      {value} {unit}
                    </div>
                  </div>
                );
            }
            var embedComponent;
            if (status === "private" && isPublic) {
              embedComponent = (
                <div>
                  <div
                    className="buttonShadowSmall"
                    onClick={this.toggle.bind(this, varTag, name)}
                  >
                    Embed
                  </div>
                </div>
              );
            } else if (status === "private" && !isPublic) {
              embedComponent = (
                <div>
                  <button
                    className="buttonShadowSmall"
                    onClick={this.togglePublic.bind(this)}
                  >
                    <div>Make public for embed</div>
                  </button>
                </div>
              );
            } else {
              embedComponent = null;
            }
            return (
              <div
                className="specialItem"
                style={{
                  textAlign: "center",
                  // width: "500px",
                  maxWidth:
                    gabungan.length === 1
                      ? this.state.width < 720
                        ? "100%"
                        : "50%"
                      : "100%",
                }}
                key={"column" + varTag}
              >
                <h3>
                  {name} {" - "} {tipe ? tipe : "CHART"}
                </h3>
                {embedComponent}
                <br />
                <div>{chartComponent}</div>
                <br />
              </div>
            );
          })}
        </div>
      );
    }
    const modalImage = this.state.modal_image && (
      <ModalComponent
        modalSize="medium"
        id="modal"
        isOpen={this.state.modal_image}
        onClose={this.toggleImage}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }} className="welcome">
            <img
              src={this.state.url_image}
              alt="Internet of Things Device"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </ModalComponent>
    );
    const modalEmbedFormContent = this.state.modal && (
      <ModalComponent
        modalSize="medium"
        id="modal"
        isOpen={this.state.modal}
        onClose={this.toggle.bind(this, variable, variableName)}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }} className="welcome">
            Embed {variableName} chart
          </div>
          <div>
            <select
              className="form-control"
              id="iFrame"
              onChange={this.handleChange.bind(this)}
              value={iFrame}
            >
              <option value="CHART">Chart</option>
              <option value="GG">Car gauge</option>
              <option value="ARC">Arc gauge</option>
              <option value="CMP">Compass</option>
            </select>
            <br />
            <CopyToClipboard text={iFrameString}>
              <button
                className="buttonShadowSmall"
                onClick={this.handleCopied.bind(this)}
              >
                Copy{" "}
                {copied ? (
                  <span className="buttonSimpleTightNormal" id="edit">
                    iframe copied
                  </span>
                ) : null}
              </button>
            </CopyToClipboard>{" "}
            <a
              href={reactLink}
              target="_blank"
              rel="noopener noreferrer"
              className="buttonShadowSmall"
            >
              Link
            </a>
          </div>
          <br />
          <textarea value={iFrameString} rows="10" disabled />
        </div>
      </ModalComponent>
    );

    const modalPublicContent = this.state.modalPublic && (
      <ModalComponent
        modalSize="medium"
        id="modal"
        isOpen={this.state.modalPublic}
        onClose={this.togglePublic}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }} className="welcome">
            Are you sure make this public?
          </div>
          <div>
            <button
              className="buttonShadowSmall"
              onClick={this.handlePublic.bind(this)}
            >
              <big>Ok!</big>
            </button>
          </div>
        </div>
      </ModalComponent>
    );

    const modalDetailData = (
      <Dialog
        open={this.state.modal_detail_data}
        onClose={() => {
          this.setState({ modal_detail_data: false });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Detail Data"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <List component="nav" aria-label="main mailbox folders">
              <ListItem button>
                <ListItemText primary={<DateComp date={this.state.data_X} />} />
                <span style={{ marginLeft: 10, marginRight: 10 }}>&bull;</span>
                <ListItemText primary={`${this.state.data_y} Celcius`} />
                <IconButton aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    );
    return (
      <div className="dashboardSpecial">
        {headerContent}
        {itemContent}
        {imageContent}
        {modalImage}
        {modalEmbedFormContent}
        {modalPublicContent}
        {modalDetailData}
      </div>
    );
  }
}
Chart.propTypes = {
  clearCurrentItem: PropTypes.func.isRequired,
  getItemPublic: PropTypes.func.isRequired,
  getItem: PropTypes.func.isRequired,
  updatePublicItem: PropTypes.func.isRequired,
  sendToMachine: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  item: state.item,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getItem,
  clearCurrentItem,
  getItemPublic,
  updatePublicItem,
  sendToMachine,
})(Chart);
