import React, { Component } from "react";
import { Container } from "@material-ui/core";
import { connect } from "react-redux";
import {
  clear_outlier,
  getLogNewDataset,
  kalman_filter,
  toyo_filter,
} from "../../actions/itemActions";
import Chart from "./Chart";
import Table from "./Table";
import { Button } from "@material-ui/core";

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: "chart",
      skip: -100,
      limit: 100,
    };
  }
  componentDidMount() {
    let status = this.props.match.params.status;
    let _id = this.props.match.params.id;
    switch (status) {
      case "private":
        this.props.getLogNewDataset({
          _id,
          skip: this.state.skip,
          limit: this.state.limit,
          _var: "var0",
        });
        break;
      default:
        return null;
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.load_more_dataset !== this.props.load_more_dataset &&
      this.props.load_more_dataset === true
    ) {
      let skip = this.state.skip + -100;

      let status = this.props.match.params.status;
      let _id = this.props.match.params.id;
      const append = true;
      switch (status) {
        case "private":
          this.props.getLogNewDataset(
            {
              _id,
              skip: skip,
              limit: this.state.limit,
              _var: "var0",
            },
            append
          );
          this.setState({
            skip: skip,
          });
          break;
        default:
          return null;
      }
    }
  }
  kalmanFilter = () => {
    this.props.kalman_filter();
  };
  clearOutlier = () => {
    this.props.clear_outlier();
  };
  toyoFilter = () => {
    this.props.toyo_filter();
  };
  render() {
    const { nav } = this.state;
    const navActive = {
      border: "solid 2px",
      borderBottom: "none",
      borderRadius: ".5rem .5rem 0rem 0rem",
      backgroundColor: "white",
      padding: "1rem 2rem",
      marginBottom: "-2px",
      fontWeight: "bold",
      textColor: "black",
    };
    const navNonActive = {
      border: "none",
      borderBottom: "solid 2px",
      backgroundColor: "white",
      padding: "1rem 2rem",
      marginBottom: "-2px",
    };
    const { name } = this.props.new_dataset;
    return (
      <div>
        <Container>
          <div>
            <div>
              <h1>{name}</h1>
            </div>
            <div
              style={{ margin: "1rem 0rem", display: "flex", gap: ".25rem" }}
            >
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={this.clearOutlier}
              >
                clear outlier
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={this.kalmanFilter}
              >
                set kalman filter
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={this.toyoFilter}
              >
                Toyo Filter
              </Button>
            </div>
            <div style={{ display: "flex", borderBottom: "solid 2px" }}>
              <div
                style={nav === "chart" ? navActive : navNonActive}
                onClick={() => this.setState({ nav: "chart" })}
              >
                CHART
              </div>
              <div
                style={nav === "table" ? navActive : navNonActive}
                onClick={() => this.setState({ nav: "table" })}
              >
                TABLE
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: "white" }}>
            {nav === "chart" && <Chart />}
            {nav === "table" && <Table />}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  new_dataset: state.item.new_dataset,
  load_more_dataset: state.item.load_more_new_dataset,
});

const mapDispatchToProps = {
  getLogNewDataset,
  kalman_filter,
  clear_outlier,
  toyo_filter,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
