import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import NumericLabel from "react-pretty-numbers";

import { deleteItem, addItem } from "../../actions/itemActions";
import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";
import icon_data from "../../img/icon_data.svg";
import icon_time from "../../img/icon_time.svg";
import icon_comment from "../../img/icon_comment.svg";

class DatasetsResume extends Component {
  state = {
    modal: false,
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  render() {
    const { items, loading } = this.props.item;
    var itemsContent;
    if (loading) {
      itemsContent = (
        <div style={{ textAlign: "center", margin: "auto" }}>
          <SpinnerSimpleLogo
            width={200}
            unik="loading_item_list"
            marginTop="0px"
          />
        </div>
      );
    } else {
      if (items.length === 0) {
        itemsContent = (
          <div style={{ textAlign: "center", margin: "auto" }}>
            <SpinnerSimpleLogo
              width={200}
              unik="loading_item_list"
              marginTop="0px"
            />
          </div>
        );
      } else {
        itemsContent = (
          <div className="widget-container">
            {items.map(
              (
                {
                  _id,
                  name,
                  user,
                  last_data,
                  entries_number,
                  last_entry_at,
                  comments,
                },
                idx
              ) => {
                var dataset_name = name.toString();
                dataset_name = dataset_name.split(" ");
                var name_potong;
                switch (dataset_name.length) {
                  case 1:
                    name_potong = dataset_name[0];
                    break;
                  case 2:
                    name_potong = dataset_name[0] + " " + dataset_name[1];
                    break;
                  case 3:
                    name_potong =
                      dataset_name[0] +
                      " " +
                      dataset_name[1] +
                      " " +
                      dataset_name[2];
                    break;
                  default:
                    name_potong =
                      dataset_name[0] +
                      " " +
                      dataset_name[1] +
                      " " +
                      dataset_name[2];
                }
                const array_last_data = Object.keys(last_data).map(
                  (i) => last_data[i]
                );
                const sensorContent = (
                  <div className="four-container">
                    {array_last_data.map(({ name, unit, values }, index) => {
                      return (
                        <div
                          key={`sensor_${index}`}
                          style={{ textAlign: "center" }}
                        >
                          <div className="buttonSimpleTightNormal">
                            {values[0]}
                          </div>
                          <div style={{ display: "block" }}>{unit}</div>
                          <div style={{ display: "block" }}>{name}</div>
                        </div>
                      );
                    })}
                  </div>
                );
                const imageWidth = "25px";
                const params = {
                  shortFormat: true,
                  justification: "C",
                };
                const footerContent = (
                  <div className="three-container">
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={icon_data}
                        alt="icon_data"
                        width={imageWidth}
                        className="centerImage"
                      />
                      <div className="buttonSimpleTightNormal text-center">
                        <NumericLabel params={params}>
                          {Number(entries_number)}
                        </NumericLabel>
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={icon_time}
                        alt="icon_time"
                        width={imageWidth}
                        className="centerImage"
                      />
                      <div className="buttonSimpleTightNormal">
                        {last_entry_at
                          ? moment(last_entry_at).from()
                          : "No data"}
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={icon_comment}
                        alt="icon_comment"
                        width={imageWidth}
                        className="centerImage"
                      />
                      <div className="buttonSimpleTightNormal">
                        {comments.length}
                      </div>
                    </div>
                  </div>
                );
                return (
                  <Link to={`/chart/private/${_id}`} key={"column" + _id}>
                    <div className="widget-item-picture">
                      <div
                        className="specialItem"
                        style={{
                          width: "100%",
                          cursor: "pointer",
                          minHeight: "250px",
                        }}
                      >
                        {name_potong}
                        <hr />
                        {sensorContent}
                        <hr />
                        {footerContent}
                      </div>
                    </div>
                  </Link>
                );
              }
            )}
          </div>
        );
      }
    }
    return <div>{itemsContent}</div>;
  }
}

DatasetsResume.propTypes = {
  deleteItem: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  item: state.item,
});

export default connect(mapStateToProps, { deleteItem, addItem })(
  DatasetsResume
);
