import React, { Component } from "react";
import { Route, Switch, Router } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Provider } from "react-redux";
import store from "./store";
import moment from "moment";

import PrivateRoute from "./components/common/PrivateRoute";
import AdminRoute from "./components/common/AdminRoute";
import Navigation from "./components/layout/Navigation";
// import Landing from "./components/layout/Landing";
import ThankYou from "./components/layout/ThankYou";
// import Login from "./components/auth/Login";

import LandingRegister from "./components/auth/LandingRegister";
import LandingLogin from "./components/auth/LandingLogin";

import Dashboard from "./components/dashboard/Dashboard";
import CreateTask from "./components/dashboard/CreateTask";
import ViewTask from "./components/dashboard/ViewTask";
import EditTask from "./components/dashboard/EditTask";
import GetToken from "./components/dashboard/GetToken";
import DatasetsResume from "./components/dashboard/DatasetsResume";
import UpdateItem from "./components/dashboard/UpdateItem";
import DataConvert from "./components/dashboard/DataConvert";

import Main from "./components/chart/Main";
import Logchart from "./components/chart/Logchart";
import Download from "./components/chart/Download";
import DownloadUnix from "./components/chart/DownloadUnix";
import Current from "./components/chart/Current";
import UploadDashboard from "./components/chart/UploadDashboard";
import Adminlogin from "./components/admin/Adminlogin";
import Adminitem from "./components/admin/Adminitem";
import Adminuser from "./components/admin/Adminuser";
import AdminPayments from "./components/admin/AdminPayments";
import AdminHelps from "./components/admin/AdminHelps";
import Admindashboard from "./components/admin/Admindashboard";

import LogNew from "./components/chart/LogNew";
import TableChart from "./components/table/index";
import NewDataset from "./components/newDataset/index";

import { MainV3 } from "./components/chart/v3";

import EditorDashboard from "./components/editor/EditorDashboard";
import ViewerDashboard from "./components/editor/ViewerDashboard";
import CreateDocument from "./components/editor/CreateDocument";
import EditDocument from "./components/editor/EditDocument";

import UserDashboard from "./components/user/UserDashboard";
import GroupDetail from "./components/user/GroupDetail";
import EditProfile from "./components/user/EditProfile";
import UploadProfileDashboard from "./components/user/UploadProfileDashboard";
import Publicboard from "./components/public/Publicboard";
import WoNav from "./components/public/WoNav";
import CodeList from "./components/documentation/CodeList";
import {
  setCurrentUser,
  setCurrentAdmin,
  logoutUser,
  logoutAdmin,
  getUserFromToken,
} from "./actions/authActions";
import his from "./actions/history";

import "./App.css";
import "./Rich.css";
import "draft-js/dist/Draft.css";

import "antd/dist/antd.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";

moment.locale("id");

// import { mapidServiceWorker } from "./mapidServiceWorker";
// mapidServiceWorker();
//check for Token
var jwtTokenMapid = localStorage.jwtTokenMapid;
if (jwtTokenMapid) {
  store.dispatch(setCurrentUser(JSON.parse(localStorage.getItem("mapidUser"))));
  store.dispatch(getUserFromToken(jwtTokenMapid));
  // console.log(JSON.parse(localStorage.getItem('mapidUser')))
  //Check for expired token
  const token = localStorage.jwtTokenMapid;
  const decoded = jwt_decode(token);
  const expireDate = decoded.exp;
  const currentTime = Date.now() / 1000;
  if (expireDate < currentTime) {
    //Log user out
    store.dispatch(logoutUser());
  }
} else {
  // console.log("bukan user");
}
//check for Token Admin

if (localStorage.jwtTokenMapidAdmin) {
  //set auth token header
  const tokenAdmin = localStorage.jwtTokenMapidAdmin;
  const decodedAdmin = jwt_decode(tokenAdmin);
  const expireDateAdmin = decodedAdmin.exp;
  const currentTimeAdmin = Date.now() / 1000;
  store.dispatch(setCurrentAdmin(decodedAdmin));
  console.log("admin masuk");
  if (expireDateAdmin < currentTimeAdmin) {
    //Log user out
    store.dispatch(logoutAdmin());
  }
} else {
  // console.log("bukan admin");
}
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={his}>
          <div>
            <Navigation />
            <Route exact path="/" component={LandingLogin} />
            <Route exact path="/login" component={LandingLogin} />
            <Route exact path="/register" component={LandingRegister} />
            <Route exact path="/join/:kode" component={LandingRegister} />

            <Route exact path="/thankyou" component={ThankYou} />
            <Route exact path="/token/:token" component={GetToken} />
            {/* <Route exact path="/login" component={Login} /> */}
            <Route exact path="/public" component={Publicboard} />
            <Route exact path="/chart/:status/:id" component={Main} />
            <Route exact path="/table/:status/:id" component={TableChart} />
            <Route
              exact
              path="/new_dataset/:status/:id"
              component={NewDataset}
            />
            <Route exact path="/chart/v3/:status/:id" component={MainV3} />
            <Route exact path="/log/:id" component={Logchart} />
            <Route exact path="/log/:id/v2" component={LogNew} />
            <Route path="/chart/:id/:property/:type" component={WoNav} />
            <Route exact path="/code" component={CodeList} />
            <Route exact path="/admin" component={Adminlogin} />
            <Route exact path="/editor" component={EditorDashboard} />
            <Route exact path="/data-convert" component={DataConvert} />
            <Route
              exact
              path="/viewer/:bracket_link"
              component={ViewerDashboard}
            />
            <Route
              exact
              path="/viewer/:bracket_link/:link"
              component={ViewerDashboard}
            />
            <Route
              exact
              path="/create_document/:bracket_link"
              component={CreateDocument}
            />
            <Route
              exact
              path="/edit_document/:bracket_link/:link"
              component={EditDocument}
            />
            <Switch>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
            </Switch>
            <Switch>
              <PrivateRoute
                exact
                path="/create_doc_iot/:task_or_report/:folder"
                component={CreateTask}
              />
            </Switch>
            <Switch>
              <PrivateRoute
                exact
                path="/create_doc_iot/:task_or_report/:folder/:dataset"
                component={CreateTask}
              />
            </Switch>
            <Switch>
              <PrivateRoute
                exact
                path="/view_doc_iot/:task_or_report/:id"
                component={ViewTask}
              />
            </Switch>
            <Switch>
              <PrivateRoute
                exact
                path="/edit_doc_iot/:task_or_report/:folder"
                component={EditTask}
              />
            </Switch>
            <Switch>
              <PrivateRoute
                exact
                path="/edit_doc_iot/:task_or_report/:folder/:dataset"
                component={EditTask}
              />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/overview" component={DatasetsResume} />
            </Switch>
            <Switch>
              <PrivateRoute
                exact
                path="/upload/:id"
                component={UploadDashboard}
              />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/download/:id" component={Download} />
            </Switch>
            <Switch>
              <PrivateRoute
                exact
                path="/downloadunix/:id"
                component={DownloadUnix}
              />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/current/:id" component={Current} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/update/:id" component={UpdateItem} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/user" component={UserDashboard} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/group/:id" component={GroupDetail} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/editprofile" component={EditProfile} />
            </Switch>
            <Switch>
              <PrivateRoute
                exact
                path="/userpic"
                component={UploadProfileDashboard}
              />
            </Switch>
            <Switch>
              <AdminRoute
                exact
                path="/admin/dashboard"
                component={Admindashboard}
              />
            </Switch>
            <Switch>
              <AdminRoute exact path="/admin/user" component={Adminuser} />
            </Switch>
            <Switch>
              <AdminRoute exact path="/admin/item" component={Adminitem} />
            </Switch>
            <Switch>
              <AdminRoute exact path="/admin/helps" component={AdminHelps} />
            </Switch>
            <Switch>
              <AdminRoute
                exact
                path="/admin/payments"
                component={AdminPayments}
              />
            </Switch>
            {/* <Switch>
              <Route exact path="/log/:id/v2"><LogNew /></Route>
            </Switch> */}
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
