export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_TOKEN = "SET_CURRENT_TOKEN";
export const SET_CURRENT_ADMIN = "SET_CURRENT_ADMIN";
export const SET_CURRENT_ADMIN_TOKEN = "SET_CURRENT_ADMIN_TOKEN";

export const GET_ITEMS = "GET_ITEMS";
export const GET_PUBLIC_ITEMS = "GET_PUBLIC_ITEMS";
export const GET_LANDING_ITEMS = "GET_LANDING_ITEMS";
export const GET_ITEM = "GET_ITEM";
export const GET_ITEM_PUBLIC = "GET_ITEM_PUBLIC";
export const GET_LOG_ITEM = "GET_LOG_ITEM";

export const ADD_ITEM = "ADD_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const CLEAR_ALL_DATA = "CLEAR_ALL_DATA";
export const UPDATE_PUBLIC_ITEM = "UPDATE_PUBLIC_ITEM";
export const MAP_GEO = "MAP_GEO";

export const ITEM_LOADING = "ITEM_LOADING";
export const LOG_LOADING = "LOG_LOADING";
export const ITEMS_LOADING = "ITEMS_LOADING";

export const DELETE_ITEM = "DELETE_ITEM";

export const CLEAR_CURRENT_ITEMS = "CLEAR_CURRENT_ITEMS";
export const CLEAR_CURRENT_ITEM = "CLEAR_CURRENT_ITEM";
export const CLEAR_CURRENT_LOG = "CLEAR_CURRENT_LOG";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const GET_ADMIN_ITEMS = "GET_ADMIN_ITEMS";
export const GET_ADMIN_USERS = "GET_ADMIN_USERS";
export const GET_ADMIN_USERS_PENDING = "GET_ADMIN_USERS_PENDING";

//Notif and comment
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_REPLY = "ADD_REPLY";
export const EDIT_COMMENT = "EDIT_COMMENT";
export const EDIT_REPLY = "EDIT_REPLY";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_REPLY = "DELETE_REPLY";
export const PUSH_DATASET = "PUSH_DATASET";
export const SUB_NOTIF = "SUB_NOTIF";
export const GET_COMMENTS = "GET_COMMENTS";

//Payment and user
export const GET_USER = "GET_USER";
export const ADD_BANK = "ADD_BANK";
export const ACTIVATE_BANK = "ACTIVATE_BANK";
export const BUY_QUOTA = "BUY_QUOTA";
export const CONFIRM_PAYMENT = "CONFIRM_PAYMENT";
export const CANCEL_PAYMENT = "CANCEL_PAYMENT";
export const ADMIN_CONFIRM_PAYMENT = "ADMIN_CONFIRM_PAYMENT";
export const ADMIN_CANCEL_PAYMENT = "ADMIN_CANCEL_PAYMENT";
export const GET_PRICES = "GET_PRICES";
export const GET_PAYMENTS = "GET_PAYMENTS";
export const PAYMENT_LOADING = "PAYMENT_LOADING";
export const CLEAR_PAYMENT_LOADING = "CLEAR_PAYMENT_LOADING";
export const GET_DOCS = "GET_DOCS";

//Auth
export const AUTH_LOADING = "AUTH_LOADING";
export const CLEAR_AUTH_LOADING = "CLEAR_AUTH_LOADING";

//Admin
export const ADMIN_LOADING = "ADMIN_LOADING";
export const CLEAR_ADMIN_LOADING = "CLEAR_ADMIN_LOADING";

export const ADD_HELP = "ADD_HELP";
export const GET_HELPS = "GET_HELPS";
