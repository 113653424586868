import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import {
  addComment,
  addReply,
  editComment,
  editReply,
  deleteComment,
  deleteReply,
  getComment,
} from "../../actions/commentAction";
import ModalComponent from "../common/ModalComponent";
import { clearErrors } from "../../actions/authActions";
import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import pic_static from "../../img/profile_pic_comment.svg";
import official_badge from "../../img/official_badge.svg";
//Sign in
import Login from "../auth/Login";
// import SpinnerSimpleBlue from "../common/SpinnerSimpleBlue";
const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};
class Comment extends Component {
  state = {
    text_state: "",
    text_child: "",
    text_edit_comment: "",
    text_edit_reply: "",
    errors: {},
    index: "",
    index_child: "",
    comment_id: "",
    reply_id: "",
    modal_reply: false,
    modal_edit_comment: false,
    modal_edit_reply: false,
    modal_delete_comment: false,
    modal_delete_reply: false,
    loginModal: false,
    id: this.props.id,
  };
  componentDidMount() {
    this.setState({ id: this.props.id }, () => {
      let id = this.state.id;
      this.props.getComment(id);
    });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      return {
        loginModal: false,
      };
    } else return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors });
    } else if (this.props.id !== prevProps.id) {
      this.setState({ id: this.props.id }, () => {
        var id = this.state.id;
        this.props.getComment(id);
      });
    } else {
      return null;
    }
  }
  toggleLogin = () => {
    this.setState({
      loginModal: !this.state.loginModal,
    });
  };
  componentWillUnmount() {
    this.props.clearErrors();
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    var auth = this.props.auth;
    var user_id_props = auth && auth.user && auth.user._id ? auth.user._id : {};
    var { item } = this.props.comment;
    const commentData = {
      id: item._id,
      text: this.state.text_state,
      user_id: user_id_props,
    };
    this.props.addComment(commentData);
    this.setState({ text_state: "" });
  };
  handleReply = (idx) => {
    this.setState({
      index: idx,
      modal_reply: !this.state.modal_reply,
      modal_edit_comment: false,
      modal_edit_reply: false,
    });
  };
  onReply = (e) => {
    e.preventDefault();
    var auth = this.props.auth;
    var user_id_props = auth && auth.user && auth.user._id ? auth.user._id : {};
    var { item, loading } = this.props.comment;
    const comment_array = item.comments ? item.comments : [];
    const comment_length = comment_array.length;
    const new_idx = comment_length - 1 - this.state.index;
    const commentData = {
      id: item._id,
      text: this.state.text_child,
      user_id: user_id_props,
      index: new_idx,
    };
    this.props.addReply(commentData);
    this.setState({ text_child: "" });
    setTimeout(
      function () {
        this.setState({ modal_reply: loading });
      }.bind(this),
      3000
    );
  };
  handleEditComment = (idx, text) => {
    this.setState({
      index: idx,
      text_edit_comment: text,
      modal_edit_comment: !this.state.modal_edit_comment,
      modal_reply: false,
      modal_edit_reply: false,
    });
  };
  onEditComment = (e) => {
    e.preventDefault();
    var auth = this.props.auth;
    var user_id_props = auth && auth.user && auth.user._id ? auth.user._id : {};
    var { item, loading } = this.props.comment;
    //reverse the index
    const comment_array = item.comments ? item.comments : [];
    const comment_length = comment_array.length;
    const new_idx = comment_length - 1 - this.state.index;
    const commentData = {
      id: item._id,
      text: this.state.text_edit_comment,
      user_id: user_id_props,
      index: new_idx,
    };
    this.props.editComment(commentData);
    this.setState({ text_edit_comment: "" });
    setTimeout(
      function () {
        this.setState({ modal_edit_comment: loading });
      }.bind(this),
      3000
    );
  };
  handleEditReply = (idx, idx_child, text_child) => {
    this.setState({
      index: idx,
      index_child: idx_child,
      text_edit_reply: text_child,
      modal_edit_comment: false,
      modal_reply: false,
      modal_edit_reply: !this.state.modal_edit_reply,
    });
  };
  onEditReply = (e) => {
    e.preventDefault();
    var auth = this.props.auth;
    var user_id_props = auth && auth.user && auth.user._id ? auth.user._id : {};
    var { item, loading } = this.props.comment;
    //reverse the index
    var comment_array = item.comments ? item.comments : [];
    var comment_length = comment_array.length;
    var new_idx = comment_length - 1 - this.state.index;
    var comment_child_array = comment_array[new_idx].child;
    var comment_child_length = comment_child_array.length;
    var new_idx_child = comment_child_length - 1 - this.state.index_child;
    //end of the reversing
    var commentData = {
      id: item._id,
      text: this.state.text_edit_reply,
      user_id: user_id_props,
      index: new_idx,
      child_index: new_idx_child,
    };
    this.props.editReply(commentData);
    this.setState({ text_edit_reply: "" });
    setTimeout(
      function () {
        this.setState({ modal_edit_reply: loading });
      }.bind(this),
      3000
    );
  };
  toggleDeleteComment = (idx, comment_id) => {
    this.setState({
      index: idx,
      comment_id: comment_id,
      modal_delete_comment: !this.state.modal_delete_comment,
      modal_reply: false,
      modal_edit_comment: false,
      modal_edit_reply: false,
    });
  };
  onDeleteComment = (e) => {
    e.preventDefault();
    var auth = this.props.auth;
    var user_id_props = auth && auth.user && auth.user._id ? auth.user._id : {};
    var { item, loading, comments } = this.props.comment;
    const comment_array = comments ? comments : [];
    const comment_length = comment_array.length;
    const new_idx = comment_length - 1 - this.state.index;
    const commentData = {
      id: item._id,
      comment_id: this.state.comment_id,
      user_id: user_id_props,
      index: new_idx,
    };
    this.props.deleteComment(commentData);
    setTimeout(
      function () {
        this.setState({ modal_delete_comment: loading });
      }.bind(this),
      3000
    );
  };
  toggleDeleteReply = (idx, idx_child, reply_id) => {
    this.setState({
      index: idx,
      index_child: idx_child,
      reply_id: reply_id,

      modal_delete_reply: !this.state.modal_delete_reply,
      modal_delete_comment: false,
      modal_reply: false,
      modal_edit_comment: false,
      modal_edit_reply: false,
    });
  };
  onDeleteReply = (e) => {
    e.preventDefault();
    var auth = this.props.auth;
    var user_id_props = auth && auth.user && auth.user._id ? auth.user._id : {};
    var { item, loading } = this.props.comment;
    //reverse the index
    var comment_array = item.comments ? item.comments : [];
    var comment_length = comment_array.length;
    var new_idx = comment_length - 1 - this.state.index;
    var comment_child_array = comment_array[new_idx].child;
    var comment_child_length = comment_child_array.length;
    var new_idx_child = comment_child_length - 1 - this.state.index_child;
    //end of the reversing
    const commentData = {
      id: item._id,
      user_id: user_id_props,
      index: new_idx,
      child_index: new_idx_child,
      reply_id: this.state.reply_id,
    };
    this.props.deleteReply(commentData);
    setTimeout(
      function () {
        this.setState({ modal_delete_reply: loading });
      }.bind(this),
      3000
    );
  };
  render() {
    const {
      item,
      // loadingGet,
      // loadingDelete,
      // loadingParent,
      // loadingChild,
    } = this.props.comment;
    const { auth } = this.props;
    const comment_array = item && item.comments ? item.comments : [];
    const user_props = auth && auth.user ? auth.user : {};
    const {
      errors,
      text_state,
      text_child,
      text_edit_comment,
      text_edit_reply,
      index,
      index_child,
      modal_reply,
      modal_edit_comment,
      modal_edit_reply,
    } = this.state;
    const comment_array_reversed = comment_array.slice().reverse();
    // const loadingDelete = loading ? (
    //   <div>
    //     <SpinnerSimple width={80} unik="loadingDelete" />
    //   </div>
    // ) : null;
    var itemContent;
    itemContent = (
      <div>
        {!isEmpty(user_props) ? (
          <div>
            <div>
              <div>
                <div
                  key="user_profile"
                  alt="user_profile"
                  className={`bgUser`}
                  draggable={false}
                />
                <style>
                  {`.bgUser{
                  background: url(${
                    user_props &&
                    user_props.profile_picture &&
                    user_props.profile_picture.url_compressed
                      ? user_props.profile_picture.url_compressed
                      : user_props.profile_picture.url
                      ? user_props.profile_picture.url
                      : pic_static
                  }) no-repeat center center/ cover;
                  background-color: #dcdcdc;
                  height: 60px;
                  width: 60px;
                  border-radius: 50%;
                  display: inline-block;
                  align: center;
                  vertical-align: middle;
                  border: 2px solid #b0b0b0;
                  box-shadow: 0 0 12px #0000004e;
                  -webkit-box-shadow: 0 0 12px #0000004e;
                  -moz-box-shadow: 0 0 12px #0000004e;
                  margin-top: 20px;
                  margin-bottom: 20px;
                  }`}
                </style>
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginLeft: "10px",
                    fontSize: "17px",
                    color: "black",
                  }}
                >
                  {user_props && user_props.full_name
                    ? user_props.full_name
                    : user_props.name
                    ? user_props.name
                    : "Username"}
                </div>
              </div>
              <form noValidate onSubmit={this.onSubmit}>
                <TextAreaFieldGroup
                  placeholder="Join the conversation..."
                  name="text_state"
                  type="text"
                  value={text_state}
                  onChange={this.onChange}
                  error={errors.text}
                />
                <button type="submit" className="buttonShadow">
                  Comment
                </button>
              </form>
            </div>
          </div>
        ) : (
          <div>
            <div
              className="buttonShadowSmall"
              onClick={this.toggleLogin.bind(this)}
            >
              Sign in
            </div>{" "}
            to comment this dataset
          </div>
        )}

        {comment_array_reversed.map(({ date, child, _id, user, text }, idx) => {
          const url_pic =
            user && user.profile_picture && user.profile_picture.url_compressed
              ? user.profile_picture.url_compressed
              : user &&
                user.profile_picture &&
                user.profile_picture.url &&
                user.profile_picture.url !== "-"
              ? user.profile_picture.url
              : pic_static;
          const badge = user.badge ? user.badge : "";
          const child_reversed = child.slice().reverse();
          const badge_content = (
            <span>
              <div className="header_span">
                {user.full_name ? user.full_name : "anonym"}
                {badge === "official" ? (
                  <img src={official_badge} alt="official" />
                ) : null}
              </div>
              {item.user._id === user._id ? (
                <div className="smallButton" id="purple">
                  owner
                </div>
              ) : null}
              {user_props._id === user._id ? (
                <span>
                  <div
                    className="smallButton"
                    id="editBright"
                    onClick={this.handleEditComment.bind(this, idx, text)}
                  >
                    edit
                  </div>
                  <div
                    className="smallButton"
                    id="deleteBright"
                    onClick={this.toggleDeleteComment.bind(this, idx, _id)}
                  >
                    delete
                  </div>
                </span>
              ) : null}
              <div className="smallButton" id="bright_clear">
                {moment(date).from()}
              </div>
            </span>
          );
          return (
            <div key={_id} style={{ marginBottom: "20px" }}>
              <div className="comment-container">
                <div>
                  <div
                    key={_id}
                    alt={_id}
                    className={`bg${_id}`}
                    draggable={false}
                  />
                  <style
                    dangerouslySetInnerHTML={{
                      __html: `.bg${_id}{background-image: url(${url_pic});  background-size: cover;height:48px;width:48px; border-radius: 50%;}`,
                    }}
                  />
                </div>
                <div>
                  {badge_content}
                  <div style={{ display: "block" }}>{text}</div>
                  {!isEmpty(user_props) ? (
                    <div
                      className="buttonShadowSmall"
                      onClick={this.handleReply.bind(this, idx)}
                    >
                      Reply
                    </div>
                  ) : null}
                </div>
              </div>
              {index === idx && modal_reply ? (
                <div>
                  <div className="comment-container">
                    <div>
                      <div
                        key={user_props._id}
                        alt={user_props._id}
                        className={`bg${user_props._id}`}
                        draggable={false}
                      />
                      <style
                        dangerouslySetInnerHTML={{
                          __html: `.bg${user_props._id}{background-image: url(${
                            user_props &&
                            user_props.profile_picture &&
                            user_props.profile_picture.url_compressed
                              ? user_props.profile_picture.url_compressed
                              : user_props &&
                                user_props.profile_picture &&
                                user_props.profile_picture.url
                              ? user_props.profile_picture.url
                              : pic_static
                          });
                          background-size: cover;
                          height: 48px;
                          width: 48px;
                          border-radius: 50%;
                        }`,
                        }}
                      />
                    </div>
                    <div>
                      <form noValidate onSubmit={this.onReply}>
                        <TextAreaFieldGroup
                          placeholder="Reply..."
                          name="text_child"
                          type="text"
                          value={text_child}
                          onChange={this.onChange}
                          error={errors.text}
                        />
                        <button type="submit" className="buttonShadowSmall">
                          Reply
                        </button>
                      </form>
                    </div>
                  </div>
                  <br />
                </div>
              ) : (
                <div />
              )}
              {index === idx && modal_edit_comment ? (
                <div>
                  <div className="comment-container">
                    <div>
                      <div
                        key={user_props._id}
                        alt={user_props._id}
                        className={`bg${user_props._id}`}
                        draggable={false}
                      />
                      <style
                        dangerouslySetInnerHTML={{
                          __html: `.bg${user_props._id}{background-image: url(${
                            user_props &&
                            user_props.profile_picture &&
                            user_props.profile_picture.url_compressed
                              ? user_props.profile_picture.url_compressed
                              : user_props &&
                                user_props.profile_picture &&
                                user_props.profile_picture.url
                              ? user_props.profile_picture.url
                              : pic_static
                          });  background-size: cover;height:30px;width:30px; border-radius: 50%;}`,
                        }}
                      />
                    </div>
                    <div>
                      <form noValidate onSubmit={this.onEditComment}>
                        <TextAreaFieldGroup
                          placeholder="Edit..."
                          name="text_edit_comment"
                          type="text"
                          value={text_edit_comment}
                          onChange={this.onChange}
                          error={errors.text}
                        />
                        <button type="submit" className="buttonShadowSmall">
                          Save
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              ) : (
                <div />
              )}
              {child_reversed ? (
                child_reversed.map(({ date, _id, user, text }, idx_child) => {
                  const url_pic_child =
                    user &&
                    user.profile_picture &&
                    user.profile_picture.url_compressed
                      ? user.profile_picture.url_compressed
                      : user && user.profile_picture && user.profile_picture.url
                      ? user.profile_picture.url
                      : pic_static;
                  const badge_child = user.badge ? user.badge : "";
                  const badge_content_child = (
                    <div>
                      <div className="header_span_small">
                        {user.full_name ? user.full_name : "anonym"}
                        {badge_child === "official" ? (
                          <img src={official_badge} alt="official" />
                        ) : null}
                      </div>
                      {item.user._id === user._id ? (
                        <div className="smallButton" id="purple">
                          owner
                        </div>
                      ) : null}
                      {user_props._id === user._id ? (
                        <span>
                          <div
                            className="smallButton"
                            id="editBright"
                            onClick={this.handleEditReply.bind(
                              this,
                              idx,
                              idx_child,
                              text
                            )}
                          >
                            edit
                          </div>
                          <div
                            className="smallButton"
                            id="deleteBright"
                            onClick={this.toggleDeleteReply.bind(
                              this,
                              idx,
                              idx_child,
                              _id
                            )}
                          >
                            delete
                          </div>
                        </span>
                      ) : null}
                      <div className="smallButton" id="bright_clear">
                        {moment(date).from()}
                      </div>
                    </div>
                  );

                  return (
                    <div
                      key={_id}
                      style={{ marginLeft: "65px", marginBottom: "10px" }}
                    >
                      <div className="comment-child-container">
                        <div>
                          <div
                            key={_id}
                            alt={_id}
                            className={`bg${_id}`}
                            draggable={false}
                          />
                          <style
                            dangerouslySetInnerHTML={{
                              __html: `.bg${_id}{background-image: url(${url_pic_child});  background-size: cover;height:30px;width:30px; border-radius: 15px;}`,
                            }}
                          />
                        </div>
                        <div>
                          {badge_content_child}
                          <small>{text}</small>
                        </div>
                      </div>
                      {index === idx &&
                      index_child === idx_child &&
                      modal_edit_reply ? (
                        <div className="comment-container">
                          <div>
                            <div
                              key={user_props._id}
                              alt={user_props._id}
                              className={`bg${user_props._id}`}
                              draggable={false}
                            />
                            <style
                              dangerouslySetInnerHTML={{
                                __html: `.bg${
                                  user_props._id
                                }{background-image: url(${
                                  user_props &&
                                  user_props.profile_picture &&
                                  user_props.profile_picture.url_compressed
                                    ? user_props.profile_picture.url_compressed
                                    : user_props &&
                                      user_props.profile_picture &&
                                      user_props.profile_picture.url
                                    ? user_props.profile_picture.url
                                    : pic_static
                                });  background-size: cover;height:30px;width:30px; border-radius: 15px;}`,
                              }}
                            />
                          </div>
                          <div>
                            <form noValidate onSubmit={this.onEditReply}>
                              <TextAreaFieldGroup
                                placeholder="Edit..."
                                name="text_edit_reply"
                                type="text"
                                value={text_edit_reply}
                                onChange={this.onChange}
                                error={errors.text}
                              />
                              <button
                                type="submit"
                                className="buttonShadowSmall"
                              >
                                Save
                              </button>
                            </form>
                          </div>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  );
                })
              ) : (
                <div />
              )}
            </div>
          );
        })}
      </div>
    );

    const modalDeleteParent = this.state.modal_delete_comment && (
      <ModalComponent
        modalSize="small"
        id="modal"
        isOpen={this.state.modal_delete_comment}
        onClose={this.toggleDeleteComment}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }} className="welcome">
            Are you sure to delete this comment?
            <br />
            <button
              className="buttonShadowSmall"
              id="deleteBright"
              onClick={this.onDeleteComment}
            >
              Delete
            </button>
          </div>
        </div>
      </ModalComponent>
    );

    const modalDeleteChild = this.state.modal_delete_reply && (
      <ModalComponent
        modalSize="small"
        id="modal"
        isOpen={this.state.modal_delete_reply}
        onClose={this.toggleDeleteReply}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }} className="welcome">
            Are you sure to delete this reply?
            <br />
            <button
              className="buttonShadowSmall"
              id="deleteBright"
              onClick={this.onDeleteReply}
            >
              Delete
            </button>
          </div>
        </div>
      </ModalComponent>
    );

    const modalLoginContent = this.state.loginModal && (
      <ModalComponent
        modalSize="medium"
        id="modal"
        isOpen={this.state.loginModal}
        onClose={this.toggleLogin}
      >
        <div className="box-body">
          <Login isNav={true} />
        </div>
      </ModalComponent>
    );

    return (
      <div className="documentContainer" style={{ marginBottom: "200px" }}>
        {itemContent}
        {modalLoginContent}
        {modalDeleteChild}
        {modalDeleteParent}
      </div>
    );
  }
}
Comment.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired,
  addReply: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  editComment: PropTypes.func.isRequired,
  editReply: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  deleteReply: PropTypes.func.isRequired,
  getComment: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  comment: state.comment,
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  addComment,
  addReply,
  clearErrors,
  editComment,
  editReply,
  deleteComment,
  deleteReply,
  getComment,
})(Comment);
