import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function TimeComp(props) {
  const [state, setState] = useState("");
  const filterTime = useSelector((state) => state.config.filterTime);
  const filterTimeCheckbox = useSelector(
    (state) => state.config.filterTimeCheckbox
  );

  useEffect(() => {
    let d = new Date(props.date);
    let h = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
    let m = d.getMinutes() < 10 ? `0${d.getMinutes()}` : `${d.getMinutes()}`;
    let s = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();
    if (filterTime) {
      const minuteSlice = m;
      let firstNumber = minuteSlice.slice("")[0];
      let secondNumber = minuteSlice.slice("")[1];
      if (secondNumber !== "5" && secondNumber !== "0") {
        // secondNumber = "0";
        let intSecondNumber = parseInt(secondNumber);
        if (intSecondNumber < 5) {
          const delta = 5 - intSecondNumber;
          intSecondNumber -= 5 - delta;
        }
        if (intSecondNumber > 5) {
          const delta = 10 - intSecondNumber;
          intSecondNumber -= 5 - delta;
        }
        secondNumber = intSecondNumber;
      }

      if (filterTimeCheckbox) {
        const nowNumber = parseInt(`${firstNumber}${secondNumber}`);
        if (nowNumber >= 0 && nowNumber < 15) {
          firstNumber = 0;
          secondNumber = 0;
        }
        if (nowNumber >= 15 && nowNumber < 30) {
          firstNumber = 1;
          secondNumber = 5;
        }
        if (nowNumber >= 30 && nowNumber < 45) {
          firstNumber = 3;
          secondNumber = 0;
        }
        if (nowNumber >= 45 && nowNumber < 59) {
          firstNumber = 4;
          secondNumber = 5;
        }
      }

      setState(`${h}:${firstNumber}${secondNumber}`);
    } else {
      setState(`${h}:${m}:${s}`);
    }
  }, [props.date, filterTime, filterTimeCheckbox]);

  // useEffect(() => {
  //   setFilterTime(reak.filterTime);
  // }, [reak]);
  return <div>{state}</div>;
}
