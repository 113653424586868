import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";
import DatasetsDefaultRender from "./DatasetsDefaultRender";

class DatasetsDefault extends Component {
  state = {};

  render() {
    const { items, loadingList } = this.props.item;
    let itemContent;
    let welcomeContent;
    if (loadingList) {
      welcomeContent = (
        <div style={{ textAlign: "center", margin: "auto" }}>
          <SpinnerSimpleLogo
            width={200}
            unik="loading_item_list"
            marginTop="0px"
          />
        </div>
      );
    } else {
      if (items.length === 0) {
        welcomeContent = (
          <div style={{ textAlign: "center", margin: "auto" }}>
            <SpinnerSimpleLogo
              width={200}
              unik="loading_item_list"
              marginTop="0px"
            />
          </div>
        );
      } else {
        itemContent = (
          <div style={{ paddingTop: "20px" }}>
            <DatasetsDefaultRender items={items} />
          </div>
        );
      }
    }
    return (
      <div>
        {welcomeContent}
        {itemContent}
      </div>
    );
  }
}
DatasetsDefault.propTypes = {
  item: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  item: state.item,
  auth: state.auth,
});
export default connect(mapStateToProps, {})(DatasetsDefault);
