import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createFolderUser } from "../../actions/itemActions";
import SpinnerSimpleBlue from "../common/SpinnerSimpleBlue";
class AddFolder extends Component {
  state = {
    name: "Untitled Folder"
  };
  handleChange = e => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    const { name } = this.state;
    const content = {
      name
    };
    this.props.createFolderUser(content);
  };
  render() {
    const { name } = this.state;
    const { loading } = this.props.item;
    var itemContent;
    var iconContent;
    if (loading) {
      iconContent = (
        <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
      );
    } else {
      iconContent = (
        <button
          className="buttonSimple2"
          onClick={this.handleSubmit}
          type="submit"
        >
          Save
        </button>
      );
    }
    itemContent = (
      <form>
        <div className="form-group">
          <input
            className="form-control"
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={this.handleChange}
          />
          <div className="text-center">{iconContent}</div>
        </div>
      </form>
    );
    return <div>{itemContent}</div>;
  }
}
AddFolder.propTypes = {
  createFolderUser: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  item: state.item
});
export default connect(mapStateToProps, { createFolderUser })(AddFolder);
