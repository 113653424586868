const formatDate = function (timestamp) {
  let mon = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ];
  let d = new Date(timestamp);
  return `${d.getDate()} ${mon[d.getMonth()]} ${d.getFullYear()}`;
};

export default formatDate;
