import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";
import pic_static from "../../img/profile_pic_comment.svg";
import moment from "moment";
import { getLogItem } from "../../actions/itemActions";
// import Alert from '@material-ui/lab/Alert';
// import { Link } from "react-router-dom";

class Logchart extends Component {
  state = {};
  componentDidMount() {
    this.props.getLogItem(this.props.match.params.id);
    // console.log(this.props)
  }
  render() {
    var { log, loading } = this.props.item;
    if (!log) {
      // console.log("bentar");
    } else {
      var log_data = log.log_data;
      var last_data = log.last_data;
      var gabungan = [];
      var data;
      var options;
      var varTag;
      if (!log_data.var1.values) {
        // console.log("values kosong");
      } else {
        // console.log("mangkat");
        //Chart
        for (var property in log_data) {
          if (log_data.hasOwnProperty(property)) {
            varTag = property;
            var varData = log_data[property];
            var values = varData.values;
            var name = varData.name;
            var unit = varData.unit;
            var xValues = [];
            var yValues = [];
            var arrayValues = [];
            var arrayValuesFiltered = [];
            var tipe = varData.tipe ? varData.tipe : "";
            //min max
            var varLast = last_data[property];
            //filter
            var isFilter = varLast.isFilter ? varLast.isFilter : false;
            var filter = varLast.filter ? varLast.filter : "";
            var filterArray = [];
            var i;
            if (isFilter && filter) {
              for (i in values) {
                //eslint-disable-next-line
                eval(filter);
                yValues.push(filterArray[i]);
                xValues.push(values[i][1]);
              }
            } else {
              for (i in values) {
                yValues.push(values[i][0]);
                xValues.push(values[i][1]);
              }
            }
            for (var e = 0; e < xValues.length; e++) {
              if (varLast.config_sensor) {
                arrayValues.push({
                  x: xValues[e],
                  y: varLast.config_sensor - yValues[e],
                });
              } else {
                arrayValues.push({
                  x: xValues[e],
                  y: yValues[e],
                });
              }
            }
            arrayValuesFiltered = arrayValues
              .filter((value) => value.y !== "NaN")
              .filter((value) => value.y !== "undefined")
              .filter((value) => value.y !== "delete");
          }
          data = {
            datasets: [
              {
                // label: name,
                fill: false,
                lineTension: 0.1,
                backgroundColor: "rgba(75,192,192,0.4)",
                borderColor: "rgba(75,192,192,1)",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                borderWidth: 1.5,
                pointBorderColor: "rgba(75,192,192,1)",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "rgba(75,192,192,1)",
                pointHoverBorderColor: "rgba(220,220,220,1)",
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 10,
                data: arrayValuesFiltered,
              },
            ],
          };
          options = {
            legend: {
              display: false,
            },
            responsive: true,
            scales: {
              xAxes: [
                {
                  id: "one",
                  display: true,
                  type: "time",
                  time: {
                    unit: "day",
                    displayFormats: {
                      day: "D",
                    },
                  },
                  distribution: "linear",
                },
                {
                  id: "two",
                  display: true,
                  type: "time",
                  time: {
                    unit: "month",
                    displayFormats: {
                      month: "MMM",
                    },
                  },
                  distribution: "linear",
                  scaleLabel: {
                    display: true,
                    labelString: "Time",
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: `${unit}`,
                  },
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          };
          gabungan.push({ data, options, varTag, name, tipe });
        }
      }
    }
    var itemContent;
    var headerContent;
    if (loading) {
      itemContent = (
        <div style={{ textAlign: "center" }}>
          <div>
            <br />
            <br />
            <br />
            <div className="text-center">
              <p>Collecting data, please wait...</p>
            </div>
            <SpinnerSimpleLogo
              width={200}
              unik="loading_item_list"
              marginTop="0px"
            />
          </div>
        </div>
      );
    } else if (!log_data) {
      itemContent = (
        <div>
          <h2>
            <br />
            <br />
            {"This dataset is private"} <br />
            {"or your token has been expired,"} <br />
            {"please re-sign in."}
          </h2>
        </div>
      );
    } else if (!values) {
      itemContent = (
        <div>
          <h4>{log.name}</h4>
          <p>There is no dataset yet.</p>
        </div>
      );
    } else {
      headerContent = (
        <div>
          <div
            key="user_profile"
            alt="user_profile"
            className={`bgUser`}
            draggable={false}
          />
          <div
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              marginLeft: "10px",
              fontSize: "17px",
              color: "black",
            }}
          >
            <div className="welcome" style={{ fontSize: "30px" }}>
              {log.name}
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "black",
              }}
            >
              {log && log.user && log.user.full_name
                ? log.user.full_name
                : "Owner"}
            </div>
            <style>
              {`.bgUser{
                  background: url(${
                    log.user &&
                    log.user.profile_picture &&
                    log.user.profile_picture.url_compressed
                      ? log.user.profile_picture.url_compressed
                      : log.user &&
                        log.user.profile_picture &&
                        log.user.profile_picture.url &&
                        log.user.profile_picture.url !== "-"
                      ? log.user.profile_picture.url
                      : pic_static
                  }) no-repeat center center/ cover;
                  background-color: #dcdcdc;
                  height: 80px;
                  width: 80px;
                  border-radius: 50%;
                  display: inline-block;
                  align: center;
                  vertical-align: middle;
                  border: 2px solid #b0b0b0;
                  box-shadow: 0 0 12px #0000004e;
                  -webkit-box-shadow: 0 0 12px #0000004e;
                  -moz-box-shadow: 0 0 12px #0000004e;
                  margin-top: 20px;
                  margin-bottom: 20px;
                  }`}
            </style>
          </div>
          <br />

          <div
            className="buttonShadowSmall"
            style={{
              padding: "10px",
              width: "200px",
              marginRight: "10px",
              textAlign: "left",
            }}
          >
            <div style={{ display: "block", marginBottom: "20px" }}>
              Last update at:
            </div>
            {log.last_entry_at ? moment(log.last_entry_at).from() : "No data"}
          </div>
          <div
            className="buttonShadowSmall"
            style={{
              padding: "10px",
              width: "200px",
              marginRight: "10px",
              textAlign: "left",
            }}
          >
            <div style={{ display: "block", marginBottom: "20px" }}>
              Calls amount:
            </div>
            {log.entries_number}
          </div>
          <div
            className="buttonShadowSmall"
            style={{
              padding: "10px",
              width: "200px",
              marginRight: "10px",
              textAlign: "left",
            }}
          >
            <div style={{ display: "block", marginBottom: "20px" }}>
              Created since:
            </div>
            {moment(log.created_at).from()}
          </div>
          <br />
        </div>
      );
      itemContent = (
        <div>
          <div>
            {gabungan.map(({ varTag, data, options, name, tipe }) => {
              const value =
                tipe === "STRING"
                  ? data.datasets[0].data &&
                    data.datasets[0].data[data.datasets[0].data.length - 1] &&
                    data.datasets[0].data[data.datasets[0].data.length - 1].y
                    ? data.datasets[0].data[data.datasets[0].data.length - 1].y
                    : 0
                  : Number(
                      data.datasets[0].data &&
                        data.datasets[0].data[
                          data.datasets[0].data.length - 1
                        ] &&
                        data.datasets[0].data[data.datasets[0].data.length - 1]
                          .y
                        ? data.datasets[0].data[
                            data.datasets[0].data.length - 1
                          ].y
                        : 0
                    );

              var chartComponent;
              switch (tipe) {
                case "STRING":
                  chartComponent = (
                    <div>
                      <h3>{value}</h3>
                    </div>
                  );
                  break;
                default:
                  chartComponent = (
                    <Line
                      data={data}
                      options={options}
                      key={"chart" + varTag}
                    />
                  );
              }
              return (
                <div key={"column" + varTag} style={{ textAlign: "center" }}>
                  <p className="text-center" key={"text" + varTag}>
                    {name}
                  </p>
                  {chartComponent}
                  <br />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    // const alert = (
    //   <>
    //     <Alert severity="info">This is old page — click <Link to={`/log/${this.props.match.params.id}/v2`}>here</Link> to use new page log data!, use new page for faster.</Alert>
    //   </>
    // )
    return (
      <div className="dashboardSpecial" style={{ marginBottom: "200px" }}>
        {/* {alert} */}
        {headerContent}
        {itemContent}
      </div>
    );
  }
}
Logchart.propTypes = {
  getLogItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  item: state.item,
});
export default connect(mapStateToProps, { getLogItem })(Logchart);
