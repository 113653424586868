import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import ModalComponent from "../common/ModalComponent";
import DatasetsDefault from "./DatasetsDefault";
import DatasetsResume from "./DatasetsResume";
import DatasetsFolder from "./DatasetsFolder";
import AddModal from "./AddModal";
import AddFolder from "./AddFolder";
import SubscribeModal from "./SubscribeModal";
import ImportModal from "./ImportModal";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import {
  clearCurrentItem,
  clearCurrentLog,
  deleteAll,
  searchBus,
  getFoldersByUser,
  getItems,
} from "../../actions/itemActions";
import isEqual from "../../validation/is-equal";
import arrow_light_long from "../../img/arrow_light_long.svg";

import { withStyles } from "@material-ui/styles";
import { Button, Typography } from "@material-ui/core";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import CropFreeIcon from "@material-ui/icons/CropFree";
import FolderIcon from "@material-ui/icons/Folder";
// import CachedIcon from "@material-ui/icons/Cached";

const styles = (theme) => ({
  button: {
    backgroundColor: "#2196f3",
    marginRight: 10,
    "&:hover": {
      background: "#1e5e96",
    },
  },
  toolbar: {
    display: "flex",
    marginBottom: 10,
    overflow: "auto",
    whiteSpace: "nowrap",
  },
});

class Dashboard extends Component {
  state = {
    resume: false,
    modal: false,
    modal_new_folder: false,
    modalSub: false,
    search: "",
    mode: "All Dataset",
    isToyo: false,
  };
  componentDidUpdate(prevProps) {
    //close modal
    const { items, folders_user } = this.props.item;
    const items_prev = prevProps.item.items;
    const folders_user_prev = prevProps.item.folders_user;
    if (
      !isEqual(items, items_prev) ||
      !isEqual(folders_user, folders_user_prev)
    ) {
      this.setState({ modal: false, modalSub: false, modal_new_folder: false });
    } else {
      return null;
    }

    const domain = ["toyo.mapid.io", "localhost"];
    if (domain.includes(window.location.hostname)) {
      this.setState({ isToyo: true });
    }
  }
  toggleMode = (mode) => {
    this.setState({ mode });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  toggleNewFolder = () => {
    this.setState({
      modal_new_folder: !this.state.modal_new_folder,
    });
  };
  toggleSub = () => {
    this.setState({
      modalSub: !this.state.modalSub,
    });
  };
  toggleResume = () => {
    this.setState((prevState) => {
      return { resume: !prevState.resume };
    });
  };
  componentDidMount() {
    this.props.clearCurrentItem();
    this.props.clearCurrentLog();
    const user_id = this.props.auth.user._id;
    this.props.getFoldersByUser(user_id);
    this.props.getItems();
  }
  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.id]: e.target.value });
  };
  handleClick = (e) => {
    const { search } = this.state;
    const config = {
      search: search,
    };
    this.props.searchBus(config);
  };
  render() {
    const { classes } = this.props;
    const { search, isToyo } = this.state;
    const { user } = this.props.auth;
    const full_name = user && user.full_name ? user.full_name : "";
    var warnCompleteProfile;
    if (!full_name) {
      warnCompleteProfile = (
        <div>
          <h6>Please complete your profile</h6>
          <Link to={`/editprofile`}>
            <span className="buttonShadow" id="selected">
              Edit profile
            </span>
          </Link>
        </div>
      );
    } else {
      warnCompleteProfile = <div />;
    }
    const searchContent =
      user.name === "damribus" || user.name === "damri" ? (
        <form noValidate onSubmit={this.handleClick}>
          <div className="input-group">
            <input
              className="form-control form-control-sm text-center form-rounded"
              style={{ color: "#42acffff" }}
              type="text"
              placeholder="Cari bus dengan alamat posisi terbaru, nomor obyek, nomor SIM, atau status AC"
              id="search"
              key="search"
              value={search}
              onChange={this.handleChange.bind(this)}
            />{" "}
            <span className="input-group-btn">
              <button
                className="btn btn-sm btn-work btn-half font-weight-light input-group-btn"
                style={{
                  fontSize: "100%",
                  color: "white",
                  backgroundColor: "#42acffff",
                  backgroundImage: `linear-gradient("#42acffff", "#42acffff")`,
                }}
                onClick={this.handleClick.bind(this)}
                type="submit"
              >
                <img
                  src={arrow_light_long}
                  alt="help"
                  height="15px"
                  className="text-center"
                />
              </button>
            </span>
          </div>
        </form>
      ) : null;
    const modalNewDataset = this.state.modal && (
      <ModalComponent
        modalSize="medium"
        id="modal"
        isOpen={this.state.modal}
        onClose={this.toggle}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }} className="welcome">
            New dataset
          </div>
          <AddModal />
        </div>
      </ModalComponent>
    );
    const modalNewFolder = this.state.modal_new_folder && (
      <ModalComponent
        modalSize="small"
        id="modal"
        isOpen={this.state.modal_new_folder}
        onClose={this.toggleNewFolder}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }}>
            <div className="welcome" style={{ textAlign: "center" }}>
              New folder
            </div>
            <AddFolder />
          </div>
        </div>
      </ModalComponent>
    );
    const modalQR = this.state.modalSub && (
      <ModalComponent
        modalSize="medium"
        id="modal"
        isOpen={this.state.modalSub}
        onClose={this.toggleSub}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }} className="welcome">
            Scan QR code that comes with the device
          </div>
          <SubscribeModal />
        </div>
      </ModalComponent>
    );
    const modalImport = <ImportModal />;
    const array_of_mode = ["All Dataset", "Folder", "Overview"];
    const mode_button = (
      <div style={{ textAlign: "center" }}>
        <div className="toggleContainer">
          {array_of_mode.map((mode, idx) => {
            var activeId;
            if (mode === this.state.mode) {
              activeId = "active";
            } else {
              activeId = "notActive";
            }
            return (
              <div
                onClick={this.toggleMode.bind(this, mode)}
                className="multipleToggle"
                key={idx}
                id={activeId}
              >
                {mode}
              </div>
            );
          })}
        </div>
      </div>
    );
    var listItem;
    switch (this.state.mode) {
      case "All Dataset":
        listItem = <DatasetsDefault />;
        break;
      case "Folder":
        listItem = <DatasetsFolder />;
        break;
      case "Overview":
        listItem = <DatasetsResume />;
        break;
      default:
        listItem = <DatasetsDefault />;
        break;
    }

    return (
      <div
        className="dashboardSpecial"
        style={{ minHeight: "100vh", marginBottom: "100px" }}
      >
        <Helmet>
          <title>Dashboard | IOT MAPID</title>
          <meta name="description" content={`Dashboard | IOT MAPID`} />
        </Helmet>
        <div style={{ paddingTop: "10px" }}>
          <Typography variant="h4" style={{ paddingBottom: "10px" }}>
            Welcome {full_name}
          </Typography>
          {warnCompleteProfile}
          {/* <div className="buttonSimple2" onClick={this.toggle}>
            New Dataset
          </div>
          <div className="buttonSimple2" onClick={this.toggleSub}>
            QR Code
          </div>
          <div className="buttonSimple2" onClick={this.toggleNewFolder}>
            New Personal Folder
          </div> */}
          <div className={classes.toolbar}>
            <div>
              {!isToyo && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<NoteAddIcon />}
                  onClick={this.toggle}
                >
                  New Dataset
                </Button>
              )}
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<CropFreeIcon />}
                onClick={this.toggleSub}
              >
                QR Code
              </Button>
            </div>
            <div>
              {!isToyo && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<FolderIcon />}
                  onClick={this.toggleNewFolder}
                >
                  New Personal Folder
                </Button>
              )}
            </div>
            {!isToyo && modalImport}
            {/* <Link to="data-convert" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<CachedIcon />}
              >
                Data Conversion
              </Button>
            </Link> */}
          </div>
          {searchContent}
          <div>
            {mode_button}
            {listItem}
          </div>
        </div>
        {modalNewDataset}
        {modalNewFolder}
        {modalQR}
      </div>
    );
  }
}
Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  clearCurrentItem: PropTypes.func.isRequired,
  clearCurrentLog: PropTypes.func.isRequired,
  deleteAll: PropTypes.func.isRequired,
  searchBus: PropTypes.func.isRequired,
  getFoldersByUser: PropTypes.func.isRequired,
  getItems: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  item: state.item,
});
export default connect(mapStateToProps, {
  loginUser,
  clearCurrentItem,
  clearCurrentLog,
  deleteAll,
  searchBus,
  getFoldersByUser,
  getItems,
})(withStyles(styles)(Dashboard));
