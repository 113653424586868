import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { getGroups } from "../../actions/itemActions";
import {
  getBracketList,
  setBracket,
  createBracket,
  editBracket,
  pushGroup,
  deleteGroup,
  deleteBracket,
} from "../../actions/editorAction";
import ModalComponent from "../common/ModalComponent";
import SpinnerSimpleBlue from "../common/SpinnerSimpleBlue";

class EditorDashboard extends Component {
  state = {
    modalCreateBracket: false,
    modalEditBracket: false,
    modalPushGroup: false,
    modalDeleteGroup: false,
    modalDeleteBracket: false,
    group_id: "",
    group_name: "",
    bracket: {},
    bracket_name: "",
  };
  componentDidMount() {
    this.props.getGroups(this.props.auth.user._id);
    this.props.getBracketList(this.props.auth.user._id);
  }
  toggleCreateBracket = (group_id, group_name) => {
    this.setState({ group_id, group_name }, () => {
      this.setState({
        modalCreateBracket: !this.state.modalCreateBracket,
      });
    });
  };
  toggleEditBracket = (bracket, bracket_name) => {
    this.setState({ bracket, bracket_name }, () => {
      this.setState({
        modalEditBracket: !this.state.modalEditBracket,
      });
    });
  };
  togglePushGroup = (bracket) => {
    const groups = this.props.item.groups;
    const groups_id = groups.map((group) => group._id);
    const groups_inside_bracket = bracket.groups;
    const groups_id_inside_bracket = groups_inside_bracket.map(
      (group) => group._id
    );
    const groups_id_outside_bracket = groups_id.filter(
      (group) => !groups_id_inside_bracket.includes(group)
    );
    this.setState({ bracket, group_id: groups_id_outside_bracket[0] }, () => {
      this.setState({
        modalPushGroup: !this.state.modalPushGroup,
      });
    });
  };
  toggleDeleteGroup = (group_id, group_name) => {
    this.setState({ group_id, group_name }, () => {
      this.setState({
        modalDeleteGroup: !this.state.modalDeleteGroup,
      });
    });
  };
  toggleDeleteBracket = (bracket) => {
    this.setState({ bracket }, () => {
      this.setState({
        modalDeleteBracket: !this.state.modalDeleteBracket,
      });
    });
  };
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleSubmitCreateBracket = (e) => {
    e.preventDefault();
    const { bracket_name, group_id } = this.state;
    const content = {
      name: bracket_name,
      group_id,
      link: bracket_name
        .replace(/ /g, "_")
        .toLowerCase()
        .replace(/[^\w\s]/gi, ""),
    };
    this.props.createBracket(content);
  };
  handleSubmitEditBracket = (e) => {
    e.preventDefault();
    const { bracket_name, bracket } = this.state;
    const user_id = this.props.auth.user._id;
    const content = {
      name: bracket_name,
      id: bracket._id,
      user_id,
    };
    this.props.editBracket(content);
  };
  handleSubmitPushGroup = (e) => {
    e.preventDefault();
    const { bracket, group_id } = this.state;
    const user_id = this.props.auth.user._id;
    const content = {
      id: bracket._id,
      group_id,
      user_id,
    };
    this.props.pushGroup(content);
  };
  handleSubmitDeleteGroup = (e) => {
    e.preventDefault();
    const { bracket, group_id } = this.state;
    const user_id = this.props.auth.user._id;
    const content = {
      id: bracket._id,
      group_id,
      user_id,
    };
    this.props.deleteGroup(content);
  };
  handleSubmitDeleteBracket = (e) => {
    e.preventDefault();
    const { bracket } = this.state;
    this.props.deleteBracket(bracket._id);
  };
  handleSetBracket = (bracket) => {
    this.props.setBracket(bracket);
  };
  render() {
    //state
    const {
      modalCreateBracket,
      modalEditBracket,
      modalPushGroup,
      modalDeleteGroup,
      modalDeleteBracket,
      bracket_name,
      bracket,
      group_id,
      group_name,
    } = this.state;
    //props
    const { loadingProcess, bracket_list } = this.props.editor;
    const { auth } = this.props;
    const groups = this.props.item.groups;
    const groupsContent = (
      <div>
        <p>GROUPS</p>
        <div className="folder-container">
          {groups.map(({ name, _id }, idx) => {
            return (
              <div
                key={idx}
                className="folder"
                onClick={this.toggleCreateBracket.bind(this, _id, name)}
              >
                {name}
              </div>
            );
          })}
        </div>
      </div>
    );
    const bracketContent = (
      <div>
        <p>DOCUMENTS</p>
        <div className="folder-container">
          {bracket_list.map((bracket, idx) => {
            const { name, link } = bracket;
            return (
              <div key={idx} className="folder">
                {name}
                <div
                  className="buttonClose"
                  id="deleteBold"
                  onClick={this.toggleDeleteBracket.bind(this, bracket)}
                >
                  x
                </div>
                <br />
                <Link
                  className="buttonClose"
                  id="comfort"
                  to={`/viewer/${link}`}
                  onClick={this.handleSetBracket.bind(this, bracket)}
                >
                  Open
                </Link>
                <div
                  className="buttonClose"
                  id="comfort"
                  onClick={this.toggleEditBracket.bind(this, bracket, name)}
                >
                  Rename
                </div>
                <div
                  className="buttonClose"
                  id="comfort"
                  onClick={this.togglePushGroup.bind(this, bracket)}
                >
                  Groups
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
    const isAuthenticated = auth.isAuthenticated;
    const authContent = (
      <div>
        {groupsContent} <br />
        {bracketContent}
      </div>
    );
    const guestContent = <div>Guest</div>;
    const itemContent = isAuthenticated ? authContent : guestContent;
    var iconContentCreateBracket;
    var iconContentEditBracket;
    var iconContentPushGroup;
    var iconContentDeleteGroup;
    var iconContentDeleteBracket;
    if (loadingProcess) {
      iconContentCreateBracket = (
        <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
      );
      iconContentEditBracket = (
        <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
      );
      iconContentPushGroup = (
        <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
      );
      iconContentDeleteGroup = (
        <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
      );
      iconContentDeleteBracket = (
        <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
      );
    } else {
      iconContentCreateBracket = (
        <button
          className="btn buttonSimple2"
          type="submit"
          onClick={this.handleSubmitCreateBracket}
        >
          Create Bracket
        </button>
      );
      iconContentEditBracket = (
        <button
          className="btn buttonSimple2"
          type="submit"
          onClick={this.handleSubmitEditBracket}
        >
          Rename
        </button>
      );
      iconContentPushGroup = (
        <button
          className="btn buttonSimple2"
          type="submit"
          onClick={this.handleSubmitPushGroup}
        >
          Push Group
        </button>
      );
      iconContentDeleteGroup = (
        <button
          className="btn buttonSimple2"
          type="submit"
          onClick={this.handleSubmitDeleteGroup}
        >
          Delete Group
        </button>
      );
      iconContentDeleteBracket = (
        <button
          className="btn buttonSimple2"
          type="submit"
          onClick={this.handleSubmitDeleteBracket}
        >
          Delete Bracket
        </button>
      );
    }
    const modalCreateBracketContent = modalCreateBracket && (
      <ModalComponent
        modalSize="small"
        id="createBracketModal"
        isOpen={modalCreateBracket}
        onClose={this.toggleCreateBracket.bind(this, group_id, group_name)}
      >
        <div className="box-body">
          Create bracket inside {group_name} group
          <form>
            <input
              className="form-control"
              type="text"
              name="bracket_name"
              id="bracket_name"
              value={bracket_name}
              onChange={this.handleChange}
            />
            <div className="text-center">{iconContentCreateBracket}</div>
          </form>
        </div>
      </ModalComponent>
    );
    const modalEditBracketContent = modalEditBracket && (
      <ModalComponent
        modalSize="small"
        id="modalEditBracket"
        isOpen={modalEditBracket}
        onClose={this.toggleEditBracket.bind(this, bracket, bracket_name)}
      >
        <div className="box-body">
          Rename
          <form>
            <input
              className="form-control"
              type="text"
              name="bracket_name"
              id="bracket_name"
              value={bracket_name}
              onChange={this.handleChange}
            />
            <div className="text-center">{iconContentEditBracket}</div>
          </form>
        </div>
      </ModalComponent>
    );
    const modalPushGroupContent = modalPushGroup && (
      <ModalComponent
        modalSize="small"
        id="modalPushGroup"
        isOpen={modalPushGroup}
        onClose={this.togglePushGroup.bind(this, bracket)}
      >
        <div className="box-body">
          Current groups:
          {bracket.groups.map(({ name, _id }, idx) => {
            return (
              <div key={_id}>
                <div className="buttonClose" id="comfort">
                  {name}{" "}
                  <div
                    className="buttonClose"
                    id="deleteBold"
                    onClick={this.toggleDeleteGroup.bind(this, _id, name)}
                  >
                    x
                  </div>
                </div>
                <br />
              </div>
            );
          })}
          <br />
          <label htmlFor="group_id" className="inline-label">
            Push {bracket.name} to group:
          </label>
          <select
            type="text"
            name="group_id"
            id="group_id"
            value={group_id}
            onChange={this.handleChange}
          >
            {groups
              .filter(
                (group) =>
                  !bracket.groups.map((group) => group._id).includes(group._id)
              )
              .map(({ name, _id }, idx) => {
                return (
                  <option key={_id} value={_id}>
                    {name}
                  </option>
                );
              })}
          </select>
          <br />
          {iconContentPushGroup}
        </div>
      </ModalComponent>
    );
    const modalDeleteGroupContent = modalDeleteGroup && (
      <ModalComponent
        modalSize="small"
        id="createBracketModal"
        isOpen={modalDeleteGroup}
        onClose={this.toggleDeleteGroup.bind(this, group_id, group_name)}
      >
        <div className="box-body">
          Are you sure to delete {group_name} from {bracket.name}
          <div className="text-center">{iconContentDeleteGroup}</div>
        </div>
      </ModalComponent>
    );
    const modalDeleteBracketContent = modalDeleteBracket && (
      <ModalComponent
        modalSize="small"
        id="createBracketModal"
        isOpen={modalDeleteBracket}
        onClose={this.toggleDeleteBracket.bind(this, bracket)}
      >
        <div
          className="box-body"
          style={{ margin: "auto", textAlign: "center" }}
        >
          Are you sure to delete {bracket.name}
          <br />
          {iconContentDeleteBracket}
        </div>
      </ModalComponent>
    );
    return (
      <div className="dashboardSpecial">
        <Helmet>
          <title>Editor Doc | IOT MAPID</title>
          <meta name="description" content="Editor Doc | IOT MAPID" />
        </Helmet>
        {itemContent}
        {modalCreateBracketContent}
        {modalEditBracketContent}
        {modalPushGroupContent}
        {modalDeleteGroupContent}
        {modalDeleteBracketContent}
      </div>
    );
  }
}
EditorDashboard.propTypes = {
  getGroups: PropTypes.func.isRequired,
  createBracket: PropTypes.func.isRequired,
  getBracketList: PropTypes.func.isRequired,
  editBracket: PropTypes.func.isRequired,
  pushGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  deleteBracket: PropTypes.func.isRequired,
  setBracket: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  item: state.item,
  auth: state.auth,
  editor: state.editor,
});
export default connect(mapStateToProps, {
  getGroups,
  setBracket,
  createBracket,
  getBracketList,
  editBracket,
  pushGroup,
  deleteGroup,
  deleteBracket,
})(EditorDashboard);
