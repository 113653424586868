//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//Personal Component
import Register from "./Register";
import NavigationLanding from "../layout/NavigationLanding";
//Redux function
import { openModal, setLanguage } from "../../actions/authActions";
//Picture Asset
import landing_background from "../../img/landing_background.svg";
//General Function
import dict from "../../validation/dict.json";
import history from "../../actions/history";
class LandingRegister extends Component {
  state = {
    width: window.innerWidth,
    loginStatus: true,
    specialist_code: "",
  };
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      history.push("/dashboard");
    }
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.setState({ specialist_code: this.props.match.params.code });
    const isJoin = this.props.location.pathname.includes("join");
    this.setState({ loginStatus: !isJoin });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  onSetLanguage = (language) => {
    this.props.setLanguage(language);
  };
  onOpenModal = (name) => {
    this.props.openModal(name);
  };
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth }, () => {});
  };
  toggleLogin = () => {
    this.setState({ loginStatus: !this.state.loginStatus });
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const language_list = [
      {
        name: "INA",
        value: "ina",
      },
      {
        name: "ENG",
        value: "eng",
      },
    ];
    const language_content = (
      <main style={{ marginTop: "10px" }}>
        {language_list.map((e) => {
          let css_id = "outline_white";
          if (e.value === language) {
            css_id = "outline_blue";
          }
          return (
            <div
              onClick={this.onSetLanguage.bind(this, e.value)}
              key={e.value}
              className="button_pill_tight"
              id={css_id}
              style={{ marginRight: "5px" }}
            >
              {e.name}
            </div>
          );
        })}
      </main>
    );
    let title = "IOT MAPID";
    let sub_title = "Platform IOT Indonesia";
    const { width } = this.state;
    const itemContent =
      width < 600 ? (
        <main
          style={{
            width: "100%",
            marginTop: "100px",
            textAlign: "center",
            marginBottom: "100px",
          }}
        >
          <section className="welcomeBig" style={{ marginBottom: "50px" }}>
            {title}
          </section>
          <section
            style={{ width: "80vw", marginLeft: "auto", marginRight: "auto" }}
          >
            <Register />
            <div style={{ display: "block", marginTop: "30px" }}>
              <div className="button_account">
                {dict["Aready have an account?"][language]}
              </div>
              <Link to="/login" className="button_register">
                {dict["Login instead"][language]}
              </Link>
            </div>
            {language_content}
          </section>
        </main>
      ) : (
        <main className="landing-grid" style={{ overflowX: "hidden" }}>
          <section>
            <div
              className="landing-item"
              style={{
                textAlign: "left",
                position: "fixed",
                background: `url(${landing_background}) no-repeat right bottom/ cover`,
              }}
            >
              <div>
                <div className="welcomeBig">{title}</div>
                <div className="welcome_small">{sub_title}</div>
              </div>
            </div>
          </section>
          <section
            className="login_register container_light"
            id="bg_white"
            style={{ zIndex: "2", minWidth: "250px" }}
          >
            <main>
              <Register kode={this?.props?.match?.params?.kode} />
              <div style={{ display: "inline-block", marginRight: "10px" }}>
                <div>
                  <div className="button_account">
                    {dict["Aready have an account?"][language]}
                  </div>
                  <Link to="/login" className="button_register">
                    {dict["Login instead"][language]}
                  </Link>
                </div>
                {language_content}
              </div>
            </main>
          </section>
        </main>
      );
    return (
      <div>
        <NavigationLanding />
        {itemContent}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { openModal, setLanguage })(
  LandingRegister
);
