import axios from "axios";
import {
  GET_ADMIN_ITEMS,
  GET_ADMIN_USERS,
  GET_ADMIN_USERS_PENDING,
  ADMIN_CONFIRM_PAYMENT,
  ADMIN_CANCEL_PAYMENT,
  GET_PAYMENTS,
  ADMIN_LOADING,
  CLEAR_ADMIN_LOADING,
  GET_HELPS,
  GET_ERRORS,
} from "./types";
import his from "./history";

// const SERVER_URL = "http://localhost:4000";
const SERVER_URL = "https://api.mapid.io";
// const SERVER_URL = "http://iotmapidserver.us-west-2.elasticbeanstalk.com";

//GET iot_type
//PUBLIC
export const getIotType = () => (dispatch) => {
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + "/blog/iot_type")
    .then((res) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_IOT_TYPE",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_IOT_TYPE",
        payload: null,
      });
    });
};

//GET geo_type
//PUBLIC
export const getGeoType = () => (dispatch) => {
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + "/blog/geo_type")
    .then((res) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_GEO_TYPE",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_GEO_TYPE",
        payload: null,
      });
    });
};

//GET years_type
//PUBLIC
export const getYearsType = () => (dispatch) => {
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + "/blog/years_type")
    .then((res) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_YEARS_TYPE",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_YEARS_TYPE",
        payload: null,
      });
    });
};

//ADD iot_type
//ADMIN
export const addiot_type = (content) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  dispatch(setAdminLoading());
  axios
    .post(SERVER_URL + "/blog/addiot_type", content, config)
    .then((res) => {
      dispatch(clearAdminLoading());
      dispatch({
        type: "ADD_IOT_TYPE",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearAdminLoading());
    });
};

//ADD geo_type
//ADMIN
export const addgeo_type = (content) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  dispatch(setAdminLoading());
  axios
    .post(SERVER_URL + "/blog/addgeo_type", content, config)
    .then((res) => {
      dispatch(clearAdminLoading());
      dispatch({
        type: "ADD_GEO_TYPE",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearAdminLoading());
    });
};

//ADD years_type
//ADMIN
export const addyears_type = (content) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  dispatch(setAdminLoading());
  axios
    .post(SERVER_URL + "/blog/addyears_type", content, config)
    .then((res) => {
      dispatch(clearAdminLoading());
      dispatch({
        type: "ADD_YEARS_TYPE",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearAdminLoading());
    });
};

//Select picture
export const selectPicture = (content) => (dispatch) => {
  dispatch({
    type: "SELECT_PICTURE",
    payload: content,
  });
};

//ADD upload Doc picture
//ADMIN
export const uploadDocPicture = (content) => (dispatch) => {};

//GET list pictures
//ADMIN
export const getListPictures = (content) => (dispatch) => {
  let token;
  let url_api;
  switch (content.type) {
    case "task":
      url_api = `/blog/piclist_folder/${content.id}`;
      token = localStorage.jwtTokenMapid;
      break;
    case "report":
      url_api = `/blog/piclist_item/${content.id}`;
      token = localStorage.jwtTokenMapid;
      break;
    default:
      url_api = "/blog/piclist";
      token = localStorage.jwtTokenMapidAdmin;
      break;
  }
  const config_axos = {
    headers: {
      accesstoken: token,
    },
  };
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + url_api, config_axos)
    .then((res) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_LIST_PICTURES",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_LIST_PICTURES",
        payload: [],
      });
    });
};

//ADD Create blog
//ADMIN
export const createBlog = (content) => (dispatch) => {
  let token;
  let url_api;
  switch (content.type) {
    case "report":
      url_api = "/blog/create_task";
      token = localStorage.jwtTokenMapid;
      break;
    case "task":
      url_api = "/blog/create_task";
      token = localStorage.jwtTokenMapid;
      break;
    default:
      url_api = "/blog/createblog";
      token = localStorage.jwtTokenMapidAdmin;
      break;
  }
  const config_axos = {
    headers: {
      accesstoken: token,
    },
  };
  dispatch(setLoadingContent());
  axios
    .post(SERVER_URL + url_api, content, config_axos)
    .then((res) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: "CREATE_BLOG",
        payload: res.data,
      });
      if (content.type === "task" || content.type === "report") {
        his.push(`/dashboard`);
      } else {
        his.push(`/admin/doc/${content.current_type}/${content.link}`);
      }
    })
    .catch((err) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
      console.log(err);
    });
};

//GET list of IOT DOC
//ADMIN
export const getListIotDocAdmin = () => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + "/blog/getlistdoc_iotadmin", config)
    .then((res) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_LIST_BLOG_ADMIN",
        payload: { content: res.data, current_type: "doc_iot" },
      });
    })
    .catch((err) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_LIST_BLOG_ADMIN",
        payload: null,
      });
    });
};

//GET list of GEO DOC
//ADMIN
export const getListGeoDocAdmin = () => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + "/blog/getlistdoc_geoadmin", config)
    .then((res) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_LIST_BLOG_ADMIN",
        payload: { content: res.data, current_type: "doc_geo" },
      });
    })
    .catch((err) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_LIST_BLOG_ADMIN",
        payload: null,
      });
    });
};

//GET list of BLOG
//ADMIN
export const getListBlogAdmin = () => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + "/blog/getlistblogadmin", config)
    .then((res) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_LIST_BLOG_ADMIN",
        payload: { content: res.data, current_type: "blog" },
      });
    })
    .catch((err) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_LIST_BLOG_ADMIN",
        payload: null,
      });
    });
};

//GET blog by link
//ADMIN
export const getBlogAdmin = (content) => (dispatch) => {
  let token;
  let url_api;
  switch (content.type) {
    case "task":
      url_api = `/blog/getblog_folder/${content.id}`;
      token = localStorage.jwtTokenMapid;
      break;
    case "report":
      url_api = `/blog/getblog_dataset/${content.id}`;
      token = localStorage.jwtTokenMapid;
      break;
    default:
      url_api = `/blog/getblogadmin/${content.link}`;
      token = localStorage.jwtTokenMapidAdmin;
      break;
  }
  const config_axos = {
    headers: {
      accesstoken: token,
    },
  };
  dispatch(setLoadingContent());
  axios
    .get(SERVER_URL + url_api, config_axos)
    .then((res) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: "GET_BLOG_ADMIN",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: "GET_BLOG_ADMIN",
        payload: {},
      });
    });
};

//GET blog by _id
//ADMIN
export const getBlogAdminId = (content) => (dispatch) => {
  let token;
  let url_api;
  switch (content.type) {
    case "task":
      url_api = `/blog/getblog_folder/${content.id}`;
      token = localStorage.jwtTokenMapid;
      break;
    case "report":
      url_api = `/blog/getblog_dataset/${content.id}`;
      token = localStorage.jwtTokenMapid;
      break;
    default:
      url_api = `/blog/getblogadminid/${content.link}`;
      token = localStorage.jwtTokenMapidAdmin;
      break;
  }
  const config_axos = {
    headers: {
      accesstoken: token,
    },
  };
  dispatch(setLoadingContent());
  axios
    .get(SERVER_URL + url_api, config_axos)
    .then((res) => {
      console.log("data=", res.data);
      dispatch(clearLoadingContent());
      dispatch({
        type: "GET_BLOG_ADMIN",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: "GET_BLOG_ADMIN",
        payload: {},
      });
    });
};

//CLEAR BLOG CONTENT
//OPEN
export const clearBlog = () => (dispatch) => {
  dispatch({
    type: "GET_BLOG_ADMIN",
    payload: "",
  });
};

//SET_CURRENT_TYPE
//OPEN
export const setCurrentType = (type) => (dispatch) => {
  dispatch({
    type: "SET_CURRENT_TYPE",
    payload: type,
  });
};

//DELETE blog by _id
//ADMIN
export const deleteBlog = (content) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  dispatch(setAdminLoading());
  axios
    .delete(SERVER_URL + `/blog/deleteblog/${content.id}`, config)
    .then((res) => {
      dispatch(clearAdminLoading());
      dispatch({
        type: "DELETE_BLOG",
        payload: content.id,
      });
      his.push(`/admin/doc/${content.current_type}`);
    })
    .catch((err) => {
      dispatch(clearAdminLoading());
    });
};

//UPDATE blog by _id
//ADMIN
export const updateBlog = (content) => (dispatch) => {
  let token;
  let url_api;
  switch (content.type) {
    case "task":
      url_api = "/blog/updateblog_report";
      token = localStorage.jwtTokenMapid;
      break;
    case "report":
      url_api = "/blog/updateblog_report";
      token = localStorage.jwtTokenMapid;
      break;
    default:
      url_api = "/blog/updateblog";
      token = localStorage.jwtTokenMapidAdmin;
      break;
  }
  const config_axos = {
    headers: {
      accesstoken: token,
    },
  };
  dispatch(setLoadingList());
  axios
    .post(SERVER_URL + url_api, content, config_axos)
    .then((res) => {
      dispatch(clearLoadingList());
      if (content.type === "task" || content.type === "report") {
        his.push(`/dashboard`);
      } else {
        his.push(`/admin/doc/${content.current_type}/${content.link}`);
      }
    })
    .catch((err) => {
      dispatch(clearLoadingList());
      console.log(err);
    });
};

//ADD Create tutorial
//ADMIN
export const createTutorial = (tutorial) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  axios
    .post(SERVER_URL + "/admin/createtutorial", tutorial, config)
    .then((res) => {
      dispatch({
        type: "CREATE_TUTORIAL",
        payload: res.data,
      });
      his.push("/admin/tutorial");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
      his.push("/admin/tutorial");
    });
};

//Get list of tutorial admin
export const getListTutorialAdmin = () => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + "/admin/getlisttutorialadmin", config)
    .then((res) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_LIST_TUTORIAL_ADMIN",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_LIST_TUTORIAL_ADMIN",
        payload: null,
      });
    });
};

//Get list of tutorial public
export const getListTutorialPublic = () => (dispatch) => {
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + "/admin/getlisttutorialpublic")
    .then((res) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_LIST_TUTORIAL_PUBLIC",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_LIST_TUTORIAL_PUBLIC",
        payload: null,
      });
    });
};

//Get tutorial by link admin
export const getTutorialAdmin = (link) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  dispatch(setLoadingContent());
  axios
    .get(SERVER_URL + `/admin/gettutorialadmin/${link}`, config)
    .then((res) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: "GET_TUTORIAL_ADMIN",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: "GET_TUTORIAL_ADMIN",
        payload: "",
      });
    });
};

export const clearTutorialAdmin = () => {
  return {
    type: "CLEAR_TUTORIAL_ADMIN",
  };
};

//Get tutorial by _id public
export const getTutorialPublic = (link) => (dispatch) => {
  dispatch(setLoadingContent());
  axios
    .get(SERVER_URL + `/admin/gettutorialpublic/${link}`)
    .then((res) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: "GET_TUTORIAL_PUBLIC",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: "GET_TUTORIAL_PUBLIC",
        payload: "",
      });
    });
};
export const clearTutorialPublic = () => {
  return {
    type: "CLEAR_TUTORIAL_PUBLIC",
  };
};

//Update tutorial by _id
export const updateTutorial = (tutorial) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  axios
    .post(SERVER_URL + "/admin/updatetutorial", tutorial, config)
    .then((res) => {
      his.push("/admin/tutorial");
    })
    .catch((err) => {
      his.push("/admin/tutorial");
    });
};

//Delete tutorial by _id
export const deleteTutorial = (id) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  dispatch(setAdminLoading());
  axios
    .delete(SERVER_URL + `/admin/deletetutorial/${id}`, config)
    .then((res) => {
      dispatch(clearAdminLoading());
      dispatch({
        type: "DELETE_TUTORIAL",
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(clearAdminLoading());
    });
};

//Get devices
export const getAdminItems = () => (dispatch) => {
  dispatch(setAdminLoading());
  const configAdmin = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  axios
    .get(SERVER_URL + "/admin/items", configAdmin)
    .then((res) => {
      dispatch(clearAdminLoading());
      dispatch({
        type: GET_ADMIN_ITEMS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch(clearAdminLoading());
    });
};

//Get users
export const getAdminUsers = () => (dispatch) => {
  dispatch(setAdminLoading());
  const configAdmin = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  axios
    .get(SERVER_URL + "/admin/users", configAdmin)
    .then((res) => {
      dispatch(clearAdminLoading());
      dispatch({
        type: GET_ADMIN_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearAdminLoading());
      dispatch({
        type: GET_ADMIN_USERS,
        payload: null,
      });
    });
};

//Get all payments
export const getAdminPayments = () => (dispatch) => {
  dispatch(setAdminLoading());
  const configAdmin = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  axios
    .get(SERVER_URL + "/admin/payments", configAdmin)
    .then((res) => {
      dispatch(clearAdminLoading());
      dispatch({
        type: GET_PAYMENTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearAdminLoading());
      console.log(err);
    });
};

export const getAdminUsersPending = () => (dispatch) => {
  const configAdmin = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };

  dispatch(setAdminLoading());
  axios
    .get(SERVER_URL + "/admin/userspending", configAdmin)
    .then((res) => {
      dispatch(clearAdminLoading());
      dispatch({
        type: GET_ADMIN_USERS_PENDING,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ADMIN_USERS_PENDING,
        payload: null,
      });
      dispatch(clearAdminLoading());
    });
};

//IOT
//confirm payment
export const iotConfirmPayment = (item) => (dispatch) => {
  const configAdmin = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  axios
    .post(SERVER_URL + "/admin/confirmpaymentiot", item, configAdmin)
    .then((res) =>
      dispatch({
        type: ADMIN_CONFIRM_PAYMENT,
        payload: res.data,
      })
    );
};

//GEO
//confirm payment
export const geoConfirmPayment = (item) => (dispatch) => {
  const configAdmin = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  axios
    .post(SERVER_URL + "/admin/confirmpaymentgeo", item, configAdmin)
    .then((res) =>
      dispatch({
        type: ADMIN_CONFIRM_PAYMENT,
        payload: res.data,
      })
    );
};

//IOT
//cancel payment
export const iotCancelPayment = (item) => (dispatch) => {
  const configAdmin = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  axios.post(SERVER_URL + "/admin/canceliot", item, configAdmin).then((res) =>
    dispatch({
      type: ADMIN_CANCEL_PAYMENT,
      payload: res.data,
    })
  );
};

//GEO
//cancel payment
export const geoCancelPayment = (item) => (dispatch) => {
  const configAdmin = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  axios.post(SERVER_URL + "/admin/cancelgeo", item, configAdmin).then((res) =>
    dispatch({
      type: ADMIN_CANCEL_PAYMENT,
      payload: res.data,
    })
  );
};

//get help messages
export const getAdminHelps = () => (dispatch) => {
  dispatch(setAdminLoading());
  const configAdmin = {
    headers: {
      accesstoken: localStorage.jwtTokenMapidAdmin,
    },
  };
  axios
    .get(SERVER_URL + "/admin/helps", configAdmin)
    .then((res) => {
      dispatch(clearAdminLoading());
      dispatch({
        type: GET_HELPS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch(clearAdminLoading());
    });
};

//admin loading
export const setAdminLoading = () => {
  return {
    type: ADMIN_LOADING,
  };
};
//admin clear loading
export const clearAdminLoading = () => {
  return {
    type: CLEAR_ADMIN_LOADING,
  };
};

//admin loading
export const setLoadingContent = () => {
  return {
    type: "SET_TUTORIAL_CONTENT_LOADING",
  };
};
//admin clear loading
export const clearLoadingContent = () => {
  return {
    type: "CLEAR_TUTORIAL_CONTENT_LOADING",
  };
};

//admin loading
export const setLoadingList = () => {
  return {
    type: "SET_TUTORIAL_LIST_LOADING",
  };
};
//admin clear loading
export const clearLoadingList = () => {
  return {
    type: "CLEAR_TUTORIAL_LIST_LOADING",
  };
};
