/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import { getItemPublic } from "../../actions/itemActions";
import Counter from "../widget/Counter";
import Gauge from "../widget/Gauge";
import Compass from "../widget/Compass";
class WoNav extends Component {
  interval = null;
  componentDidMount() {
    var id =
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : this.props.id;
    this.props.getItemPublic(id);
    this.interval = setInterval(() => {
      this.props.getItemPublic(id);
    }, 4000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const { item } = this.props.item;
    const property = this.props.match.params.property;
    const chartType = this.props.match.params.type
      ? this.props.match.params.type
      : "chart";
    if (!item) {
      return null;
    } else {
      var last_data = item.last_data;
      var buffer_data = item.buffer_data;
      var gabungan = [];
      var data;
      var options;
      var varTag;
      var warningContent = {};
      if (buffer_data.hasOwnProperty(property)) {
        varTag = property;
        var varData = buffer_data[property];
        var values = varData.values;
        var name = varData.name;
        var unit = varData.unit;
        //min max
        var varLastL = last_data[property];
        var nMinL = varLastL.nMin;
        var nMaxL = varLastL.nMax;
        if (item.isNotif) {
          var varLast = last_data[property];
          var notif = varLast.notif;
          var nMin = varLast.nMin;
          var nMax = varLast.nMax;
          var lastVal = varLast.values[0];
          if (notif) {
            if (lastVal < nMin || lastVal > nMax) {
              var color = "rgba(240,101,40,255)";
              warningContent = (
                <div className="btn btn-warning btn-sm">
                  Warning: {name} is now {lastVal} {unit}
                </div>
              );
            } else {
              color = "rgba(75,192,192,1)";
              warningContent = <div />;
            }
          } else {
            // eslint-disable-next-line
            color = "rgba(75,192,192,1)";
            warningContent = <div />;
          }
        } else {
          color = "rgba(75,192,192,1)";
          warningContent = <div />;
        }
        var xValues = [];
        var yValues = [];
        var arrayValues = [];
        var tipe = varData.tipe ? varData.tipe : "";
        var correction = varData.correction ? varData.correction : 0;
        var filter = [];
        if (tipe === "WL") {
          for (let i = 1; i < values.length; i++) {
            var prevOne = values[i - 1] ? values[i - 1] : values[i];
            var prevThree = values[i - 3] ? values[i - 3] : values[i];
            var afteThree = values[i + 3] ? values[i + 3] : values[i];
            if (
              values[i][0] - prevOne[0] > 30 ||
              values[i][0] - prevOne[0] < -30 ||
              values[i][0] - prevThree[0] > 900 ||
              values[i][0] - prevThree[0] < -900 ||
              values[i][0] - afteThree[0] > 900 ||
              values[i][0] - afteThree[0] < -900 ||
              values[i][0] > 270 ||
              values[i][0] < 90
            ) {
              // filter[i] = (prevThree[0] + afteThree[0]) / 2;
              filter[i] = "error";
            } else {
              filter[i] = values[i][0];
            }
            yValues.push(parseFloat(correction - filter[i]).toFixed(2));
            xValues.push(values[i][1]);
          }
        } else {
          for (let i in values) {
            yValues.push(values[i][0]);
            xValues.push(values[i][1]);
          }
        }
        for (let e = 0; e < xValues.length; e++) {
          arrayValues.push({
            x: xValues[e],
            y: yValues[e],
          });
        }
        const panjang = arrayValues.length;
        var arrayRealtime = [];
        if (panjang < 10) {
          arrayRealtime = arrayValues;
        } else {
          arrayRealtime = arrayValues.slice(panjang - 10, panjang);
        }
      }
      data = {
        datasets: [
          {
            // label: name,
            fill: false,
            lineTension: 0.1,
            backgroundColor: "rgba(75,192,192,0.4)",
            // borderColor: "rgba(75,192,192,1)",
            borderColor: color,
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: color,
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(75,192,192,1)",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: arrayRealtime,
          },
        ],
      };
      options = {
        animation: {
          duration: 3000,
          easing: "easeOutCirc",
        },
        legend: {
          display: false,
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              display: true,
              type: "time",
              time: {
                parser: "MM/DD/YYYY hh:mm:ss a",
                displayFormats: {
                  minute: "HH:mm",
                },
              },
              distribution: "linear",
              scaleLabel: {
                display: true,
                labelString: "Time",
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: `${unit}`,
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        maintainAspectRatio: true,
      };
      gabungan.push({
        data,
        options,
        varTag,
        name,
        warningContent,
        tipe,
        nMinL,
        nMaxL,
      });
    }
    var itemContent;
    if (!item) {
      itemContent = (
        <div>
          <b>Loading...</b>
        </div>
      );
    } else {
      itemContent = (
        <div className="nomargin">
          <div className="row">
            {gabungan.map(
              ({
                varTag,
                data,
                options,
                name,
                warningContent,
                nMinL,
                nMaxL,
              }) => {
                var chartComponent;
                nMinL = Number(nMinL);
                nMaxL = Number(nMaxL);
                // const nMinWidget = nMin - Math.abs(nMax - nMin) * 0.1;
                const nMaxWidget = nMaxL + Math.abs(nMaxL - nMinL) * 0.1;
                switch (chartType) {
                  case "ARC":
                    chartComponent = (
                      <Counter
                        value={
                          data.datasets[0].data &&
                          data.datasets[0].data[
                            data.datasets[0].data.length - 1
                          ] &&
                          data.datasets[0].data[
                            data.datasets[0].data.length - 1
                          ].y
                            ? data.datasets[0].data[
                                data.datasets[0].data.length - 1
                              ].y
                            : 0
                        }
                        nMin={nMinL}
                        nMax={nMaxWidget}
                        unit={options.scales.yAxes[0].scaleLabel.labelString}
                      />
                    );
                    break;
                  case "GG":
                    chartComponent = (
                      <Gauge
                        value={
                          data.datasets[0].data &&
                          data.datasets[0].data[
                            data.datasets[0].data.length - 1
                          ] &&
                          data.datasets[0].data[
                            data.datasets[0].data.length - 1
                          ].y
                            ? data.datasets[0].data[
                                data.datasets[0].data.length - 1
                              ].y
                            : 0
                        }
                        nMin={nMinL}
                        nMax={nMaxWidget}
                        unit={options.scales.yAxes[0].scaleLabel.labelString}
                      />
                    );
                    break;
                  case "CMP":
                    chartComponent = (
                      <Compass
                        value={
                          data.datasets[0].data &&
                          data.datasets[0].data[
                            data.datasets[0].data.length - 1
                          ] &&
                          data.datasets[0].data[
                            data.datasets[0].data.length - 1
                          ].y
                            ? data.datasets[0].data[
                                data.datasets[0].data.length - 1
                              ].y
                            : 0
                        }
                        nMin={0}
                        nMax={360}
                        unit={options.scales.yAxes[0].scaleLabel.labelString}
                      />
                    );
                    break;
                  case "LAT":
                    chartComponent = null;
                    break;
                  case "LON":
                    chartComponent = null;
                    break;
                  default:
                    chartComponent = (
                      <div>
                        <Line
                          data={data}
                          options={options}
                          key={"chart" + varTag}
                        />
                      </div>
                    );
                }
                return (
                  <div className="col-md-6" key={"column" + varTag}>
                    <h4 className="text-center" key={"text" + varTag}>
                      {name}
                    </h4>
                    <div className="nomargin">{chartComponent}</div>
                    <div className="text-center">{warningContent}</div>
                    <div className="text-left">
                      <a
                        href="https://iot.mapid.io"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Mapid
                      </a>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      );
    }
    return (
      <div
        className="noNav"
        style={{ textAlign: "center", maxHeight: "100vh" }}
      >
        {itemContent}
        <style
          dangerouslySetInnerHTML={{
            __html: `
            .noNav, body{padding-top: 0px;}
            .topnav{ visibility: hidden;}
            `,
          }}
        />
      </div>
    );
  }
}
WoNav.propTypes = {
  getItemPublic: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({ item: state.item });
export default connect(mapStateToProps, { getItemPublic })(WoNav);
