import React, { Component } from "react";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import { Button } from "@material-ui/core";
import { load_more_new_dataset } from "../../actions/itemActions";

export class Chart extends Component {
  load_more_data = () => {
    this.props.load_more_new_dataset(true);
  };
  render() {
    const { new_dataset } = this.props.item;
    if (new_dataset.log_data) {
      const log_data = new_dataset.log_data;
      return (
        <div style={{ padding: "1rem 0rem" }}>
          {log_data.map((element, idx) => {
            const options = {
              maintainAspectRatio: true,
              legend: {
                display: false,
              },
              responsive: true,
              scales: {
                xAxes: [
                  {
                    id: "one",
                    display: true,
                    type: "time",
                    time: {
                      displayFormats: {
                        day: "MMM D",
                        hour: "hA",
                        minute: "HH.mm a",
                      },
                    },
                    distribution: "linear",
                  },
                  {
                    id: "two",
                    display: true,
                    type: "time",
                    time: {
                      unit: "day",
                      displayFormats: {
                        day: "MMM D",
                      },
                    },
                    distribution: "linear",
                    scaleLabel: {
                      display: false,
                      labelString: "Time",
                    },
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    scaleLabel: {
                      display: false,
                      labelString: `${element.sensorUnit}`,
                    },
                    ticks: {
                      beginAtZero: true,
                      source: "auto",
                    },
                  },
                ],
              },
            };
            const data = {
              datasets: [
                {
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: "rgba(53,162,235,0.4)",
                  borderColor: "rgba(53,162,235,1)",
                  borderCapStyle: "butt",
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderWidth: 1.5,
                  borderJoinStyle: "miter",
                  pointBorderColor: "rgba(53,162,235,1)",
                  pointBackgroundColor: "#fff",
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: "rgba(53,162,235,1)",
                  pointHoverBorderColor: "rgba(220,220,220,1)",
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: element.sensorData,
                },
              ],
            };
            return (
              <div
                key={idx}
                style={{
                  border: "solid 1px lightgrey",
                  borderRadius: ".5rem",
                  padding: ".5rem",
                }}
              >
                <h3>
                  {element.sensorName}({element.sensorUnit})
                </h3>
                <Line data={data} options={options} key={idx}></Line>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={this.load_more_data}
                >
                  More Data
                </Button>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

const mapStateToProps = (state) => ({
  item: state.item,
});

const mapDispatchToProps = {
  load_more_new_dataset,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
