import React, { Component } from "react";
import { connect } from "react-redux";
import mapidLogo from "../../img/logo_outline.svg";

class SpinnerSimpleLogo extends Component {
  render() {
    const { width } = this.props;
    const imageWidth = width / 1.7;
    const borderWidth = width / 12;
    return (
      <div className="image">
        <img
          src={mapidLogo}
          alt="Mapid Logo"
          style={{
            width: `${imageWidth}px`,
            float: "right",
            position: "relative",
            right: `${(width - imageWidth) / 1.7}px`,
            top: `${(width - imageWidth) / 2.7}px`,
            overflow: "hidden",
          }}
        />
        <div className="muter"></div>
        <style>
          {`
              .image{
                width: ${width}px;
                height: ${width}px;
                display: inline-block;
                margin-top:${
                  this.props.marginTop ? this.props.marginTop : "0px"
                };
              }
              .muter {
                border: ${borderWidth}px solid #2ca1fdff;
                border-radius: 50%;
                border-top: ${borderWidth}px solid #7eb532ff;
                border-right: ${borderWidth}px solid #1e5e96ff;
                width: ${width}px;
                height: ${width}px;
                -webkit-animation: spin 2s linear infinite; /* Safari */
                animation: spin 2s linear infinite;
                margin: auto;
              }
              /* Safari */
              @-webkit-keyframes spin {
                0% { -webkit-transform: rotate(0deg); }
                100% { -webkit-transform: rotate(360deg); }
              }
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
          `}
        </style>
      </div>
    );
  }
}
SpinnerSimpleLogo.propTypes = {};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(SpinnerSimpleLogo);
