import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  setCurrentUser,
  logoutUser,
  getUserFromToken
} from "../../actions/authActions";
import his from "../../actions/history";

class GetToken extends Component {
  componentDidMount() {
    if (this.props.match.params.token) {
      const token = this.props.match.params.token;
      const decoded = jwt_decode(token);
      console.log("decoded", decoded.username);
      localStorage.setItem("jwtTokenMapid", token);
      localStorage.setItem("mapidusername", decoded.name);
      this.props.getUserFromToken(token);
      const expireDate = decoded.exp;
      const currentTime = Date.now() / 1000;
      his.push("/dashboard");
      if (expireDate < currentTime) {
        //Log user out
        this.props.logoutUser();
      }
    } else {
      // console.log("bukan user");
    }
  }

  render() {
    return <div>Get token</div>;
  }
}
GetToken.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  getUserFromToken: PropTypes.func.isRequired
};
const mapStateToProps = state => ({});
export default connect(
  mapStateToProps,
  { setCurrentUser, logoutUser, getUserFromToken }
)(GetToken);
