import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import arrow_icon from "../../img/auth.svg";

class ThankYou extends Component {
  render() {
    const sample = (
      <div>
        Your device outside the <b>minimum</b> limit on <b>${"item.name"}</b>{" "}
        dataset.
        <br />
        <table>
          <tbody>
            <tr>
              <td>${"name"}</td>
              <td>
                <b>${"req.query[property]"}</b> ${"unit"}
              </td>
            </tr>
            <tr>
              <td>${"name"}</td>
              <td>
                <b>${"req.query[property]"}</b> ${"unit"}
              </td>
            </tr>
            <tr>
              <td>${"name"}</td>
              <td>
                <b>${"req.query[property]"}</b> ${"unit"}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <h6>IOT MAPID NOTIFICATION</h6>
      </div>
    );
    return (
      <div>
        {sample}
        <div className="container text-center">
          <h1>Thank you for your feedback!</h1>
          <p>We will then contact you by email.</p>
          <br />
          <div className="text-center">
            <Link to={"/"}>
              <button
                type="submit"
                className="badge badge-pill badge-primary font-weight-light"
                style={{ fontSize: "160%" }}
              >
                <div className="row">
                  <div className="col text-left">Back to landing page</div>
                  <div className="col text-right nomargin">
                    <img
                      src={arrow_icon}
                      alt="upload"
                      width="25"
                      className="text-left"
                    />
                  </div>
                </div>
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
ThankYou.propTypes = {};
const mapStateToProps = state => ({});
export default connect(
  mapStateToProps,
  {}
)(ThankYou);
