import isEmpty from "../validation/is-empty";
import {
  SET_CURRENT_USER,
  SET_CURRENT_ADMIN,
  ADD_BANK,
  ACTIVATE_BANK,
  AUTH_LOADING,
  CLEAR_AUTH_LOADING,
} from "../actions/types";
const initialState = {
  isAuthenticated: false,
  language: "indonesia",
  user: {},
  isAdmin: false,
  admin: {},
  loading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };
    case AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_AUTH_LOADING:
      return {
        ...state,
        loading: false,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case SET_CURRENT_ADMIN:
      return {
        ...state,
        isAdmin: !isEmpty(action.payload),
        admin: action.payload,
      };
    case ADD_BANK:
      return {
        ...state,
        user: action.payload,
      };
    case ACTIVATE_BANK:
      return {
        ...state,
        user: action.payload,
      };
    case "UPDATE_USER_PROFILE":
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}
