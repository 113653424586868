import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ModalComponent from "../common/ModalComponent";
import { logoutUser } from "../../actions/authActions";
import { clearCurrentItems } from "../../actions/itemActions";
import { subNotification } from "../../actions/commentAction";
import pic_static from "../../img/profile_pic_comment.svg";
import mapidLogo from "../../img/logo_outline.svg";
import signoutIcon from "../../img/signout.svg";
import telegram from "../../img/telegram.png";
import facebook from "../../img/facebook.png";
import logo_iot_circle from "../../img/logo_iot_circle.png";
import Login from "../auth/Login";
import VersioningInfo from "../auth/VersioningInfo";

class Navigation extends Component {
  state = {
    loginModal: false,
    width: window.innerWidth,
    version: "1.0.0",
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      return {
        loginModal: false,
      };
    } else return null;
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    if (!this.props.auth.isAuthenticated) {
      this.setState({ loginModal: true });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  toggleLogin = () => {
    this.setState({
      loginModal: !this.state.loginModal,
    });
  };
  onLogoutClick() {
    this.props.clearCurrentItems();
    this.props.logoutUser();
  }
  render() {
    const { width, version } = this.state;
    const isMobile = width <= 800;
    const { auth } = this.props;
    const user_props = auth && auth.user ? auth.user : {};
    const isAuthenticated = auth.isAuthenticated;
    const authLinks = (
      <div>
        <div
          className="right"
          onClick={this.onLogoutClick.bind(this)}
          style={{ cursor: "pointer" }}
        >
          {isMobile ? (
            "Signout"
          ) : (
            <img src={signoutIcon} alt="signout" height="22px" />
          )}
        </div>
        <Link className="right" to="/user">
          <div
            key="User photo"
            alt="User photo"
            className={`photoNav`}
            draggable={false}
          />
          <style>
            {`.photoNav{background-image: url(${
              user_props &&
              user_props.profile_picture &&
              user_props.profile_picture.url_compressed
                ? user_props.profile_picture.url_compressed
                : user_props &&
                  user_props.profile_picture &&
                  user_props.profile_picture.url
                ? user_props.profile_picture.url
                : pic_static
            });  background-size: cover;height:26px;width:26px; border-radius: 13px;}`}
          </style>
        </Link>
      </div>
    );
    const guestLinks = (
      <div className="right">
        <div
          className="buttonShadowSmall"
          onClick={this.toggleLogin.bind(this)}
        >
          Sign in
        </div>
      </div>
    );
    const socialMediaContent = (
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <a
          href="https://www.facebook.com/groups/mapidforum"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={facebook} height="40px" alt="facebook" style={{}} />
        </a>
        <a
          href="https://t.me/joinchat/P3iwAxTh-0qKSPOmKiSCyQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={telegram}
            height="40px"
            alt="telegram"
            style={{
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
        </a>
        <Link to="/public">
          <img
            src={logo_iot_circle}
            height="40px"
            alt="iot mapid forum"
            style={{}}
          />
        </Link>
      </div>
    );
    const content_1 = (
      <div className="topnav navbarDefault">
        <div className="dashboardSpecial">
          <Link to={"/dashboard"}>
            <img
              src={mapidLogo}
              height="27px"
              alt="Mapid Iot Internet of Things"
            />
            <small style={{ marginLeft: "1rem" }}>{version}</small>
          </Link>
          {!isMobile ? (
            <Link to={"/dashboard"}>
              <div className="navbarBrand">TOYO SENSING</div>
            </Link>
          ) : null}
          <div>
            {/* {!isMobile ? (
              <a
                href="https://mapid.io/product"
                target="_blank"
                rel="noopener noreferrer"
                className="navbarItem"
              >
                About
              </a>
            ) : null} */}
            {/* <a
              href="https://doc.mapid.io/doc_iot"
              target="_blank"
              rel="noopener noreferrer"
              className="navbarItem"
            >
              Docs
            </a>
            <Link to="/public" className="navbarItem">
              Community
            </Link> */}
            {isMobile ? (isAuthenticated ? authLinks : guestLinks) : null}
          </div>
          {!isMobile ? (isAuthenticated ? authLinks : guestLinks) : null}

          {this.state.loginModal && (
            <ModalComponent
              modalSize="small"
              id="loginModal"
              isOpen={this.state.loginModal}
              onClose={this.toggleLogin}
            >
              <div className="box-body">
                <Login isNav={true} />
                <div style={{ textAlignLast: "center" }}>
                  {" "}
                  <VersioningInfo width={width} isNav={true} />
                </div>
                <div style={{ textAlignLast: "center" }}>
                  {socialMediaContent}
                </div>
              </div>
            </ModalComponent>
          )}
        </div>
      </div>
    );

    return <div>{content_1}</div>;
  }
}
Navigation.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  clearCurrentItems: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  logoutUser,
  clearCurrentItems,
  subNotification,
})(Navigation);
