import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addItem } from "../../actions/itemActions";
import CSVReader from "react-csv-reader";
import moment from "moment";
import ModalComponent from "../common/ModalComponent";

import { withStyles } from '@material-ui/styles';
import {
  Button,
} from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const styles = theme => ({
  button: {
    backgroundColor: '#2196f3',
    marginRight: 10,
    '&:hover': {
      background: "#1e5e96",
    },
  }
});

class ImportModal extends Component {
  state = {
    modal: false,
    isData: false,
    isClicked: false,
    name: "",
    entries_number: "",
    logData: [{ name: "", unit: "", values: "" }],
    lastData: [{ name: "", unit: "", values: [] }],
    from: "mapid",
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
    this.setState({ isData: false });
  };
  handleClick = () => {
    this.setState({
      isClicked: !this.state.isClicked,
    });
    this.toggle();
    this.setState({ isData: false });
  };
  onChange = (e) => {
    if (e.target.className === "item") {
      this.setState({ name: e.target.value });
    }
    if (["name", "unit"].includes(e.target.id)) {
      let logData = [...this.state.logData];
      let lastData = [...this.state.lastData];
      logData[e.target.dataset.id][e.target.id] = e.target.value;
      lastData[e.target.dataset.id][e.target.id] = e.target.value;
      this.setState({ logData, lastData });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  handleForce = (data) => {
    var jumlah_var;
    var jumlah_data = data.length;
    var log_data = [];
    var last_data = [];
    var values;
    var lastValues;
    var { from } = this.state;
    var timeFormat;
    var entries_number;
    if (from === "mapid") {
      timeFormat = "DD/MM/YYYY HH.mm.ss";
      jumlah_var = data[1].length / 2;
      entries_number = data.length - 1;
      for (let i = 0; i < jumlah_var - 1; i++) {
        values = [];
        lastValues = [];
        for (let e = 1; e < jumlah_data; e++) {
          let str = data[e][0].replace(/"/g, "");
          values.push([
            data[e][i + 2],
            parseInt(moment(str, timeFormat).format("x"), 10),
          ]);
          lastValues.push([data[e][i + 2]]);
        }
        let lastlastValues = lastValues[lastValues.length - 1];
        log_data[i] = {
          values: values,
          name: data[0][i + 2],
          unit: data[0][i + jumlah_var + 1],
        };
        last_data[i] = {
          values: lastlastValues,
          name: data[0][i + 2],
          unit: data[0][i + jumlah_var + 1],
        };
      }
    } else {
      timeFormat = "YYYY-MM-DD HH:mm:ss UTC";
      jumlah_var = data[1].length;
      entries_number = data.length - 2;
      for (let i = 0; i < jumlah_var - 2; i++) {
        values = [];
        lastValues = [];
        for (let e = 1; e < jumlah_data - 1; e++) {
          let str = data[e][0].replace(/"/g, "");
          // let newStr = data[e][0].substring(0, data[e][0].length - 4);
          // console.log(newStr);
          values.push([
            data[e][i + 2],
            parseInt(moment(str, timeFormat).format("x"), 10),
          ]);
          lastValues.push([data[e][i + 2]]);
        }
        let lastlastValues = lastValues[lastValues.length - 2];
        // console.log(lastlastValues);
        log_data[i] = { values: values, name: data[0][i + 2], unit: "" };
        last_data[i] = {
          values: lastlastValues,
          name: data[0][i + 2],
          unit: "",
        };
      }
    }
    this.setState({
      isData: true,
      logData: log_data,
      lastData: last_data,
      entries_number: entries_number,
      name: data[0][1],
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.toggle();
    this.setState({ isData: false });
    const { user } = this.props.auth;
    var log_data = {};
    var last_data = {};
    let { logData, lastData, name, entries_number } = this.state;
    for (var i = 0; i < logData.length; i++) {
      log_data["var" + (i + 1)] = logData[i];
      last_data["var" + (i + 1)] = lastData[i];
    }
    const newItem = {
      name: name,
      last_entry_at: Date.now(),
      log_data: log_data,
      last_data: last_data,
      entries_number: entries_number,
      username: user.name,
    };
    // Add item via addItem action
    this.props.addItem(newItem);
  };
  render() {
    const { classes } = this.props;
    var { name, logData, from } = this.state;
    var itemContent;
    if (this.state.isData) {
      itemContent = (
        <div>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <div>
              <label>Name</label>
              <div>
                <input
                  type="text"
                  name="name"
                  id="item"
                  className="item"
                  placeholder="Example: Weather Station"
                  value={name}
                  onChange={this.onChange}
                />
              </div>
            </div>
            {logData.map((val, idx) => {
              let nameId = `name-${idx}`;
              let unitId = `n-${idx}`;
              return (
                <div key={idx}>
                  <div className="row">
                    <div className="col-sm-6">
                      <input
                        type="text"
                        name={nameId}
                        data-id={idx}
                        id="name"
                        value={logData[idx].name}
                        className="form-control"
                        placeholder={`Sensor #${idx + 1}`}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        name={unitId}
                        data-id={idx}
                        id="unit"
                        value={logData[idx].unit}
                        className="form-control"
                        placeholder="unit"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              );
            })}

            <button className="buttonSimple2" style={{ marginTop: "2rem" }}>
              Create
            </button>
          </form>
        </div>
      );
    } else {
      itemContent = (
        <div>
          <div>
            <div className="col">Data from</div>
            <div className="col">
              <select
                className="form-control"
                id="from"
                onChange={this.handleChange.bind(this)}
                value={from}
              >
                <option value="mapid">Mapid</option>
                <option value="ts">Others</option>
              </select>
            </div>
          </div>
          <br />
          <CSVReader onFileLoaded={this.handleForce.bind(this)} />
        </div>
      );
    }
    const modalComponent = this.state.modal && (
      <ModalComponent
        modalSize="medium"
        id="modal"
        isOpen={this.state.modal}
        onClose={this.toggle}
      >
        <div className="box-body">
          <div>{itemContent}</div>
          <a
            href="https://doc.mapid.io/doc_iot/import_data"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            See documentation
          </a>
        </div>
      </ModalComponent>
    );
    return (
      <div>
        {/* <div className="buttonSimple2" onClick={this.toggle}>
          Import CSV
        </div> */}
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<ImportExportIcon />}
          onClick={this.toggle}
        >
          Import CSV
        </Button>
        {modalComponent}
      </div>
    );
  }
}
ImportModal.propTypes = {
  addItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  item: state.item,
  auth: state.auth,
});
export default connect(mapStateToProps, { addItem })(withStyles(styles)(ImportModal));
