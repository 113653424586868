import {} from "../actions/types";

const initialState = {
  item: {},
  loadingGet: false,
  loadingDelete: false,
  loadingParent: false,
  loadingChild: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_LOADING_GET_COMMENT":
      return {
        ...state,
        loadingGet: false,
      };
    case "SET_LOADING_DELETE":
      return {
        ...state,
        loadingDelete: false,
      };
    case "SET_LOADING_PARENT":
      return {
        ...state,
        loadingParent: true,
      };
    case "SET_LOADING_CHILD":
      return {
        ...state,
        loadingChild: true,
      };
    case "CLEAR_LOADING":
      return {
        ...state,
        loadingGet: false,
        loadingDelete: false,
        loadingParent: false,
        loadingChild: false,
      };
    case "GET_COMMENTS":
      return {
        ...state,
        item: action.payload,
        comments: action.payload.comments,
      };
    default:
      return state;
  }
}
