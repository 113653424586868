import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
// import mapidLogo from "../../img/logo_outline.svg";

class NavigationLanding extends Component {
  render() {
    // const content_3 = (
    //   <div className="topnav navbarLanding" id="navbarLanding">
    //     <div className="dashboardSpecial">
    //       <Link to={"/dashboard"}>
    //         <img src={mapidLogo} height="27px" alt="Mapid Logo" />
    //       </Link>
    //       <Link to={"/dashboard"} className="navbarBrand">
    //         IOT MAPID
    //       </Link>
    //       <a
    //         className="right navbarItem"
    //         href="https://mapid.io/register"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         Register
    //       </a>
    //       <a
    //         className="right navbarItem"
    //         id="docsButton"
    //         href="https://doc.mapid.io/doc_iot"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         Docs
    //       </a>
    //     </div>
    //   </div>
    // );
    return (
      <div className="navKeren">
        {/* {content_3} */}
        <style>{`
        .navKeren, body{padding-top: 0px; }
        .commonNavbar{ visibility: hidden;}
        .footer{ visibility: hidden;}
        #modal-root{ visibility: hidden;}
      `}</style>
      </div>
    );
  }
}
NavigationLanding.propTypes = {};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(NavigationLanding);
