//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
import TextFieldGroup from "../common/TextFieldGroup";
//Redux function
import { register_new } from "../../actions/authActions";
//Picture Asset
//General Function
import dict from "../../validation/dict.json";
class Register extends Component {
  constructor() {
    super();
    this.state = {
      phone_number: "08",
      name: "",
      password: "",
      password2: "",
      errors: {},
      show_password: false,
    };
  }
  toggleShowPassword = () => {
    this.setState({ show_password: !this.state.show_password });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    } else {
      return null;
    }
  }
  onSubmit = (e) => {
    e.preventDefault();
    const { name, phone_number, password, password2 } = this.state;
    let body = {
      name: name.toLowerCase().replace(/\s/g, ""),
      phone_number,
      password,
      password2,
    };
    if (this.props.kode) {
      body = {
        phone_number,
        name: name.toLowerCase().replace(/\s/g, ""),
        password,
        password2,
        specialist_code: this.props.kode,
      };
    }
    this.props.register_new(body);
  };
  onChange = (e) => {
    if (e.target.name === "name") {
      this.setState({ [e.target.name]: e.target.value.replace(/\s/g, "") });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { errors, name, phone_number, password, password2, show_password } =
      this.state;
    const { loading } = this.props.auth;
    let iconContent;
    const kode = this.props.kode;
    let kode_content;
    if (kode) {
      kode_content = (
        <section style={{ marginBottom: "10px" }}>
          Kode specialist: <b>{kode}</b>
        </section>
      );
    }
    if (loading) {
      iconContent = (
        <div style={{ marginTop: "30px" }}>
          <button className="button_pill" type="submit">
            {dict["Registering..."][language]}
          </button>
        </div>
      );
    } else {
      iconContent = (
        <div style={{ marginTop: "30px" }}>
          <button className="button_pill" type="submit">
            {dict["Register"][language]}
          </button>
        </div>
      );
    }
    return (
      <div style={{ maxWidth: "90vw", margin: "auto" }}>
        {kode_content}
        <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
          <TextFieldGroup
            type="text"
            placeholder={dict?.["WhatsApp number"]?.[language]}
            name="phone_number"
            id="phone_number"
            value={phone_number}
            onChange={this.onChange}
            error={errors.phone_number}
          />
          <TextFieldGroup
            type="text"
            placeholder={dict["User name"][language]}
            name="name"
            value={name.toLowerCase()}
            onChange={this.onChange}
            error={errors.name}
          />
          <TextFieldGroup
            type={show_password ? "text" : "password"}
            isPassword={true}
            placeholder={dict["Password"][language]}
            name="password"
            id="password"
            value={password}
            onChange={this.onChange}
            error={errors.password}
            toggleShowPassword={this.toggleShowPassword}
          />
          <TextFieldGroup
            type={show_password ? "text" : "password"}
            isPassword={true}
            placeholder={dict["Confirm password"][language]}
            name="password2"
            id="password2"
            value={password2}
            onChange={this.onChange}
            error={errors.password2}
            toggleShowPassword={this.toggleShowPassword}
          />
          {iconContent}
        </form>
        <style>{`
        #bottom_bar{ display:none;}
     `}</style>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { register_new })(Register);
