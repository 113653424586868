import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import RichViewer from "../common/RichViewer";
import { getDocFolder, getDocDataset } from "../../actions/editorAction";
import isEmpty from "../../validation/is-empty";
import isEqual from "../../validation/is-equal";

class ViewTask extends Component {
  //Init
  state = {
    doc: {}
  };
  componentDidMount() {
    if (this.props.match.params.task_or_report === "task") {
      this.props.getDocFolder(this.props.match.params.id);
    } else {
      this.props.getDocDataset(this.props.match.params.id);
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const doc_new = !isEmpty(nextProps.editor.doc) ? nextProps.editor.doc : {};
    const doc_state = prevState.doc;
    if (!isEqual(doc_new, doc_state)) {
      return {
        doc: doc_new
      };
    } else return null;
  }
  toggleDeleteDoc = doc => {
    this.setState(
      {
        doc
      },
      () => {
        this.setState({
          modalDeleteDoc: !this.state.modalDeleteDoc
        });
      }
    );
  };
  render() {
    const { doc } = this.state;
    const viewerContent =
      this.props.match.params.task_or_report === "task" ? (
        <RichViewer
          isFromIOT={true}
          doc={doc}
          task_or_report={this.props.match.params.task_or_report}
          id={this.props.match.params.id}
          toggleDelete={this.toggleDeleteDoc.bind(this)}
        />
      ) : (
        <RichViewer
          isFromIOT={true}
          doc={doc}
          task_or_report={this.props.match.params.task_or_report}
          id={this.props.match.params.id}
          toggleDelete={this.toggleDeleteDoc.bind(this)}
        />
      );
    return <div>{viewerContent}</div>;
  }
}
ViewTask.propTypes = {
  //Object
  editor: PropTypes.object.isRequired,
  //Function
  getDocFolder: PropTypes.func.isRequired,
  getDocDataset: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  item: state.item,
  auth: state.auth,
  editor: state.editor
});
export default connect(mapStateToProps, {
  getDocFolder,
  getDocDataset
})(ViewTask);
