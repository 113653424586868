/* eslint-disable */
import React, { Component } from "react";
import SVGcompass from "../../img/compass.svg";
import SVGgaugebackground from "../../img/gauge_background.svg";
class Gauge extends Component {
  state = {
    value: 0,
    donut1dasharray: 0,
    shown_number: 0,
    dasharray: 0,
    janganDiganggu: false
  };
  componentDidMount() {
    const value = parseFloat(this.props.value);
    const nMin = this.props.nMin;
    const nMax = this.props.nMax;
    if (this.state.janganDiganggu) {
      return null;
    } else {
      if (value === "undefined") {
        this.setState({ donut1dasharray: 0, shown_number: 0, dasharray: 0 });
      } else {
        if (value < nMin) {
          // console.log("kasus minus");
          this.setState({
            donut1dasharray: nMin,
            shown_number: value,
            dasharray: nMin
          });
        } else if (value > nMax) {
          // console.log("kasus melebihi");
          this.setState({
            donut1dasharray: nMax,
            shown_number: value,
            dasharray: nMax
          });
        } else {
          if (value !== this.state.shown_number) {
            // console.log("update receive", value);
            this.setState({ janganDiganggu: true });
            var start = this.state.donut1dasharray;
            var end = value;
            var range = end - start;
            var duration = 100;
            var incBesar = Math.abs(range) / 100;
            var incKecil = Math.abs(range) / 4;
            var increment;
            if (Math.abs(range) > 5) {
              increment = end > start ? incBesar : -incBesar;
            } else {
              increment = end > start ? incKecil : -incKecil;
            }
            var stepTime = Math.abs(Math.floor(duration / range));
            this.timer = setInterval(() => {
              this.setState(prevState => ({
                donut1dasharray: prevState.donut1dasharray + increment,
                dasharray: parseFloat(
                  prevState.donut1dasharray + increment
                ).toFixed(1),
                shown_number: parseFloat(
                  prevState.donut1dasharray + increment
                ).toFixed(2),
                janganDiganggu: true
              }));
              var current = this.state.donut1dasharray;
              var selisih = Math.abs(end - current);
              if (selisih < 0.01) {
                this.setState({ janganDiganggu: false });
                selisih = 0;
                clearInterval(this.timer);
              }
            }, stepTime);
          } else {
            // console.log("something happen", value);
            this.setState(prevState => ({
              donut1dasharray: prevState.donut1dasharray,
              shown_number: prevState.shown_number,
              dasharray: prevState.dasharray,
              janganDiganggu: false
            }));
          }
        }
      }
    }
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      const value = parseFloat(this.props.value);
      const nMin = this.props.nMin;
      const nMax = this.props.nMax;
      if (this.state.janganDiganggu) {
        return null;
      } else {
        if (!value || value === null || value === undefined) {
          // console.log("kasus kosong");
          this.setState({ donut1dasharray: 0, shown_number: 0, dasharray: 0 });
        } else {
          if (value < nMin) {
            // console.log("kasus minus");
            this.setState({
              donut1dasharray: nMin,
              shown_number: value,
              dasharray: nMin
            });
          } else if (value > nMax) {
            // console.log("kasus melebihi");
            this.setState({
              donut1dasharray: nMax,
              shown_number: value,
              dasharray: nMax
            });
          } else {
            if (value !== this.state.shown_number) {
              this.setState({ janganDiganggu: true });
              var start = this.state.donut1dasharray;
              var end = value; //value 0-50
              var range = end - start;
              var duration = 100;
              var incBesar = Math.abs(range) / 100;
              var incKecil = Math.abs(range) / 4;
              var increment;
              if (Math.abs(range) > 5) {
                increment = end > start ? incBesar : -incBesar;
              } else {
                increment = end > start ? incKecil : -incKecil;
              }
              var stepTime = Math.abs(Math.floor(duration / range));
              this.timer = setInterval(() => {
                this.setState(prevState => ({
                  donut1dasharray: prevState.donut1dasharray + increment,
                  dasharray: parseFloat(
                    prevState.donut1dasharray + increment
                  ).toFixed(1),
                  shown_number: parseFloat(
                    prevState.donut1dasharray + increment
                  ).toFixed(2),
                  janganDiganggu: true
                }));
                var current = this.state.donut1dasharray;
                var selisih = Math.abs(end - current);
                if (selisih < 0.01) {
                  this.setState({ janganDiganggu: false });
                  selisih = 0;
                  clearInterval(this.timer);
                }
              }, stepTime);
            } else {
              this.setState(prevState => ({
                donut1dasharray: prevState.donut1dasharray,
                shown_number: prevState.shown_number,
                dasharray: prevState.dasharray,
                janganDiganggu: false
              }));
            }
          }
        }
      }
    } else return null;
  }
  render() {
    const { shown_number, dasharray } = this.state;
    const { nMin, nMax, unit } = this.props;
    const mapValue = 270 * ((dasharray - nMin) / (nMax - nMin));
    var valuesArray = [];
    for (let i = 0; i <= 12; i++) {
      valuesArray.push({
        value: parseInt(nMin + ((12 - i) / 12) * (nMax - nMin)),
        rotation: 22.5 * i
      });
    }
    const gauge = (
      <div className="text-center">
        <svg
          width={this.props.width ? this.props.width : "100%"}
          height={this.props.width ? this.props.width : "100%"}
          viewBox="0 0 20 20"
          className="compass"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* <rect width="20" height="20" fill="yellow" /> */}
          <image
            className="SVGgaugebackground"
            href={SVGgaugebackground}
            height={20}
            width={20}
            x={0}
            y={0}
          />
          {valuesArray.map(({ value, rotation }, idx) => {
            return (
              <text
                key={`gauge-${idx}`}
                className={idx}
                x="15"
                y="15.3"
                textAnchor="middle"
                fontFamily="Arial"
                fontSize="1"
                fill="white"
                fontStyle="normal"
                transform={`rotate(-${rotation} 5 5) rotate(${rotation} 10 10)  `}
              >
                {value}
              </text>
            );
          })}
          <image
            className="compass"
            href={SVGcompass}
            height="13"
            width="13"
            x="3.5"
            y="3.5"
            transform={`rotate(${mapValue} 10 10)`}
          />
          <text
            className="shown_number"
            x="10"
            y="16.5"
            textAnchor="middle"
            fontFamily="Arial"
            fontSize="2"
            fill="white"
            fontStyle="bold"
          >
            {shown_number}
          </text>
          <text
            x="10"
            y="18"
            textAnchor="middle"
            fontFamily="Arial"
            fontSize="1"
            fill="white"
            fontStyle="normal"
          >
            {unit}
          </text>
        </svg>
      </div>
    );
    return <div>{gauge}</div>;
  }
}
export default Gauge;
