const geoServer = "https://geoserver.mapid.io";
const geoServerLocal = "http://localhost:4000";
const geoServerIp = `http://${window.location.host}:4000`;

const iotServer = "https://api.mapid.io";
const iotServerLocal = "http://localhost:4001";
const iotServerIp = `http://${window.location.host}:4001`;

const mode = "online"; // online, offline, ip

if (mode === "offline") {
  module.exports = {
    geoServerBaseUrl: geoServerLocal,
    iotServerBaseUrl: iotServerLocal,
  };
} else if (mode === "ip") {
  module.exports = {
    geoServerBaseUrl: geoServerIp,
    iotServerBaseUrl: iotServerIp,
  };
} else {
  module.exports = {
    geoServerBaseUrl: geoServer,
    iotServerBaseUrl: iotServer,
  };
}
