import React from "react";
import PropTypes from "prop-types";

const TextAreaFieldGroup = ({
  name,
  placeholder,
  value,
  error,
  info,
  onChange,
}) => {
  return (
    <div>
      <textarea
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
      />
      {info && (
        <small
          style={{
            padding: "0px 0px 0px 0px",
            color: "#c62121ff",
            textAlign: "center",
          }}
        >
          {info}
        </small>
      )}
      {error && (
        <small
          style={{
            padding: "0px 0px 0px 0px",
            color: "#c62121ff",
            textAlign: "center",
          }}
        >
          {error}
        </small>
      )}
    </div>
  );
};

TextAreaFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TextAreaFieldGroup;
