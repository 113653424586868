import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginAdmin } from "../../actions/authActions";
import TextFieldGroup from "../common/TextFieldGroup";
import SpinnerSimpleBlue from "../common/SpinnerSimpleBlue";
class Adminlogin extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      password: "",
      password2: "",
      errors: {},
    };
  }
  componentDidMount() {
    if (this.props.auth.isAdmin) {
      this.props.history.push("/admin/dashboard");
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    } else return null;
  }
  onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      name: this.state.name,
      password: this.state.password,
    };

    this.props.loginAdmin(userData);
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { errors } = this.state;
    const { loading } = this.props.auth;
    var loadingContent;
    if (loading) {
      loadingContent = (
        <SpinnerSimpleBlue
          width={200}
          unik="loading_item_list"
          marginTop="0px"
        />
      );
    } else loadingContent = null;
    return (
      <div
        style={{
          textAlign: "center",
          textAlignLast: "center",
          width: "500px",
          maxWidth: "80vw",
          margin: "auto",
        }}
      >
        <div>
          <h1 className="welcomeBig">Admin</h1>
          <p>Mapid Admin</p>
          <form noValidate onSubmit={this.onSubmit}>
            <TextFieldGroup
              placeholder="Username"
              name="name"
              type="name"
              value={this.state.name}
              onChange={this.onChange}
              error={errors.name}
            />
            <TextFieldGroup
              placeholder="Password"
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.onChange}
              error={errors.password}
            />
            <button type="submit" className="buttonShadow">
              Sign in
            </button>
            {loadingContent}
          </form>
          <style>{`
        #loginModal{ visibility: hidden;}
     `}</style>
        </div>
      </div>
    );
  }
}
Adminlogin.propTypes = {
  loginAdmin: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { loginAdmin })(Adminlogin);
