import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  pushImageBracket,
  pushImageFolder,
  pushImageDataset,
} from "../../../actions/editorAction";
class UploadPicture extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
  }
  handleSubmit(e) {
    e.preventDefault();
    const file = this.fileInput.current.files[0];
    //console.log(file);
    const date_now = moment(Date.now()).format("X");
    const front_url = "https://s3.us-west-2.amazonaws.com/machrus/";
    const file_name = date_now + "." + file.name.split(".").pop();
    const key = "doc_pictures/" + file_name;
    const objParams = {
      Bucket: "machrus",
      Key: key,
      Body: file,
      ContentType: file.type,
    };
    const picture_data = {
      picture_object: {
        url: front_url + key,
        date: date_now,
      },
    };
    let finalObject;

    if (!this.props.isFromIOT) {
      finalObject = {
        objParams,
        picture_data,
        id: this.props.id,
      };
      this.props.pushImageBracket(finalObject);
    } else {
      if (this.props.task_or_report === "task") {
        finalObject = {
          objParams,
          picture_data,
          id: this.props.folder,
        };
        this.props.pushImageFolder(finalObject);
      } else {
        finalObject = {
          objParams,
          picture_data,
          id: this.props.dataset,
        };
        this.props.pushImageDataset(finalObject);
      }
    }
  }
  render() {
    const { loadingProcess } = this.props.editor;
    let itemContent;
    if (loadingProcess) {
      itemContent = "Uploading...";
    } else {
      itemContent = (
        <label className="fileContainer">
          Upload Image
          <input
            type="file"
            accept="image/*"
            ref={this.fileInput}
            onChange={this.handleSubmit}
          />
        </label>
      );
    }
    return <div>{itemContent}</div>;
  }
}
UploadPicture.propTypes = {
  editor: PropTypes.object.isRequired,
  pushImageBracket: PropTypes.func.isRequired,
  pushImageFolder: PropTypes.func.isRequired,
  pushImageDataset: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  editor: state.editor,
});
export default connect(mapStateToProps, {
  pushImageBracket,
  pushImageFolder,
  pushImageDataset,
})(UploadPicture);
