import React, { Component } from "react";
import { connect } from "react-redux";
import Counter from "../widget/Counter";
function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}
class CompassRender extends Component {
  state = {
    value: 30
  };
  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        value: getRandomArbitrary(
          this.props.nMin ? this.props.nMin : 60,
          this.props.nMax ? this.props.nMax : 100
        )
      });
    }, 6300);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    return (
      <div x={this.props.x} y={this.props.y}>
        <Counter
          value={parseInt(
            this.props.value ? this.props.value : this.state.value
          )}
          nMin={this.props.nMin ? this.props.nMin : 0}
          nMax={this.props.nMax ? this.props.nMax : 100}
          unit={this.props.unit ? this.props.unit : "Water level"}
          width={this.props.width}
          height={this.props.width}
        />
      </div>
    );
  }
}
CompassRender.propTypes = {};
const mapStateToProps = state => ({});
export default connect(
  mapStateToProps,
  {}
)(CompassRender);
