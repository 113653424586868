import React, { Component } from "react";
import { connect } from "react-redux";
import { EditorState, convertFromRaw } from "draft-js";
import Editor from "draft-js-plugins-editor";
import moment from "moment";

import { mediaBlockRenderer } from "../common/plugins/mediaBlockRenderer";
import pic_static from "../../img/profile_pic_comment.svg";
class RichViewerEmpty extends Component {
  state = {
    doc: {
      editorState: `{"blocks":[{"key":"5ji2c","text":"Sorry, the document with the link you entered was not found. Maybe the documents has been removed or changed.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
    },
    editorState: EditorState.createEmpty(),
  };
  onChange = (editorState) => this.setState({ editorState });
  componentDidMount() {
    const { editorState } = this.state.doc;
    const contentState = convertFromRaw(JSON.parse(editorState));
    const editorStateFinal = EditorState.createWithContent(contentState);
    this.setState({ editorState: editorStateFinal });
  }
  render() {
    //state
    const { editorState } = this.state;
    let className = "RichEditor-editor";
    var contentStateForClassname = editorState.getCurrentContent();
    if (!contentStateForClassname.hasText()) {
      if (
        contentStateForClassname.getBlockMap().first().getType() !== "unstyled"
      ) {
        className += " RichEditor-hidePlaceholder";
      }
    }
    const itemContent = (
      <div className={className} onClick={this.focus}>
        <Editor
          blockRendererFn={mediaBlockRenderer}
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          editorState={editorState}
          onChange={this.onChange}
          placeholder="Loading document..."
          ref="editor"
          spellCheck={false}
          readOnly={true}
        />
      </div>
    );
    const authorContent = (
      <div style={{ marginTop: "30px" }}>
        <div className={`photo`} />
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            marginLeft: "10px",
            fontSize: "17px",
            color: "black",
          }}
        >
          Oops!
          <br />
          <div
            style={{
              fontSize: "12px",
              color: "black",
            }}
          >
            {moment(Date.now()).format("MMM DD YYYY")}
          </div>
        </div>
        <style>
          {`.photo{
            background: url(${pic_static}
            ) no-repeat center center/ cover;
            background-color: #dcdcdc;
            height: 80px;
            width: 80px;
            border-radius: 50%;
            display: inline-block;
            align: center;
            vertical-align: middle;
            border: 2px solid #b0b0b0;
            box-shadow: 0 0 12px #0000004e;
            -webkit-box-shadow: 0 0 12px #0000004e;
            -moz-box-shadow: 0 0 12px #0000004e;
            }`}
        </style>
      </div>
    );
    return (
      <div style={{ marginBottom: "100px" }}>
        <div className="titleWraper">
          <div className="dashboardSpecial">
            <div className="titleDocument">Oops! Document Not Found</div>
          </div>
        </div>
        <div className="documentContainer">
          {authorContent}
          {itemContent}
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            body{padding-top: 0px;}
            `,
          }}
        />
      </div>
    );
  }
}

//Other start
// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    case "center":
      return "RichEditor-center";
    default:
      return null;
  }
}
RichViewerEmpty.propTypes = {};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(RichViewerEmpty);
