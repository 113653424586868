import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { addItem, addItemByFolder } from "../../actions/itemActions";
import SpinnerSimpleBlue from "../common/SpinnerSimpleBlue";
class AddModal extends Component {
  state = {
    dataset_name: "Untitled",
    logData: [
      {
        name: "Latitude",
        unit: "degree",
        values: [],
        nMin: 0,
        nMax: 100,
        notif: false,
        hold: false,
      },
      {
        name: "Longitude",
        unit: "degree",
        values: [],
        nMin: 0,
        nMax: 100,
        notif: false,
        hold: false,
      },
      {
        name: "Sensor1",
        unit: "unit",
        values: [],
        nMin: 0,
        nMax: 100,
        notif: false,
        hold: false,
      },
    ],
    folder_id: "",
    owner_id: "",
    owner_name: "",
    group_status: "personal",
  };
  onClickFolder = (folder_id, group_status) => {
    const { folders_user } = this.props.item;
    var owner_id;
    var owner_name;
    if (folder_id && group_status === "group") {
      const folder = folders_user.filter(
        (folder) => folder._id === folder_id
      )[0];
      const members = folder.group.members;
      const user = members.filter((member) => member.status === "creator")[0]
        .user;
      owner_id = user._id;
      owner_name = user.name;
    } else {
      owner_id = "";
      owner_name = "";
    }
    this.setState({ folder_id, owner_id, owner_name, group_status });
  };
  handleChange = (e) => {
    if (["name", "unit"].includes(e.target.name)) {
      let logData = [...this.state.logData];
      logData[e.target.dataset.id][e.target.name] = e.target.value;
      this.setState({ logData });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  addSensor = () => {
    if (this.state.logData.length - 1 < 11) {
      this.setState((prevState) => ({
        logData: [
          ...prevState.logData,
          {
            name: "",
            unit: "",
            values: [],
            nMin: 0,
            nMax: 100,
            notif: false,
            hold: false,
          },
        ],
      }));
      // console.log("jumlah sensor = ", this.state.logData.length - 1);
    } else {
      // console.log("bablas");
    }
  };
  removeSensor(idx) {
    let { logData } = this.state;
    logData.splice(idx, 1);
    this.setState({ logData: logData });
    // console.log("jumlah sensor = ", this.state.logData.length - 1);
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let {
      logData,
      dataset_name,
      folder_id,
      owner_id,
      owner_name,
      group_status,
    } = this.state;
    var log_data = {};
    for (var i = 0; i < logData.length; i++) {
      log_data["var" + (i + 1)] = logData[i];
    }
    const newItem = {
      name: dataset_name,
      log_data: log_data,
      folder_id,
      owner_id,
      owner_name,
    };
    if (folder_id && group_status === "group") {
      this.props.addItemByFolder(newItem);
    } else {
      this.props.addItem(newItem);
    }
  };
  render() {
    const { dataset_name, logData, folder_id } = this.state;
    const { loading, folders_user } = this.props.item;
    var itemContent;
    var buttonContent;
    const folders_user_personal = folders_user.filter(
      (folder) => folder.owner_type === "personal"
    );
    const folders_user_group = folders_user.filter(
      (folder) => folder.owner_type === "group"
    );
    const folders_group_content = (
      <div className="carousellGroup" style={{ marginTop: "10px" }}>
        <div
          className="buttonSimpleTightNormal"
          style={{ marginRight: "10px", marginBottom: "20px" }}
          onClick={this.onClickFolder.bind(this, "")}
        >
          X
        </div>
        {folders_user_group.map(({ name, _id }, idx) => {
          var classnameFolder;
          if (folder_id === _id) {
            classnameFolder = "selected";
          } else {
            classnameFolder = "edit";
          }
          return (
            <div
              key={_id}
              className="buttonSimpleTightNormal"
              id={classnameFolder}
              onClick={this.onClickFolder.bind(this, _id, "group")}
              style={{
                marginRight: "10px",
                marginBottom: "20px",
                whiteSpace: "nowrap",
              }}
            >
              {name}
            </div>
          );
        })}
      </div>
    );
    const folders_personal_content = (
      <div className="carousellGroup" style={{ marginTop: "10px" }}>
        <div
          className="buttonSimpleTightNormal"
          style={{ marginRight: "10px", marginBottom: "20px" }}
          onClick={this.onClickFolder.bind(this, "")}
        >
          X
        </div>
        {folders_user_personal.map(({ name, _id }, idx) => {
          var classnameFolder;
          if (folder_id === _id) {
            classnameFolder = "selected";
          } else {
            classnameFolder = "edit";
          }
          return (
            <div
              key={_id}
              className="buttonSimpleTightNormal"
              id={classnameFolder}
              onClick={this.onClickFolder.bind(this, _id, "personal")}
              style={{
                marginRight: "10px",
                marginBottom: "20px",
                whiteSpace: "nowrap",
              }}
            >
              {name}
            </div>
          );
        })}
      </div>
    );

    if (loading) {
      buttonContent = (
        <SpinnerSimpleBlue
          width={48}
          unik="loading-qr-code-1"
          marginTop="30px"
        />
      );
    } else {
      buttonContent = (
        <button
          type="submit"
          className="buttonSimple2"
          style={{ marginTop: "1rem" }}
        >
          Create!
        </button>
      );
    }
    itemContent = (
      <div>
        <form onSubmit={this.handleSubmit.bind(this)}>
          Name
          <input
            type="text"
            name="dataset_name"
            id="dataset_name"
            className="item"
            value={dataset_name}
            onChange={this.handleChange}
          />
          {folders_group_content}
          {folders_personal_content}
          {logData.map((val, idx) => {
            // let nameId = `name-${idx}`;
            // let unitId = `n-${idx}`;
            return (
              <div key={idx}>
                <div className="grid-container-sensor-input">
                  <div className="var">{`var${idx + 1}`}</div>
                  <div className="sensor_name">
                    <input
                      type="text"
                      name="name"
                      data-id={idx}
                      value={logData[idx].name}
                      className="form-control"
                      placeholder={`Sensor${idx - 1}`}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="sensor_unit">
                    <input
                      type="text"
                      name="unit"
                      data-id={idx}
                      value={logData[idx].unit}
                      className="form-control"
                      placeholder="unit"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div
                    onClick={this.removeSensor.bind(this, idx)}
                    className="buttonSimpleTight delete_button"
                    id="delete"
                    style={{ cursor: "pointer" }}
                  >
                    x
                  </div>
                </div>
              </div>
            );
          })}
          <div style={{ textAlign: "center" }}>
            <div onClick={this.addSensor} className="buttonSimple2">
              +
            </div>
            <div>{buttonContent}</div>
          </div>
        </form>
      </div>
    );
    return <div>{itemContent}</div>;
  }
}
AddModal.propTypes = {
  addItem: PropTypes.func.isRequired,
  addItemByFolder: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  item: state.item,
});
export default connect(mapStateToProps, { addItem, addItemByFolder })(AddModal);
