import React, { Component } from "react";
import { connect } from "react-redux";
import UploadProfilePictures from "./UploadProfilePictures";
class UploadProfileDashboard extends Component {
  state = {};
  render() {
    var itemContent;
    itemContent = (
      <div>
        <UploadProfilePictures />
        <hr />
        <b>Upload best picture of you</b>
      </div>
    );
    return <div style={{ textAlign: "center" }}>{itemContent}</div>;
  }
}
UploadProfileDashboard.propTypes = {};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(UploadProfileDashboard);
