/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";
import { CSVLink } from "react-csv";
import moment from "moment";
import { getItem } from "../../actions/itemActions";
class Current extends Component {
  constructor() {
    super();
    this.state = {
      item: null,
    };
  }
  componentDidMount() {
    this.props.getItem(this.props.match.params.id);
  }
  render() {
    var { item, loadingContent } = this.props.item;
    var last_data = item.last_data;
    var buffer_data = item.buffer_data;
    // var dataset_name = item.name;
    var varTag;
    var dataCSV = [];
    var headers;
    // var arrayAll = [];
    // var headersAll = [];
    if (!item) {
      // console.log("bentar");
    } else {
      if (!buffer_data.var1.values) {
        // console.log("values kosong");
      } else {
        // arrayAll = Object.keys(buffer_data.var1.values).map(key => {
        //   return {};
        // });
        // headersAll = [
        //   { label: "Time", key: "x" },
        //   { label: dataset_name, key: "y" }
        // ];
        // headersAll = [
        //   ...headersAll,
        //   ...Object.keys(buffer_data).map(key => {
        //     return { label: buffer_data[key]["name"], key };
        //   })
        // ];
        // headersAll = [
        //   ...headersAll,
        //   ...Object.keys(buffer_data).map(key => {
        //     return { label: buffer_data[key]["unit"], key: `${key}-unit` };
        //   })
        // ];
        // console.log("mangkat");
        for (var property in buffer_data) {
          if (buffer_data.hasOwnProperty(property)) {
            varTag = property;
            var varData = buffer_data[property];
            var values = varData.values;
            var name = varData.name;
            var unit = varData.unit;
            var xValues = [];
            var yValues = [];
            var arrayValues = [];
            var arrayValuesFiltered = [];
            var varLast = last_data[property];
            var isFilter = varLast.isFilter ? varLast.isFilter : false;
            var filter = varLast.filter ? varLast.filter : "";
            var filterArray = [];
            if (isFilter && filter) {
              for (let i in values) {
                eval(filter);
                yValues.push(filterArray[i]);
                xValues.push(
                  moment(values[i][1]).format("DD/MM/YYYY HH.mm.ss")
                );
                // arrayAll[i]["x"] = moment(values[i][1]).format(
                //   "DD/MM/YYYY HH.mm.ss"
                // );
                // arrayAll[i][property] = parseFloat(filterArray[i]);
              }
            } else {
              for (let i in values) {
                yValues.push(values[i][0]);
                let timeLabels = moment(values[i][1]).format(
                  "DD/MM/YYYY HH.mm.ss"
                );
                xValues.push(timeLabels);
                // arrayAll[i]["x"] = moment(values[i][1]).format(
                //   "DD/MM/YYYY HH.mm.ss"
                // );
                // arrayAll[i][property] = parseFloat(values[i][0]);
              }
            }
            for (var e = 0; e < xValues.length; e++) {
              arrayValues.push({
                x: xValues[e],
                idx: e,
                y: parseFloat(yValues[e]),
              });
            }
            arrayValuesFiltered = arrayValues
              .filter((value) => value.y !== "NaN")
              .filter((value) => value.y !== "delete")
              .filter((value) => value.y);
          }
          headers = [
            { label: "Time", key: "x" },
            { label: `${item.name}_id`, key: "idx" },
            { label: `${name}(${unit})`, key: "y" },
          ];
          dataCSV.push({ arrayValuesFiltered, headers, varTag, name });
        }
      }
    }
    let downloadContent;
    if (loadingContent) {
      downloadContent = (
        <div style={{ textAlign: "center" }}>
          <p>Collecting data, please wait...</p>
          <SpinnerSimpleLogo
            width={200}
            unik="loading_item_list"
            marginTop="0px"
          />
        </div>
      );
    } else if (!item) {
      downloadContent = (
        <div>
          <h2>
            <br />
            <br />
            {"This dataset is private"} <br />
            {"or your token has been expired,"} <br />
            {"try to refresh your browser."}
          </h2>
        </div>
      );
    } else {
      // console.log("dataCSV=", dataCSV);
      downloadContent = (
        <div>
          <div>
            {dataCSV.map(({ varTag, headers, arrayValuesFiltered, name }) => (
              <div key={"list" + varTag} style={{ marginBottom: "10px" }}>
                <CSVLink
                  data={arrayValuesFiltered}
                  headers={headers}
                  key={"download" + varTag}
                  target="_blank"
                  filename={`${name}.csv`}
                  enclosingCharacter={``}
                  className="buttonSimpleTightNormal"
                  id="edit"
                >
                  {name}
                </CSVLink>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <div className="dashboardSpecial">
        <h1>Download Current</h1>
        <a
          href="https://doc.mapid.io/doc_iot/download_data"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          See documentation
        </a>
        <br />
        {downloadContent}
      </div>
    );
  }
}
Current.propTypes = {
  getItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({ item: state.item });
export default connect(mapStateToProps, { getItem })(Current);
