/* eslint-disable */
import React, { Component } from "react";
class String extends Component {
  state = {
    value: 0,
    janganDiganggu: false
  };
  render() {
    const string = (
      <div className="text-center">
        <svg
          width={this.props.width ? this.props.width : "100%"}
          height={this.props.width ? this.props.width : "100%"}
          viewBox="0 0 20 20"
          className="compass"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="donutRing"
            cx="10"
            cy="10"
            r="10"
            fill="transparent"
          />
          <text
            x="10"
            y="10"
            textAnchor="middle"
            fontFamily="Arial"
            fontSize={5}
            fill="black"
            fontStyle="normal"
          >
            Text
          </text>
        </svg>
      </div>
    );
    return <div>{string}</div>;
  }
}
export default String;
