import React, { Component } from "react";
import { connect } from "react-redux";
import Compass from "../widget/Compass";
function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}
class CompassRender extends Component {
  state = {
    value: 90
  };
  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ value: parseInt(getRandomArbitrary(15, 300)) });
    }, 4000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    return (
      <div>
        <Compass
          value={this.state.value}
          nMin={0}
          nMax={360}
          unit="wind"
          width={this.props.width}
        />
      </div>
    );
  }
}
CompassRender.propTypes = {};
const mapStateToProps = state => ({});
export default connect(
  mapStateToProps,
  {}
)(CompassRender);
