import React, { Component } from "react";
import { connect } from "react-redux";
import { setPicture, setBracket } from "../../actions/editorAction";
import PropTypes from "prop-types";

class ListPictures extends Component {
  onClickImage = picture_object => {
    this.props.setPicture(picture_object);
  };
  render() {
    const { pictures_array } = this.props.editor;
    const picListContent = (
      <div className="pictureWraper">
        <div className="pictureGrid">
          {pictures_array.map(({ url }, idx) => {
            return (
              <div key={idx}>
                <button
                  alt={idx}
                  className={`btn btn-light bg${idx}`}
                  onClick={this.onClickImage.bind(this, pictures_array[idx])}
                >
                  <style>
                    {`.bg${idx}{
                  background-image: url(${url});  
                  background-size: cover;
                  background-position: center;
                  height:50px;
                  width:70px;
                  border-radius: 5px;
                  }`}
                  </style>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
    return <div>{picListContent}</div>;
  }
}
ListPictures.propTypes = {
  setPicture: PropTypes.func.isRequired,
  setBracket: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  editor: state.editor
});
export default connect(mapStateToProps, { setPicture, setBracket })(
  ListPictures
);
