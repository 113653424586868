import axios from "axios";
import {
  PAYMENT_LOADING,
  CLEAR_PAYMENT_LOADING,
  GET_DOCS,
  ADD_HELP
} from "./types";
import his from "./history";
// const SERVER_URL = "http://localhost:4000";
const SERVER_URL = "https://api.mapid.io";
// const SERVER_URL = "http://iotmapidserver.us-west-2.elasticbeanstalk.com";

//Add idea
export const addIdea = item => dispatch => {
  dispatch(setAddIdeaLoading());
  axios
    .post(SERVER_URL + "/ideas/add", item)
    .then(res => {
      dispatch({
        type: "IDEA",
        payload: res.data
      });
      dispatch(clearAddIdeaLoading());
    })
    .catch(err => {
      console.log("error");
    });
};

//Edit idea
export const editIdea = item => dispatch => {
  dispatch(setAddIdeaLoading());
  axios
    .post(SERVER_URL + "/ideas/edit", item)
    .then(res => {
      dispatch({
        type: "IDEA",
        payload: res.data
      });
      dispatch(clearAddIdeaLoading());
    })
    .catch(err => {
      console.log("error");
    });
};

//Claim idea
export const claimIdea = item => dispatch => {
  dispatch(setPaymentLoading());
  axios
    .post(SERVER_URL + "/ideas/claim", item)
    .then(res => {
      dispatch({
        type: "IDEA",
        payload: res.data
      });
      dispatch(clearPaymentLoading());
    })
    .catch(err => {
      console.log("error");
    });
};

//Get ideas
export const getIdeas = () => dispatch => {
  dispatch(setPaymentLoading());
  axios
    .get(SERVER_URL + "/ideas/get")
    .then(res => {
      dispatch({
        type: "IDEA",
        payload: res.data
      });
      dispatch(clearPaymentLoading());
    })
    .catch(err => {
      console.log(err);
    });
};

//Get docs
export const getDocs = () => dispatch => {
  axios
    .get(SERVER_URL + "/docs")
    .then(res =>
      dispatch({
        type: GET_DOCS,
        payload: res.data
      })
    )
    .catch(err => {
      console.log(err);
    });
};

//Add help message
export const addHelpMessage = item => dispatch => {
  dispatch(setPaymentLoading());
  console.log("process");
  axios
    .post(SERVER_URL + "/prices/help", item)
    .then(res => {
      dispatch({
        type: ADD_HELP,
        payload: "success"
      });
      dispatch(clearPaymentLoading());
      console.log("success");
      his.push("/thankyou");
    })
    .catch(err => {
      console.log("error");
      dispatch({
        type: ADD_HELP,
        payload: "error"
      });
    });
};

export const addPictureMessage = item => {
  console.log("process");
  axios
    .post(SERVER_URL + "/prices/help", item)
    .then(res => {
      console.log("success");
      his.push("/thankyou");
    })
    .catch(err => {
      console.log("error");
    });
};

//Loading
export const setPaymentLoading = () => {
  return {
    type: PAYMENT_LOADING
  };
};
//Clear Loading
export const clearPaymentLoading = () => {
  return {
    type: CLEAR_PAYMENT_LOADING
  };
};

//ADD IDEA Loading
export const setAddIdeaLoading = () => {
  return {
    type: "SET_ADD_IDEA_LOADING"
  };
};
//ADD IDEA Clear Loading
export const clearAddIdeaLoading = () => {
  return {
    type: "CLEAR_ADD_IDEA_LOADING"
  };
};
