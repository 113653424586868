import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import {
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
  AtomicBlockUtils,
} from "draft-js";
import Editor from "draft-js-plugins-editor";
import { Link } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet";

import { setDoc } from "../../actions/editorAction";
import { mediaBlockRenderer } from "../common/plugins/mediaBlockRenderer";
import isEqual from "../../validation/is-equal";
import pic_static from "../../img/profile_pic_comment.svg";

class RichViewer extends Component {
  state = { doc: {}, editorState: EditorState.createEmpty() };
  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.doc, prevProps.doc) && this.props.doc.link) {
      const { editorState } = this.props.doc;
      const contentState = convertFromRaw(JSON.parse(editorState));
      const editorStateFinal = EditorState.createWithContent(contentState);
      const rawEntity = convertToRaw(editorStateFinal.getCurrentContent())
        .entityMap;
      console.log("rawEntity from componentDidUpdate=", rawEntity);
      this.setState({ doc: this.props.doc, editorState: editorStateFinal });
    }
  }
  componentDidMount() {
    if (this.props && this.props.doc && this.props.doc.link) {
      const { editorState } = this.props.doc;
      const contentState = convertFromRaw(JSON.parse(editorState));
      const editorStateFinal = EditorState.createWithContent(contentState);
      const rawEntity = convertToRaw(editorStateFinal.getCurrentContent())
        .entityMap;
      console.log("rawEntity from componentDidMount=", rawEntity);
      this.setState({ doc: this.props.doc, editorState: editorStateFinal });
    }
  }
  //Local change
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (["tag"].includes(name)) {
      var tagArray = value.split(" ");
      this.setState({
        tag: value,
        tagArray: tagArray,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  //Rich editor stuff
  focus = () => this.refs.editor.focus();
  onChange = (editorState) => this.setState({ editorState });
  handleKeyCommand = (command) => {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };
  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };
  toggleInlineStyle = (inlineStyle) => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  };
  //Image upload stuff
  onAddImage = (picNew) => {
    const editorState = this.state.editorState;
    const urlValue = picNew.url;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      { src: urlValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );
    this.setState(
      {
        editorState: AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          " "
        ),
      },
      () => {
        setTimeout(() => this.focus(), 0);
      }
    );
  };
  //Link stuff
  onURLChange = (e) => this.setState({ urlValue: e.target.value });
  onAddLink = () => {
    const editorState = this.state.editorState;
    const selection = editorState.getSelection();
    const link = window.prompt("Paste the link -");
    if (!link) {
      this.onChange(RichUtils.toggleLink(editorState, selection, null));
      return "handled";
    }
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity("LINK", "IMMUTABLE", {
      url: link,
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "create-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    this.onChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
    return "handled";
  };
  render() {
    //state
    const { doc, editorState } = this.state;
    //Ambil gambar pertama
    const rawEntity = convertToRaw(editorState.getCurrentContent()).entityMap;
    const rawEntityArray = Object.keys(rawEntity).map((i) => rawEntity[i]);
    const rawEntityArrayFiltered = rawEntityArray.filter(
      (element) => element.type === "image"
    );
    const url_img =
      rawEntityArrayFiltered &&
      rawEntityArrayFiltered[0] &&
      rawEntityArrayFiltered[0].data &&
      rawEntityArrayFiltered[0].data.src
        ? rawEntityArrayFiltered[0].data.src
        : "";
    const object_style = url_img
      ? {
          background: `linear-gradient(
            #ffffff14, 
            #ffffff14, 
            #ffffff14, 
            #ffffffbb,
            #ffffff
      ),
      url(${url_img}) no-repeat center 38%/ cover`,
        }
      : {};
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = "RichEditor-editor";
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }
    const itemContent = (
      <div className="RichEditor-root ">
        <div className={className} onClick={this.focus}>
          <Editor
            blockRendererFn={mediaBlockRenderer}
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand.bind(this)}
            onChange={this.onChange}
            placeholder="Write your document here.."
            ref="editor"
            spellCheck={false}
            readOnly={true}
          />
        </div>
      </div>
    );

    const authorContent = (
      <div style={{ marginTop: "30px" }}>
        <div
          className={`photo_${
            doc.authors &&
            doc.authors[0] &&
            doc.authors[0].user &&
            doc.authors[0].user._id
              ? doc.authors[0].user._id
              : ""
          }`}
        />
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            marginLeft: "10px",
            fontSize: "17px",
            color: "black",
          }}
        >
          {doc.authors &&
          doc.authors[0] &&
          doc.authors[0].user &&
          doc.authors[0].user.full_name
            ? doc.authors[0].user.full_name
            : ""}
          <br />
          <div
            style={{
              fontSize: "12px",
              color: "black",
            }}
          >
            {moment(doc.date).format("MMM DD YYYY")}
          </div>
        </div>
        <style>
          {`.photo_${
            doc.authors &&
            doc.authors[0] &&
            doc.authors[0].user &&
            doc.authors[0].user._id
              ? doc.authors[0].user._id
              : ""
          }{
            background: url(${
              doc.authors &&
              doc.authors[0] &&
              doc.authors[0].user &&
              doc.authors[0].user.profile_picture &&
              doc.authors[0].user.profile_picture.url
                ? doc.authors[0].user.profile_picture.url
                : pic_static
            }
            ) no-repeat center center/ cover;
            background-color: #dcdcdc;
            height: 80px;
            width: 80px;
            border-radius: 50%;
            display: inline-block;
            align: center;
            vertical-align: middle;
            border: 2px solid #b0b0b0;
            box-shadow: 0 0 12px #0000004e;
            -webkit-box-shadow: 0 0 12px #0000004e;
            -moz-box-shadow: 0 0 12px #0000004e;
            }`}
        </style>
      </div>
    );

    const bracketContent = (
      <div>
        <Link
          to={`/edit_document/${doc.bracket}/${doc.link}`}
          className="buttonSimpleTightNormal"
          id="editBright"
        >
          Edit
        </Link>
        <div
          className="buttonSimpleTightNormal"
          id={doc.isPublish ? "editBright" : "deleteBright"}
          style={{ marginLeft: "10px" }}
        >
          {doc.isPublish ? "Published" : "Not Published Yet"}
        </div>
        <div
          className="buttonSimpleTightNormal"
          id="deleteBright"
          style={{ marginLeft: "10px" }}
          onClick={this.props.toggleDelete.bind(this, doc)}
        >
          Delete
        </div>
      </div>
    );

    return (
      <div style={{ marginBottom: "200px" }}>
        <Helmet>
          <title>{doc.title}</title>
          <meta name="description" content={`MAPID | ${doc.title}`} />
        </Helmet>
        <div className="titleWraper" style={object_style}>
          <div className="dashboardSpecial">
            <div className="titleDocument">
              <strong>{doc.title}</strong>
            </div>
          </div>
        </div>
        <div className="documentContainer">
          {authorContent}
          {!this.props.isFromIOT ? bracketContent : null}
          {itemContent}
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            body{padding-top: 0px;}
            `,
          }}
        />
      </div>
    );
  }
}
// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    case "center":
      return "RichEditor-center";
    default:
      return null;
  }
}
RichViewer.propTypes = {
  auth: PropTypes.object.isRequired,
  setDoc: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  editor: state.editor,
});
export default connect(mapStateToProps, {
  setDoc,
})(RichViewer);
