/* eslint-disable */
import React, { Component } from "react";
class Toggle extends Component {
  state = {
    value: true,
    janganDiganggu: false
  };
  render() {
    const toggle = (
      <div className="text-center">
        <svg
          width={this.props.width ? this.props.width : "100%"}
          height={this.props.width ? this.props.width : "100%"}
          viewBox="0 0 20 20"
          className="compass"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="donutRing"
            cx="10"
            cy="10"
            r="10"
            fill="transparent"
          />
          <rect
            width={9}
            height={5}
            fill="#2196f3"
            x={10 - 9 / 2}
            y={10 - 5 / 2}
            rx={2.5}
            ry={2.5}
          />
          <circle
            className="donutRing"
            cx={10 - 2}
            cy={10}
            r={2}
            fill="white"
          />
        </svg>
      </div>
    );
    return <div>{toggle}</div>;
  }
}
export default Toggle;
