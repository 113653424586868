import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
// import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  getItem,
  getItemPublic,
  sendToMachine,
} from "../../actions/itemActions";
import moment from "moment";
import pic_static from "../../img/profile_pic_comment.svg";
import Counter from "../widget/Counter";
import Gauge from "../widget/Gauge";
import Compass from "../widget/Compass";
import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";

// import Paho from 'paho-mqtt'; 

class Cc extends Component {
  state = {
    width: window.innerWidth,
  };
  interval = null;
  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    clearInterval(this.interval);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  componentDidMount() {
    var status = this.props.status;
    var id = this.props.id;
    if (status === "private") {
      this.props.getItem(id);
      this.interval = setInterval(() => {
        this.props.getItem(id);
      }, 4000);
    } else {
      this.props.getItemPublic(id);
      this.interval = setInterval(() => {
        this.props.getItemPublic(id);
      }, 4000);
    }

    // var client = new Paho.Client("mqtt.mapid.io", Number(8080), "clientId")
    // client.connect({onSuccess: function(){
    //   client.subscribe("b0df0dd40f3e3316b0b48eb40e4d3176");
    // }});
    // client.onConnectionLost = onConnectionLost;
    // client.onMessageArrived = onMessageArrived;
    // function onConnectionLost(responseObject) {
    //     if (responseObject.errorCode !== 0) {
    //         // console.log("onConnectionLost:"+responseObject.errorMessage);
    //     }
    // }
    // // called when a message arrives
    // function onMessageArrived(message) {
    //     console.log("onMessageArrived: "+ message.payloadString);
    //     // let data = message.payloadString.split("var1=");
    //     // document.getElementById("root").innerHTML = data[1];
    //     // console.log(data[1]);
    // }
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      var status = this.props.status;
      var id = this.props.id;
      switch (status) {
        case "private":
          this.props.getItem(id);
          break;
        case "public":
          this.props.getItemPublic(id);
          break;
        default:
          return null;
      }
    }
    
  }
  handleMachine = (write_key, property, value) => {
    const item = {
      write_key,
      property,
      value,
    };
    this.props.sendToMachine(item);

    // var client = new Paho.Client("mqtt.mapid.io", Number(8080), "clientId")
    // client.connect({onSuccess: function(){
    //   client.subscribe("b0df0dd40f3e3316b0b48eb40e4d3176");
    //   let message = new Paho.Message(`${value}`);
    //   message.destinationName = "b0df0dd40f3e3316b0b48eb40e4d3176";
    //   client.send(message);
    // }});
  };
  render() {
    const { item } = this.props.item;
    // const { width } = this.state;
    // const isMobile = width <= 800;
    // var className;
    // isMobile ? (className = "nomargin") : (className = "container");
    if (item) {
      var user = item.user;
      var last_data = item.last_data;
      var buffer_data = item.buffer_data;
      var gabungan = [];
      var data;
      var options;
      var varTag;
      var warningContent = {};
      if (!buffer_data.var1.values) {
        return null;
      } else {
        for (var property in buffer_data) {
          if (buffer_data.hasOwnProperty(property)) {
            varTag = property;
            var varData = buffer_data[property];
            var values = varData.values;
            var name = varData.name;
            var unit = varData.unit;
            var xValues = [];
            var yValues = [];
            var arrayValues = [];
            var arrayValuesFiltered = [];
            var tipe = varData.tipe ? varData.tipe : "";
            //min max
            var varLast = last_data[property];
            var nMin = varLast.nMin;
            var nMax = varLast.nMax;
            //Notif
            var notif = varLast.notif;
            var lastVal = varLast.values[0];
            var color;
            //filter
            var isFilter = varLast.isFilter ? varLast.isFilter : false;
            var filter = varLast.filter ? varLast.filter : "";
            var filterArray = [];
            if (item.isNotif) {
              if (notif) {
                if (tipe === "LON") {
                  if (lastVal <= nMin) {
                    color = "rgba(240,101,40,255)";
                    warningContent = (
                      <div className="buttonSimpleTightNormal" id="delete">
                        Kendaraan masuk daerah merah
                      </div>
                    );
                  } else if (lastVal >= nMax) {
                    color = "rgba(240,101,40,255)";
                    warningContent = (
                      <div className="buttonSimpleTightNormal" id="delete">
                        Kendaraan masuk daerah kuning
                      </div>
                    );
                  } else {
                    color = "rgba(240,101,40,255)";
                    warningContent = (
                      <div className="buttonSimpleTightNormal" id="edit">
                        Posisi aman
                      </div>
                    );
                  }
                } else {
                  if (lastVal <= nMin) {
                    color = "rgba(240,101,40,255)";
                    warningContent = (
                      <div className="buttonSimpleTightNormal" id="delete">
                        {name} melebihi ambang batas bawah: {lastVal} {unit}
                      </div>
                    );
                  } else if (lastVal >= nMax) {
                    color = "rgba(240,101,40,255)";
                    warningContent = (
                      <div className="buttonSimpleTightNormal" id="delete">
                        {/* Max: {name} is now {lastVal} {unit} */}
                        {name} melebihi ambang batas atas: {lastVal} {unit}
                      </div>
                    );
                  } else {
                    color = "rgba(75,192,192,1)";
                    warningContent = (
                      <div className="buttonSimpleTightNormal" id="edit">
                        {/* Max: {name} is now {lastVal} {unit} */}
                        {name} aman
                      </div>
                    );
                  }
                }
              } else {
                // eslint-disable-next-line
                color = "rgba(75,192,192,1)";
                warningContent = <div />;
              }
            } else {
              color = "rgba(75,192,192,1)";
              warningContent = <div />;
            }
            var i;
            if (isFilter && filter) {
              for (i in values) {
                //eslint-disable-next-line
                eval(filter);
                yValues.push(filterArray[i]);
                xValues.push(values[i][1]);
              }
            } else {
              for (i in values) {
                yValues.push(values[i][0]);
                xValues.push(values[i][1]);
              }
            }
            for (var e = 0; e < xValues.length; e++) {
              arrayValues.push({
                x: xValues[e],
                y: yValues[e],
              });
            }
            arrayValuesFiltered = arrayValues
              .filter((value) => value.y !== "NaN")
              .filter((value) => value.y !== "undefined")
              .filter((value) => value.y !== "delete");
            const panjang = arrayValuesFiltered.length;
            var arrayRealtime = [];
            if (panjang < 10) {
              arrayRealtime = arrayValuesFiltered;
            } else {
              arrayRealtime = arrayValuesFiltered.slice(panjang - 10, panjang);
            }
          }
          data = {
            datasets: [
              {
                // label: name,
                fill: false,
                lineTension: 0.1,
                backgroundColor: "rgba(75,192,192,0.4)",
                // borderColor: "rgba(75,192,192,1)",
                borderColor: color,
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: color,
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "rgba(75,192,192,1)",
                pointHoverBorderColor: "rgba(220,220,220,1)",
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 10,
                data: arrayRealtime,
              },
            ],
          };
          options = {
            maintainAspectRatio: true,
            aspectRatio: 2,
            animation: {
              duration: 3000,
              easing: "easeOutCirc",
            },
            legend: {
              display: false,
            },
            responsive: true,
            scales: {
              xAxes: [
                {
                  display: true,
                  type: "time",
                  time: {
                    parser: "MM/DD/YYYY hh:mm:ss a",
                    // unit: "minute",
                    // displayFormats: {
                    //   minute: "HH:mm"
                    // }
                  },
                  distribution: "linear",
                  scaleLabel: {
                    display: true,
                    labelString: "Time",
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: `${unit}`,
                  },
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          };
          gabungan.push({
            data,
            options,
            varTag,
            name,
            warningContent,
            tipe,
            nMin,
            nMax,
          });
        }
      }
    } else {
      return null;
    }
    var headerContent;
    var itemContent;
    if (!item) {
      itemContent = (
        <div>
          <h2>
            <br />
            <br />
            {"This dataset is private"} <br />
            {"or your token has been expired,"} <br />
            {"please sign in again."}
          </h2>
        </div>
      );
    } else {
      headerContent = (
        <div>
          <Helmet>
            <title>{item.name}</title>
            <meta name="description" content={`${item.name} | IOT MAPID`} />
          </Helmet>
          <div
            key="user_profile"
            alt="user_profile"
            className={`bgUser`}
            draggable={false}
          />
          <div
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              marginLeft: "10px",
              fontSize: "17px",
              color: "black",
            }}
          >
            <div className="welcome" style={{ fontSize: "30px" }}>
              {item.name}
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "black",
              }}
            >
              {item && item.user && item.user.full_name
                ? item.user.full_name
                : "Owner"}
            </div>
            <style>
              {`.bgUser{
                  background: url(${
                    user &&
                    user.profile_picture &&
                    user.profile_picture.url_compressed
                      ? user.profile_picture.url_compressed
                      : user &&
                        user.profile_picture &&
                        user.profile_picture.url &&
                        user.profile_picture.url !== "-"
                      ? user.profile_picture.url
                      : pic_static
                  }) no-repeat center center/ cover;
                  background-color: #dcdcdc;
                  height: 80px;
                  width: 80px;
                  border-radius: 50%;
                  display: inline-block;
                  align: center;
                  vertical-align: middle;
                  border: 2px solid #b0b0b0;
                  box-shadow: 0 0 12px #0000004e;
                  -webkit-box-shadow: 0 0 12px #0000004e;
                  -moz-box-shadow: 0 0 12px #0000004e;
                  margin-top: 20px;
                  margin-bottom: 20px;
                  }`}
            </style>
          </div>
          <br />

          <div
            className="buttonShadowSmall"
            style={{
              padding: "10px",
              width: "200px",
              marginRight: "10px",
              textAlign: "left",
            }}
          >
            <div style={{ display: "block", marginBottom: "20px" }}>
              Last update at:
            </div>
            {item.last_entry_at ? moment(item.last_entry_at).from() : "No data"}
          </div>
          <div
            className="buttonShadowSmall"
            style={{
              padding: "10px",
              width: "200px",
              marginRight: "10px",
              textAlign: "left",
            }}
          >
            <div style={{ display: "block", marginBottom: "20px" }}>
              Calls amount:
            </div>
            {item.entries_number}
          </div>
          <div
            className="buttonShadowSmall"
            style={{
              padding: "10px",
              width: "200px",
              marginRight: "10px",
              textAlign: "left",
            }}
          >
            <div style={{ display: "block", marginBottom: "20px" }}>
              Created since:
            </div>
            {moment(item.created_at).from()}
          </div>
          <br />
          {/* <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            {this.props.status === "private" ? (
              <Link
                className="buttonSimple2"
                to={`/edit_doc_iot/report/${
                  item && item.folder && item.folder._id
                    ? item.folder._id
                    : item._id
                }/${item._id}`}
              >
                Edit Doc
              </Link>
            ) : null}
            <Link
              className="buttonSimple2"
              to={`/view_doc_iot/report/${item._id}`}
            >
              View Doc
            </Link>
          </div> */}
        </div>
      );
      itemContent = (
        <div
          className="widget-container"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          {gabungan.map(
            ({
              varTag,
              data,
              options,
              name,
              warningContent,
              tipe,
              nMin,
              nMax,
            }) => {
              const unit = options.scales.yAxes[0].scaleLabel.labelString;
              var chartComponent;
              nMax = Number(nMax);
              nMin = Number(nMin);
              // const nMinWidget = nMin - Math.abs(nMax - nMin) * 0.1;
              const nMaxWidget = nMax + Math.abs(nMax - nMin) * 0.1;
              const value =
                tipe === "STRING"
                  ? data.datasets[0].data &&
                    data.datasets[0].data[data.datasets[0].data.length - 1] &&
                    data.datasets[0].data[data.datasets[0].data.length - 1].y
                    ? data.datasets[0].data[data.datasets[0].data.length - 1].y
                    : 0
                  : Number(
                      data.datasets[0].data &&
                        data.datasets[0].data[
                          data.datasets[0].data.length - 1
                        ] &&
                        data.datasets[0].data[data.datasets[0].data.length - 1]
                          .y
                        ? data.datasets[0].data[
                            data.datasets[0].data.length - 1
                          ].y
                        : 0
                    );
              const actionValue = value === 0 ? 1 : 0;
              const dataLength = data.datasets[0].data.length;
              if (dataLength > 0) {
                switch (tipe) {
                  case "ARC":
                    chartComponent = (
                      <Counter
                        value={value}
                        nMin={nMin}
                        nMax={nMaxWidget}
                        unit={options.scales.yAxes[0].scaleLabel.labelString}
                      />
                    );
                    break;
                  case "GG":
                    chartComponent = (
                      <Gauge
                        value={value}
                        nMin={nMin}
                        nMax={nMaxWidget}
                        unit={options.scales.yAxes[0].scaleLabel.labelString}
                      />
                    );
                    break;
                  case "CMP":
                    chartComponent = (
                      <Compass
                        value={value}
                        nMin={0}
                        nMax={360}
                        unit={options.scales.yAxes[0].scaleLabel.labelString}
                      />
                    );
                    break;
                  case "STRING":
                    chartComponent = (
                      <div>
                        <div className="buttonSimple2">{value}</div>
                      </div>
                    );
                    break;
                  case "TG":
                    chartComponent = (
                      <div style={{ textAlign: "center" }}>
                        <div
                          className="buttonSimpleTightNormal"
                          onClick={this.handleMachine.bind(
                            this,
                            item.write_key,
                            varTag,
                            actionValue
                          )}
                        >
                          Click here to take toggle action!
                        </div>
                        <br />
                        <br />
                        <br />
                        <label className="simpleCheck">
                          <input
                            type="checkbox"
                            id="isPublic"
                            value={value}
                            checked={value}
                            readOnly
                          />
                          <span className="buttonCheck">
                            {name}{" "}
                            <span className="buttonSimpleTightNormal" id="edit">
                              {value ? "ON" : "OFF"}
                            </span>
                          </span>
                        </label>
                      </div>
                    );
                    break;
                  case "LAT":
                    chartComponent = null;
                    break;
                  case "LON":
                    chartComponent = null;
                    break;
                  default:
                    chartComponent = (
                      <div>
                        <Line
                          data={data}
                          options={options}
                          key={"chart" + varTag}
                          width={100}
                          height={50}
                        />
                        <div className="buttonSimple2">
                          {value} {unit}
                        </div>
                      </div>
                    );
                }
              } else {
                chartComponent = (
                  <div style={{ textAlign: "center" }}>
                    <SpinnerSimpleLogo
                      width={100}
                      unik="loading_realtime"
                      marginTop="0px"
                    />
                    <br />
                    <h6>Waiting for the first data</h6>
                  </div>
                );
              }
              return (
                <div className="widget-item-chart" key={"column" + varTag}>
                  <div className="specialItem" style={{ textAlign: "center", width: gabungan.length === 1 ? this.state.width < 720 ? '100%':'50%': '100%' }}>
                    <h3>
                      {name} {" - "} {unit ? unit : "unit"}
                    </h3>
                    <div>{chartComponent}</div>
                    <div>{warningContent}</div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      );
    }
    return (
      <div className="dashboardSpecial">
        {headerContent}
        {itemContent}
      </div>
    );
  }
}
Cc.propTypes = {
  getItem: PropTypes.func.isRequired,
  getItemPublic: PropTypes.func.isRequired,
  sendToMachine: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  item: state.item,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getItem,
  getItemPublic,
  sendToMachine,
})(Cc);
