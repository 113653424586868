import React, { Component } from "react";
import PropTypes from "prop-types";
import ModalComponent from "../common/ModalComponent";
import { connect } from "react-redux";

import { deleteItem, addItem } from "../../actions/itemActions";
import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";
import DatasetsDefaultRender from "./DatasetsDefaultRender";
import DatasetsFolderRender from "./DatasetsFolderRender";

class DatasetsFolder extends Component {
  state = {
    modal: false,
    modalViewDatasets: false,
    items: [],
    folder_item: {},
  };
  toggleViewDatasets = (items, folder_item) => {
    this.setState({ items, folder_item }, () => {
      this.setState({
        modalViewDatasets: !this.state.modalViewDatasets,
      });
    });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  render() {
    const { items, loading, folders_user } = this.props.item;
    const folders_id = folders_user.map(({ _id }, idx) => _id);
    var foldersContent;
    var dataset_free_render;
    var welcomeContent;
    var modalContentDatasets;
    if (loading) {
      welcomeContent = (
        <div style={{ textAlign: "center", margin: "auto" }}>
          <SpinnerSimpleLogo
            width={200}
            unik="loading_item_list"
            marginTop="0px"
          />
        </div>
      );
    } else {
      if (items.length === 0) {
        welcomeContent = (
          <div style={{ textAlign: "center", margin: "auto" }}>
            <SpinnerSimpleLogo
              width={200}
              unik="loading_item_list"
              marginTop="0px"
            />
          </div>
        );
      } else {
        //Dataset inside folder
        foldersContent = (
          <div className="folder-container">
            {folders_user.map((folder_item, idx) => {
              const datasets_on_folder = items.filter(
                (item) => item.folder === folder_item._id
              );
              return (
                <div key={folder_item._id}>
                  <div
                    className="folder"
                    onClick={this.toggleViewDatasets.bind(
                      this,
                      datasets_on_folder,
                      folder_item
                    )}
                  >
                    {folder_item.name.length > 40 ? folder_item.name.slice(0, 40) + '...' : folder_item.name}
                  </div>
                </div>
              );
            })}
          </div>
        );
        //Dataset outside folder
        const datasets_free = items.filter(
          (item) => !folders_id.includes(item.folder)
        );
        dataset_free_render = (
          <div style={{ paddingTop: "20px" }}>
            <DatasetsDefaultRender items={datasets_free} />
          </div>
        );
        modalContentDatasets = this.state.modalViewDatasets && (
          <ModalComponent
            modalSize="lg"
            id="modal"
            isOpen={this.state.modalViewDatasets}
            onClose={this.toggleViewDatasets.bind(
              this,
              this.state.items,
              this.state.folder_item
            )}
          >
            <div className="box-body">
              <DatasetsFolderRender
                items={this.state.items}
                folder_item={this.state.folder_item}
              />
            </div>
          </ModalComponent>
        );
      }
    }
    return (
      <div style={{ paddingTop: "20px" }}>
        {welcomeContent}
        {foldersContent}
        {modalContentDatasets}
        {dataset_free_render}
      </div>
    );
  }
}

DatasetsFolder.propTypes = {
  deleteItem: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  item: state.item,
});

export default connect(mapStateToProps, { deleteItem, addItem })(
  DatasetsFolder
);
