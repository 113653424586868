import axios from "axios";
//import his from "./history";
import his from "./history";
// const SERVER_URL = "http://localhost:4000";
const SERVER_URL = "https://api.mapid.io";
// const SERVER_URL = "http://iotmapidserver.us-west-2.elasticbeanstalk.com";

/*GENERAL*/

export const setLoadingProcess = () => {
  return {
    type: "SET_LOADING_PROCESS",
  };
};

export const setLoadingContent = () => {
  return {
    type: "SET_LOADING_CONTENT",
  };
};

export const setLoadingList = () => {
  return {
    type: "SET_LOADING_LIST",
  };
};

export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING",
  };
};

export const setPicture = (content) => (dispatch) => {
  dispatch({
    type: "SET_PICTURE",
    payload: content,
  });
};

/* ****************************Document on Bracket Start**************************** */
//1. CREATE BRACKET ADD (PUSH) GROUP_ID ATRRIBUTE
export const createBracket = (item) => (dispatch) => {
  dispatch(setLoadingProcess());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/blog/create_bracket", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "CREATE_BRACKET",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//2. EDIT BRACKET
export const editBracket = (item) => (dispatch) => {
  dispatch(setLoadingProcess());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/blog/edit_bracket", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_BRACKET_LIST",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//3. PUSH GROUP TO BRACKET
export const pushGroup = (item) => (dispatch) => {
  dispatch(setLoadingProcess());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/blog/push_group", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_BRACKET_LIST",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//4. DELETE GROUP FROM BRACKET
export const deleteGroup = (item) => (dispatch) => {
  dispatch(setLoadingProcess());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/blog/delete_group", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_BRACKET_LIST",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//5. DELETE BRACKET
export const deleteBracket = (id) => (dispatch) => {
  dispatch(setLoadingProcess());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .delete(SERVER_URL + `/blog/delete_bracket/${id}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "DELETE_BRACKET",
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//6. ADD SUB_BRACKET
export const pushSubBracket = (item) => (dispatch) => {
  dispatch(setLoadingProcess());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/blog/push_sub_bracket", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "SET_BRACKET",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//7. EDIT SUB_BRACKET
export const editSubBracket = (item) => (dispatch) => {
  dispatch(setLoadingProcess());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/blog/edit_sub_bracket", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "SET_BRACKET",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//8. DELETE SUB_BRACKET
export const deleteSubBracket = (item) => (dispatch) => {
  dispatch(setLoadingProcess());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/blog/delete_sub_bracket", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "SET_BRACKET",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//9. ADD PICTURE TO BRACKET
export const pushImageBracket = (item) => (dispatch) => {};

//10. CREATE DOCUMENT INSIDE BRACKET & SUB_BRACKET
export const createDoc = (item) => (dispatch) => {
  dispatch(setLoadingProcess());
  axios
    .post(SERVER_URL + "/blog/create_doc", item)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "CREATE_DOC",
        payload: res.data,
      });
      his.push(`/viewer/${item.bracket}/${item.link}`);
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//11. EDIT DOCUMENT INSIDE BRACKET & SUB_BRACKET
export const editDoc = (item) => (dispatch) => {
  dispatch(setLoadingProcess());
  axios
    .post(SERVER_URL + "/blog/edit_doc", item)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_DOC",
        payload: res.data,
      });
      his.push(`/viewer/${item.bracket}/${item.link}`);
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//12. DELETE DOCUMENT INSIDE BRACKET & SUB_BRACKET
export const deleteDoc = (item) => (dispatch) => {
  dispatch(setLoadingProcess());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .delete(SERVER_URL + `/blog/delete_doc/${item.id}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "DELETE_DOC",
        payload: item.id,
      });
      his.push(`/viewer/${item.bracket}`);
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//13. GET BRACKETS BY USER_ID
export const getBracketList = (user_id) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + `/blog/get_brackets/${user_id}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_BRACKET_LIST",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_BRACKET_LIST",
        payload: [],
      });
    });
};

//14. GET BRACKETS BY BRACKET_LINK
export const getBracket = (bracket_link) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingContent());
  axios
    .get(SERVER_URL + `/blog/get_bracket/${bracket_link}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "SET_BRACKET",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "SET_BRACKET",
        payload: {
          sub_brackets: [],
        },
      });
    });
};

//15. GET PICTURE LIST BY BRACKET_LINK
export const getBracketPictureList = (bracket_link) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + `/blog/get_bracket_picture_list/${bracket_link}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "SET_PICTURE_ARRAY",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "SET_PICTURE_ARRAY",
        payload: [],
      });
    });
};

//16. GET DOC LIST BY BRACKET LINK (AUTHOR/MEMBERS)
export const getDocList = (bracket_link) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + `/blog/get_list_docs_private/${bracket_link}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_DOC_LIST",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_DOC_LIST",
        payload: [],
      });
    });
};

//17. GET DOC DETAIL BY DOC_LINK (AUTHOR/MEMBERS)
export const getDoc = (doc_link) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingContent());
  axios
    .get(SERVER_URL + `/blog/get_doc_by_link_private/${doc_link}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_DOC",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_DOC",
        payload: [],
      });
    });
};

//18. SET BRACKET OBJECT
export const setBracket = (bracket) => (dispatch) => {
  dispatch({
    type: "SET_BRACKET",
    payload: bracket,
  });
};

//19. SET DOC OBJECT
export const setDoc = (doc) => (dispatch) => {
  dispatch({
    type: "GET_DOC",
    payload: doc,
  });
};
/* ****************************Document on Bracket Finish**************************** */

/* ****************************Document on Folder/Dataset Start**************************** */
//1. CREATE TASK || REPORT (OKE)
export const createTask = (item) => (dispatch) => {
  dispatch(setLoadingProcess());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/blog/create_task", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_DOC",
        payload: res.data,
      });
      his.push(`/dashboard`);
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//2. EDIT TASK || REPORT (OKE)
export const editTask = (item) => (dispatch) => {
  dispatch(setLoadingProcess());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/blog/edit_task", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_DOC",
        payload: res.data,
      });
      his.push(`/dashboard`);
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//3. ADD PICTURE TO FOLDER (OKE)
export const pushImageFolder = (item) => (dispatch) => {};

//4. ADD PICTURE TO DATASET (OKE)
export const pushImageDataset = (item) => (dispatch) => {};

//5. GET DOC BY FOLDER ID (OKE)
export const getDocFolder = (folder_id) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingContent());
  axios
    .get(SERVER_URL + `/blog/get_doc_by_id_folder/${folder_id}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_DOC",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_DOC",
        payload: [],
      });
    });
};

//6. GET DOC BY DATASET (OKE)
export const getDocDataset = (dataset_id) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingContent());
  axios
    .get(SERVER_URL + `/blog/get_doc_by_id_dataset/${dataset_id}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_DOC",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_DOC",
        payload: [],
      });
    });
};

//7. GET PICTURE LIST IN FOLDER (OKE)
export const getFolderPictureList = (id) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + `/blog/get_folder_picture_list/${id}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "SET_PICTURE_ARRAY",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "SET_PICTURE_ARRAY",
        payload: [],
      });
    });
};

//8. GET PICTURE LIST DATASET (OKE)
export const getDatasetPictureList = (id) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + `/blog/get_dataset_picture_list/${id}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "SET_PICTURE_ARRAY",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "SET_PICTURE_ARRAY",
        payload: [],
      });
    });
};

//GET VERSIONING
export const getVersioningIOT = () => (dispatch) => {
  dispatch(setLoadingContent());
  axios
    .get(SERVER_URL + `/blog/get_versioning_iot`)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_VERSIONING_IOT",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_VERSIONING_IOT",
        payload: "",
      });
    });
};

//GET EVENTS
export const getEvents = () => (dispatch) => {
  dispatch(setLoadingContent());
  axios
    .get(SERVER_URL + `/blog/get_list_docs_public/events`)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_EVENTS",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_EVENTS",
        payload: [],
      });
    });
};

/* **************************** Document on Folder/Dataset Finish **************************** */
