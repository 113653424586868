const formatTime = function (timestamp, filterTime, checkboxFilter) {
  let d = new Date(timestamp);
  let h = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
  let m = d.getMinutes() < 10 ? `0${d.getMinutes()}` : `${d.getMinutes()}`;
  let s = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();
  if (filterTime) {
    const minuteSlice = m;
    let firstNumber = minuteSlice.slice("")[0];
    let secondNumber = minuteSlice.slice("")[1];
    if (secondNumber !== "5" && secondNumber !== "0") {
      // secondNumber = "0";
      let intSecondNumber = parseInt(secondNumber);
      if (intSecondNumber < 5) {
        const delta = 5 - intSecondNumber;
        intSecondNumber -= 5 - delta;
      }
      if (intSecondNumber > 5) {
        const delta = 10 - intSecondNumber;
        intSecondNumber -= 5 - delta;
      }
      secondNumber = intSecondNumber;
    }

    if (checkboxFilter) {
      const nowNumber = parseInt(`${firstNumber}${secondNumber}`);
      if (nowNumber >= 0 && nowNumber < 15) {
        firstNumber = 0;
        secondNumber = 0;
      }
      if (nowNumber >= 15 && nowNumber < 30) {
        firstNumber = 1;
        secondNumber = 5;
      }
      if (nowNumber >= 30 && nowNumber < 45) {
        firstNumber = 3;
        secondNumber = 0;
      }
      if (nowNumber >= 45 && nowNumber < 59) {
        firstNumber = 4;
        secondNumber = 5;
      }
    }

    return `${h}:${firstNumber}${secondNumber}`;
  } else {
    return `${h}:${m}:${s}`;
  }
};

export default formatTime;
