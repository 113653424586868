import React, { Component } from "react";
import PropTypes from "prop-types";
import ModalComponent from "../common/ModalComponent";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "qrcode.react";
import NumericLabel from "react-pretty-numbers";
import moment from "moment";

import { deleteItem } from "../../actions/itemActions";
// import iconChart from "../../img/icon_chart.svg";
import iconQrcode from "../../img/icon_qrcode.svg";
import iconApikey from "../../img/icon_apikey.svg";
import iconDownloadcurrent from "../../img/icon_downloadcurrent.svg";
import iconDownloadall from "../../img/icon_downloadall.svg";
import iconNotifactive from "../../img/icon_notifactive.svg";
import iconNotifdisabled from "../../img/icon_notifdisabled.svg";
import pic_static from "../../img/profile_pic_comment.svg";
import TimelineIcon from "@material-ui/icons/Timeline";
import ListAltIcon from "@material-ui/icons/ListAlt";
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { OutlinedInput, InputAdornment, Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

class DatasetsDefaultRender extends Component {
  state = {
    copied: false,
    copied_req: false,
    modal: false,
    modal_delete: false,
    modal_write: false,
    modal_tools: false,
    modal_qrcode: false,
    currentId: "",
    currentName: "",
    name: "Dataset",
    logData: [
      { name: "Latitude", unit: "degree", values: [] },
      { name: "Longitude", unit: "degree", values: [] },
      { name: "Sensor1", unit: "unit", values: [] },
    ],
    currentIndex: 0,
    index: "",
    arrayUser: [],
    anchorEl: null,

    search: false,
    searchResult: [],
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };
  handleCopied = () => {
    this.setState({
      copied: true,
    });
    setTimeout(
      function () {
        this.setState({ copied: false });
      }.bind(this),
      3000
    );
  };
  handleCopiedReq = () => {
    this.setState({
      copied_req: true,
    });

    setTimeout(
      function () {
        this.setState({ copied_req: false });
      }.bind(this),
      3000
    );
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  handleClick = () => {
    this.setState({
      isClicked: !this.state.isClicked,
    });
    this.toggle();
  };
  toggle_delete = (id, name) => {
    this.setState({ currentId: id, currentName: name }, () => {
      this.setState({
        modal_delete: !this.state.modal_delete,
      });
    });
  };
  toggle_write = (id, name, idx) => {
    this.setState(
      { currentId: id, currentName: name, currentIndex: idx },
      () => {
        this.setState({
          modal_write: !this.state.modal_write,
        });
      }
    );
  };
  toggle_qrcode = (index) => {
    this.setState(
      {
        index: index,
      },
      () => {
        this.setState({ modal_qrcode: !this.state.modal_qrcode });
      }
    );
  };
  toggle_user = (users) => {
    this.setState(
      {
        arrayUser: users,
      },
      () => {
        this.setState({ modal_user: !this.state.modal_user });
      }
    );
  };
  onDeleteClick(id) {
    this.props.deleteItem(id);
    this.toggle_delete();
  }

  handleSearchDataset = (e) => {
    let query = e.target.value;
    let state = this.props.items;

    let matches = state.filter((state) => {
      const regex = new RegExp(`${query}`, "gi");
      return state.name.match(regex);
    });
    if (matches.length > 0) {
      this.setState({
        search: true,
        searchResult: matches,
      });
    } else {
      this.setState({
        search: false,
      });
    }

    // let result = this.props.items.find( x => x.name === `${ query }` );
    // if ( result )
    // {
    //   this.setState( {
    //     search: true,
    //     searchResult: [ result ],
    //   } )
    // } else
    // {
    //   this.setState( {
    //     search: false,
    //   } )
    //}
  };

  render() {
    var { currentIndex, copied, copied_req, arrayUser } = this.state;
    const items = this.props.items;
    var item = items && items[currentIndex] ? items[currentIndex] : " ";
    var last_data = item.last_data;
    var total_string = "";
    var total_example = "";
    var copy_write_key = item.write_key;
    var copy_read_key = item.read_key;
    const start_url = "https://api.mapid.io/api/update?key=";
    const start_url_req = "https://api.mapid.io/api/request?key=";
    //eslint-disable-next-line
    for (let property in last_data) {
      if (last_data.hasOwnProperty(property)) {
        let varData = last_data[property];
        let sensorName = varData.name.replace(/ +/g, "");
        let var_name = property + "=" + sensorName + "&";
        total_string = total_string + var_name;
      }
    }
    //eslint-disable-next-line
    for (let property in last_data) {
      if (last_data.hasOwnProperty(property)) {
        let var_name = property + "=" + 100 + "&";
        total_example = total_example + var_name;
      }
    }
    var cutted = total_string.substring(0, total_string.length - 1);
    var cutted_example = total_example.substring(0, total_example.length - 1);
    var full_url = start_url + copy_write_key + "&" + cutted;
    var full_url_example = start_url + copy_write_key + "&" + cutted_example;
    var full_url_req = start_url_req + copy_read_key;
    const itemsContent = (
      <div>
        {!this.state.search ? (
          <div className="widget-container">
            {items.map(
              (
                {
                  _id,
                  name,
                  entries_number,
                  isDamri,
                  last_data,
                  index,
                  isNotif,
                  sub_users_id,
                  last_entry_at,
                  user,
                },
                idx
              ) => {
                const chartButton = (
                  <Link to={`/chart/private/${_id}`}>
                    <div className="simpleContainer">
                      <TimelineIcon style={{ color: "#2196f3" }} />
                      <p>CHART</p>
                    </div>
                  </Link>
                );
                const tableButton = (
                  <Link to={`/table/private/${_id}`}>
                    <div className="simpleContainer">
                      <ListAltIcon />
                      <p>TABLE</p>
                    </div>
                  </Link>
                );
                // const datasetButton = (
                //   <Link to={`/dataset/private/${_id}`}>
                //     <div className="simpleContainer">
                //       <ListAltIcon />
                //       <p>DATA</p>
                //     </div>
                //   </Link>
                // );
                const user_id_current = this.props.auth.user._id;
                const sub_users_id_filtered = sub_users_id.filter(
                  (userSub) => userSub._id === user_id_current
                );
                var soldStatus;
                var firstOwner;
                if (
                  sub_users_id.length > 0 &&
                  sub_users_id_filtered.length === 0
                ) {
                  soldStatus = (
                    <div
                      className="buttonSold"
                      onClick={this.toggle_user.bind(this, sub_users_id)}
                    >
                      Sold
                    </div>
                  );
                  const firstUser = sub_users_id[0];
                  const username = firstUser.full_name
                    ? firstUser.full_name
                    : firstUser.name;
                  firstOwner = <div>{username}</div>;
                }
                let params = {
                  shortFormat: true,
                };
                const countContent = (
                  <div className="buttonSimpleTight">
                    <NumericLabel params={params}>
                      {Number(entries_number)}
                    </NumericLabel>
                  </div>
                );
                const notifContent = isNotif ? (
                  <div className="buttonSimpleTight">
                    <img
                      src={iconNotifactive}
                      alt="Notification"
                      width="20px"
                    />
                  </div>
                ) : (
                  <div className="buttonSimpleTight">
                    <img
                      src={iconNotifdisabled}
                      alt="Notification"
                      width="20px"
                    />
                  </div>
                );
                const editContent = (
                  <Link
                    to={`/update/${_id}`}
                    className="buttonSimpleTightNormal"
                    id="edit"
                    style={{ width: "70px" }}
                  >
                    Edit
                  </Link>
                );
                const deleteContent = (
                  <div
                    onClick={this.toggle_delete.bind(this, _id, name)}
                    className="buttonSimpleTightNormal"
                    id="delete"
                    style={{ color: "#bc2020" }}
                  >
                    Delete
                  </div>
                );
                const dateContent = (
                  <div className="buttonSimpleTightNormal" id="delete">
                    {last_entry_at ? moment(last_entry_at).from() : "No data"}
                  </div>
                );

                const imageWidth = "30px";
                const footerContent = (
                  <div className="four-container">
                    <div onClick={this.toggle_qrcode.bind(this, index)}>
                      <img
                        src={iconQrcode}
                        alt="QR-CODE"
                        width={imageWidth}
                        className="simpleImage"
                      />
                      <div className="simpleParagraph">QR-CODE</div>
                    </div>

                    <div onClick={this.toggle_write.bind(this, _id, name, idx)}>
                      <img
                        src={iconApikey}
                        alt="API-KEY"
                        width={imageWidth}
                        className="simpleImage"
                      />
                      <div className="simpleParagraph">API-KEY</div>
                    </div>

                    <Link to={`/current/${_id}`}>
                      <img
                        src={iconDownloadcurrent}
                        alt="DOWNLOAD CURRENT"
                        width={imageWidth}
                        className="simpleImage"
                      />
                      <div className="simpleParagraph">CURRENT</div>
                    </Link>

                    <Link to={`/download/${_id}`}>
                      <img
                        src={iconDownloadall}
                        alt="DOWNLOAD ALL"
                        width={imageWidth}
                        className="simpleImage"
                      />
                      <div className="simpleParagraph">ALL</div>
                    </Link>
                  </div>
                );
                //eslint-disable-next-line
                const geocodeContent = isDamri ? (
                  <p>
                    {last_data &&
                    last_data.var5 &&
                    last_data.var5.values &&
                    last_data.var5.values[0]
                      ? last_data.var5.values[0]
                      : "No position"}
                  </p>
                ) : null;

                return (
                  <div
                    key={`dataset_${_id}`}
                    className="specialItem"
                    style={{ width: "100%" }}
                  >
                    <div style={{ position: "relative" }}>
                      <div>
                        <b>
                          <Link to={`/new_dataset/private/${_id}`}>{name}</Link>
                        </b>
                        {soldStatus ? soldStatus : <div></div>}
                        {firstOwner ? firstOwner : ""}
                      </div>
                      {/* <div style={{position: "absolute",top: -10,right: -10}}>
                      <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={this.handleMenu}
                        >
                          <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={this.state.anchorEl}
                        // keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleCloseMenu}
                        style={{boxShadow: 'none'}}
                      >
                        <MenuItem>{editContent}</MenuItem>
                        <MenuItem>{deleteContent}</MenuItem>
                      </Menu>
                    </div> */}
                    </div>
                    <hr />
                    <div className="grid-container-chart-box">
                      <div className="chart">{chartButton}</div>
                      <div className="table">{tableButton}</div>
                      {/* <div className="">{datasetButton}</div> */}
                      <div className="bulet_1">{countContent}</div>
                      <div className="bulet_2">{notifContent}</div>
                      <div className="edit">{editContent}</div>
                      {user_id_current === user._id && (
                        <div className="delete">{deleteContent}</div>
                      )}
                      <div className="time">{dateContent}</div>
                    </div>
                    <hr />
                    <div>{footerContent}</div>
                  </div>
                );
              }
            )}
          </div>
        ) : (
          <div className="widget-container">
            {this.state.searchResult.map(
              (
                {
                  _id,
                  name,
                  entries_number,
                  isDamri,
                  last_data,
                  index,
                  isNotif,
                  sub_users_id,
                  last_entry_at,
                  user,
                },
                idx
              ) => {
                const chartButton = (
                  <Link to={`/chart/private/${_id}`}>
                    <div className="simpleContainer">
                      <TimelineIcon style={{ color: "#2196f3" }} />
                      <p>CHART</p>
                    </div>
                  </Link>
                );
                const tableButton = (
                  <Link to={`/table/private/${_id}`}>
                    <div className="simpleContainer">
                      <ListAltIcon />
                      <p>TABLE</p>
                    </div>
                  </Link>
                );
                const user_id_current = this.props.auth.user._id;
                const sub_users_id_filtered = sub_users_id.filter(
                  (userSub) => userSub._id === user_id_current
                );
                var soldStatus;
                var firstOwner;
                if (
                  sub_users_id.length > 0 &&
                  sub_users_id_filtered.length === 0
                ) {
                  soldStatus = (
                    <div
                      className="buttonSold"
                      onClick={this.toggle_user.bind(this, sub_users_id)}
                    >
                      Sold
                    </div>
                  );
                  const firstUser = sub_users_id[0];
                  const username = firstUser.full_name
                    ? firstUser.full_name
                    : firstUser.name;
                  firstOwner = <div>{username}</div>;
                }
                let params = {
                  shortFormat: true,
                };
                const countContent = (
                  <div className="buttonSimpleTight">
                    <NumericLabel params={params}>
                      {Number(entries_number)}
                    </NumericLabel>
                  </div>
                );
                const notifContent = isNotif ? (
                  <div className="buttonSimpleTight">
                    <img
                      src={iconNotifactive}
                      alt="Notification"
                      width="20px"
                    />
                  </div>
                ) : (
                  <div className="buttonSimpleTight">
                    <img
                      src={iconNotifdisabled}
                      alt="Notification"
                      width="20px"
                    />
                  </div>
                );
                const editContent = (
                  <Link
                    to={`/update/${_id}`}
                    className="buttonSimpleTightNormal"
                    id="edit"
                    style={{ width: "70px" }}
                  >
                    Edit
                  </Link>
                );
                const deleteContent = (
                  <div
                    onClick={this.toggle_delete.bind(this, _id, name)}
                    className="buttonSimpleTightNormal"
                    id="delete"
                    style={{ color: "#bc2020" }}
                  >
                    Delete
                  </div>
                );
                const dateContent = (
                  <div className="buttonSimpleTightNormal" id="delete">
                    {last_entry_at ? moment(last_entry_at).from() : "No data"}
                  </div>
                );

                const imageWidth = "30px";
                const footerContent = (
                  <div className="four-container">
                    <div onClick={this.toggle_qrcode.bind(this, index)}>
                      <img
                        src={iconQrcode}
                        alt="QR-CODE"
                        width={imageWidth}
                        className="simpleImage"
                      />
                      <div className="simpleParagraph">QR-CODE</div>
                    </div>

                    <div onClick={this.toggle_write.bind(this, _id, name, idx)}>
                      <img
                        src={iconApikey}
                        alt="API-KEY"
                        width={imageWidth}
                        className="simpleImage"
                      />
                      <div className="simpleParagraph">API-KEY</div>
                    </div>

                    <Link to={`/current/${_id}`}>
                      <img
                        src={iconDownloadcurrent}
                        alt="DOWNLOAD CURRENT"
                        width={imageWidth}
                        className="simpleImage"
                      />
                      <div className="simpleParagraph">CURRENT</div>
                    </Link>

                    <Link to={`/download/${_id}`}>
                      <img
                        src={iconDownloadall}
                        alt="DOWNLOAD ALL"
                        width={imageWidth}
                        className="simpleImage"
                      />
                      <div className="simpleParagraph">ALL</div>
                    </Link>
                  </div>
                );
                //eslint-disable-next-line
                const geocodeContent = isDamri ? (
                  <p>
                    {last_data &&
                    last_data.var5 &&
                    last_data.var5.values &&
                    last_data.var5.values[0]
                      ? last_data.var5.values[0]
                      : "No position"}
                  </p>
                ) : null;

                return (
                  <div
                    key={`dataset_${_id}`}
                    className="specialItem"
                    // style={ { width: "33.3%" } }
                  >
                    <div style={{ position: "relative" }}>
                      <div>
                        <b>{name}</b>
                        {soldStatus ? soldStatus : <div></div>}
                        {firstOwner ? firstOwner : ""}
                      </div>
                    </div>
                    <hr />
                    <div className="grid-container-chart-box">
                      <div className="chart">{chartButton}</div>
                      <div className="table">{tableButton}</div>
                      <div className="bulet_1">{countContent}</div>
                      <div className="bulet_2">{notifContent}</div>
                      <div className="edit">{editContent}</div>
                      {user_id_current === user._id && (
                        <div className="delete">{deleteContent}</div>
                      )}
                      <div className="time">{dateContent}</div>
                    </div>
                    <hr />
                    <div>{footerContent}</div>
                  </div>
                );
              }
            )}
            <div className="specialItem hide"></div>
            <div className="specialItem hide"></div>
            <div className="specialItem hide"></div>
          </div>
        )}
      </div>
    );
    const modal_user_content = this.state.modal_user && (
      <ModalComponent
        modalSize="small"
        id="modal"
        isOpen={this.state.modal_user}
        onClose={this.toggle_user.bind(this, arrayUser)}
      >
        <div className="box-body">
          Subscriber
          {arrayUser.map(({ full_name, name, profile_picture, _id }, idx) => {
            return (
              <div key={idx}>
                <div
                  alt={_id}
                  className={`photo_${_id}`}
                  samesite="None"
                  secure="true"
                />
                <style>
                  {`.photo_${_id}{
                              background: url(${
                                profile_picture &&
                                profile_picture.url_compressed
                                  ? profile_picture.url_compressed
                                  : profile_picture &&
                                    profile_picture.url &&
                                    profile_picture.url !==
                                      "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                                    profile_picture.url !== "-" &&
                                    profile_picture.url !== "default"
                                  ? profile_picture.url
                                  : pic_static
                              }) no-repeat center center/ cover;
                              background-color: #c1c1c1;
                              height:40px;
                              width:40px;
                              border-radius: 50%;
                              border: 2px solid #2196f3;
                              display: inline-block;
                              align: center;
                              vertical-align: middle;
                              margin-right: 10px;
                              margin-bottom: 10px;
                }`}
                </style>
                {full_name ? full_name : name}
              </div>
            );
          })}
        </div>
      </ModalComponent>
    );
    const modal_qrcode_content = this.state.modal_qrcode && (
      <ModalComponent
        modalSize="small"
        id="modal"
        isOpen={this.state.modal_qrcode}
        onClose={this.toggle_qrcode.bind(this, this.state.index)}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <div className="welcome" style={{ marginBottom: "30px" }}>
            QR Code to Transfer Dataset Ownership
          </div>
          <div>
            <QRCode value={this.state.index} />
            <br />
            <p>{this.state.index}</p>
          </div>
        </div>
      </ModalComponent>
    );
    const modal_delete_content = this.state.modal_delete && (
      <ModalComponent
        modalSize="small"
        id="modal"
        isOpen={this.state.modal_delete}
        onClose={this.toggle_delete}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }}>
            <p>Are you sure to delete</p>
            <br />
            <div className="welcome" style={{ textAlign: "center" }}>
              {this.state.currentName}?
            </div>
            <br />
            <button
              className="buttonSimpleTightNormal"
              id="delete"
              onClick={this.onDeleteClick.bind(this, this.state.currentId)}
            >
              Delete
            </button>
            <button
              className="buttonSimpleTightNormal"
              id="edit"
              onClick={this.toggle_delete}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalComponent>
    );
    const modal_write_content = this.state.modal_write && (
      <ModalComponent
        modalSize="medium"
        id="modal"
        isOpen={this.state.modal_write}
        onClose={this.toggle_write}
      >
        <div className="box-body">
          <div className="welcome">
            {"API key for"}{" "}
            {items && items[currentIndex] && items[currentIndex].name
              ? items[currentIndex].name
              : " "}
          </div>
          <br />
          <p>
            POST DATA
            <textarea value={full_url} rows="3" readOnly className="full" />
            <CopyToClipboard text={full_url}>
              <button
                className="buttonShadowSmall"
                onClick={this.handleCopied.bind(this)}
              >
                Copy with variable
              </button>
            </CopyToClipboard>
            <br />
            Example
            <textarea
              value={full_url_example}
              rows="3"
              readOnly
              className="full"
            />
            <CopyToClipboard text={full_url_example}>
              <button
                className="buttonShadowSmall"
                onClick={this.handleCopied.bind(this)}
              >
                Copy example
              </button>
            </CopyToClipboard>{" "}
            <CopyToClipboard text={copy_write_key}>
              <button
                className="buttonShadowSmall"
                onClick={this.handleCopied.bind(this)}
              >
                Copy API
              </button>
            </CopyToClipboard>{" "}
            {copied ? (
              <span className="buttonSimpleTightNormal">write api copied</span>
            ) : null}
          </p>
          <br />
          <p>
            GET DATA
            <textarea value={full_url_req} rows="3" readOnly className="full" />
            <CopyToClipboard text={full_url_req}>
              <button
                className="buttonShadowSmall"
                onClick={this.handleCopiedReq.bind(this)}
              >
                Copy URL
              </button>
            </CopyToClipboard>{" "}
            <CopyToClipboard text={copy_read_key}>
              <button
                className="buttonShadowSmall"
                onClick={this.handleCopiedReq.bind(this)}
              >
                Copy API
              </button>
            </CopyToClipboard>{" "}
            {copied_req ? (
              <span className="buttonSimpleTightNormal">read api copied</span>
            ) : null}
          </p>
          <br />
          <button className="buttonSimple2" onClick={this.toggle_write}>
            Close
          </button>
          <Link className="buttonSimple2" to="/code">
            Sample code
          </Link>
        </div>
      </ModalComponent>
    );

    const searchForm = (
      <div style={{ marginBottom: "1rem" }}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <OutlinedInput
              onChange={(e) => this.handleSearchDataset(e)}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
              placeholder="Search Dataset"
              labelWidth={0}
            />
          </Grid>
        </Grid>
      </div>
    );

    return (
      <div>
        {searchForm}
        {itemsContent}
        {modal_user_content}
        {modal_qrcode_content}
        {modal_delete_content}
        {modal_write_content}
      </div>
    );
  }
}
DatasetsDefaultRender.propTypes = {
  deleteItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  item: state.item,
  auth: state.auth,
});
export default connect(mapStateToProps, { deleteItem })(DatasetsDefaultRender);
