import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

import TableRow from "@material-ui/core/TableRow";
import {
  Container,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Backdrop,
  Switch,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
// import Alert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";

import DateComp from "./../chart/DateComp";
import {
  getLogAll,
  getLogV2,
  // deleteSingle
} from "./../../actions/itemActions";
import TimeComp from "../chart/TimeComp";
import { useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import formatDate from "../../validation/format-date";
import formatTime from "../../validation/format-time";

let columns = [
  // { id: "checkbox", label: "#", align: "center" },
  // { id: "#", label: "No." },
  { id: "data", label: "Data", align: "left", unit: true },
  { id: "date", label: "Date", align: "left" },
  { id: "time", label: "Time", align: "left" },
  // { id: "action", label: "Action", align: "center" },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    // maxHeight: 440,
  },
  title: {
    textAlign: "left",
  },
  buttonProgress: {
    // color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
  },
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
  menu: {
    paddingBottom: 10,
  },
  input: {
    padding: "7px 20px",
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 10,
  },
});

export default function Index(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [log, setLog] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [boolCheckbox, setBoolCheckbox] = useState([]);
  const [page, setPage] = useState(-8);
  const [notif, setNotif] = useState([]);
  const [dialogNotif, setDialogNotif] = useState(false);
  const [filterTime, setFilterTime] = useState(true);
  const [dataCSV, setDataCSV] = useState([]);
  const [fileName, setFileName] = useState("");
  const [loadingDownload, setloadingDownload] = useState(false);
  const [checkboxFilter, setCheckboxFilter] = useState(false);
  const [dialogSetting, setDialogSetting] = useState(false);
  const [timeSetting, setTimeSetting] = useState(0);

  async function getLog(paramsPage) {
    const param = {
      id: props.match.params.id,
      page: paramsPage,
      total: 8,
      var: "var0",
    };
    let d = await getLogV2(param);
    let arrLog = [];
    let index;
    for (index in d.log_data) {
      let config_sensor = 0;
      if (d.last_data.hasOwnProperty(index)) {
        const config_sensor_value = d.last_data[index]?.config_sensor
          ? d.last_data[index].config_sensor
          : 0;
        config_sensor = config_sensor_value;
      }
      if (d.log_data.hasOwnProperty(index)) {
        let name = d.log_data[index].name;
        let unit = d.log_data[index].unit;
        let arrData = [];
        d.log_data[index].values.forEach((element) => {
          arrData.push([element[0], element[1]]);
        });
        arrLog.push({
          name,
          arrData: arrData.reverse(),
          backupArrData: arrData,
          unit,
          config_sensor,
        });
      }
    }
    setLog(arrLog);
    setLoading(false);
    setOpen(false);
    setNotif(d.alert ? d.alert.reverse() : []);
    // calculate(timeSetting, arrLog);
    console.log(timeSetting);
  }

  useEffect(() => {
    async function getInit() {
      const param = {
        id: props.match.params.id,
        page: -8,
        total: 8,
        var: "var0",
      };
      let d = await getLogV2(param);
      let arrLog = [],
        arrCheckbox = [];
      let index;
      for (index in d.log_data) {
        let config_sensor = 0;
        if (d.last_data.hasOwnProperty(index)) {
          const config_sensor_value = d.last_data[index]?.config_sensor
            ? d.last_data[index].config_sensor
            : 0;
          config_sensor = config_sensor_value;
        }
        if (d.log_data.hasOwnProperty(index)) {
          let name = d.log_data[index].name;
          let unit = d.log_data[index].unit;
          let arrData = [];
          d.log_data[index].values.forEach((element) => {
            arrData.push([element[0], element[1]]);
          });
          arrCheckbox.push(false);
          arrLog.push({
            name,
            arrData: arrData.reverse(),
            backupArrData: arrData,
            unit,
            config_sensor,
          });
        }
      }
      setLog(arrLog);
      setBoolCheckbox(arrCheckbox);
      setNotif(d.alert ? d.alert.reverse() : []);
      setFileName(d.name);
    }
    getInit();
  }, [props]);

  const handleConfirmDelete = (e) => {
    setLoading(true);
    async function delFun() {
      getLog(-24);
    }
    delFun();
  };

  const handleLeft = () => {
    let p = page;
    p += -8;
    setPage(p);
    getLog(p);
  };
  const handleRight = () => {
    let p = page;
    p += 8;
    setPage(p);
    getLog(p);
  };

  const handleFilter = (event) => {
    setFilterTime(event.target.checked);
    dispatch({ type: "SET_FILTER_TIME", payload: event.target.checked });
  };

  const handleCheckbox = (event) => {
    setCheckboxFilter(event.target.checked);
    dispatch({
      type: "SET_FILTER_TIME_CHECKBOX",
      payload: event.target.checked,
    });
  };

  const handleDownload = async () => {
    setloadingDownload(true);
    const result_log = await getLogAll({ id: props.match.params.id });

    if (result_log) {
      let arrLog = [];
      let index;
      for (index in result_log.log_data) {
        let config_sensor = 0;
        if (result_log.last_data.hasOwnProperty(index)) {
          const config_sensor_value = result_log.last_data[index]?.config_sensor
            ? result_log.last_data[index].config_sensor
            : 0;
          config_sensor = config_sensor_value;
        }
        if (result_log.log_data.hasOwnProperty(index)) {
          let name = result_log.log_data[index].name;
          let unit = result_log.log_data[index].unit;
          let arrData = [];
          result_log.log_data[index].values.forEach((element) => {
            arrData.push([element[0], element[1]]);
          });
          arrLog.push({
            name,
            arrData: arrData.reverse(),
            unit,
            config_sensor,
          });
        }
      }

      let arr = [];
      let row = [];
      for (let index = 0; index < arrLog.length; index++) {
        const element = arrLog[index];
        row.push(`${element.name}(${element.unit})`);
        row.push("Date");
        row.push("Time");
        arr.push(row);
        row = [];
        for (let index2 = 0; index2 < element.arrData.length; index2++) {
          const element2 = element.arrData[index2];
          const config_sensor = element.config_sensor;
          if (config_sensor > 0) {
            row.push(config_sensor - element2[0]);
          } else {
            row.push(element2[0]);
          }
          row.push(formatDate(element2[1]));
          row.push(formatTime(element2[1], filterTime, checkboxFilter));
          arr.push(row);
          row = [];
        }
        arr.push(["", "", ""]);
      }

      setDataCSV(arr);
      setloadingDownload(false);
    }
  };

  const handleTimeSetting = async (e) => {
    setTimeSetting(parseInt(e.target.value));
    calculate(parseInt(e.target.value), log);
  };

  const calculate = (paramsTime, datalog) => {
    let result = [];
    datalog.forEach((sensor) => {
      let newObj = {};
      sensor.backupArrData.forEach((element) => {
        const nowDate = new Date(element[1]);
        let nowTime = `${nowDate.getFullYear()}-${nowDate.getMonth()}-${nowDate.getDate()} ${nowDate.getHours()}:0:0`;
        if (paramsTime === 30) {
          if (nowDate.getMinutes() < 30) {
            nowTime = `${nowDate.getFullYear()}-${nowDate.getMonth()}-${nowDate.getDate()} ${nowDate.getHours()}:0:0`;
          } else {
            nowTime = `${nowDate.getFullYear()}-${nowDate.getMonth()}-${nowDate.getDate()} ${nowDate.getHours()}:30:0`;
          }
        }
        if (paramsTime === 15) {
          if (nowDate.getMinutes() < 15) {
            nowTime = `${nowDate.getFullYear()}-${nowDate.getMonth()}-${nowDate.getDate()} ${nowDate.getHours()}:0:0`;
          } else if (nowDate.getMinutes() < 30) {
            nowTime = `${nowDate.getFullYear()}-${nowDate.getMonth()}-${nowDate.getDate()} ${nowDate.getHours()}:15:0`;
          } else if (nowDate.getMinutes() < 45) {
            nowTime = `${nowDate.getFullYear()}-${nowDate.getMonth()}-${nowDate.getDate()} ${nowDate.getHours()}:30:0`;
          } else {
            nowTime = `${nowDate.getFullYear()}-${nowDate.getMonth()}-${nowDate.getDate()} ${nowDate.getHours()}:45:0`;
          }
        }

        if (newObj[nowTime]) {
          newObj[nowTime] = [...newObj[nowTime], element[0]];
        } else {
          newObj[nowTime] = [element[0]];
        }
      });
      result.push({ ...sensor, newArrData: newObj });
      newObj = [];
    });
    calculate2(result);
  };

  const calculate2 = (data) => {
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      const newArrData = element.newArrData;
      let temporaryData = [];
      for (var key of Object.keys(newArrData)) {
        const avg = average(newArrData[key]);
        console.log(key + " -> " + newArrData[key], avg);
        temporaryData.push([avg, new Date(key).getTime()]);
      }
      data[index].arrData = temporaryData;
      temporaryData = [];
    }

    // finish
    setLog(data);
  };

  const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

  return (
    <>
      <Container>
        {/* <Alert severity="info">
          This page is under development, please do not use it first.
        </Alert> */}
        <h1>{fileName}</h1>
        <Grid container spacing={2}>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            style={{ textAlign: "left" }}
          >
            <IconButton onClick={() => handleLeft()}>
              <ArrowLeftIcon />
            </IconButton>
            <IconButton onClick={() => handleRight()}>
              <ArrowRightIcon />
            </IconButton>
            <IconButton onClick={() => setDialogNotif(true)}>
              <NotificationsIcon />
            </IconButton>
            <IconButton onClick={() => setDialogSetting(true)}>
              <SettingsIcon />
            </IconButton>
            <FormControlLabel
              control={
                <Switch defaultChecked onChange={(e) => handleFilter(e)} />
              }
              label={`${filterTime ? "Filter Time On" : "Filter Time Off"}`}
            />
            <FormControlLabel
              control={<Checkbox onChange={(e) => handleCheckbox(e)} />}
              label="15m"
            />

            {/* {props.match.params.id === "6228d2578f17ef9f27e40f89" ? (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    onChange={(e) => handleCheckbox(e)}
                  />
                }
                label="15m"
              />
            ) : (
              <FormControlLabel
                control={<Checkbox onChange={(e) => handleCheckbox(e)} />}
                label="15m"
              />
            )} */}

            {dataCSV.length === 0 ? (
              <Button
                variant="text"
                onClick={() => handleDownload()}
                disabled={loadingDownload}
              >
                {loadingDownload ? "Loading..." : "Pre Download Data"}
              </Button>
            ) : (
              <Button variant="text">
                <CSVLink
                  data={dataCSV}
                  separator=";"
                  filename={`${fileName}-${new Date().toString()}.csv`}
                >
                  Download
                </CSVLink>
              </Button>
            )}
          </Grid>
          {log.length === 0 ? (
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <div className="ty-container">
              {log.map((d, v) => (
                <div key={v} className="ty-item">
                  <Typography variant="h6" className={classes.title}>
                    {d.name}
                  </Typography>
                  <Paper className={classes.root}>
                    <table className="ty-table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.id === "checkbox" ? (
                                boolCheckbox[v] ? (
                                  <IconButton
                                    aria-label="delete"
                                    color="secondary"
                                    size="small"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : (
                                  "#"
                                )
                              ) : (
                                column.label
                              )}
                              {column.unit ? ` (${d.unit})` : ""}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {d.arrData.map((element, i) => (
                          <TableRow key={i}>
                            {/* <TableCell>{i + 1}</TableCell> */}
                            <TableCell>
                              {(() => {
                                if (d.config_sensor !== 0) {
                                  const final = d.config_sensor - element[0];
                                  return final.toFixed(1);
                                }
                                return element[0].toFixed(1);
                              })()}
                            </TableCell>
                            <TableCell>
                              <DateComp date={element[1]} />
                            </TableCell>
                            <TableCell>
                              <TimeComp date={element[1]} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </table>
                  </Paper>
                </div>
              ))}
            </div>
          )}
        </Grid>
      </Container>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Data will be deleted permanently and can`t be restored!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>

          <div className={classes.wrapper}>
            <Button
              onClick={handleConfirmDelete}
              color="secondary"
              variant="contained"
              disabled={loading}
            >
              Delete
            </Button>
            {loading && (
              <CircularProgress
                color="secondary"
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogNotif}
        onClose={() => setDialogNotif(false)}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogContent>
          {notif.map((e) => (
            <div
              style={{
                display: "flex",
                borderBottom: ".5px solid grey",
                marginBottom: "1rem",
              }}
              key={e.date}
            >
              <div style={{ flex: 2 }}>{e.msg}</div>
              <div style={{ flex: 1, textAlign: "right" }}>
                <DateComp date={e.date} />
                {new Date(e.date).toLocaleTimeString()}
              </div>
            </div>
          ))}
        </DialogContent>
      </Dialog>

      <Dialog
        open={dialogSetting}
        onClose={() => setDialogSetting(false)}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>{"Settings"}</DialogTitle>
        <DialogContent>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Time</FormLabel>
            <RadioGroup
              row
              onChange={handleTimeSetting}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="15"
                control={<Radio />}
                label="15 Minute"
              />
              <FormControlLabel
                value="30"
                control={<Radio />}
                label="30 Minute"
              />
              <FormControlLabel
                value="60"
                control={<Radio />}
                label="60 Minute"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
      </Dialog>
    </>
  );
}

// export default React.memo(Index);
