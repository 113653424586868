import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";

import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";
import { getAdminUsers } from "../../actions/adminAction";
import { logoutAdmin } from "../../actions/authActions";

class Adminuser extends Component {
  state = {
    modal: false,
  };

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutAdmin();
  }

  componentDidMount() {
    this.props.getAdminUsers();
  }

  render() {
    const { adminusers, loading } = this.props.admin;
    var itemsContent;
    var adminuserFiltered = [];
    var userLengkapNama = [];
    var userLengkapNomor = [];
    if (loading) {
      itemsContent = (
        <div style={{ textAlign: "center" }}>
          <SpinnerSimpleLogo
            width={200}
            unik="loading_item_list"
            marginTop="0px"
          />
        </div>
      );
    } else if (!adminusers) {
      itemsContent = (
        <div style={{ textAlign: "center" }}>
          <SpinnerSimpleLogo
            width={200}
            unik="loading_item_list"
            marginTop="0px"
          />
        </div>
      );
    } else {
      for (var i in adminusers) {
        adminuserFiltered.push({
          username: adminusers[i].name,
          full_name: adminusers[i].full_name ? adminusers[i].full_name : "",
          phone_number: adminusers[i].phone_number
            ? adminusers[i].phone_number
            : "",
          email: adminusers[i].email,
          category: adminusers[i].category ? adminusers[i].category : "NOW",
        });
      }
      adminuserFiltered = adminuserFiltered
        .filter((value) => value.email !== "-")
        .filter((value) => value.email !== "")
        .filter((value) => value.email !== "undefined");
      adminuserFiltered = adminuserFiltered.filter(
        (value, index, self) =>
          index === self.findIndex((v) => v.email === value.email)
      );
      userLengkapNama = adminuserFiltered
        .filter((value) => value.full_name !== "-")
        .filter((value) => value.full_name !== "")
        .filter((value) => value.full_name !== "undefined");
      userLengkapNomor = adminuserFiltered
        .filter((value) => value.phone_number !== "-")
        .filter((value) => value.phone_number !== "")
        .filter((value) => value.phone_number !== "undefined");
      var headers = [
        { label: "Username", key: "username" },
        { label: "Nama lengkap", key: "full_name" },
        { label: "Nomor hp", key: "phone_number" },
        { label: "Email", key: "email" },
        { label: "category", key: "category" },
      ];
      itemsContent = (
        <div className="dashboardSpecial">
          <button
            onClick={this.onLogoutClick.bind(this)}
            className="buttonShadowSmall"
            id="deleteBright"
          >
            Sign out
          </button>
          <br />
          <br />
          <div className="welcomeBig">Total users {adminusers.length}</div>
          <CSVLink
            data={adminuserFiltered}
            headers={headers}
            target="_blank"
            filename={`User.csv`}
            enclosingCharacter={`"`}
            separator={";"}
          >
            <div className="buttonSimple2">Download Semua</div>
          </CSVLink>
          <CSVLink
            data={userLengkapNama}
            headers={headers}
            target="_blank"
            filename={`User.csv`}
            enclosingCharacter={`"`}
            separator={";"}
          >
            <div className="buttonSimple2">
              Download yang namanya lengkap saja
            </div>
          </CSVLink>
          <CSVLink
            data={userLengkapNomor}
            headers={headers}
            target="_blank"
            filename={`User.csv`}
            enclosingCharacter={`"`}
            separator={";"}
          >
            <div className="buttonSimple2">
              Download yang nomornya lengkap saja
            </div>
          </CSVLink>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Fullname</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Category</th>
                <th scope="col">Created on</th>
                <th scope="col">Bank</th>
              </tr>
            </thead>
            {adminusers.map(
              ({
                _id,
                name,
                full_name,
                email,
                category,
                created_on,
                phone_number,
                bank_data,
              }) => (
                <tbody key={_id}>
                  <tr>
                    <td>{name}</td>
                    <td>{full_name}</td>
                    <td>{email}</td>
                    <td>{phone_number}</td>
                    <td>{category}</td>
                    <td>{created_on ? moment(created_on).from() : ""}</td>
                    <td>{bank_data.length ? bank_data.length : ""}</td>
                  </tr>
                </tbody>
              )
            )}
          </table>
        </div>
      );
    }
    return (
      <div>
        {itemsContent}
        <style>{`
        #loginModal{ visibility: hidden;}
     `}</style>
      </div>
    );
  }
}

Adminuser.propTypes = {
  getAdminUsers: PropTypes.func.isRequired,
  logoutAdmin: PropTypes.func.isRequired,
  admin: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps, { getAdminUsers, logoutAdmin })(
  Adminuser
);
