import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import RichEditorNew from "../common/RichEditorNew";
import {
  getFolderPictureList,
  getDatasetPictureList
} from "../../actions/editorAction";

class CreateTask extends Component {
  componentDidMount() {
    if (this.props.match.params.task_or_report === "task") {
      this.props.getFolderPictureList(this.props.match.params.folder);
    } else {
      this.props.getDatasetPictureList(this.props.match.params.dataset);
    }
  }
  render() {
    const itemContent =
      this.props.match.params.task_or_report === "task" ? (
        <RichEditorNew
          folder={this.props.match.params.folder}
          dataset={this.props.match.params.folder}
          isFromIOT={true}
          task_or_report={this.props.match.params.task_or_report}
        />
      ) : (
        <RichEditorNew
          folder={this.props.match.params.folder}
          dataset={this.props.match.params.dataset}
          isFromIOT={true}
          task_or_report={this.props.match.params.task_or_report}
        />
      );
    return <div>{itemContent}</div>;
  }
}
CreateTask.propTypes = {
  getFolderPictureList: PropTypes.func.isRequired,
  getDatasetPictureList: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  editor: state.editor
});
export default connect(mapStateToProps, {
  getFolderPictureList,
  getDatasetPictureList
})(CreateTask);
