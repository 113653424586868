import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";
import { getItem, clearCurrentItem } from "../../actions/itemActions";
import UploadPictures from "./UploadPictures";
class UploadDashboard extends Component {
  state = {};
  componentDidMount() {
    this.props.getItem(this.props.match.params.id);
  }
  render() {
    var { item, loading } = this.props.item;
    var itemContent;
    if (loading) {
      itemContent = (
        <div style={{ textAlign: "center" }}>
          <p>Collecting data, please wait...</p>
          <SpinnerSimpleLogo
            width={200}
            unik="loading_item_list"
            marginTop="0px"
          />
        </div>
      );
    } else if (!item) {
      itemContent = (
        <div style={{ textAlign: "center" }}>
          <p>Collecting data, please wait...</p>
          <SpinnerSimpleLogo
            width={200}
            unik="loading_item_list"
            marginTop="0px"
          />
        </div>
      );
    } else {
      itemContent = (
        <div>
          <h1>{item.name}</h1>
          <UploadPictures
            dataset_name={item.name.replace(/ +/g, "")}
            id={item._id}
          />
        </div>
      );
    }
    return <div style={{ textAlign: "center" }}>{itemContent}</div>;
  }
}
UploadDashboard.propTypes = {
  clearCurrentItem: PropTypes.func.isRequired,
  getItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({ item: state.item });
export default connect(mapStateToProps, { getItem, clearCurrentItem })(
  UploadDashboard
);
