import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Card,
  CardActions,
  CardContent,
  // CardHeader,
  Button,
  Typography,
  IconButton,
  // LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  // CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import {
  Alert
} from '@material-ui/lab';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: 60,
    paddingRight: 60,
  },
  grid: {
    marginTop: 20,
  },
  card: {
    textAlign: 'center',
    marginTop: 20,
  },
  iconButton: {
    marginTop: 15,
  },
  iconUpload: {
    fontSize: 100,
  },
  btnDownload: {
    marginTop: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    textAlign: 'left',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fileName: {
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

function DataConvert() {
  const classes = useStyles();
  const [log, setLog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnDownLoad, setBtnDownLoad] = useState(false);
  const [device, setDevice] = useState('');
  const [varImage, setVarImage] = useState([]);
  const [width, setWidth] = useState(0);

  const fileUpload = React.createRef();

  React.useEffect(() => {
    setWidth(window.innerWidth)
  }, [])

  const handleFile = (e) => {
    setVarImage(e.target.files);
  }

  const handleIconUpload = () => {
    fileUpload.current.click();
  }

  const handleSelect = (e) => {
    setDevice(e.target.value)
  }

  const handleConvert = () => {
    if (varImage.length === 0) {
      alert('Please Insert File')
    } else {
      setLoading(true);
      let files = varImage;
      const file = files[0];

      if(device === 'toyo'){
        let reader = new FileReader();
        reader.onload = (e) => {
          const file = e.target.result;
          const lines = file.split(/\r|\n/);
          const arr = lines.join().split(",");

          let dArr = [];
          let i = 0
          for (let index = 0; index < ((arr.length - 1) / 6); index++) {
            let wl = arr[i++];
            let tm = arr[i++];
            let st = arr[i++];
            let init = arr[i++];
            let bat = arr[i++];
            let info = arr[i++];
            dArr.push({
              waterLevel: wl,
              time: dateConvert(tm),
              status: st,
              init: init,
              bat: bat,
              info: info,
            })
          }
          setLog(dArr)
          setLoading(false);
          setBtnDownLoad(true);
        }

        reader.onerror = (e) => console.log(e.target.error);
        reader.readAsText(file);
      }else{
        setLoading(false);
      }
    }
  }

  const dateConvert = (e) => {
    // console.log(e)
    if (e === '0') {
      return 0;
    } else {
      let unix = (e * 1000);
      unix -= (7 * 60 * 60 * 1000);
      let d = new Date(unix)
      // console.log(d);
      let mon = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
      return `${d.getDate()} ${mon[d.getMonth()]} ${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
    }
  }

  return (
    <Container className={width < 576 ? '': classes.container}>
      <Grid container justify="center" className={classes.grid}>
        <Grid item xl={6} xs={12} sm={12} md={6} lg={6}>
          {/* <Alert severity="info">This is an info alert — check it out!</Alert> */}
          <Card className={classes.card}>
            <div style={{textAlign: 'left', margin: 20}}>
              <Link to="/dashboard">
                ← Back to Dashboard
              </Link>
            </div>
            <CardContent>
              {
                !btnDownLoad ? (
                  <div>
                    <Typography className={classes.title}>
                      Upload File
                    </Typography>
                    {
                      varImage.length !== 0 ? (
                        <div className={classes.fileName}>
                          <Typography className={classes.title}>
                            {varImage[0].name}
                          </Typography>
                        </div>
                      ):(
                        <div>
                          <IconButton aria-label="upload" className={classes.iconButton} onClick={handleIconUpload}>
                            <CloudUploadIcon className={classes.iconUpload} />
                          </IconButton>
                        </div>
                      )
                    }
                    <input type="file" onChange={handleFile} id="input-file" ref={fileUpload} style={{display: 'none'}}/>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-outlined-label">Choose Device</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={device}
                        onChange={handleSelect}
                        label="Choose Device"
                      >
                        <MenuItem value={'general'}>General</MenuItem>
                        <MenuItem value={'simpel'}>Simple</MenuItem>
                        <MenuItem value={'toyo'}>Toyo Sensing</MenuItem>
                      </Select>
                    </FormControl>
                    {loading ? (
                      <div>
                        Loading...
                      </div>
                    ):(
                      <div>
                      </div>
                    )}
                    <Button className={classes.btnDownload} variant="contained" color="primary" onClick={handleConvert}>Convert</Button>
                  </div>
                ):(
                  <div>
                    <Typography className={classes.title}>
                      Success Converted
                    </Typography>
                    <br />
                    <Alert severity="info">File success converted, klik button Download for download file.csv</Alert>
                    <Button className={classes.btnDownload} variant="contained" color="primary">
                      <CSVLink
                        data={log}
                        target="_blank"
                        filename={`${varImage[0].name}.csv`}
                        enclosingCharacter={``}
                        id="edit"
                        style={{color: '#fff', backgroundColor: 'transparent'}}
                      >
                        Download
                      </CSVLink>
                    </Button>
                  </div>
                )
              }
            </CardContent>
            <CardActions>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      {
        btnDownLoad ? (
          <TableContainer component={Paper} style={{marginTop: 20}}>
            <Typography variant="h6" style={{marginLeft: 20}}>
              Log Data
            </Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="left">Water Level</TableCell>
                  <TableCell align="left">Baterai</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Setup</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {log.map((d, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">{i + 1}</TableCell>
                    <TableCell scope="row">{d.waterLevel}</TableCell>
                    <TableCell align="left">{d.bat}</TableCell>
                    <TableCell align="left">{d.time}</TableCell>
                    <TableCell align="left">{d.status}</TableCell>
                    <TableCell align="left">{d.init}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ):(
          <div></div>
        )
      }
    </Container>
  )
}

export default DataConvert;