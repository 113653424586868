import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import {
  EditorState,
  RichUtils,
  convertToRaw,
  AtomicBlockUtils,
} from "draft-js";
import Editor from "draft-js-plugins-editor";

import { createDoc, createTask } from "../../actions/editorAction";
import SpinnerSimpleBlue from "../common/SpinnerSimpleBlue";
import { mediaBlockRenderer } from "./plugins/mediaBlockRenderer";
import UploadPicture from "./plugins/UploadPicture";
import ListPictures from "./ListPictures";
import ModalComponent from "../common/ModalComponent";
import isEqual from "../../validation/is-equal";
const datenow = new Date(Date.now());
const datenowInput =
  datenow.getFullYear() +
  "-" +
  ("0" + (datenow.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + datenow.getDate()).slice(-2);

class RichEditorNew extends Component {
  //init
  state = {
    title: "Untitled",
    date: datenowInput,
    isPublish: false,
    editorState: EditorState.createEmpty(),
    picNew: {},
    sub_brackets: [],
    sub_bracket: "",
    bracket: {},
    openStatus: true,
    modalLink: false,
    link_text: "Link name",
    link_url: "https://mapid.io",
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEqual(nextProps.editor.bracket.sub_brackets, prevState.sub_brackets) &&
      nextProps.editor.bracket.sub_brackets
    ) {
      const sub_brackets = nextProps.editor.bracket.sub_brackets;
      const sub_brackets_length = sub_brackets.length;
      const sub_bracket = sub_brackets[sub_brackets_length - 1].link;
      const bracket = nextProps.editor.bracket;
      return {
        sub_brackets,
        sub_bracket,
        bracket,
      };
    } else return null;
  }
  componentDidUpdate(prevProps) {
    const picture_cycle_new = this.props.editor.picture_cycle;
    const picture_cycle_old = prevProps.editor.picture_cycle;
    if (picture_cycle_new !== picture_cycle_old) {
      this.onAddImage(this.props.editor.picture);
    }
  }
  //Local change
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (["tag"].includes(name)) {
      var tagArray = value.split(" ");
      this.setState({
        tag: value,
        tagArray: tagArray,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  handleSubmit = () => {
    const author = this.props.auth.user._id;
    const {
      editorState,
      title,
      date,
      isPublish,
      bracket,
      sub_bracket,
    } = this.state;
    const raw = convertToRaw(editorState.getCurrentContent());
    let content;
    if (this.props.isFromIOT) {
      content = {
        //main
        user_id: author,
        title,
        editorState: JSON.stringify(raw),
        date,
        //attribute khusus iot
        folder: this.props.folder,
        dataset: this.props.dataset,
      };
      this.props.createTask(content);
    } else {
      content = {
        //main
        user_id: author,
        title,
        editorState: JSON.stringify(raw),
        date,
        //attribute bracket
        bracket: bracket.link,
        sub_bracket,
        isPublish,
        link: title
          .replace(/ /g, "_")
          .toLowerCase()
          .replace(/[^\w\s]/gi, ""),
      };
      this.props.createDoc(content);
    }
  };
  //Rich editor stuff
  focus = () => this.refs.editor.focus();
  onChange = (editorState) => this.setState({ editorState });
  handleKeyCommand = (command) => {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };
  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };
  toggleInlineStyle = (inlineStyle) => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  };
  //Image upload stuff
  onAddImage = (picNew) => {
    const editorState = this.state.editorState;
    const urlValue = picNew.url;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      { src: urlValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );
    this.setState(
      {
        editorState: AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          " "
        ),
      },
      () => {
        setTimeout(() => this.focus(), 0);
      }
    );
  };
  //Link stuff
  toggleLink = () => {
    this.setState({ modalLink: !this.state.modalLink });
  };
  onAddLink = () => {
    const { editorState, link_text, link_url } = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "link",
      "IMMUTABLE",
      { link_text, link_url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );
    this.setState(
      {
        editorState: AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          " "
        ),
      },
      () => {
        setTimeout(() => this.focus(), 0);
        this.setState({ modalLink: false });
      }
    );
  };
  //SideBar action
  toggleSideBar = () => {
    this.setState(
      {
        openStatus: !this.state.openStatus,
      },
      () => {
        this.state.openStatus ? this.openNav() : this.closeNav();
      }
    );
  };
  openNav = () => {
    this.setState({ openStatus: true }, () => {
      document.getElementById("mySidebar").style.left = "2vw";
      document.getElementById("buttonSlider").style.marginLeft =
        "calc((2vw + 300px - 20px)/2)";
    });
  };
  closeNav = () => {
    this.setState({ openStatus: false }, () => {
      document.getElementById("mySidebar").style.left = "-300px";
      document.getElementById("buttonSlider").style.marginLeft = "2vw";
    });
  };
  render() {
    //state
    const {
      sub_brackets,
      bracket,
      editorState,
      title,
      date,
      isPublish,
      sub_bracket,
      openStatus,
      modalLink,
      link_text,
      link_url,
    } = this.state;
    //props
    const { loadingProcess } = this.props.editor;
    var iconContentCreateDoc;
    if (loadingProcess) {
      iconContentCreateDoc = (
        <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
      );
    } else {
      iconContentCreateDoc = (
        <button
          className="btn buttonSimple2"
          type="submit"
          onClick={this.handleSubmit}
        >
          Create Document
        </button>
      );
    }
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = "RichEditor-editor";
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }
    const toolsButtonContent = (
      <div>
        <BlockStyleControls
          editorState={editorState}
          onToggle={this.toggleBlockType}
        />
        <InlineStyleControls
          editorState={editorState}
          onToggle={this.toggleInlineStyle}
        />
        <div className="buttonContainer">
          <UploadPicture
            isFromIOT={this.props.isFromIOT}
            task_or_report={this.props.task_or_report}
            folder={this.props.folder}
            dataset={this.props.dataset}
            id={this.props.id}
          />
        </div>
        <button className="buttonContainer" onClick={this.toggleLink}>
          Add Link
        </button>
      </div>
    );
    const bracketContent = (
      <div>
        <br />
        <div className="buttonShadow" style={{ marginBottom: "10px" }}>
          Create document in {bracket.name}
        </div>
        <br />
        <div className="buttonShadow" style={{ marginBottom: "10px" }}>
          <label htmlFor="group_id" className="inline-label ">
            Choose folder
          </label>
          <select
            type="text"
            name="sub_bracket"
            id="sub_bracket"
            value={sub_bracket}
            onChange={this.handleChange}
          >
            {sub_brackets.map(({ name, link }, idx) => {
              return (
                <option key={idx} value={link}>
                  {name}
                </option>
              );
            })}
          </select>
        </div>
        <br />
        <div className="buttonShadow" style={{ marginBottom: "10px" }}>
          <label htmlFor="date" className="inline-label ">
            Choose date
          </label>
          <input
            type="date"
            name="date"
            id="date"
            value={date}
            onChange={this.handleChange}
          />
        </div>
        <br />
        <div style={{ marginBottom: "10px" }}>
          <label className="simpleCheck">
            <input
              className="form-control"
              type="checkbox"
              name="isPublish"
              id="isPublish"
              value={isPublish}
              onChange={this.handleChange}
            />
            <span className="buttonCheck">
              Publish Now{" "}
              <span className="badge badge-light">
                {isPublish ? "ON" : "OFF"}
              </span>
            </span>
          </label>
        </div>
      </div>
    );
    const editorInputContent = (
      <div>
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            className="form-control"
            type="text"
            name="title"
            id="title"
            value={title}
            onChange={this.handleChange}
          />
          {!this.props.isFromIOT ? bracketContent : null}
        </div>
        <div className="RichEditor-root " id="documentEditor">
          <div className={className} onClick={this.focus}>
            <Editor
              blockRendererFn={mediaBlockRenderer}
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand.bind(this)}
              onChange={this.onChange}
              placeholder="Write your document here.."
              ref="editor"
              spellCheck={false}
            />
          </div>
        </div>
      </div>
    );
    const modalLinkContent = modalLink && (
      <ModalComponent
        modalSize="small"
        id="createBracketModal"
        isOpen={modalLink}
        onClose={this.toggleLink}
      >
        <div className="box-body">
          <form>
            <input
              className="form-control"
              type="text"
              name="link_text"
              id="link_text"
              value={link_text}
              onChange={this.handleChange}
            />
            <input
              className="form-control"
              type="text"
              name="link_url"
              id="link_url"
              value={link_url}
              onChange={this.handleChange}
            />
            <div style={{ textAlign: "center" }}>
              <div className="buttonShadow" onClick={this.onAddLink}>
                Insert Link
              </div>
            </div>
          </form>
        </div>
      </ModalComponent>
    );
    return (
      <div style={{ marginBottom: "100px" }}>
        <div id="mySidebar" className="sidebar stickySidebar">
          {toolsButtonContent}
          <ListPictures
            isFromIOT={this.props.isFromIOT}
            task_or_report={this.props.task_or_report}
            folder={this.props.folder}
            dataset={this.props.dataset}
            id={this.props.id}
          />
          <br />
          <div style={{ textAlign: "center" }}>{iconContentCreateDoc}</div>
        </div>
        <div
          className="openbtn stickyOpenbtn"
          id="buttonSlider"
          style={{ zIndex: "2" }}
          onClick={this.toggleSideBar}
        >
          {openStatus ? "<" : ">"}
        </div>
        <div className="documentContainer">
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {editorInputContent}
          </div>
          <div style={{ textAlign: "center" }}>{iconContentCreateDoc}</div>
        </div>
        {modalLinkContent}
      </div>
    );
  }
}
// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    case "center":
      return "RichEditor-center";
    default:
      return null;
  }
}
class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}
const BLOCK_TYPES = [
  // { label: "H1", style: "header-one" },
  // { label: "H2", style: "header-two" },
  { label: "HEADER", style: "header-three" },
  // { label: "H4", style: "header-four" },
  // { label: "H5", style: "header-five" },
  // { label: "H6", style: "header-six" },
  { label: "Blockquote", style: "blockquote" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" },
  { label: "Code Block", style: "code-block" },
  { label: "Center", style: "center" },
];
const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
var INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" },
  { label: "Monospace", style: "CODE" },
];
const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
RichEditorNew.propTypes = {
  createDoc: PropTypes.func.isRequired,
  createTask: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  editor: state.editor,
});
export default connect(mapStateToProps, {
  createDoc,
  createTask,
})(RichEditorNew);
