import axios from "axios";
import { SUB_NOTIF, GET_ERRORS, GET_COMMENTS } from "./types";
// const SERVER_URL = "http://localhost:4000";
const SERVER_URL = "https://api.mapid.io";
// const SERVER_URL = "http://iotmapidserver.us-west-2.elasticbeanstalk.com";
// console.log("localstorage dari item", localStorage.getItem("jwtTokenMapid"));

/*GENERAL*/

export const setLoadingGet = () => {
  return {
    type: "SET_LOADING_GET_COMMENT",
  };
};

export const setLoadingDelete = () => {
  return {
    type: "SET_LOADING_DELETE",
  };
};

export const setLoadingParent = () => {
  return {
    type: "SET_LOADING_PARENT",
  };
};

export const setLoadingChild = () => {
  return {
    type: "SET_LOADING_CHILD",
  };
};

export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING",
  };
};

//data for public
export const getComment = (id) => (dispatch) => {
  dispatch(setLoadingGet());
  axios
    .get(SERVER_URL + `/comments/${id}`)
    .then((res) => {
      dispatch({
        type: GET_COMMENTS,
        payload: res.data,
      });
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
      dispatch(clearLoading());
    });
};

//Add Comment
export const addComment = (comment_object) => (dispatch) => {
  dispatch(setLoadingParent());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/comments/add", comment_object, config)
    .then((res) => {
      dispatch({
        type: GET_COMMENTS,
        payload: res.data,
      });
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
      dispatch(clearLoading());
    });
};

//Add Reply Comment
export const addReply = (comment_object) => (dispatch) => {
  dispatch(setLoadingChild());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/comments/reply", comment_object, config)
    .then((res) => {
      dispatch({
        type: GET_COMMENTS,
        payload: res.data,
      });
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
      dispatch(clearLoading());
    });
};

//Edit Comment
export const editComment = (comment_object) => (dispatch) => {
  dispatch(setLoadingParent());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/comments/edit", comment_object, config)
    .then((res) => {
      dispatch({
        type: GET_COMMENTS,
        payload: res.data,
      });
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
      dispatch(clearLoading());
    });
};

//Edit Reply
export const editReply = (comment_object) => (dispatch) => {
  dispatch(setLoadingChild());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/comments/editreply", comment_object, config)
    .then((res) => {
      dispatch({
        type: GET_COMMENTS,
        payload: res.data,
      });
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
      dispatch(clearLoading());
    });
};

//Delete Comment
export const deleteComment = (comment_object) => (dispatch) => {
  dispatch(setLoadingDelete());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/comments/deletecomment", comment_object, config)
    .then((res) => {
      dispatch({
        type: GET_COMMENTS,
        payload: res.data,
      });
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
      dispatch(clearLoading());
    });
};

//Delete Reply
export const deleteReply = (comment_object) => (dispatch) => {
  dispatch(setLoadingDelete());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/comments/deletereply", comment_object, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: GET_COMMENTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
      dispatch(clearLoading());
    });
};

//Sub Notification
export const subNotification = (sub_object) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/users/sub", JSON.stringify(sub_object), config)
    .then((res) => {
      dispatch({
        type: SUB_NOTIF,
        payload: res.data,
      });
      console.log(res.data);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
      console.log(err);
    });
};
