import axios from "axios";
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_CURRENT_ADMIN,
  CLEAR_ERRORS,
  SET_CURRENT_TOKEN,
  AUTH_LOADING,
  CLEAR_AUTH_LOADING,
} from "./types";
import his from "./history";
import { getGroups } from "./itemActions";
import { getBracketList } from "./editorAction";
import { iotServerBaseUrl, geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = iotServerBaseUrl;
const SERVER_GEO_URL = geoServerBaseUrl;

//register_new
export const register_new = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess());
    await axios.post(SERVER_GEO_URL + "/users/register_new", body);
    dispatch(clearLoading());
    dispatch({
      type: "SET_REGISTER_SUCCESS",
    });
    dispatch({
      type: "GET_ERRORS",
      payload: {},
    });
    const { name, password } = body;
    const body_new = { name, password };
    dispatch(loginUser(body_new));
  } catch (error) {
    console.log(error);
    dispatch(clearLoading());
    dispatch({
      type: "GET_ERRORS",
      payload: error?.response?.data,
    });
  }
};

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  dispatch(setLoadingProcess());
  axios
    .post(SERVER_URL + "/users/register", userData)
    .then((res) => {
      localStorage.setItem("mapidusername", userData.name);
      // console.log(res);
      history.push("/confirm");
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
    });
};

// Confirm User
export const confirmUser = (userData, history) => (dispatch) => {
  dispatch(setLoadingProcess());
  axios
    .post(SERVER_URL + "/users/confirm", userData)
    .then((res) => {
      dispatch(clearLoading());
      history.push("/login");
      // console.log(res.data);
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
    });
};

//Login
export const loginUser = (userData) => (dispatch) => {
  dispatch(setLoadingProcess());
  var isNav = function (fromisNav) {
    if (fromisNav) {
      return null;
    } else {
      return his.push("/dashboard");
    }
  };
  axios
    .post(`${geoServerBaseUrl}/users/login`, userData)
    .then((res) => {
      // const { token } = res.data;
      localStorage.setItem("jwtTokenMapid", res.data.token);
      localStorage.setItem("mapidusername", res.data.user.name);
      localStorage.setItem("mapidUser", JSON.stringify(res.data.user));

      // console.log(res.data);
      dispatch(setCurrentUser(res.data.user));
      dispatch(setCurrentToken(res.data.token));
      dispatch(getGroups(res.data.user._id));
      dispatch(getBracketList(res.data.user._id));
      dispatch(clearLoading());
      dispatch(clearErrors());

      isNav(userData.isNav);

      // console.log("local token from login", localStorage.jwtTokenMapid);
      // console.log("res token", res.data.token);
      // eslint-disable-next-line
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenMapid,
        },
      };
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
      dispatch(clearLoading());
    });
};

//Get user from token
export const getUserFromToken = (token) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: token,
    },
  };
  axios
    .get(SERVER_URL + "/users", config)
    .then((res) => {
      dispatch(setCurrentUser(res.data));
      localStorage.setItem("mapidUser", JSON.stringify(res.data));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
    });
};

// Set logged in user
export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  };
};

// Set logged in user
export const setCurrentToken = (decoded) => {
  return {
    type: SET_CURRENT_TOKEN,
    payload: decoded,
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtTokenMapid");
  // Remove auth header for future requests
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

//Admin Mapid

// Admin - Get User Token
export const loginAdmin = (userData) => (dispatch) => {
  dispatch(setLoadingProcess());
  axios
    .post(SERVER_URL + "/admin/login", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtTokenMapidAdmin", token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentAdmin(decoded));
      dispatch(clearLoading());
      his.push("/admin/dashboard");
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
    });
};

// Set logged in user
export const setCurrentAdmin = (decoded) => {
  return {
    type: SET_CURRENT_ADMIN,
    payload: decoded,
  };
};

// Log user out
export const logoutAdmin = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtTokenMapidAdmin");
  // Remove auth header for future requests
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentAdmin({}));
};

//update user profile
export const updateProfile = (user_data) => (dispatch) => {
  dispatch(setLoadingProcess());
  const config_axos = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/users/profile", user_data, config_axos)
    .then((res) => {
      dispatch({
        type: "UPDATE_USER_PROFILE",
        payload: res.data,
      });
      // console.log("axios success: ", res);
      his.push("/user");
      dispatch(clearLoading());
    })
    .catch((err) => {
      // console.log("axios error: ", err);
      dispatch(clearLoading());
    });
};

//upload profile picture
export const uploadPicture = (picture_data) => {
  const config_axos = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/users/profile", picture_data, config_axos)
    .then((res) => {
      his.push("/user");
      // console.log("axios success: ", res);
    })
    .catch((err) => {
      console.log("axios error: ", err);
      console.log("picture data : ", picture_data);
    });
};

export const setLoadingProcess = () => {
  return {
    type: AUTH_LOADING,
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_AUTH_LOADING,
  };
};

export const openModal = (name) => {
  return {
    type: "OPEN_MODAL",
    payload: name,
  };
};

export const setLanguage = (language) => {
  localStorage.setItem("language", language);
  return {
    type: "SET_LANGUAGE",
    payload: language,
  };
};

//send link forget password
export const sendLink = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("send_link"));
    await axios.post(SERVER_URL + "/users/send_link_forget_pass", body);
    dispatch({
      type: "SEND_LINK",
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//verify link
export const verifyLink = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("verify_link"));
    await axios.post(SERVER_URL + "/users/verify_link", body);
    dispatch({
      type: "VERIFY_LINK",
      payload: "success",
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch({
      type: "VERIFY_LINK",
      payload: "failed",
    });
    dispatch(clearLoading());
  }
};

//send new password and verify link
export const sendPassword = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("setting_password"));
    await axios.post(SERVER_URL + "/users/setting_password", body);
    dispatch({
      type: "VERIFY_LINK",
      payload: "send",
    });
    dispatch(clearLoading());
    his.push("/login");
  } catch (e) {
    dispatch({
      type: "GET_ERRORS",
      payload: e?.response?.data,
    });
    dispatch({
      type: "VERIFY_LINK",
      payload: "failed",
    });
    dispatch(clearLoading());
  }
};
