import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NumericLabel from "react-pretty-numbers";
import moment from "moment";
import { Link } from "react-router-dom";

import { getAdminItems } from "../../actions/adminAction";
import { logoutAdmin } from "../../actions/authActions";

import pic_static from "../../img/profile_pic_comment.svg";
import icon_data from "../../img/icon_data.svg";
import icon_time from "../../img/icon_time.svg";
import icon_comment from "../../img/icon_comment.svg";
import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";

class Adminitem extends Component {
  state = {
    modal: false,
  };

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutAdmin();
  }

  componentDidMount() {
    this.props.getAdminItems();
  }

  render() {
    const { adminitems, loading } = this.props.admin;
    var itemsContent;
    if (loading || adminitems.length === 0) {
      itemsContent = (
        <div style={{ textAlign: "center" }}>
          <SpinnerSimpleLogo
            width={200}
            unik="loading_item_list"
            marginTop="0px"
          />
        </div>
      );
    } else {
      itemsContent = (
        <div>
          <h1 className="welcomeBig" style={{ textAlign: "center" }}>
            Total Dataset <div>{adminitems.length}</div>
          </h1>
          <div className="widget-container">
            {adminitems.map(
              (
                {
                  _id,
                  name,
                  user,
                  last_data,
                  entries_number,
                  last_entry_at,
                  comments,
                },
                idx
              ) => {
                const user_full_name =
                  user && user.full_name ? user.full_name : "Owner";
                const userButton = (
                  <div>
                    <div
                      alt={user_full_name}
                      className={`photo_${user && user._id ? user._id : idx}`}
                    />
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginLeft: "10px",
                        fontSize: "12px",
                      }}
                    >
                      {user_full_name}
                    </div>
                    <style>
                      {`.photo_${user && user._id ? user._id : idx}{
                background: url(${
                  user &&
                  user.profile_picture &&
                  user.profile_picture.url_compressed
                    ? user.profile_picture.url_compressed
                    : user &&
                      user.profile_picture &&
                      user.profile_picture.url &&
                      user.profile_picture.url !== "-"
                    ? user.profile_picture.url
                    : pic_static
                }) no-repeat center center/ cover;
                background-color: #c1c1c1;
                height:40px;
                width:40px;
                border-radius: 50%;
                display: inline-block;
                align: center;
                vertical-align: middle;
                }`}
                    </style>
                  </div>
                );
                var dataset_name = name.toString();
                dataset_name = dataset_name.split(" ");
                var name_potong;
                switch (dataset_name.length) {
                  case 1:
                    name_potong = dataset_name[0];
                    break;
                  case 2:
                    name_potong = dataset_name[0] + " " + dataset_name[1];
                    break;
                  case 3:
                    name_potong =
                      dataset_name[0] +
                      " " +
                      dataset_name[1] +
                      " " +
                      dataset_name[2];
                    break;
                  default:
                    name_potong =
                      dataset_name[0] +
                      " " +
                      dataset_name[1] +
                      " " +
                      dataset_name[2];
                }
                const array_last_data = Object.keys(
                  last_data ? last_data : []
                ).map((i) => last_data[i]);
                const sensorContent = (
                  <div className="four-container">
                    {array_last_data.map(({ name, unit, values }, index) => {
                      return (
                        <div
                          key={`sensor_${index}`}
                          style={{ textAlign: "center" }}
                        >
                          <div className="buttonSimpleTightNormal">
                            {values[0]}
                          </div>
                          <div style={{ display: "block" }}>{unit}</div>
                          <div style={{ display: "block" }}>{name}</div>
                        </div>
                      );
                    })}
                  </div>
                );
                const imageWidth = "25px";
                const params = {
                  shortFormat: true,
                  justification: "C",
                };
                const footerContent = (
                  <div className="three-container">
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={icon_data}
                        alt="icon_data"
                        width={imageWidth}
                        className="centerImage"
                      />
                      <div className="buttonSimpleTightNormal text-center">
                        <NumericLabel params={params}>
                          {Number(entries_number)}
                        </NumericLabel>
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={icon_time}
                        alt="icon_time"
                        width={imageWidth}
                        className="centerImage"
                      />
                      <div className="buttonSimpleTightNormal">
                        {last_entry_at
                          ? moment(last_entry_at).from()
                          : "No data"}
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={icon_comment}
                        alt="icon_comment"
                        width={imageWidth}
                        className="centerImage"
                      />
                      <div className="buttonSimpleTightNormal">
                        {comments.length}
                      </div>
                    </div>
                  </div>
                );
                return (
                  <Link to={`/chart/private/${_id}`} key={"column" + _id}>
                    <div className="widget-item-picture">
                      <div
                        className="specialItem"
                        style={{
                          width: "100%",
                          cursor: "pointer",
                          minHeight: "250px",
                        }}
                      >
                        {name_potong}
                        <hr />
                        {userButton}
                        <br />
                        {sensorContent}
                        <hr />
                        {footerContent}
                      </div>
                    </div>
                  </Link>
                );
              }
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="dashboardSpecial" style={{ marginBottom: "200px" }}>
        {itemsContent}
        <style>{`
        #loginModal{ visibility: hidden;}
     `}</style>
      </div>
    );
  }
}

Adminitem.propTypes = {
  logoutAdmin: PropTypes.func.isRequired,
  getAdminItems: PropTypes.func.isRequired,
  admin: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps, { getAdminItems, logoutAdmin })(
  Adminitem
);
