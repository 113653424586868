import React from "react";
import icon_password_hide from "../../img/icon_password_hide.svg";
import icon_password_view from "../../img/icon_password_view.svg";

import isEmpty from "../../validation/is-empty";

const styleObjectNormal = {
  overflow: "hidden",
  boxSizing: "border-box",
  border: "none",
  borderRadius: "0px",
  borderBottom: "1px solid #e4e4e4",
  backgroundColor: "transparent",
  padding: "10px 10px 10px 10px",
  fontSize: "16px",
  color: "#1e5e96ff",
  textAlign: "left",
  marginTop: "5px",
};

const styleObjectError = {
  overflow: "hidden",
  boxSizing: "border-box",
  border: "none",
  borderRadius: "0px",
  borderBottom: "1px solid red",
  backgroundColor: "transparent",
  padding: "10px 10px 10px 10px",
  fontSize: "16px",
  color: "#1e5e96ff",
  textAlign: "left",
  marginTop: "5px",
};

const styleObjectPassword = {
  overflow: "hidden",
  boxSizing: "border-box",
  border: "none",
  borderRadius: "0px",
  backgroundColor: "transparent",
  padding: "10px 10px 10px 10px",
  fontSize: "16px",
  color: "#1e5e96ff",
  textAlign: "left",
  marginTop: "5px",
};

const stylePasswordNormal = {
  borderBottom: "1px solid #e4e4e4",
};

const stylePasswordError = {
  borderBottom: "1px solid red",
};

const TextFieldGroup = ({
  name,
  placeholder,
  value,
  error,
  info,
  type,
  onChange,
  disabled,
  list,
  id,
  toggleShowPassword,
  isPassword,
  list_array,
}) => {
  let itemContent;
  if (isPassword) {
    itemContent = (
      <main style={{ marginBottom: "40px" }}>
        <section
          style={{
            textAlign: "left",
            color: error ? "red" : "#1e5e96ff",
            fontSize: "15px",
          }}
        >
          {error ? error : placeholder}
        </section>
        <section
          className="input_icon"
          style={error ? stylePasswordError : stylePasswordNormal}
        >
          <input
            type={type}
            placeholder={placeholder}
            name={name}
            id={id}
            value={value}
            onChange={onChange}
            disabled={disabled}
            style={styleObjectPassword}
            list={list ? list : ""}
            autoComplete="off"
          />
          <img
            alt="show password"
            src={type === "password" ? icon_password_view : icon_password_hide}
            width="25px"
            onClick={toggleShowPassword}
          />
        </section>
        {info && <small>{info}</small>}
      </main>
    );
  } else {
    if (isEmpty(list_array)) {
      itemContent = (
        <main style={{ marginBottom: "40px" }}>
          <section
            style={{
              textAlign: "left",
              color: error ? "red" : "#1e5e96ff",
              fontSize: "15px",
            }}
          >
            {error ? error : placeholder}
          </section>
          <input
            type={type}
            placeholder={placeholder}
            name={name}
            id={id}
            value={value}
            onChange={onChange}
            disabled={disabled}
            style={error ? styleObjectError : styleObjectNormal}
            list={list ? list : ""}
            autoComplete="off"
          />
          {info && <small>{info}</small>}
        </main>
      );
    } else {
      itemContent = (
        <main style={{ marginBottom: "40px" }}>
          <section
            style={{
              textAlign: "left",
              color: error ? "red" : "#1e5e96ff",
              fontSize: "15px",
            }}
          >
            {error ? error : placeholder}
          </section>
          <input
            type={type}
            placeholder={placeholder}
            name={name}
            id={id}
            value={value}
            onChange={onChange}
            disabled={disabled}
            style={error ? styleObjectError : styleObjectNormal}
            list={list}
            autoComplete="off"
          />
          <datalist id={list}>
            {list_array.map((element, idx) => {
              return <option value={element} key={idx} />;
            })}
          </datalist>
          {info && <small>{info}</small>}
        </main>
      );
    }
  }
  return itemContent;
};

TextFieldGroup.defaultProps = {
  type: "text",
};

export default TextFieldGroup;
