const initialState = {
  data: {},
  folders_group: [],
  items_group: [],
  folders_user: [],
  groups: [],
  group: {},
  item: "",
  items: [],
  log: "",
  loading: false,
  loadingContent: false,
  loadingList: false,
  landingitems: [],
  publicitems: [],
  user_list: [],
  new_dataset: [],
  loading_new_dataset: false,
  load_more_new_dataset: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "CREATE_FOLDER_GROUP":
      return {
        ...state,
        folders_group: [action.payload, ...state.folders_group],
      };
    case "CREATE_FOLDER_USER":
      return {
        ...state,
        folders_user: [action.payload, ...state.folders_user],
      };
    case "GET_FOLDERS_BY_GROUP":
      return {
        ...state,
        folders_group: action.payload,
      };
    case "GET_ITEMS_BY_GROUP":
      return {
        ...state,
        items_group: action.payload,
      };
    case "GET_FOLDERS_BY_USER":
      return {
        ...state,
        folders_user: action.payload,
      };
    case "SEARCH_USERS":
      return {
        ...state,
        user_list: action.payload,
      };
    case "ADD_GROUP":
      return {
        ...state,
        groups: [action.payload, ...state.groups],
      };
    case "GET_GROUPS":
      return {
        ...state,
        groups: action.payload,
      };
    case "GET_GROUP":
      return {
        ...state,
        group: action.payload,
      };
    case "DELETE_GROUP":
      return {
        ...state,
        groups: state.groups.filter((group) => group._id !== action.payload),
      };
    case "EDIT_GROUP":
      return {
        ...state,
        groups: [
          action.payload,
          ...state.groups.filter((group) => group._id !== action.payload._id),
        ],
      };
    case "GET_COUNT":
      return {
        ...state,
        data: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "CLEAR_LOADING":
      return {
        ...state,
        loading: false,
      };
    case "SET_DATASET_CONTENT_LOADING":
      return {
        ...state,
        loadingContent: true,
      };
    case "CLEAR_DATASET_CONTENT_LOADING":
      return {
        ...state,
        loadingContent: false,
      };
    case "SET_DATASET_LIST_LOADING":
      return {
        ...state,
        loadingList: true,
      };
    case "CLEAR_DATASET_LIST_LOADING":
      return {
        ...state,
        loadingList: false,
      };
    case "GET_ITEMS":
      return {
        ...state,
        items: action.payload,
        loading: false,
      };
    case "GET_PUBLIC_ITEMS":
      return {
        ...state,
        publicitems: action.payload,
        loading: false,
      };
    case "GET_LANDING_ITEMS":
      return {
        ...state,
        landingitems: action.payload,
        loading: false,
      };
    case "GET_ITEM":
      return {
        ...state,
        item: action.payload,
        loading: false,
      };
    case "GET_ITEM_PUBLIC":
      return {
        ...state,
        item: action.payload,
        loading: false,
      };
    case "GET_LOG_ITEM":
      return {
        ...state,
        log: action.payload,
        loading: false,
      };
    case "DELETE_ITEM":
      return {
        ...state,
        items: state.items.filter((item) => item._id !== action.payload),
      };
    case "ADD_ITEM":
      return {
        ...state,
        items: [action.payload, ...state.items],
      };
    case "ADD_DAMRI":
      return {
        ...state,
        items: action.payload,
      };
    case "UPDATE_ITEM":
      return {
        ...state,
        item: action.payload,
      };
    case "CLEAR_ALL_DATA":
      return {
        ...state,
        item: action.payload,
      };
    case "UPDATE_PUBLIC_ITEM":
      return {
        ...state,
        item: action.payload,
      };
    case "CLEAR_CURRENT_ITEMS":
      return {
        ...state,
        items: [],
      };
    case "CLEAR_CURRENT_ITEM":
      return {
        ...state,
        item: "",
      };
    case "CLEAR_CURRENT_LOG":
      return {
        ...state,
        log: "",
      };
    case "BUY_QUOTA":
      return {
        ...state,
        items: state.items,
      };
    case "CONFIRM_PAYMENT":
      return {
        ...state,
        items: state.items,
      };
    case "ADD_COMMENT":
      return {
        ...state,
        item: action.payload,
      };
    case "ADD_REPLY":
      return {
        ...state,
        item: action.payload,
      };
    case "EDIT_COMMENT":
      return {
        ...state,
        item: action.payload,
      };
    case "EDIT_REPLY":
      return {
        ...state,
        item: action.payload,
      };
    case "DELETE_COMMENT":
      return {
        ...state,
        item: action.payload,
      };
    case "DELETE_REPLY":
      return {
        ...state,
        item: action.payload,
      };
    case "SUB_NOTIF":
      return {
        ...state,
        sub_object: action.payload,
      };
    case "GET_LOG_NEW_DATASET":
      return {
        ...state,
        new_dataset: action.payload,
      };
    case "GET_LOG_NEW_DATASET_APPEN":
      return {
        ...state,
        new_dataset: log_new_dataset_appen(state.new_dataset, action.payload),
      };
    case "SET_LOADING_NEW_DATASET":
      return {
        ...state,
        loading_new_dataset: action.payload,
      };
    case "SET_LOAD_MORE_NEW_DATASET":
      return {
        ...state,
        load_more_new_dataset: action.payload,
      };
    case "KALMAN_FILTER":
      return {
        ...state,
        new_dataset: kalman_filter(state.new_dataset),
      };
    case "CLEAR_OUILIER":
      return {
        ...state,
        new_dataset: clear_outlier(state.new_dataset),
      };
    case "TOYO_FILTER":
      return {
        ...state,
        new_dataset: toyo_filter(state.new_dataset),
      };
    default:
      return state;
  }
}

const log_new_dataset_appen = (prev_data, new_data) => {
  let result = prev_data;
  let _new = new_data.log_data;
  let _final = prev_data.log_data;
  for (let index = 0; index < _final.length; index++) {
    let element = _final[index];
    element.sensorData = [...element.sensorData, ..._new[index].sensorData];
  }
  result.log_data = _final;
  return result;
};

const kalman_filter = (data) => {
  const log_data = data.log_data;
  for (let index = 0; index < log_data.length; index++) {
    const element = log_data[index];
    const result = kalman_filter_process(element.sensorData);
    data.log_data[index].sensorData = result;
  }

  return data;
};

const kalman_filter_process = (data) => {
  // const yData = data.map((e) => e.y);
  const yData = data;

  let sensorData = 0;
  let kalmanFilterData = 0;
  let Xt = 0,
    Xt_update = 0,
    Xt_prev = yData[0].y;
  let Pt = 0,
    Pt_update = 0,
    Pt_prev = 0;
  let Kt = 0,
    R = 0,
    Q = 0;

  R = 10;
  Q = 0.5;

  let result = [];
  yData.forEach((element) => {
    sensorData = element.y;
    Xt_update = Xt_prev;
    Pt_update = Pt_prev + Q;
    Kt = Pt_update / (Pt_update + R);
    Xt = Xt_update + Kt * (sensorData - Xt_update);
    Pt = (1 - Kt) * Pt_update;

    Xt_prev = Xt;
    Pt_prev = Pt;

    kalmanFilterData = Xt;

    // console.log("data: ", sensorData, "kalman: ", kalmanFilterData);
    result.push({
      y: kalmanFilterData,
      x: element.x,
    });
  });
  return result;
};

const clear_outlier = (data) => {
  const log_data = data.log_data;
  for (let index = 0; index < log_data.length; index++) {
    const element = log_data[index];
    const { maxValue, minValue } = outlier_process(element.sensorData);
    const filteredValues = element.sensorData.filter(function (el) {
      return el.y <= maxValue && el.y >= minValue;
    });
    data.log_data[index].sensorData = filteredValues;
  }

  return data;
};

const outlier_process = (someArray) => {
  // Copy the values, rather than operating on references to existing values
  let yData = someArray.map((e) => e.y);
  var values = yData.concat();

  // Then sort
  values.sort(function (a, b) {
    return a - b;
  });

  /* Then find a generous IQR. This is generous because if (values.length / 4)
   * is not an int, then really you should average the two elements on either
   * side to find q1.
   */
  var q1 = values[Math.floor(values.length / 4)];
  // Likewise for q3.
  var q3 = values[Math.ceil(values.length * (3 / 4))];
  var iqr = q3 - q1;

  // Then find min and max values
  var maxValue = q3 + iqr * 1.5;
  var minValue = q1 - iqr * 1.5;

  // Then filter anything beyond or beneath these values.
  // var filteredValues = values.filter(function (x) {
  //   return x <= maxValue && x >= minValue;
  // });

  // Then return
  return { maxValue, minValue };
};

const toyo_filter = (data) => {
  const log_data = data.log_data;
  for (let index = 0; index < log_data.length; index++) {
    const element = log_data[index];
    const { maxValue, minValue } = toyo_filter_process(element.sensorData);

    let filteredValues = [];
    for (let index = 0; index < element.sensorData.length; index++) {
      const el = element.sensorData[index];
      if (el.y <= maxValue && el.y >= minValue) {
        filteredValues.push(el);
      } else {
        let new_y = element.sensorData[index - 1].y;
        // if (new_y <= maxValue && new_y >= minValue) {
        //   new_y = element.sensorData[index - 2].y;
        // }
        filteredValues.push({
          y: new_y,
          x: el.x,
        });
      }
    }
    console.log(maxValue, minValue);
    data.log_data[index].sensorData = filteredValues;
  }

  return data;
};

const toyo_filter_process = (someArray) => {
  // Copy the values, rather than operating on references to existing values
  let yData = someArray.map((e) => e.y);
  var values = yData.concat();

  // Then sort
  values.sort(function (a, b) {
    return a - b;
  });

  /* Then find a generous IQR. This is generous because if (values.length / 4)
   * is not an int, then really you should average the two elements on either
   * side to find q1.
   */
  var q1 = values[Math.floor(values.length / 4)];
  // Likewise for q3.
  var q3 = values[Math.ceil(values.length * (3 / 4))];
  var iqr = q3 - q1;

  // Then find min and max values
  var maxValue = q3 + iqr * 1.5;
  var minValue = q1 - iqr * 1.5;

  // Then filter anything beyond or beneath these values.
  // var filteredValues = values.filter(function (x) {
  //   return x <= maxValue && x >= minValue;
  // });

  // Then return
  return { maxValue, minValue };
};
