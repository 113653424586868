/* eslint-disable */
import React, { Component } from "react";
class Compass extends Component {
  state = {
    value: 0,
    janganDiganggu: false
  };
  componentDidMount() {
    const value = parseInt(this.props.value);
    this.setState({
      value: value
    });
  }
  componentWillUnmount() {
    clearInterval(this.timerR);
  }
  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value && !this.state.janganDiganggu) {
      const value = parseInt(this.props.value);
      this.setState({ janganDiganggu: true });
      if (value !== this.state.value) {
        let endR = value;
        var inc = 1;
        var stepTime = 20;
        //logic 1
        if (Math.abs(this.props.value - prevProps.value) > 180) {
          if (this.props.value > prevProps.value) {
            var incrementR = -inc;
            this.timerR = setInterval(() => {
              var currentR = this.state.value;
              if (currentR <= 0) {
                this.setState({ value: 360 });
              } else {
                this.setState(prevState => ({
                  value: prevState.value + incrementR
                }));
              }
              var selisihR = Math.abs(endR - currentR);
              if (
                selisihR === 0 ||
                this.state.value < 0 ||
                this.state.value > 360
              ) {
                this.setState({
                  janganDiganggu: false
                });
                clearInterval(this.timerR);
              }
            }, stepTime);
          }
          if (this.props.value < prevProps.value) {
            incrementR = inc;
            this.timerR = setInterval(() => {
              var currentR = this.state.value;
              if (currentR >= 360) {
                this.setState({ value: 0 });
              } else {
                this.setState(prevState => ({
                  value: prevState.value + incrementR
                }));
              }
              var selisihR = Math.abs(endR - currentR);
              if (
                selisihR === 0 ||
                this.state.value < 0 ||
                this.state.value > 360
              ) {
                this.setState({
                  janganDiganggu: false
                });
                clearInterval(this.timerR);
              }
            }, stepTime);
          }
        }
        //logic 2
        if (Math.abs(this.props.value - prevProps.value) <= 180) {
          if (this.props.value > prevProps.value) {
            incrementR = inc;
            this.timerR = setInterval(() => {
              this.setState(prevState => ({
                value: prevState.value + incrementR
              }));
              var currentR = this.state.value;
              var selisihR = Math.abs(endR - currentR);
              if (
                selisihR === 0 ||
                this.state.value < 0 ||
                this.state.value > 360
              ) {
                this.setState({
                  janganDiganggu: false
                });
                clearInterval(this.timerR);
              }
            }, stepTime);
          }
          if (this.props.value < prevProps.value) {
            incrementR = -inc;
            this.timerR = setInterval(() => {
              this.setState(prevState => ({
                value: prevState.value + incrementR
              }));
              var currentR = this.state.value;
              var selisihR = Math.abs(endR - currentR);
              if (
                selisihR === 0 ||
                this.state.value < 0 ||
                this.state.value > 360
              ) {
                this.setState({
                  janganDiganggu: false,
                  isMuter: false
                });
                clearInterval(this.timerR);
              }
            }, stepTime);
          }
        }
      }
    } else return null;
  }
  render() {
    const { value } = this.state;
    const shown_number_int = parseInt(value);
    const nMin = 0;
    const nMax = 360;
    const mapValue = 360 * ((value - nMin) / (nMax - nMin));
    var numberArray = [];
    for (let i = 0; i < 360; i = i + 15) {
      numberArray.push({
        value: i
      });
    }
    var smallNumberArray = [];
    for (let i = 0; i < 360; i = i + 5) {
      smallNumberArray.push({
        value: i
      });
    }
    var compassArray = [
      { string: "N" },
      { string: "NE" },
      { string: "E" },
      { string: "SE" },
      { string: "S" },
      { string: "SW" },
      { string: "W" },
      { string: "NW" }
    ];
    compassArray.forEach(function(element, idx) {
      element.value = idx * 45;
    });
    var shown_compass_point;
    if (
      (mapValue >= 0 && mapValue < 22.5) ||
      (mapValue > 337.5 && mapValue < 360)
    ) {
      shown_compass_point = "N";
    } else if (mapValue >= 22.5 && mapValue < 67.5) {
      shown_compass_point = "NE";
    } else if (mapValue >= 67.5 && mapValue < 112.5) {
      shown_compass_point = "E";
    } else if (mapValue >= 112.5 && mapValue < 157.5) {
      shown_compass_point = "SE";
    } else if (mapValue >= 157.5 && mapValue < 202.5) {
      shown_compass_point = "S";
    } else if (mapValue >= 202.5 && mapValue < 247.5) {
      shown_compass_point = "SW";
    } else if (mapValue >= 247.5 && mapValue < 292.5) {
      shown_compass_point = "W";
    } else if (mapValue >= 292.5 && mapValue < 337.5) {
      shown_compass_point = "NW";
    } else {
      shown_compass_point = "?";
    }
    const compass = (
      <div className="text-center">
        <svg
          width={this.props.width ? this.props.width : "100%"}
          height={this.props.width ? this.props.width : "100%"}
          viewBox="0 0 20 20"
          className="compass"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* <rect width="20" height="20" fill="yellow" /> */}
          <circle className="donutRing" cx="10" cy="10" r="10" fill="black" />
          <g transform={`rotate(${-mapValue} 10 10)`}>
            <circle
              className="hijauMuda"
              cx="10"
              cy="10"
              r="9.5"
              fill="#196b6aff"
            />
            <circle
              className="hijaugelap"
              cx="10"
              cy="10"
              r="7"
              fill="#0000005b"
            />
            {numberArray.map(({ value }, idx) => {
              var fontSize;
              var fill;
              var height;
              var width;
              if (value === 0) {
                width = 0.16 + 0.05;
                fontSize = 1.2;
                fill = "red";
                height = 0.8 + 0.1;
              } else {
                width = 0.08 + 0.05;
                fontSize = 0.8;
                fill = "white";
                height = 0.4 + 0.1;
              }
              return (
                <g className={idx} key={`big-${idx}`}>
                  <text
                    x="10"
                    y="2"
                    textAnchor="middle"
                    fontFamily="Arial"
                    fontSize={fontSize}
                    fill={fill}
                    fontStyle="normal"
                    transform={`rotate(${value} 10 10)`}
                  >
                    {value}
                  </text>
                  <rect
                    width={width}
                    height={height}
                    fill={fill}
                    x={10 - width / 2}
                    y="2.3"
                    transform={`rotate(${value} 10 10)`}
                  />
                </g>
              );
            })}

            {smallNumberArray.map(({ value }, idx) => {
              var fill;
              if (value === 0) {
                fill = "red";
              } else {
                fill = "white";
              }
              return (
                <rect
                  className={idx}
                  key={`small-${idx}`}
                  width={0.08 + 0.05}
                  height={0.2 + 0.05}
                  fill={fill}
                  x={10 - 0.08 / 2}
                  y={2.3}
                  transform={`rotate(${value} 10 10)`}
                />
              );
            })}
            {compassArray.map(({ string, value }, idx) => {
              var lineContent;
              var fill;
              if (value === 0) {
                fill = "red";
              } else {
                fill = "white";
              }
              if (
                value === 0 ||
                value === 90 ||
                value === 180 ||
                value === 270
              ) {
                lineContent = (
                  <rect
                    width={0.16 + 0.05}
                    height={1.3 + 1}
                    fill={fill}
                    x={10 - 0.16 / 2}
                    y={5}
                    transform={`rotate(${value} 10 10)`}
                  />
                );
              } else {
                lineContent = null;
              }
              return (
                <g key={`stringCompass-${idx}`}>
                  <text
                    x={10}
                    y={4.2}
                    textAnchor="middle"
                    fontFamily="Arial"
                    fontSize="1"
                    fill={fill}
                    fontStyle="normal"
                    transform={`rotate(${value} 10 10)`}
                  >
                    {string}
                  </text>
                  {lineContent}
                </g>
              );
            })}
          </g>
          <rect
            className="pointer"
            width="0.2"
            height="2.5"
            fill="#ff9024ca"
            x={10 - 0.2 / 2}
            y="0.5"
            rx="0.1"
            ry="0.1"
          />
          <text
            className="shown_number"
            x="10"
            y={10 + 0.5}
            textAnchor="middle"
            fontFamily="Arial"
            fontSize="1.2"
            fill="white"
          >
            {shown_number_int}°
          </text>
          <text
            className="shown_number"
            x="10"
            y={10 + 1.5}
            textAnchor="middle"
            fontFamily="Arial"
            fontSize="0.7"
            fill="white"
          >
            {shown_compass_point}
          </text>
        </svg>
      </div>
    );
    return <div>{compass}</div>;
  }
}
export default Compass;
