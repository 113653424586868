import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { logoutAdmin } from "../../actions/authActions";
class Admindashboard extends Component {
  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutAdmin();
  }
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <Link to="/admin/user" className="buttonShadowSmall">
          User
        </Link>
        <br />
        <br />
        <Link to="/admin/item" className="buttonShadowSmall">
          Dataset
        </Link>
        <br />
        <br />
        <Link to="/admin/payments" className="buttonShadowSmall">
          Payments
        </Link>
        <br />
        <br />
        <Link to="/admin/helps" className="buttonShadowSmall">
          Request Messages
        </Link>
        <br />
        <button
          onClick={this.onLogoutClick.bind(this)}
          className="buttonShadowSmall"
          id="deleteBright"
        >
          Sign out
        </button>
        <style>{`
        #loginModal{ visibility: hidden;}
     `}</style>
      </div>
    );
  }
}
Admindashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutAdmin: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  admin: state.admin,
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutAdmin })(Admindashboard);
