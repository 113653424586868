/*eslint-disable*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { getDocs } from "../../actions/paymentAction";
import PropTypes from "prop-types";
class CodeList extends Component {
  componentDidMount() {
    this.props.getDocs();
  }
  render() {
    const { docs } = this.props.payment;
    var itemContent;
    itemContent = (
      <div className="row">
        {docs.map(({ name, desc, picture, url, raw, project_url }, idx) => {
          return (
            <div className="col text-center" key={idx}>
              <div className="list-group-item">
                <img
                  src={require(`../../img/${picture}`)}
                  alt={picture}
                  className="img-fluid"
                  width="70px"
                />
                <br />
                <br />
                <h6>
                  <small>
                    <b>{name}</b>
                  </small>
                </h6>
                <a href={url} rel="noopener noreferrer" target="_blank">
                  Github
                </a>
                {" - "}
                <a href={raw} rel="noopener noreferrer" target="_blank">
                  Raw
                </a>
                <br />
                <a href={project_url} rel="noopener noreferrer" target="_blank">
                  Project
                </a>
                <hr />
                <div>{desc}</div>
              </div>
              <br />
            </div>
          );
        })}
      </div>
    );
    return <div className="container">{itemContent}</div>;
  }
}
CodeList.propTypes = {
  getDocs: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  payment: state.payment
});
export default connect(mapStateToProps, { getDocs })(CodeList);
