import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { load_more_new_dataset } from "../../actions/itemActions";

export class Table extends Component {
  unixToDate = (unix) => {
    const d = new Date(unix);
    return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
  };

  unixToTime = (unix) => {
    const d = new Date(unix);
    const hour = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
    const minute = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
    const second = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();
    return `${hour}:${minute}:${second}`;
  };

  load_more_data = () => {
    this.props.load_more_new_dataset(true);
  };

  render() {
    const { new_dataset } = this.props.item;
    if (new_dataset.log_data) {
      const log_data = new_dataset.log_data;
      return (
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            gap: ".5rem",
            overflow: "auto",
            padding: "1rem 0rem",
          }}
        >
          {log_data.map((data, idx) => (
            <div
              key={idx}
              style={{
                padding: ".5rem",
                border: "solid 1px lightgrey",
                borderRadius: ".5rem",
                backgroundColor: "white",
                minWidth: "280px",
              }}
            >
              <h3>{data.sensorName}</h3>
              <div
                style={{ width: "100%", maxHeight: "400px", overflowY: "auto" }}
              >
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr
                      style={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: "white",
                      }}
                    >
                      <th style={{ padding: ".5rem .25rem" }}>
                        Data ({data.sensorUnit})
                      </th>
                      <th style={{ padding: ".5rem .25rem" }}>Date</th>
                      <th style={{ padding: ".5rem .25rem" }}>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.sensorData.map((value, idx_sensor_data) => (
                      <tr
                        key={idx_sensor_data}
                        style={{
                          borderBottom: "solid 1px lightgrey",
                        }}
                      >
                        <td style={{ padding: ".5rem .25rem" }}>{value.y}</td>
                        <td style={{ padding: ".5rem .25rem" }}>
                          {this.unixToDate(value.x)}
                        </td>
                        <td style={{ padding: ".5rem .25rem" }}>
                          {this.unixToTime(value.x)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={this.load_more_data}
                >
                  More Data
                </Button>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

const mapStateToProps = (state) => ({
  item: state.item,
});

const mapDispatchToProps = {
  load_more_new_dataset,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
