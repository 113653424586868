import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

class UploadPictures extends Component {
  state = {
    date_now: moment(Date.now()).format("x"),
  };
  handleClick = () => {
    this.setState({
      date_now: moment(Date.now()).format("x"),
    });
  };
  render() {
    return (
      <button
        className="specialItem"
        onClick={this.handleClick.bind(this)}
      ></button>
    );
  }
}
UploadPictures.propTypes = {
  item: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  item: state.item,
  auth: state.auth,
});
export default connect(mapStateToProps, {})(UploadPictures);
