import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getAdminHelps } from "../../actions/adminAction";
class AdminHelps extends Component {
  state = {};
  componentDidMount() {
    this.props.getAdminHelps();
  }
  render() {
    const { helps, loading } = this.props.admin;
    var itemContent;
    var loadingContent;
    if (loading) {
      loadingContent = <div>Processing...</div>;
    } else {
      loadingContent = null;
    }
    itemContent = (
      <div>
        <h1 className="welcomeBig">Request messages</h1>
        {loadingContent}
        <div>
          {helps.map(
            (
              {
                _id,
                user,
                name,
                date,
                category,
                email,
                desc,
                picture_url,
                reply,
              },
              idx
            ) => {
              var imageItem;
              imageItem = !picture_url ? (
                <div>No image</div>
              ) : (
                <div>
                  <img
                    key={`help${_id}`}
                    alt={`help${_id}`}
                    className={`rounded`}
                    style={{ width: "100%", height: "100%" }}
                    src={picture_url}
                  />
                  <br />
                  <br />
                </div>
              );
              return (
                <div key={idx} className="specialItem">
                  <div className="four-container">
                    <div>
                      {name} {email}
                    </div>
                    <div>
                      {desc} <br /> <b>{category}</b>
                    </div>
                    <div>{moment(date).format("DD-MMM-YYYY")}</div>
                    <div>{imageItem}</div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    );

    return (
      <div className="dashboardSpecial" style={{ marginBottom: "200px" }}>
        {itemContent}
        <style>{`
        #loginModal{ visibility: hidden;}
     `}</style>
      </div>
    );
  }
}
AdminHelps.propTypes = {
  admin: PropTypes.object.isRequired,
  getAdminHelps: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  admin: state.admin,
});
export default connect(mapStateToProps, { getAdminHelps })(AdminHelps);
