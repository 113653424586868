import {
  GET_ADMIN_ITEMS,
  GET_ADMIN_USERS,
  GET_ADMIN_USERS_PENDING,
  GET_PAYMENTS,
  ADMIN_CONFIRM_PAYMENT,
  ADMIN_CANCEL_PAYMENT,
  ADMIN_LOADING,
  CLEAR_ADMIN_LOADING,
  GET_HELPS,
} from "../actions/types";
const initialState = {
  loading: false,
  loadingContent: false,
  loadingList: false,
  adminitems: [],
  adminusers: [],
  payments: [],
  helps: [],
  tutorial_list_admin: [],
  tutorial_list_public: [],
  tutorial_admin: "",
  tutorial_public: "",
  blog_list_admin: [],
  blog_admin: "",
  picture_list: [],
  picture_selected: {},
  picture_idx: 0,
  iot_type: [],
  geo_type: [],
  years_type: [],
  current_type: "blog",
  versioning: 3.2,
};
export default function (state = initialState, action) {
  switch (action.type) {
    //Blog
    case "GET_IOT_TYPE":
      return {
        ...state,
        iot_type: action.payload,
      };
    case "GET_GEO_TYPE":
      return {
        ...state,
        geo_type: action.payload,
      };
    case "GET_YEARS_TYPE":
      return {
        ...state,
        years_type: action.payload,
      };
    case "ADD_IOT_TYPE":
      return {
        ...state,
        iot_type: [action.payload, ...state.iot_type],
      };
    case "ADD_GEO_TYPE":
      return {
        ...state,
        geo_type: [action.payload, ...state.geo_type],
      };
    case "ADD_YEARS_TYPE":
      return {
        ...state,
        years_type: [action.payload, ...state.years_type],
      };
    case "SELECT_PICTURE":
      return {
        ...state,
        picture_selected: action.payload,
        picture_idx: state.picture_idx + 1,
      };
    case "CREATE_BLOG":
      return {
        ...state,
        blog_list_admin: [action.payload, ...state.blog_list_admin],
      };
    case "GET_LIST_PICTURES":
      return {
        ...state,
        picture_list: action.payload,
      };
    case "UPLOAD_PICTURE":
      return {
        ...state,
        picture_list: [action.payload, ...state.picture_list],
      };
    case "GET_LIST_BLOG_ADMIN":
      return {
        ...state,
        blog_list_admin: action.payload.content,
        current_type: action.payload.current_type,
      };
    case "SET_CURRENT_TYPE":
      return {
        ...state,
        current_type: action.payload,
      };
    case "GET_BLOG_ADMIN":
      return {
        ...state,
        blog_admin: action.payload,
      };
    case "DELETE_BLOG":
      return {
        ...state,
        blog_list_admin: state.blog_list_admin.filter(
          (blog) => blog._id !== action.payload
        ),
      };
    //Tutorial
    case "CREATE_TUTORIAL":
      return {
        ...state,
        tutorial_list_admin: [action.payload, ...state.tutorial_list],
      };
    case "GET_LIST_TUTORIAL_ADMIN":
      return {
        ...state,
        tutorial_list_admin: action.payload,
      };

    case "GET_LIST_TUTORIAL_PUBLIC":
      return {
        ...state,
        tutorial_list_public: action.payload,
      };
    case "GET_TUTORIAL_ADMIN":
      return {
        ...state,
        tutorial_admin: action.payload,
      };
    case "CLEAR_TUTORIAL_ADMIN":
      return {
        ...state,
        tutorial_admin: "",
      };
    case "GET_TUTORIAL_PUBLIC":
      return {
        ...state,
        tutorial_public: action.payload,
      };
    case "CLEAR_TUTORIAL_PUBLIC":
      return {
        ...state,
        tutorial_public: "",
      };
    case "DELETE_TUTORIAL":
      return {
        ...state,
        tutorial_list_admin: state.tutorial_list_admin.filter(
          (tutorial) => tutorial._id !== action.payload
        ),
      };
    case "SET_TUTORIAL_CONTENT_LOADING":
      return {
        ...state,
        loadingContent: true,
      };
    case "CLEAR_TUTORIAL_CONTENT_LOADING":
      return {
        ...state,
        loadingContent: false,
      };
    case "SET_TUTORIAL_LIST_LOADING":
      return {
        ...state,
        loadingList: true,
      };
    case "CLEAR_TUTORIAL_LIST_LOADING":
      return {
        ...state,
        loadingList: false,
      };
    case ADMIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ADMIN_LOADING:
      return {
        ...state,
        loading: false,
      };
    case GET_ADMIN_ITEMS:
      return {
        ...state,
        adminitems: action.payload,
        loading: false,
      };
    case GET_ADMIN_USERS:
      return {
        ...state,
        adminusers: action.payload,
        loading: false,
      };
    case GET_ADMIN_USERS_PENDING:
      return {
        ...state,
        adminusers: action.payload,
        loading: false,
      };
    case ADMIN_CONFIRM_PAYMENT:
      return {
        ...state,
        payments: action.payload,
      };
    case ADMIN_CANCEL_PAYMENT:
      return {
        ...state,
        payments: action.payload,
      };
    case GET_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
      };
    case GET_HELPS:
      return {
        ...state,
        helps: action.payload,
      };
    default:
      return state;
  }
}
