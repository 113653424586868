//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
import ModalSpecialComponent from "../common/ModalSpecialComponent";
import TextFieldGroup from "../common/TextFieldGroup";
//Redux function
import { loginUser, sendLink } from "../../actions/authActions";
//Picture Asset
import official_account from "../../img/official_account.svg";
//General Function
import history from "../../actions/history";
import dict from "../../validation/dict.json";
class Login extends Component {
  state = {
    name: "",
    password: "",
    password2: "",
    email: "",
    errors: {},
    width: window.innerWidth,
    show_password: false,
    is_forget_password: false,
    modal_send_link: false,
    modal_success: false,
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    } else {
      return null;
    }
  }
  componentDidMount() {
    const isNav = this.props.isNav ? this.props.isNav : null;
    if (!isNav) {
      if (this.props.auth.isAuthenticated) {
        history.push("/");
      }
    } else {
      return null;
    }
    if (this.props.auth.verify_link_status === "send") {
      console.log("mount");
      this.setState({ modal_success: true });
    }
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentDidUpdate(prevProps) {
    if (
      !!(
        this.props.auth.verify_link_status !== prevProps.auth.verify_link_status
      ) &&
      this.props.auth.verify_link_status === "send"
    ) {
      console.log("mount");
      this.setState({ modal_success: true });
    }
    if (this.props.auth.send_link_status !== prevProps.auth.send_link_status) {
      this.setState({ modal_send_link: true });
    }
    const isNav = this.props.isNav ? this.props.isNav : null;
    if (!isNav) {
      if (this.props.auth.isAuthenticated) {
        history.push("/");
      }
    } else {
      return null;
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  toggleSuccess = () => {
    this.setState({ modal_success: !this.state.modal_success });
  };
  toggleSendLink = () => {
    this.setState({ modal_send_link: !this.state.modal_send_link });
  };
  toggleForgetPassword = () => {
    this.setState({
      is_forget_password: !this.state.is_forget_password,
      errors: {},
    });
  };
  toggleShowPassword = () => {
    this.setState({ show_password: !this.state.show_password });
  };
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth }, () => {});
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const isNav = this.props.isNav ? this.props.isNav : null;
    const userData = {
      name: this.state.name,
      password: this.state.password,
      isNav,
    };
    this.props.loginUser(userData);
  };
  onSubmitForgetPass = (e) => {
    e.preventDefault();
    const isNav = this.props.isNav ? this.props.isNav : null;
    const body = {
      email: this.state.email,
      isNav,
    };
    this.props.sendLink(body);
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      errors,
      width,
      name,
      password,
      show_password,
      is_forget_password,
      email,
      modal_send_link,
      modal_success,
    } = this.state;
    const { loadingProcess, itemLoading, send_link_status } = this.props.auth;
    let iconContent = (
      <div style={{ marginTop: "30px" }}>
        <button className="button_pill" type="submit">
          {dict["Sign in"][language]}
        </button>
      </div>
    );
    if (loadingProcess && itemLoading === "sign_in") {
      iconContent = (
        <div style={{ marginTop: "30px" }}>
          <button className="button_pill" type="submit">
            {dict["Sign in..."][language]}
          </button>
        </div>
      );
    }
    let fp_button = (
      <div style={{ marginTop: "30px" }}>
        <button className="button_pill" type="submit">
          {dict["Send link"][language]}
        </button>
      </div>
    );
    if (loadingProcess && itemLoading === "forget_password") {
      fp_button = (
        <div style={{ marginTop: "30px" }}>
          <div className="button_pill">{dict["Sending link..."][language]}</div>
        </div>
      );
    } else if (send_link_status) {
      fp_button = (
        <div style={{ marginTop: "30px" }}>
          <div className="button_pill" onClick={this.toggleSendLink}>
            {dict["Link has been sent"][language]}
          </div>
        </div>
      );
    }
    let item_content = (
      <form noValidate onSubmit={this.onSubmit}>
        <TextFieldGroup
          placeholder={dict["User name"][language]}
          name="name"
          id="name"
          value={name}
          onChange={this.handleChange}
          error={errors.name}
        />
        <TextFieldGroup
          type={show_password ? "text" : "password"}
          isPassword={true}
          placeholder={dict["Password"][language]}
          name="password"
          id="password"
          value={password}
          onChange={this.handleChange}
          error={errors.password}
          toggleShowPassword={this.toggleShowPassword}
        />
        <div onClick={this.toggleForgetPassword} className="button_register">
          {dict["Forgot Password"][language]}
        </div>
        {iconContent}
      </form>
    );
    if (is_forget_password) {
      item_content = (
        <main>
          <p className="text_medium">
            {
              dict["Enter the username or email associated with your account."][
                language
              ]
            }
          </p>
          <p className="text_inferior">
            {
              dict[
                "We will send a link to your email, click on the link and follow the password settings."
              ][language]
            }
          </p>
          <form
            noValidate
            onSubmit={this.onSubmitForgetPass}
            style={{ marginTop: "50px" }}
          >
            <TextFieldGroup
              placeholder={dict["Email or user name"][language]}
              name="email"
              id="email"
              value={email}
              onChange={this.handleChange}
              error={errors.email}
            />
            {fp_button}
          </form>
        </main>
      );
    }
    const modal_edit_content = modal_send_link && (
      <ModalSpecialComponent
        modalSize="small"
        id="modal"
        isOpen={modal_send_link}
        onClose={this.toggleSendLink}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <img src={official_account} width="100px" alt="official account" />
          <p className="text_bold" style={{ marginBottom: "20px" }}>
            {dict["Link has been sent"][language]}
          </p>
          <p className="text_medium">
            {
              dict["Check your email and click the link for the next step."][
                language
              ]
            }
          </p>
        </div>
      </ModalSpecialComponent>
    );
    const modal_success_content = modal_success && (
      <ModalSpecialComponent
        modalSize="small"
        id="modal"
        isOpen={modal_success}
        onClose={this.toggleSuccess}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <img src={official_account} width="100px" alt="official account" />
          <p className="text_bold" style={{ marginBottom: "20px" }}>
            {dict["Your password has been changed"][language]}
          </p>
          <p className="text_medium">
            {dict["Enter your user name and new password."][language]}
          </p>
        </div>
      </ModalSpecialComponent>
    );
    return (
      <main
        style={{
          textAlign: width < 600 || this.props.isNav ? "center" : "left",
          maxWidth: "90vw",
          margin: "auto",
        }}
      >
        {item_content}
        {modal_edit_content}
        {modal_success_content}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { loginUser, sendLink })(Login);
