import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import ModalComponent from "../common/ModalComponent";
import {
  createFolderGroup,
  getFoldersByGroup,
  getGroup,
  getItemsByGroup,
  deleteMember,
  editMember,
  editGroup,
} from "../../actions/itemActions";
import isEqual from "../../validation/is-equal";
import SpinnerSimpleBlue from "../common/SpinnerSimpleBlue";
import pic_static from "../../img/profile_pic_comment.svg";
import pic_tambah from "../../img/pic_tambah.svg";
import DatasetsFolderRender from "../dashboard/DatasetsFolderRender";

class GroupDetail extends Component {
  state = {
    user: {},
    modal: false,
    modalViewDatasets: false,
    modalMembers: false,
    modalDeleteMember: false,
    name: "",
    items: [],
    folder_item: {},
    member: {},
    status: "accepted",
    permission: this.props.item.group.permission,
  };
  toggleViewDatasets = (items, folder_item) => {
    this.setState({ items, folder_item }, () => {
      this.setState({
        modalViewDatasets: !this.state.modalViewDatasets,
      });
    });
  };
  toggleMembers = () => {
    this.setState({
      modalMembers: !this.state.modalMembers,
      permission: this.props.item.group.permission,
    });
  };
  toggleDeleteMember = (member) => {
    this.setState({ member }, () => {
      this.setState({
        modalDeleteMember: !this.state.modalDeleteMember,
      });
    });
  };
  handleDeleteMember = () => {
    const group_id = this.props.item.group._id;
    const user_id = this.state.member._id;
    const content = {
      group_id,
      user_id,
    };
    this.props.deleteMember(content);
    this.toggleDeleteMember(this.state.member);
  };
  setStateMember = (member) => {
    this.setState({ member });
  };
  handleEditMember = (e) => {
    e.preventDefault();
    this.setState({ status: e.target.value }, () => {
      const user_id = this.state.member._id;
      const status = this.state.status;
      const content = {
        user_id,
        status,
      };
      this.props.editMember(content);
    });
  };
  handleEditGroup = (e) => {
    e.preventDefault();
    this.setState({ permission: e.target.value }, () => {
      const user_id = this.props.auth.user._id;
      const group_id = this.props.item.group._id;
      const permission = this.state.permission;
      const content = {
        user_id,
        group_id,
        permission,
      };
      this.props.editGroup(content);
    });
  };
  componentDidMount() {
    const this_user =
      this.props.auth && this.props.auth.user ? this.props.auth.user : {};
    this.setState({ user: this_user }, () => {
      const group_id = this.props.match.params.id;
      const content = {
        group_id,
        user_id: this.state.user._id,
      };
      this.props.getGroup(content);
      this.props.getFoldersByGroup(group_id);
      this.props.getItemsByGroup(group_id);
    });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const next_user =
      nextProps.auth && nextProps.auth.user ? nextProps.auth.user : [];
    const this_user = prevState.user;
    if (!isEqual(next_user, this_user)) {
      return {
        user: this_user,
      };
    } else return null;
  }
  componentDidUpdate(prevProps) {
    //close modal
    const { folders_group } = this.props.item;
    const folders_group_prev = prevProps.item.folders_group;
    if (!isEqual(folders_group, folders_group_prev)) {
      this.setState({ modal: false });
    } else {
      return null;
    }
    //prevent user error when token expired
    const prev_user =
      prevProps.auth && prevProps.auth.user ? prevProps.auth.user : [];
    const this_user =
      this.props.auth && this.props.auth.user ? this.props.auth.user : [];
    if (!isEqual(prev_user, this_user)) {
      this.setState({ user: this_user });
    }
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { name } = this.state;
    const group_id = this.props.item.group._id;
    const content = {
      name,
      group_id,
    };
    this.props.createFolderGroup(content);
  };
  render() {
    const { group, loading, folders_group, items_group } = this.props.item;
    const { user } = this.props.auth;
    const { name, permission } = this.state;
    let memberStatus = "accepted";
    if (group._id) {
      memberStatus = group.members.filter(
        (member) => member.user._id === user._id
      )[0].status;
    }
    var iconContent;
    if (loading) {
      iconContent = (
        <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
      );
    } else {
      iconContent = (
        <button
          className="buttonSimple2"
          onClick={this.handleSubmit}
          type="submit"
        >
          Save
        </button>
      );
    }

    const modal = this.state.modal && (
      <ModalComponent
        modalSize="small"
        id="newfoldermodal"
        isOpen={this.state.modal}
        onClose={this.toggle}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          Create a new folder
          <form>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={this.handleChange}
              />
              <div style={{ textAlign: "center" }}>{iconContent}</div>
            </div>
          </form>
        </div>
      </ModalComponent>
    );

    const foldersContent = (
      <div className="folder-container">
        {folders_group.map((folder_item, idx) => {
          const datasets_on_folder = items_group.filter(
            (item) => item.folder === folder_item._id
          );
          return (
            <div key={folder_item._id}>
              <div
                className="folder"
                onClick={this.toggleViewDatasets.bind(
                  this,
                  datasets_on_folder,
                  folder_item
                )}
              >
                {folder_item.name}
              </div>
            </div>
          );
        })}
      </div>
    );
    const memberArray = group.members ? group.members : [];
    var memberArraySliced;
    if (memberArray.length <= 3) {
      memberArraySliced = memberArray;
    } else {
      memberArraySliced = memberArray.slice(0, 3);
    }
    const membersContent = (
      <div>
        {memberArraySliced.map(({ status, user }, idx) => {
          return (
            <div
              className={`photo_${user._id}`}
              key={user._id}
              onClick={this.toggleMembers}
            >
              <style>
                {`.photo_${user._id}{
                background: url(${
                  user.profile_picture && user.profile_picture.url_compressed
                    ? user.profile_picture.url_compressed
                    : user.profile_picture &&
                      user.profile_picture.url &&
                      user.profile_picture.url !==
                        "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                      user.profile_picture.url !== "-" &&
                      user.profile_picture.url !== "default"
                    ? user.profile_picture.url
                    : pic_static
                }) no-repeat center center/ cover;
                background-color: white;
                height:40px;
                width:40px;
                border-radius: 50%;
                border: 3px solid white;
                margin: 0px -5px 5px -5px;
                display: inline-block;
                cursor: pointer;
          }`}
              </style>
            </div>
          );
        })}
        {memberArray.length > 3 ? (
          <div className={`photo_tambah`} onClick={this.toggleMembers}>
            <style>
              {`.photo_tambah{
                background: url(${pic_tambah}) no-repeat center center/ cover;
                background-color: white;
                height:40px;
                width:40px;
                border-radius: 50%;
                border: 3px solid white;
                margin: 0px -5px 5px -5px;
                display: inline-block;
                cursor: pointer;
                padding : 0px;
          }`}
            </style>
          </div>
        ) : null}
      </div>
    );

    const modalDeleteMemberContent = this.state.modalDeleteMember && (
      <ModalComponent
        modalSize="small"
        id="deleteModal"
        isOpen={this.state.modalDeleteMember}
        onClose={this.toggleDeleteMember.bind(this, this.state.member)}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          Are you sure to delete
          <div className="welcome" style={{ textAlign: "center" }}>
            {this.state.member.user.full_name
              ? this.state.member.user.full_name
              : this.state.member.user.name}
          </div>
          <br />
          <div className="buttonSimple2" onClick={this.handleDeleteMember}>
            Delete
          </div>
        </div>
      </ModalComponent>
    );

    const permissionArray = ["restrict", "open"];
    const permissionEditContent =
      memberStatus === "creator" ? (
        <tr>
          <td>Group permission:</td>
          <td>
            <select
              id="permission"
              value={permission}
              onChange={this.handleEditGroup}
            >
              {permissionArray.map((status, idx) => {
                return (
                  <option key={idx} value={status}>
                    {status}
                  </option>
                );
              })}
            </select>
          </td>
          <td>
            <br />
            <br />
            <br />
          </td>
        </tr>
      ) : (
        <tr>
          <td>Group permission:</td>
          <td>
            <select id="permission" value={permission} readOnly={true}>
              {permissionArray.map((status, idx) => {
                return (
                  <option key={idx} value={status}>
                    {status}
                  </option>
                );
              })}
            </select>
          </td>
          <td>
            <br />
            <br />
            <br />
          </td>
        </tr>
      );

    const modalMembersContent = this.state.modalMembers && (
      <ModalComponent
        modalSize="lg"
        id="modal"
        isOpen={this.state.modalMembers}
        onClose={this.toggleMembers}
      >
        <div className="box-body">
          <table>
            <tbody>
              {permissionEditContent}
              {group.members.map((member, idx) => {
                const user = member.user;
                const status = member.status;
                const { _id, full_name, profile_picture, name } = user;
                let deleteButton;
                const statusArray = ["admin", "accepted", "rejected"];
                let statusButton;
                if (status === "creator") {
                  statusButton = (
                    <select id="cars" value="creator" readOnly={true}>
                      <option value="creator">creator</option>
                    </select>
                  );
                } else if (
                  memberStatus === "creator" ||
                  memberStatus === "admin" ||
                  group.permission === "open"
                ) {
                  statusButton = (
                    <select
                      id="status"
                      value={status}
                      onChange={this.handleEditMember}
                      onClick={this.setStateMember.bind(this, member)}
                    >
                      {statusArray.map((status, idx) => {
                        return (
                          <option key={idx} value={status}>
                            {status}
                          </option>
                        );
                      })}
                    </select>
                  );
                } else {
                  statusButton = (
                    <select id="status" value={status} readOnly={true}>
                      {statusArray.map((status, idx) => {
                        return (
                          <option key={idx} value={status}>
                            {status}
                          </option>
                        );
                      })}
                    </select>
                  );
                }
                if (
                  memberStatus === "creator" ||
                  memberStatus === "admin" ||
                  group.permission === "open"
                ) {
                  deleteButton = (
                    <div
                      className="buttonSimpleTightNormal"
                      id="delete"
                      onClick={this.toggleDeleteMember.bind(this, member)}
                    >
                      Delete
                    </div>
                  );
                } else {
                  deleteButton = null;
                }
                return (
                  <tr key={_id}>
                    <td
                      style={{
                        textAlign: "left",
                        marginBottom: "10px",
                        width: "40px",
                      }}
                    >
                      <div
                        alt={_id}
                        className={`photo_member_${_id}`}
                        samesite="None"
                        secure="true"
                      />
                      <style>
                        {`.photo_member_${_id}{
                              background: url(${
                                profile_picture &&
                                profile_picture.url_compressed
                                  ? profile_picture.url_compressed
                                  : profile_picture &&
                                    profile_picture.url &&
                                    profile_picture.url !==
                                      "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                                    profile_picture.url !== "-" &&
                                    profile_picture.url !== "default"
                                  ? profile_picture.url
                                  : pic_static
                              }) no-repeat center center/ cover;
                              background-color: white;
                              height:40px;
                              width:40px;
                              border-radius: 50%;
                              border: 2px solid white;
                              display: inline-block;
                              align: center;
                              vertical-align: middle;
                }`}
                      </style>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                          marginLeft: "10px",
                        }}
                      >
                        {full_name && full_name !== "" && full_name !== "-"
                          ? full_name
                          : name}
                      </div>
                    </td>
                    <td>{statusButton}</td>
                    <td>{deleteButton}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </ModalComponent>
    );

    const modalContentDatasets = this.state.modalViewDatasets && (
      <ModalComponent
        modalSize="lg"
        id="modal"
        isOpen={this.state.modalViewDatasets}
        onClose={this.toggleViewDatasets.bind(
          this,
          this.state.items,
          this.state.folder_item
        )}
      >
        <div className="box-body">
          <DatasetsFolderRender
            items={this.state.items}
            folder_item={this.state.folder_item}
          />
        </div>
      </ModalComponent>
    );

    const itemContent = (
      <div style={{ textAlign: "center" }}>
        <Helmet>
          <title>{`${group.name ? group.name : "Group"} | IOT MAPID`}</title>
          <meta name="description" content={group.name} />
        </Helmet>
        <h1>{group.name}</h1>
        {membersContent}
        <br />
        <div
          className="buttonSimple2"
          style={{
            marginTop: "5px",
            cursor: "pointer",
            marginBottom: "10px",
          }}
          onClick={this.toggle}
        >
          New Folder
        </div>
        {foldersContent}
      </div>
    );
    return (
      <div className="dashboardSpecial">
        {itemContent}
        {modal}
        {modalContentDatasets}
        {modalMembersContent}
        {modalDeleteMemberContent}
      </div>
    );
  }
}
GroupDetail.propTypes = {
  createFolderGroup: PropTypes.func.isRequired,
  getFoldersByGroup: PropTypes.func.isRequired,
  getGroup: PropTypes.func.isRequired,
  getItemsByGroup: PropTypes.func.isRequired,
  deleteMember: PropTypes.func.isRequired,
  editMember: PropTypes.func.isRequired,
  editGroup: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  item: state.item,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  createFolderGroup,
  getFoldersByGroup,
  getGroup,
  getItemsByGroup,
  deleteMember,
  editMember,
  editGroup,
})(GroupDetail);
