import React, { Component } from "react";
import { connect } from "react-redux";
import Chart from "./Chart";
import Cc from "./Cc";
import { Link } from "react-router-dom";
import Comment from "../public/Comment";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/styles";
import {
  Switch,
  Typography,
  FormControlLabel,
  Grid,
  Button,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import ImportExportIcon from "@material-ui/icons/ImportExport";

const styles = (theme) => ({
  button: {
    backgroundColor: "#2196f3",
    marginRight: 10,
    width: "100%",
    "&:hover": {
      background: "#1e5e96",
    },
  },
  href: {
    color: "#fff",
    textDecoration: "none",
    "&:link": {
      textDecoration: "none",
    },
    "&:hover": {
      textDecoration: "none",
    },
    "&:visited": {
      textDecoration: "none",
    },
    "&:active": {
      textDecoration: "none",
    },
  },
});

class Main extends Component {
  state = {
    realtime: false,
    status: "",
    id: "",

    isToyo: false,
  };

  // toggleRealtime = () => {
  //   this.setState((prevState) => {
  //     return { realtime: !prevState.realtime };
  //   });
  // };

  componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      this.setState({ id: this.props.match.params.id });
    } else {
      this.setState({ id: this.props.id });
    }
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.status
    ) {
      this.setState({ status: this.props.match.params.status });
    } else {
      this.setState({ status: this.props.status });
    }

    const domain = ["toyo.mapid.io", "localhost"];
    if (domain.includes(window.location.hostname)) {
      this.setState({ isToyo: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.state)
  }

  toggleRealtime = () => {
    let togRealtime = this.state.realtime;
    if (togRealtime) {
      this.setState({ realtime: false });
      // console.log(false)
    } else {
      this.setState({ realtime: true });
      // console.log(true)
    }
    // this.setState({
    //   realtime: togRealtime
    // });
  };

  render() {
    const { item } = this.props.item;
    const { classes } = this.props;

    let itemContent;
    itemContent = (
      <div>
        <div className="dashboardSpecial">
          <Grid container spacing={1}>
            <Grid item xl={1}>
              <Link
                to={`/log/${
                  this.props.match &&
                  this.props.match.params &&
                  this.props.match.params.id
                    ? this.props.match.params.id
                    : this.props.id
                }`}
                // target="_blank"
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<EqualizerIcon />}
                >
                  Log Data
                </Button>
              </Link>
            </Grid>
            <Grid item xl={1}>
              {/* {this.state.status === "private" ? (
                <Link
                  className="btn buttonSimple2"
                  to={`/edit_doc_iot/report/${
                    item && item.folder && item.folder._id
                      ? item.folder._id
                      : item._id
                  }/${item._id}`}
                >
                  Edit Doc
                </Link>
              ) : null} */}
              {this.state.status === "private" && !this.state.isToyo ? (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<EditIcon />}
                >
                  {/* Edit Doc */}
                  <Link
                    // className="btn buttonSimple2"
                    className={classes.href}
                    to={`/edit_doc_iot/report/${
                      item && item.folder && item.folder._id
                        ? item.folder._id
                        : item._id
                    }/${item._id}`}
                  >
                    Edit Doc
                  </Link>
                </Button>
              ) : null}
            </Grid>
            <Grid item xl={1}>
              {/* <Link
                className="btn buttonSimple2"
                to={`/view_doc_iot/report/${item._id}`}
              >
                View Doc
              </Link> */}

              {!this.state.isToyo && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<DescriptionIcon />}
                  onClick={this.toggle}
                >
                  <Link
                    className={classes.href}
                    to={`/view_doc_iot/report/${item._id}`}
                  >
                    View Doc
                  </Link>
                </Button>
              )}
            </Grid>
            <Grid item xl={1}>
              {/* <Link
                className="btn buttonSimple2"
                to={`/view_doc_iot/report/${item._id}`}
              >
                View Doc
              </Link> */}

              {!this.state.isToyo && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<ImportExportIcon />}
                  onClick={this.toggle}
                >
                  COMMAND
                </Button>
              )}
            </Grid>
            <Grid item xl={1}>
              {!this.state.isToyo && (
                <div style={{ display: "flex", marginLeft: 10 }}>
                  <Typography variant="h6">Realtime</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.realtime}
                        onChange={this.toggleRealtime}
                        name="checkedA"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                    label={`${this.state.realtime ? "ON" : "OFF"}`}
                    style={{ marginLeft: 2 }}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </div>

        {this.state.realtime ? (
          <Cc
            id={
              this.props.match &&
              this.props.match.params &&
              this.props.match.params.id
                ? this.props.match.params.id
                : this.props.id
            }
            status={
              this.props.match &&
              this.props.match.params &&
              this.props.match.params.status
                ? this.props.match.params.status
                : this.props.status
            }
          />
        ) : (
          <Chart
            id={
              this.props.match &&
              this.props.match.params &&
              this.props.match.params.id
                ? this.props.match.params.id
                : this.props.id
            }
            status={
              this.props.match &&
              this.props.match.params &&
              this.props.match.params.status
                ? this.props.match.params.status
                : this.props.status
            }
          />
        )}
        {/* <TableData /> */}
        <Comment
          id={
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.id
              ? this.props.match.params.id
              : this.props.id
          }
          status={
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.status
              ? this.props.match.params.status
              : this.props.status
          }
        />
      </div>
    );
    return <div>{itemContent}</div>;
  }
}
Main.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  item: state.item,
});
export default connect(mapStateToProps, {})(withStyles(styles)(Main));
