/* eslint-disable */
import React, { Component } from "react";
class Counter extends Component {
  state = {
    value: 0,
    donut1dasharray: 0,
    shown_number: 0,
    dasharray: 0,
    janganDiganggu: false
  };
  componentDidMount() {
    const value = parseFloat(this.props.value);
    const nMin = this.props.nMin;
    const nMax = this.props.nMax;
    if (this.state.janganDiganggu) {
      return null;
    } else {
      if (!value || value === null || value === undefined) {
        // console.log("kasus kosong");
        this.setState({ donut1dasharray: 0, shown_number: 0, dasharray: 0 });
      } else {
        if (value <= nMin) {
          console.log("kasus minus");
          this.setState({
            donut1dasharray: nMin,
            shown_number: value,
            dasharray: nMin
          });
        } else if (value >= nMax) {
          console.log("kasus melebihi");
          this.setState({
            donut1dasharray: nMax,
            shown_number: value,
            dasharray: nMax
          });
        } else {
          if (value !== this.state.shown_number) {
            // console.log("update receive");
            this.setState({ janganDiganggu: true });
            var start = this.state.donut1dasharray;
            var end = value; //value 0-50
            var range = end - start;
            var duration = 100;
            var incBesar = Math.abs(range) / 100;
            var incKecil = Math.abs(range) / 4;
            var increment;
            if (Math.abs(range) > 5) {
              increment = end > start ? incBesar : -incBesar;
            } else {
              increment = end > start ? incKecil : -incKecil;
            }
            var stepTime = Math.abs(Math.floor(duration / range));
            this.timer = setInterval(() => {
              this.setState(prevState => ({
                donut1dasharray: prevState.donut1dasharray + increment,
                dasharray: parseFloat(
                  prevState.donut1dasharray + increment
                ).toFixed(1),
                shown_number: parseFloat(
                  prevState.donut1dasharray + increment
                ).toFixed(2),
                janganDiganggu: true
              }));
              var current = this.state.donut1dasharray;
              var selisih = Math.abs(end - current);
              // console.log("proses");
              // console.log("selisih", selisih);
              // console.log("increment", increment);
              // console.log("jangan diganggu", this.state.janganDiganggu);
              //console.log("state", this.state.donut1dasharray);
              if (selisih < 0.01) {
                this.setState({ janganDiganggu: false });
                selisih = 0;
                // console.log("done");
                // console.log("selisih akhir", selisih);
                // console.log("donut1dasharray", this.state.donut1dasharray);
                // console.log("shown_number", this.state.shown_number);
                // console.log("jangan diganggu", this.state.janganDiganggu);
                clearInterval(this.timer);
              }
            }, stepTime);
          } else {
            // console.log("something happen");
            // console.log("done");
            this.setState(prevState => ({
              donut1dasharray: prevState.donut1dasharray,
              shown_number: prevState.shown_number,
              dasharray: prevState.dasharray,
              janganDiganggu: false
            }));
          }
        }
      }
    }
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      const value = parseFloat(this.props.value);
      const nMin = this.props.nMin;
      const nMax = this.props.nMax;
      if (this.state.janganDiganggu) {
        return null;
      } else {
        if (!value || value === null || value === undefined) {
          // console.log("kasus kosong");
          this.setState({ donut1dasharray: 0, shown_number: 0, dasharray: 0 });
        } else {
          if (value < nMin) {
            // console.log("kasus minus");
            this.setState({
              donut1dasharray: nMin,
              shown_number: value,
              dasharray: nMin
            });
          } else if (value > nMax) {
            // console.log("kasus melebihi");
            this.setState({
              donut1dasharray: nMax,
              shown_number: value,
              dasharray: nMax
            });
          } else {
            if (value !== this.state.shown_number) {
              this.setState({ janganDiganggu: true });
              var start = this.state.donut1dasharray;
              var end = value; //value 0-50
              var range = end - start;
              var duration = 100;
              var incBesar = Math.abs(range) / 100;
              var incKecil = Math.abs(range) / 4;
              var increment;
              if (Math.abs(range) > 5) {
                increment = end > start ? incBesar : -incBesar;
              } else {
                increment = end > start ? incKecil : -incKecil;
              }
              var stepTime = Math.abs(Math.floor(duration / range));
              this.timer = setInterval(() => {
                this.setState(prevState => ({
                  donut1dasharray: prevState.donut1dasharray + increment,
                  dasharray: parseFloat(
                    prevState.donut1dasharray + increment
                  ).toFixed(1),
                  shown_number: parseFloat(
                    prevState.donut1dasharray + increment
                  ).toFixed(2),
                  janganDiganggu: true
                }));
                var current = this.state.donut1dasharray;
                var selisih = Math.abs(end - current);
                // console.log("proses");
                // console.log("selisih", selisih);
                // console.log("increment", increment);
                // console.log("jangan diganggu", this.state.janganDiganggu);
                //console.log("state", this.state.donut1dasharray);
                if (selisih < 0.01) {
                  this.setState({ janganDiganggu: false });
                  selisih = 0;
                  // console.log("done");
                  // console.log("selisih akhir", selisih);
                  // console.log("donut1dasharray", this.state.donut1dasharray);
                  // console.log("shown_number", this.state.shown_number);
                  // console.log("jangan diganggu", this.state.janganDiganggu);
                  clearInterval(this.timer);
                }
              }, stepTime);
            } else {
              // console.log("done");
              this.setState(prevState => ({
                donut1dasharray: prevState.donut1dasharray,
                shown_number: prevState.shown_number,
                dasharray: prevState.dasharray,
                janganDiganggu: false
              }));
            }
          }
        }
      }
    } else return null;
  }
  render() {
    var { shown_number, dasharray } = this.state;
    var { nMin, nMax, unit } = this.props;
    const keliling = 50;
    var mapValue = 0.75 * keliling * ((dasharray - nMin) / (nMax - nMin));
    // console.log(dasharray, mapValue);
    //RGB Color
    const rMin = 21;
    const rMax = 211;
    const gMin = 175;
    const gMax = 64;
    const bMin = 255;
    const bMax = 85;
    function colorMap(min, max) {
      return min + ((max - min) / (nMax - nMin)) * (dasharray - nMin);
    }
    var red = colorMap(rMin, rMax);
    var green = colorMap(gMin, gMax);
    var blue = colorMap(bMin, bMax);
    var valuesArray = [];
    for (let i = 0; i <= 12; i++) {
      valuesArray.push({
        value: parseInt(nMin + ((12 - i) / 12) * (nMax - nMin)),
        rotation: 22.5 * i
      });
    }
    var donut1 = (
      <div className="text-center">
        <svg
          width={this.props.width ? this.props.width : "100%"}
          height={this.props.width ? this.props.width : "100%"}
          viewBox="0 0 20 20"
          className="donut"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="background"
            cx="10"
            cy="10"
            r={10}
            fill="#d4d6ccff"
          />
          <circle
            className="penambal"
            cx="10"
            cy="10"
            r={6.366}
            fill="transparent"
            stroke="#2f483bff"
            strokeWidth={(10 - 6.366) * 0.6}
            strokeLinecap="none"
            strokeDasharray={[0.8 * 40, 0.2 * 40]}
            strokeDashoffset={-(0.7 * 40 * 0.5)}
          />
          <circle
            className="donutRing"
            cx="10"
            cy="10"
            r={7.957}
            fill="transparent"
            stroke="#2f483bff"
            strokeWidth={(10 - 7.957) * 1.5}
            strokeLinecap="none"
            strokeDasharray={[0.8 * 50, 0.2 * 50]}
            strokeDashoffset={-(0.7 * 50 * 0.5)}
          />
          <circle
            className="kosong"
            cx="10"
            cy="10"
            r={7.957}
            fill="transparent"
            strokeWidth={1.5}
            strokeLinecap="none"
            strokeDasharray={`${0.75 * 50},${50 - 0.75 * 50} `}
            strokeDashoffset={-(0.75 * 50 * 0.5)}
            stroke="#d4d6ccff"
          />
          {/* Keliling lingkaran = 50 */}
          <circle
            className="donutActive"
            cx="10"
            cy="10"
            r="7.957"
            fill="transparent"
            strokeWidth={1.5}
            strokeLinecap="none"
            strokeDasharray={`${mapValue},${50 - mapValue} `}
            strokeDashoffset={-(0.75 * 50 * 0.5)}
            stroke={`rgb(${red},${green}, ${blue}) `}
          />
          {valuesArray.map(({ value, rotation }, idx) => {
            return (
              <text
                key={`gauge-${idx}`}
                className={idx}
                x="14.5"
                y="14.8"
                textAnchor="middle"
                fontFamily="Arial"
                fontSize={0.9}
                fill="#d4d6ccff"
                fontStyle="normal"
                transform={`rotate(-${rotation} 5.5 5.5) rotate(${rotation} 10 10)  `}
              >
                {value}
              </text>
            );
          })}
          <text
            className="shown_number"
            x={10}
            y={11.5}
            textAnchor="middle"
            fontFamily="Arial"
            fontSize="4"
            fill="#2f483bff"
            fontStyle="bold"
          >
            {parseInt(shown_number)}
          </text>
          <text
            x="10"
            y={19 - 3 / 4}
            textAnchor="middle"
            fontFamily="Arial"
            fontSize="1.5"
            fill="#2f483bff"
            fontStyle="normal"
          >
            {unit}
          </text>
        </svg>
      </div>
    );
    return <div>{donut1}</div>;
  }
}
export default Counter;
