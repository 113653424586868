import axios from "axios";
import his from "./history";
import {
  //Dataset
  GET_ITEMS,
  GET_PUBLIC_ITEMS,
  GET_LANDING_ITEMS,
  GET_ITEM,
  GET_ITEM_PUBLIC,
  GET_LOG_ITEM,
  ADD_ITEM,
  UPDATE_ITEM,
  DELETE_ITEM,
  MAP_GEO,
  //Quota
  CLEAR_CURRENT_ITEMS,
  CLEAR_CURRENT_ITEM,
  CLEAR_CURRENT_LOG,
  UPDATE_PUBLIC_ITEM,
} from "./types";
// const SERVER_URL = "http://localhost:4001";
const SERVER_URL = "https://api.mapid.io";
// const SERVER_URL = "http://iotmapidserver.us-west-2.elasticbeanstalk.com";

//POST create group
export const addGroup = (item) => (dispatch) => {
  dispatch(setLoading());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/groups/create", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "ADD_GROUP",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      console.log(err);
    });
};

//GET groups by user
export const getGroups = (user_id) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + `/groups/get_by_member/${user_id}`, config)
    .then((res) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_GROUPS",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingList());
      dispatch({
        type: "GET_GROUPS",
        payload: [],
      });
    });
};

//DELETE group
export const deleteGroup = (content) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .delete(
      SERVER_URL + `/groups/${content.group_id}/${content.user_id}`,
      config
    )
    .then((res) =>
      dispatch({
        type: "DELETE_GROUP",
        payload: content.group_id,
      })
    )
    .catch((err) => {
      console.log(err);
    });
};

//EDIT group
export const editGroup = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/groups/edit", item, config)
    .then((res) => {
      dispatch({
        type: "GET_GROUPS",
        payload: res.data,
      });
    })
    .catch((err) => {});
};

//SEARCH user
export const searchUser = (search) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .get(SERVER_URL + `/groups/search_user/${search}`, config)
    .then((res) => {
      dispatch({
        type: "SEARCH_USERS",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: "SEARCH_USERS",
        payload: [],
      });
    });
};

//ADD member to group
export const addMember = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/groups/add_member", item, config)
    .then((res) => {
      dispatch({
        type: "GET_GROUPS",
        payload: res.data,
      });
    })
    .catch((err) => {});
};

//DELETE member from group
export const deleteMember = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/groups/delete_member", item, config)
    .then((res) => {
      dispatch({
        type: "GET_GROUP",
        payload: res.data,
      });
    })
    .catch((err) => {});
};

//EDIT member in a group
export const editMember = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/groups/edit_member", item, config)
    .then((res) => {
      dispatch({
        type: "GET_GROUP",
        payload: res.data,
      });
    })
    .catch((err) => {});
};

//CREATE folder on the group
export const createFolderGroup = (item) => (dispatch) => {
  dispatch(setLoading());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/folders/create_folder_group", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "CREATE_FOLDER_GROUP",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//CREATE folder on the user
export const createFolderUser = (item) => (dispatch) => {
  dispatch(setLoading());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/folders/create_folder_user", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "CREATE_FOLDER_USER",
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch(clearLoading());
    });
};

//GET group by _id confirm the user is the member of the group
export const getGroup = (content) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .get(SERVER_URL + `/groups/${content.group_id}/${content.user_id}`, config)
    .then((res) =>
      dispatch({
        type: "GET_GROUP",
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: "GET_GROUP",
        payload: [],
      });
    });
};

//GET folder by group
export const getFoldersByGroup = (group_id) => (dispatch) => {
  dispatch(setLoadingContent());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .get(SERVER_URL + `/folders/get_by_group/${group_id}`, config)
    .then((res) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: "GET_FOLDERS_BY_GROUP",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: "GET_FOLDERS_BY_GROUP",
        payload: [],
      });
    });
};

//GET items by group
export const getItemsByGroup = (group_id) => (dispatch) => {
  dispatch(setLoadingContent());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .get(SERVER_URL + `/items/get_by_group/${group_id}`, config)
    .then((res) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: "GET_ITEMS_BY_GROUP",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: "GET_ITEMS_BY_GROUP",
        payload: [],
      });
    });
};

//GET folder by user
export const getFoldersByUser = (user_id) => (dispatch) => {
  dispatch(setLoadingContent());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .get(SERVER_URL + `/folders/get_by_user/${user_id}`, config)
    .then((res) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: "GET_FOLDERS_BY_USER",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: "GET_FOLDERS_BY_USER",
        payload: [],
      });
    });
};

//GET data for itemList
export const getItems = () => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + "/items", config)
    .then((res) => {
      dispatch(clearLoadingList());
      dispatch({
        type: GET_ITEMS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingList());
      dispatch({
        type: GET_ITEM,
        payload: null,
      });
    });
};

export const searchBus = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoading());
  axios
    .post(SERVER_URL + "/items/searchbus", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: GET_ITEMS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ITEM,
        payload: null,
      })
    );
};

//GET data for public item
export const getPublicItems = () => (dispatch) => {
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + "/items/public")
    .then((res) => {
      dispatch(clearLoadingList());
      dispatch({
        type: GET_PUBLIC_ITEMS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingList());
      dispatch({
        type: GET_PUBLIC_ITEMS,
        payload: [],
      });
    });
};

//GET data for public item
export const getLandingItems = () => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(SERVER_URL + "/items/landing")
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: GET_LANDING_ITEMS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: GET_LANDING_ITEMS,
        payload: [],
      });
    });
};

//GET data for private chart
export const getItem = (id) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingContent());
  axios
    .get(SERVER_URL + `/items/${id}`, config)
    .then((res) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: GET_ITEM,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: GET_ITEM,
        payload: "",
      });
    });
};

//GET data for public chart
export const getItemPublic = (id) => (dispatch) => {
  dispatch(setLoadingContent());
  axios
    .get(SERVER_URL + `/items/public/${id}`)
    .then((res) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: GET_ITEM_PUBLIC,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoadingContent());
      dispatch({
        type: GET_ITEM_PUBLIC,
        payload: null,
      });
    });
};

//GET data for log chart
export const getLogItem = (id) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoading());
  axios
    .get(SERVER_URL + `/items/log/${id}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: GET_LOG_ITEM,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_LOG_ITEM,
        payload: null,
      })
    );
};

// GET data for new feature dataset
export const getLogNewDataset =
  ({ _id, skip, limit, _var }, append = false) =>
  async (dispatch) => {
    dispatch({
      type: "SET_LOADING_NEW_DATASET",
      payload: true,
    });
    try {
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenMapid,
        },
      };
      let url = `${SERVER_URL}/items/log/${_id}/v2`;
      url += `?page=${skip}&total=${limit}&_var=${_var}`;

      const res = await axios.get(url, config);
      const { log_data, last_data, name, username } = res.data;

      let result = {
        name,
        username,
        log_data: [],
      };
      for (var key of Object.keys(log_data)) {
        const config_sensor = last_data[key].config_sensor;
        const sensorName = log_data[key].name;
        const sensorUnit = log_data[key].unit;
        const sensorData = log_data[key].values.reverse().map((e) => {
          if (config_sensor > 0) {
            return { y: config_sensor - e[0], x: e[1] };
          } else {
            return { y: e[0], x: e[1] };
          }
        });
        result.log_data.push({
          config_sensor,
          sensorData,
          sensorName,
          sensorUnit,
        });
      }

      if (append) {
        dispatch({
          type: "GET_LOG_NEW_DATASET_APPEN",
          payload: result,
        });
      } else {
        dispatch({
          type: "GET_LOG_NEW_DATASET",
          payload: result,
        });
      }
      dispatch({
        type: "SET_LOAD_MORE_NEW_DATASET",
        payload: false,
      });
      dispatch({
        type: "SET_LOADING_NEW_DATASET",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING_NEW_DATASET",
        payload: false,
      });
      dispatch({
        type: "SET_LOAD_MORE_NEW_DATASET",
        payload: false,
      });
    }
  };

//POST add dataset by folder
export const addItemByFolder = (item) => (dispatch) => {
  dispatch(setLoading());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/items/create_by_folder", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "ADD_ITEM",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      console.log(err);
    });
};

//POST add dataset
export const addItem = (item) => (dispatch) => {
  dispatch(setLoading());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/items", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: ADD_ITEM,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());

      console.log(err);
    });
};

//add dataset
export const addDamri = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/items/damri", item, config)
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: "ADD_DAMRI",
        payload: res.data,
      });
    })
    .catch((err) => {
      // console.log("gagal", err);
      dispatch({
        type: "ADD_DAMRI",
        payload: null,
      });
    });
};

//edit dataset
export const updateItem = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/items/update", item, config)
    .then((res) => {
      dispatch({
        type: UPDATE_ITEM,
        payload: res.data,
      });
      his.push("/dashboard");
      // console.log(res);
    })
    .catch((err) => {
      // console.log(err);
    });
};

//delete all data var
export const clearAllData = (item) => {
  return new Promise((resolve) => {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
      data: {
        _var: item[0],
      },
    };
    axios
      .delete(SERVER_URL + "/items/all/" + item[1], config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        resolve(err);
      });
  });
};

//delete singel data
export const deleteSingle = (item) => {
  return new Promise((resolve) => {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
      data: {
        value: item.dat,
        time: item.time,
        _var: item.vaar,
      },
    };
    axios
      .delete(SERVER_URL + "/items/single/" + item.id, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        resolve(err);
      });
  });
};

//public dataset
export const updatePublicItem = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/items/updatepublic", item, config)
    .then((res) => {
      dispatch({
        type: UPDATE_PUBLIC_ITEM,
        payload: res.data,
      });
      his.push(`/chart/private/${item.id}`);
    })
    .catch((err) => {
      console.log(err);
    });
};

//public dataset
export const subcribeDevice = (item) => (dispatch) => {
  dispatch(setLoading());
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/items/subscribe", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: ADD_ITEM,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      console.log(err);
    });
};

//upload IoT Device picture
export const uploadDevicePicture = (picture_data) => {
  const config_axos = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/items/upload", picture_data, config_axos)
    .then((res) => {
      his.push(`/chart/private/${picture_data.id}`);
      console.log("axios success: ", res);
    })
    .catch((err) => {
      console.log("axios error: ", err);
      console.log("picture data : ", picture_data);
    });
};

//API to GEO
export const mapGeo = (item) => (dispatch) => {
  axios
    .post(
      "https://jok5ivt3r4.execute-api.us-west-2.amazonaws.com/mapidiotdev/insertsensorlayer",
      item
    )
    .then((res) =>
      dispatch({
        type: MAP_GEO,
        payload: res.data,
      })
    );
};

//delete dataset
export const deleteItem = (id) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .delete(SERVER_URL + `/items/${id}`, config)
    .then((res) =>
      dispatch({
        type: DELETE_ITEM,
        payload: id,
      })
    )
    .catch((err) => {
      // dispatch({
      //   type: ADD_ITEM,
      //   payload: null
      // });

      console.log(err);
      // console.log(item);
    });
};

//delete ALLLL
export const deleteAll = () => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + `/items/deletebatch`, {}, config)
    .then((res) => {
      dispatch({
        type: GET_ITEMS,
        payload: [],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

//Action send data to machine
export const sendToMachine = (item) => (dispatch) => {
  axios
    .get(
      SERVER_URL +
        `/api/update?key=${item.write_key}&${item.property}=${item.value}`
    )
    .then((res) =>
      dispatch({
        type: "MACHINE",
        payload: res.data,
      })
    )
    .catch((err) => console.log(err));
};

//Get count data
export const getCount = () => (dispatch) => {
  axios
    .get(SERVER_URL + "/items/count")
    .then((res) => {
      dispatch({
        type: "GET_COUNT",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: "GET_COUNT",
        payload: { iots: 101, users: 321, projects: 4 },
      });
      console.log(err);
    });
};

// new fitur
// let baseUrl = "https://api.mapid.io";
// let baseUrl = "http://localhost:4000";
export const getLogV2 = (param) => {
  return new Promise((resolve) => {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    // resolve(param.id)
    let url = `${SERVER_URL}/items/log/${param.id}/v2`;
    url += `?page=${param.page}&total=${param.total}&_var=${param.var}`;

    axios
      .get(url, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const getLogAll = (param) => {
  return new Promise((resolve) => {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    // resolve(param.id)
    let url = `${SERVER_URL}/items/log/${param.id}`;

    axios
      .get(url, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

// Set loading state
export const setLoading = () => {
  return {
    type: "SET_LOADING",
  };
};

// Set loading state
export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING",
  };
};

//admin loading
export const setLoadingContent = () => {
  return {
    type: "SET_DATASET_CONTENT_LOADING",
  };
};
//admin clear loading
export const clearLoadingContent = () => {
  return {
    type: "CLEAR_DATASET_CONTENT_LOADING",
  };
};

//admin loading
export const setLoadingList = () => {
  return {
    type: "SET_DATASET_LIST_LOADING",
  };
};
//admin clear loading
export const clearLoadingList = () => {
  return {
    type: "CLEAR_DATASET_LIST_LOADING",
  };
};

// Clear items
export const clearCurrentItems = () => {
  return {
    type: CLEAR_CURRENT_ITEMS,
  };
};

// Clear item
export const clearCurrentItem = () => {
  return {
    type: CLEAR_CURRENT_ITEM,
  };
};

// Clear log
export const clearCurrentLog = () => {
  return {
    type: CLEAR_CURRENT_LOG,
  };
};

// load more new dataset
export const load_more_new_dataset = (param) => {
  return {
    type: "SET_LOAD_MORE_NEW_DATASET",
    payload: param,
  };
};

// kalman filter new dataset
export const kalman_filter = () => {
  return {
    type: "KALMAN_FILTER",
  };
};

// clear outlier
export const clear_outlier = () => {
  return {
    type: "CLEAR_OUILIER",
  };
};

// clear outlier
export const toyo_filter = () => {
  return {
    type: "TOYO_FILTER",
  };
};
