import {
  PAYMENT_LOADING,
  CLEAR_PAYMENT_LOADING,
  GET_DOCS,
  ADD_HELP
} from "../actions/types";
const initialState = {
  add_idea_loading: false,
  loading: false,
  docs: [],
  ideas: [],
  help: ""
};
export default function(state = initialState, action) {
  switch (action.type) {
    case "IDEA":
      return {
        ...state,
        ideas: action.payload
      };
    case PAYMENT_LOADING:
      return {
        ...state,
        loading: true
      };
    case CLEAR_PAYMENT_LOADING:
      return {
        ...state,
        loading: false
      };
    case "SET_ADD_IDEA_LOADING":
      return {
        ...state,
        add_idea_loading: true
      };
    case "CLEAR_ADD_IDEA_LOADING":
      return {
        ...state,
        add_idea_loading: false
      };
    case GET_DOCS:
      return {
        ...state,
        docs: action.payload
      };
    case ADD_HELP:
      return {
        ...state,
        help: action.payload
      };
    default:
      return state;
  }
}
