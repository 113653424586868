import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { deleteItem } from "../../actions/itemActions";
class DatasetsFolderRender extends Component {
  state = {
    copied: false,
    copied_req: false,
    modal: false,
    modal_editor: false,
    modal_delete: false,
    modal_write: false,
    modal_tools: false,
    modal_qrcode: false,
    currentId: "",
    currentName: "",
    name: "Dataset",
    logData: [
      { name: "Latitude", unit: "degree", values: [] },
      { name: "Longitude", unit: "degree", values: [] },
      { name: "Sensor1", unit: "unit", values: [] },
    ],
    currentIndex: 0,
    index: "",
    arrayUser: [],
  };
  toggle_editor = () => {
    this.setState({
      modal_editor: !this.state.modal_editor,
    });
  };
  handleCopied = () => {
    this.setState({
      copied: true,
    });
    setTimeout(
      function () {
        this.setState({ copied: false });
      }.bind(this),
      3000
    );
  };
  handleCopiedReq = () => {
    this.setState({
      copied_req: true,
    });

    setTimeout(
      function () {
        this.setState({ copied_req: false });
      }.bind(this),
      3000
    );
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  handleClick = () => {
    this.setState({
      isClicked: !this.state.isClicked,
    });
    this.toggle();
  };
  toggle_delete = (id, name) => {
    this.setState({ currentId: id, currentName: name }, () => {
      this.setState({
        modal_delete: !this.state.modal_delete,
      });
    });
  };
  toggle_write = (id, name, idx) => {
    this.setState(
      { currentId: id, currentName: name, currentIndex: idx },
      () => {
        this.setState({
          modal_write: !this.state.modal_write,
        });
      }
    );
  };
  toggle_qrcode = (index) => {
    this.setState(
      {
        index: index,
      },
      () => {
        this.setState({ modal_qrcode: !this.state.modal_qrcode });
      }
    );
  };
  toggle_user = (users) => {
    this.setState(
      {
        arrayUser: users,
      },
      () => {
        this.setState({ modal_user: !this.state.modal_user });
      }
    );
  };
  onDeleteClick(id) {
    this.props.deleteItem(id);
    this.toggle_delete();
  }
  render() {
    const { items, folder_item, auth } = this.props;
    const user = auth.user;
    let members;
    let member;
    let statusUser;
    let permission = "restrict";
    if (folder_item.owner_type === "group") {
      members = folder_item.group.members;
      member = members.filter(
        (member) =>
          (member.user._id ? member.user._id : member.user) === user._id
      )[0];
      statusUser = member.status;
      permission = folder_item.group.permission
        ? folder_item.group.permission
        : "restrict";
    } else {
      statusUser = "admin";
    }
    const editContent =
      statusUser === "creator" ||
      statusUser === "admin" ||
      permission === "open" ? (
        <Link
          className="btn buttonSimple2"
          to={`/edit_doc_iot/task/${folder_item._id}`}
        >
          Edit Document
        </Link>
      ) : null;
    const itemsContent = (
      <div>
        <div style={{ textAlign: "center" }}>
          <div className="welcome" style={{ textAlign: "center" }}>
            {folder_item.name}
          </div>
          {editContent}
          <Link
            className="btn buttonSimple2"
            to={`/view_doc_iot/task/${folder_item._id}`}
          >
            View Document
          </Link>
        </div>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <th style={{ fontSize: "min(2.5vw, 13px)" }} valign="top">
                Name
              </th>
              <th style={{ fontSize: "min(2.5vw, 13px)" }} valign="top">
                Owner
              </th>
              <th style={{ fontSize: "min(2.5vw, 13px)" }} valign="top">
                Created at
              </th>
              <th style={{ fontSize: "min(2.5vw, 13px)" }} valign="top">
                Last data
              </th>
            </tr>

            {items.map(
              (
                {
                  _id,
                  name,
                  sub_users_id,
                  last_entry_at,
                  created_at,
                  user,
                  folder,
                },
                idx
              ) => {
                const user_id_current = this.props.auth.user._id;
                const sub_users_id_filtered = sub_users_id.filter(
                  (userSub) => userSub._id === user_id_current
                );
                let firstOwner;
                let personalContent;
                if (
                  sub_users_id.length > 0 &&
                  sub_users_id_filtered.length === 0
                ) {
                  const firstUser = sub_users_id[0];
                  const username = firstUser.full_name
                    ? firstUser.full_name
                    : firstUser.name;
                  firstOwner = username;
                  if (
                    statusUser === "creator" ||
                    statusUser === "admin" ||
                    permission === "open"
                  ) {
                    personalContent = (
                      <div>
                        <Link
                          className="buttonShadowSmall"
                          to={`/view_doc_iot/report/${_id}`}
                        >
                          View Doc
                        </Link>
                        <br />
                        <Link
                          className="buttonShadowSmall"
                          to={`/edit_doc_iot/report/${folder}/${_id}`}
                        >
                          Edit Doc
                        </Link>
                        <br />
                        <Link
                          className="buttonShadowSmall"
                          to={`/chart/private/${_id}`}
                        >
                          Chart
                        </Link>
                      </div>
                    );
                  } else {
                    personalContent = null;
                  }
                } else {
                  firstOwner = "me";
                  personalContent = (
                    <div>
                      <Link
                        className="buttonShadowSmall"
                        to={`/view_doc_iot/report/${_id}`}
                      >
                        View Doc
                      </Link>
                      <br />
                      <Link
                        className="buttonShadowSmall"
                        to={`/edit_doc_iot/report/${folder}/${_id}`}
                      >
                        Edit Doc
                      </Link>
                      <br />
                      <Link
                        className="buttonShadowSmall"
                        to={`/chart/private/${_id}`}
                      >
                        Chart
                      </Link>
                    </div>
                  );
                }
                return (
                  <tr key={_id} style={{ marginBottom: "100px" }}>
                    <td valign="top">
                      <div style={{ maxWidth: "35vw" }}>
                        <b>{name}</b>
                        <br />
                        {personalContent}
                        <br />
                      </div>
                    </td>
                    <td valign="top">{firstOwner}</td>
                    <td valign="top">{moment(created_at).from()}</td>
                    <td valign="top">
                      {last_entry_at ? moment(last_entry_at).from() : "No data"}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    );
    return <div>{itemsContent}</div>;
  }
}
DatasetsFolderRender.propTypes = {
  deleteItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  item: state.item,
  auth: state.auth,
});
export default connect(mapStateToProps, { deleteItem })(DatasetsFolderRender);
