import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import RichEditorNew from "../common/RichEditorNew";
import { getBracket, getBracketPictureList } from "../../actions/editorAction";

class CreateDocument extends Component {
  componentDidMount() {
    if (!this.props.editor.bracket._id) {
      this.props.getBracket(this.props.match.params.bracket_link);
    }
    this.props.getBracketPictureList(this.props.match.params.bracket_link);
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      if (!this.props.editor.bracket._id) {
        this.props.getBracket(this.props.match.params.bracket_link);
      }
      this.props.getBracketPictureList(this.props.match.params.bracket_link);
    }
  }
  render() {
    //props
    const { auth, editor } = this.props;
    const isAuthenticated = auth.isAuthenticated;
    const { loadingContent, bracket } = editor;
    const authContent = <RichEditorNew id={bracket._id} isFromIOT={false} />;
    const guestContent = <div>Guest</div>;
    const itemContent =
      isAuthenticated && !loadingContent ? authContent : guestContent;
    return (
      <div>
        <Helmet>
          <title>Create Doc | IOT MAPID</title>
          <meta name="description" content="Create Doc | IOT MAPID" />
        </Helmet>
        {itemContent}
      </div>
    );
  }
}

CreateDocument.propTypes = {
  getBracket: PropTypes.func.isRequired,
  getBracketPictureList: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  editor: state.editor,
});
export default connect(mapStateToProps, {
  getBracket,
  getBracketPictureList,
})(CreateDocument);
