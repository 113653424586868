import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import QrReader from "react-qr-reader";

import { subcribeDevice } from "../../actions/itemActions";
import SpinnerSimpleBlue from "../common/SpinnerSimpleBlue";
class SubscribeModal extends Component {
  state = {
    index: "",
    result: ""
  };
  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleScan = data => {
    if (data && !this.state.result) {
      this.setState(
        {
          result: data,
          index: data
        },
        () => {
          setTimeout(this.handleSubmitWithoutEvent, 3000);
        }
      );
    }
  };
  handleError = err => {
    console.error(err);
  };
  handleSubmit = e => {
    e.preventDefault();
    const auth = this.props.auth;
    const user_id = auth && auth.user && auth.user._id ? auth.user._id : "";
    const index = this.state.index;
    const newItem = {
      index: index,
      user_id: user_id
    };
    this.props.subcribeDevice(newItem);
  };
  handleSubmitWithoutEvent = () => {
    const auth = this.props.auth;
    const user_id = auth && auth.user && auth.user._id ? auth.user._id : "";
    const index = this.state.index;
    const newItem = {
      index: index,
      user_id: user_id
    };
    this.props.subcribeDevice(newItem);
  };
  render() {
    const { index } = this.state;
    const { loading } = this.props.item;
    var iconContent;
    if (loading || this.state.result) {
      iconContent = (
        <div>
          <SpinnerSimpleBlue
            width={48}
            unik="loading-qr-code-1"
            marginTop="30px"
          />
          <br />
          Finding Device
        </div>
      );
    } else {
      iconContent = (
        <button
          className="btn buttonSimple2"
          type="submit"
          style={{ marginTop: "30px", fontSize: "20px" }}
        >
          Find
        </button>
      );
    }
    const itemContent = (
      <div>
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: "100%" }}
        />
        <p>{this.state.result}</p>
        <form noValidate onSubmit={this.handleSubmit}>
          <input
            type="text"
            id="index"
            value={index}
            className="form-control"
            onChange={this.handleChange}
          />
          <div>{iconContent}</div>
        </form>
      </div>
    );

    return <div>{itemContent}</div>;
  }
}
SubscribeModal.propTypes = {
  subcribeDevice: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  item: state.item,
  auth: state.auth
});
export default connect(mapStateToProps, { subcribeDevice })(SubscribeModal);
