import { combineReducers } from "redux";
import authReducer from "./authReducer";
import adminReducer from "./adminReducer";
import itemReducer from "./itemReducer";
import errorReducer from "./errorReducer";
import paymentReducer from "./paymentReducer";
import commentReducer from "./commentReducer";
import editorReducer from "./editorReducer";
import configReducer from "./configReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  item: itemReducer,
  payment: paymentReducer,
  admin: adminReducer,
  comment: commentReducer,
  editor: editorReducer,
  config: configReducer,
});
