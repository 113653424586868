const initialState = {
  filterTime: true,
  filterTimeCheckbox: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_FILTER_TIME":
      return {
        ...state,
        filterTime: action.payload,
      };
    case "SET_FILTER_TIME_CHECKBOX":
      return {
        ...state,
        filterTimeCheckbox: action.payload,
      };
    default:
      return state;
  }
}
