import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getVersioningIOT, getEvents } from "../../actions/editorAction";
class VersioningInfo extends Component {
  state = {
    link: this.props.link,
  };
  componentDidMount() {
    this.props.getVersioningIOT();
    this.props.getEvents();
  }
  render() {
    const {
      versioning_iot,
      versioning_local,
      loadingContent,
      events,
    } = this.props.editor;
    let versionContent;
    let versionContentMobile;
    if (loadingContent) {
      versionContent = (
        <div className="check_update">Checking for updates..</div>
      );
      versionContentMobile = (
        <div className="check_update_mobile">Checking for updates..</div>
      );
    } else if (!versioning_iot) {
      versionContent = (
        <div className="network_error">
          Network problem, please refresh your browser in a few moments.
        </div>
      );
      versionContentMobile = (
        <div className="network_error_mobile">
          Network problem, please refresh your browser in a few moments.
        </div>
      );
    } else {
      versionContent =
        versioning_local >= versioning_iot ? (
          <div className="uptodate">Your IOT MAPID is up to date</div>
        ) : (
          <div className="updateAvailable">
            Updates available, please close the tab and open IOT MAPID on the
            new tab, or clear site data on IOT MAPID.
          </div>
        );
      versionContentMobile =
        versioning_local >= versioning_iot ? (
          <div className="uptodate_mobile">Your IOT MAPID is up to date</div>
        ) : (
          <div className="updateAvailable_mobile">
            Updates available, please close the tab and open IOT MAPID on the
            new tab, or clear site data on IOT MAPID.
          </div>
        );
    }
    let eventsContent;
    if (events.length > 0) {
      eventsContent = (
        <a
          className="events"
          href={`https://doc.mapid.io/events`}
          target="_blank"
          rel="noopener noreferrer"
        >
          New Event!
        </a>
      );
    } else if (loadingContent) {
      eventsContent = (
        <div className="events_static">Checking for events..</div>
      );
    } else {
      eventsContent = <div className="events_static">No new event</div>;
    }
    return (
      <div
        style={{
          marginTop: "20px",
        }}
      >
        {this.props.width < 600 || this.props.isNav
          ? versionContentMobile
          : versionContent}
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            marginBottom: "20px",
          }}
        >
          {eventsContent}
        </div>
      </div>
    );
  }
}
VersioningInfo.propTypes = {
  editor: PropTypes.object.isRequired,
  getVersioningIOT: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  editor: state.editor,
});
export default connect(mapStateToProps, { getVersioningIOT, getEvents })(
  VersioningInfo
);
