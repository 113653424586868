import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ModalComponent from "../common/ModalComponent";
import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";
import SpinnerSimpleBlue from "../common/SpinnerSimpleBlue";
import QuotaCounter from "../widget/QuotaCounter";
import { isNotif, urlB64ToUint8Array } from "../../registerServiceWorker";
import icon from "../../img/icon-128x128.png";
import badge from "../../img/badge_notification.png";
import image from "../../img/dashboard.png";
import pic_static from "../../img/profile_pic_comment.svg";
import checklist from "../../img/checklist.svg";
import isEqual from "../../validation/is-equal";
import {
  addGroup,
  getGroups,
  deleteGroup,
  editGroup,
  searchUser,
  addMember,
} from "../../actions/itemActions";

function displayNotif() {
  if ("serviceWorker" in navigator) {
    var options = {
      body: "Great! You subscribed to IOTMAPID!",
      icon: icon,
      image: image,
      dir: "ltr",
      lang: "en-US", //BCP 47
      vibrate: [100, 50, 200],
      badge: badge,
      tag: "mapid",
      renotify: true,
      actions: [{ action: "ok", title: "Okay", icon: icon }],
    };
    navigator.serviceWorker.ready.then(function (swreg) {
      swreg.showNotification("Mapid", options);
    });
  }
}
// const SERVER_URL = "http://localhost:4000";
const SERVER_URL = "https://api.mapid.io";
// const SERVER_URL = "http://iotmapidserver.us-west-2.elasticbeanstalk.com";
function configurePushSub() {
  if (!("serviceWorker" in navigator)) {
    return;
  }
  var reg;
  navigator.serviceWorker.ready
    .then(function (swreg) {
      // console.log("swreg=", swreg);
      reg = swreg;
      return swreg.pushManager.getSubscription();
    })
    .then(function (sub) {
      // console.log("sub=", sub);
      if (sub === null) {
        //create a new subs
        var vapidPublicKey =
          "BJweY8HYxau5mgEUWF7F-k0E3S_bLn1px6v9cI28_Cp0lLASaE51OJbnuQpwVlOw1Xx4bva9i4mYTwdJbSLYcb8";
        var convertedKey = urlB64ToUint8Array(vapidPublicKey);
        return reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedKey,
        });
      } else {
        //We already have a subscription
      }
    })
    //kirim ke DB
    .then(function (newSub) {
      // console.log("newSub=", newSub);
      return fetch(SERVER_URL + "/users/sub", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          accesstoken: localStorage.jwtTokenMapid,
        },
        body: JSON.stringify(newSub),
      });
    })
    .then(function (res) {
      if (res.ok) {
        displayNotif();
      }
    })
    .catch(function (err) {
      // console.log(err);
    });
}
class UserDashboard extends Component {
  state = {
    isSupportNotif: isNotif(),
    user: {},
    modal: false,
    modal_delete: false,
    modal_edit: false,
    modal_add_member: false,
    name: "",
    group_id: "",
    group_name: "",
    search_user: "",
    member_id: "",
  };
  toggle_add_member = (group_id) => {
    this.setState({ group_id }, () => {
      this.setState({
        modal_add_member: true,
      });
    });
  };
  onAddMember = (member_id) => {
    this.setState({ member_id, modal_add_member: false }, () => {
      const content = {
        group_id: this.state.group_id,
        member_id: this.state.member_id,
        user_id: this.state.user._id,
      };
      this.props.addMember(content);
    });
  };
  toggle_edit = (group_id, group_name) => {
    this.setState({ group_id, group_name }, () => {
      this.setState({
        modal_edit: !this.state.modal_edit,
        modal_add_member: false,
      });
    });
  };
  onEditClick = () => {
    const content = {
      group_id: this.state.group_id,
      group_name: this.state.group_name,
      user_id: this.state.user._id,
    };
    this.props.editGroup(content);
  };
  toggle_delete = (group_id, group_name) => {
    this.setState({ group_id, group_name }, () => {
      this.setState({
        modal_delete: !this.state.modal_delete,
        modal_add_member: false,
      });
    });
  };
  onDeleteClick = () => {
    const content = {
      group_id: this.state.group_id,
      user_id: this.state.user._id,
    };
    this.props.deleteGroup(content);
    this.toggle_delete();
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    if (name === "search_user") {
      this.setState({
        [name]: value,
      });
      const searchFunction = () => {
        this.props.searchUser(this.state.search_user);
      };
      setTimeout(function () {
        searchFunction();
      }, 2000);
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { name } = this.state;
    const content = {
      name,
    };
    this.props.addGroup(content);
  };
  handleSubmitEdit = (e) => {
    e.preventDefault();
    const { group_name, group_id } = this.state;
    const content = {
      group_name,
      group_id,
      user_id: this.state.user._id,
    };
    this.props.editGroup(content);
  };
  componentDidUpdate(prevProps) {
    //close modal
    const { groups } = this.props.item;
    const groups_prev = prevProps.item.groups;
    if (!isEqual(groups, groups_prev)) {
      this.setState({ modal: false, modal_edit: false });
    } else {
      return null;
    }
    //prevent user error when token expired
    const prev_user =
      prevProps.auth && prevProps.auth.user ? prevProps.auth.user : [];
    const this_user =
      this.props.auth && this.props.auth.user ? this.props.auth.user : [];
    if (!isEqual(prev_user, this_user)) {
      this.setState({ user: this_user });
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const next_user =
      nextProps.auth && nextProps.auth.user ? nextProps.auth.user : [];
    const this_user = prevState.user;
    if (!isEqual(next_user, this_user)) {
      return {
        user: this_user,
      };
    } else return null;
  }
  askNotification() {
    Notification.requestPermission((result) => {
      // console.log("the  choice", result);
      if (result !== "granted") {
        // console.log("nope");
      } else {
        // console.log("oke");
        // displayNotif();
        configurePushSub();
      }
    });
  }
  componentDidMount() {
    const this_user =
      this.props.auth && this.props.auth.user ? this.props.auth.user : [];
    this.setState({ user: this_user }, () => {
      this.props.getGroups(this.state.user._id);
    });
  }
  render() {
    const loadingUser = this.props.auth.loading;
    const { loading, groups, user_list } = this.props.item;
    const {
      isSupportNotif,
      user,
      name,
      group_name,
      search_user,
      modal_add_member,
      group_id,
    } = this.state;
    const url_pic =
      user && user.profile_picture && user.profile_picture.url
        ? user.profile_picture.url
        : null;
    var saldo = user.quota - user.total_calls;
    var itemContent;
    var groupsContent;
    var modal;
    var modalDelete;
    var modalEdit;
    var isPicture;
    if (loadingUser) {
      itemContent = (
        <SpinnerSimpleLogo
          width={200}
          unik="loading_item_list"
          marginTop="0px"
        />
      );
    } else if (
      Object.entries(user).length === 0 &&
      user.constructor === Object
    ) {
      itemContent = (
        <SpinnerSimpleLogo
          width={200}
          unik="loading_item_list"
          marginTop="20px"
        />
      );
    } else {
      if (!url_pic) {
        isPicture = false;
      } else {
        isPicture = true;
      }
      var iconContent;
      var iconContentEdit;
      if (loading) {
        iconContent = (
          <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
        );
        iconContentEdit = (
          <SpinnerSimpleBlue width={48} unik="loading-login" marginTop="20px" />
        );
      } else {
        iconContent = (
          <button
            className="buttonSimple2"
            onClick={this.handleSubmit}
            type="submit"
          >
            Save
          </button>
        );
        iconContentEdit = (
          <button
            className="btn buttonSimple2"
            type="submit"
            onClick={this.handleSubmitEdit}
          >
            Save
          </button>
        );
      }
      groupsContent = groups.map(({ name, members, _id }, idx) => {
        const groupDetailButton = (
          <Link to={`/group/${_id}`} className="buttonSimple2">
            Explore
          </Link>
        );
        const member = members.filter(
          (member) =>
            (member?.user?._id ? member?.user?._id : member?.user) === user._id
        )[0];
        const statusUser = member && member.status ? member.status : "creator";
        var deleteButton;
        var editButton;
        var addMemberButton = null;
        var searchForm = null;
        if (statusUser === "creator") {
          deleteButton = (
            <div
              className="buttonSimpleTightNormal"
              id="delete"
              onClick={this.toggle_delete.bind(this, _id, name)}
            >
              Delete
            </div>
          );
          editButton = (
            <div
              className="buttonSimpleTightNormal"
              id="edit"
              onClick={this.toggle_edit.bind(this, _id, name)}
            >
              Rename
            </div>
          );
          addMemberButton = (
            <div
              className="buttonSimple2"
              onClick={this.toggle_add_member.bind(this, _id)}
              style={{ marginTop: "50px" }}
            >
              Add Member
            </div>
          );
        } else {
          deleteButton = null;
          editButton = null;
        }
        var members_group_id;
        if (group_id && modal_add_member) {
          const current_group = groups.filter(
            (group) => group._id === group_id
          );
          const members_group = current_group[0].members;
          members_group_id = members_group.map((member) => member.user._id);
        }

        if (modal_add_member && group_id === _id) {
          searchForm = (
            <div>
              <input
                className="form-rounded"
                type="text"
                placeholder="Search user to add"
                autocomplete="off"
                id={idx}
                name="search_user"
                value={search_user}
                onChange={this.handleChange.bind(this)}
              />
              <br />
              {user_list.map(
                ({ _id, name, full_name, profile_picture }, idx) => {
                  var statusBadge = null;
                  if (members_group_id.includes(_id)) {
                    statusBadge = (
                      <div
                        alt={_id}
                        className="badgeMember"
                        style={{ marginLeft: "5px" }}
                      >
                        <style>
                          {`.badgeMember{
                                background: url(${checklist}) no-repeat center center/ cover;
                                background-color: #c1c1c1;
                                height:15px;
                                width:15px;
                                border-radius: 50%;
                                display: inline-block;
                                align: center;
                                vertical-align: middle;
                                }`}
                        </style>
                      </div>
                    );
                  }
                  return (
                    <div
                      key={_id}
                      style={{
                        textAlign: "left",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                      onClick={this.onAddMember.bind(this, _id)}
                    >
                      <div
                        alt={_id}
                        className={`photo_${_id}`}
                        samesite="None"
                        secure="true"
                      />
                      <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                          marginLeft: "10px",
                          fontSize: "12px",
                        }}
                      >
                        {full_name && full_name !== "" && full_name !== "-"
                          ? full_name
                          : name}
                      </div>
                      <style>
                        {`.photo_${_id}{
                              background: url(${
                                profile_picture &&
                                profile_picture.url_compressed
                                  ? profile_picture.url_compressed
                                  : profile_picture &&
                                    profile_picture.url &&
                                    profile_picture.url !==
                                      "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                                    profile_picture.url !== "-" &&
                                    profile_picture.url !== "default"
                                  ? profile_picture.url
                                  : pic_static
                              }) no-repeat center center/ cover;
                              background-color: #c1c1c1;
                              height:40px;
                              width:40px;
                              border-radius: 50%;
                              border: 2px solid #2196f3;
                              display: inline-block;
                              align: center;
                              vertical-align: middle;
                }`}
                      </style>
                      {statusBadge}
                    </div>
                  );
                }
              )}
            </div>
          );
        }
        return (
          <div key={idx}>
            <div className="specialItemGroup" style={{ textAlign: "center" }}>
              <h2>{name}</h2>
              {deleteButton} {editButton}
              <br />
              {groupDetailButton}
              {addMemberButton}
              <br />
              {searchForm}
            </div>
          </div>
        );
      });

      modalDelete = this.state.modal_delete && (
        <ModalComponent
          modalSize="small"
          id="deleteModal"
          isOpen={this.state.modal_delete}
          onClose={this.toggle_delete}
        >
          <div className="box-body" style={{ textAlign: "center" }}>
            <p>Are you sure to delete this group?</p>
            <br />
            <h2>{group_name}</h2>
            <br />
            <button
              className="buttonSimpleTightNormal"
              id="delete"
              onClick={this.onDeleteClick}
            >
              Delete
            </button>
            <button
              className="buttonSimpleTightNormal"
              id="edit"
              onClick={this.toggle_delete}
            >
              Cancel
            </button>
          </div>
        </ModalComponent>
      );

      modalEdit = this.state.modal_edit && (
        <ModalComponent
          modalSize="small"
          id="deleteModal"
          isOpen={this.state.modal_edit}
          onClose={this.toggle_edit.bind(
            this,
            this.state.group_id,
            this.state.group_id
          )}
        >
          <div className="box-body" style={{ textAlign: "center" }}>
            <form>
              <input
                className="form-control"
                type="text"
                name="group_name"
                id="group_name"
                value={group_name}
                onChange={this.handleChange}
              />
              <div>{iconContentEdit}</div>
            </form>
          </div>
        </ModalComponent>
      );

      modal = this.state.modal && (
        <ModalComponent
          modalSize="small"
          id="deleteModal"
          isOpen={this.state.modal}
          onClose={this.toggle}
        >
          <div className="box-body" style={{ textAlign: "center" }}>
            Create a new group
            <form>
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={this.handleChange}
                />
                <div className="text-center">{iconContent}</div>
              </div>
            </form>
          </div>
        </ModalComponent>
      );
      itemContent = (
        <div className="carousellGroup">
          <Helmet>
            <title>{`${
              user.full_name ? user.full_name : "User"
            } | IOT MAPID`}</title>
            <meta
              name="description"
              content={user.full_name ? user.full_name : "No fullname"}
            />
          </Helmet>
          <div
            className="specialItemUser"
            style={{ marginLeft: "5vw", textAlign: "center" }}
          >
            <QuotaCounter
              value={saldo}
              nMin={0}
              nMax={saldo > 300000 ? saldo : 300000}
              isPicture={isPicture}
              url_pic={url_pic}
            />
            <h2 style={{ color: "white" }}>
              {user.full_name ? user.full_name : "No fullname"}
            </h2>
            <Link
              to={`/userpic`}
              className="buttonShadow"
              style={{ marginRight: "10px" }}
            >
              Change photo
            </Link>
            <Link
              to={`/editprofile`}
              className="buttonShadow"
              style={{ marginRight: "10px" }}
            >
              Edit profile
            </Link>
            <br />
            {isSupportNotif ? (
              <button
                className="buttonShadow"
                onClick={this.askNotification}
                style={{ marginTop: "10px" }}
              >
                Enable notification
              </button>
            ) : null}
            <div>
              <div
                className="buttonShadowSmall"
                style={{
                  padding: "10px",
                  margin: "10px 10px 10px 10px",
                  textAlign: "left",
                  width: "30%",
                  backgroundColor: "transparent",
                  color: "#ffffff",
                }}
              >
                <div style={{ display: "block", marginBottom: "15px" }}>
                  Calls
                </div>
                {user.total_calls}
              </div>

              <div
                className="buttonShadowSmall"
                style={{
                  padding: "10px",
                  margin: "10px 10px 10px 10px",
                  textAlign: "left",
                  width: "30%",
                  backgroundColor: "transparent",
                  color: "#ffffff",
                }}
              >
                <div style={{ display: "block", marginBottom: "15px" }}>
                  Saldo
                </div>
                {saldo}
              </div>
              <br />

              <a
                className="buttonShadow"
                href={`https://mapid.io/pricing`}
                rel="noopener noreferrer"
                target="_blank"
              >
                Buy API Calls
              </a>
            </div>
            <div
              className="buttonShadow"
              style={{
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={this.toggle}
            >
              New Group
            </div>
          </div>
          {groupsContent}
          {modal}
          {modalDelete}
          {modalEdit}
        </div>
      );
    }
    return <div style={{ overflowX: "hidden" }}>{itemContent}</div>;
  }
}
UserDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  addGroup: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  editGroup: PropTypes.func.isRequired,
  searchUser: PropTypes.func.isRequired,
  addMember: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  item: state.item,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  addGroup,
  getGroups,
  deleteGroup,
  editGroup,
  searchUser,
  addMember,
})(UserDashboard);
